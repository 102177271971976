import type { TypedComponentRendering } from '@innogy/jss/models';
import { getDroplinkValue } from '@innogy/jss/utils';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-angular';

import type {
  CommodityOfferAlignment,
  CommodityOfferComponentLabels,
  CommodityOfferConfig,
  CommodityOfferContent,
  CommodityOfferLabels,
  ScCommodityOffer,
} from './commodity-offers.model';

export function scToCommodityOfferContentMapper(
  rendering: TypedComponentRendering<ScCommodityOffer>
): CommodityOfferContent {
  const commodityOfferConfig: CommodityOfferConfig = {
    variant: getDroplinkValue<CommodityOfferAlignment>(
      rendering.fields,
      'Variant'
    ),
    useMinimalOfferCards: getFieldValue(
      rendering,
      'UseMinimalOfferCards',
      false
    ),
  };

  const commodityOfferComponentLabels: CommodityOfferComponentLabels = {
    componentTitle: getFieldValue(rendering, 'ComponentTitle'),
    calculationBasedOnLabel: getFieldValue(
      rendering,
      'CalculationBasedOnLabel'
    ),
    adjustCalculationLabel: getFieldValue(rendering, 'AdjustCalculationLabel'),
    offerFootNote: getFieldValue(rendering, 'OfferFootNote'),
    componentFootNote: getFieldValue(rendering, 'ComponentFootNote'),
    technicalErrorTitle: getFieldValue(rendering, 'TechnicalErrorTitle'),
    technicalErrorMessage: getFieldValue(rendering, 'TechnicalErrorMessage'),
    notEligibleErrorTitle: getFieldValue(rendering, 'NotEligibleErrorTitle'),
    notEligibleErrorMessage: getFieldValue(
      rendering,
      'NotEligibleErrorMessage'
    ),
    segmentedControlLabel: getFieldValue(rendering, 'SegmentedControlLabel'),
    customerIdLabel: getFieldValue(rendering, 'CustomerIDLabel'),
    referenceIdLabel: getFieldValue(rendering, 'ReferenceIDLabel'),
  };

  const commodityOfferLabels: CommodityOfferLabels = {
    priceDurationLabel: getFieldValue(rendering, 'PriceDurationLabel'),
    priceDurationLabelSuffix: getFieldValue(
      rendering,
      'PriceDurationLabelSuffix'
    ),
    electricityLabel: getFieldValue(rendering, 'ElectricityLabel'),
    gasLabel: getFieldValue(rendering, 'GasLabel'),
    cashbackLabel: getFieldValue(rendering, 'CashbackLabel'),
    yearlyCostsTotalWithIncentiveLabel: getFieldValue(
      rendering,
      'YearlyCostsTotalWithIncentiveLabel'
    ),
    yearlyCostsTotalWithoutIncentiveLabel: getFieldValue(
      rendering,
      'YearlyCostsTotalWithoutIncentiveLabel'
    ),
    yearlyCostsTotalMultiYearWithIncentiveLabel: getFieldValue(
      rendering,
      'YearlyCostsTotalMultiYearWithIncentiveLabel'
    ),
    yearlyCostsTotalMultiYearWithoutIncentiveLabel: getFieldValue(
      rendering,
      'YearlyCostsTotalMultiYearWithoutIncentiveLabel'
    ),
    tariffModalLinkTextLabel: getFieldValue(
      rendering,
      'TariffModalLinkTextLabel'
    ),
    tariffModalDescriptionLabel: getFieldValue(
      rendering,
      'TariffModalDescriptionLabel'
    ),
    moreInfoPriceAndConditionLinkLabel: getFieldValue(
      rendering,
      'MoreInfoPriceAndConditionLinkLabel'
    ),
    offerSubmitButtonLabel: getFieldValue(rendering, 'OfferSubmitButtonLabel'),
    offerSubmitDescriptionLabel: getFieldValue(
      rendering,
      'OfferSubmitDescriptionLabel'
    ),
    offerSubmitDescriptionTooltip: getFieldValue(
      rendering,
      'OfferSubmitDescriptionTooltip'
    ),
    memberGetMemberSharedValue: getFieldValue(
      rendering,
      'MemberGetMemberSharedValue'
    ),
    costsPerYearLabel: getFieldValue(rendering, 'CostsPerYearLabel'),
    costsPerMonthLabel: getFieldValue(rendering, 'CostsPerMonthLabel'),
    budgetbillAmountLabel: getFieldValue(rendering, 'BudgetbillAmountLabel'),
    withoutDiscountLabel: getFieldValue(rendering, 'WithoutDiscountLabel'),
    withoutDiscountTooltip: getFieldValue(rendering, 'WithoutDiscountTooltip'),
    costsPerYearWithDiscountLabel: getFieldValue(
      rendering,
      'CostsPerYearWithDiscountLabel'
    ),
    costsPerMonthWithDiscountLabel: getFieldValue(
      rendering,
      'CostsPerMonthWithDiscountLabel'
    ),
    showBudgetbillAmount: getFieldValue<boolean>(
      rendering,
      'ShowBudgetbillAmount',
      false
    ),
    showDiscountAmountInRowFormat: getFieldValue<boolean>(
      rendering,
      'ShowDiscountAmountInRowFormat',
      false
    ),
    monthlyCostsLabel: getFieldValue(rendering, 'MonthlyCostsLabel'),
    monthlyCostsLabelSuffix: getFieldValue(
      rendering,
      'MonthlyCostsLabelSuffix'
    ),
    monthlyCostsLabelDiscountInfix: getFieldValue(
      rendering,
      'MonthlyCostsLabelDiscountInfix'
    ),
    proposedBudgetBillBefore: getFieldValue(
      rendering,
      'ProposedBudgetBillBefore'
    ),
    proposedBudgetBillBeforeForDynamic: getFieldValue(
      rendering,
      'ProposedBudgetBillBeforeForDynamic'
    ),
    proposedBudgetBillBeforeTooltip: getFieldValue(
      rendering,
      'ProposedBudgetbillBeforeTooltip'
    ),
    proposedBudgetBillBeforeTooltipForDynamic: getFieldValue(
      rendering,
      'ProposedBudgetbillBeforeTooltipForDynamic'
    ),
    expectedYearlyCostLabel: getFieldValue(
      rendering,
      'ExpectedYearlyCostLabel'
    ),
    dropdownElectricityTitle: getFieldValue(
      rendering,
      'DropdownElectricityTitle'
    ),
    dropdownGasTitle: getFieldValue(rendering, 'DropdownGasTitle'),
    dropdownFixedDeliveryCostsLabel: getFieldValue(
      rendering,
      'DropdownFixedDeliveryCostsLabel'
    ),
    dropdownRedeliveryTitle: getFieldValue(
      rendering,
      'DropdownRedeliveryTitle'
    ),
    dropdownFixedRedeliveryCostsLabel: getFieldValue(
      rendering,
      'DropdownFixedRedeliveryCostsLabel'
    ),
    dropdownDynamicPricingExpectedDeliveryRateTitle: getFieldValue(
      rendering,
      'DropdownDynamicPricingExpectedDeliveryRateTitle'
    ),
  };

  return {
    ...commodityOfferConfig,
    ...commodityOfferComponentLabels,
    ...commodityOfferLabels,
  };
}
