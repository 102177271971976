import type {
  Installment,
  InvoiceForInstallment,
  FinancialItem,
  Document,
} from '@essent/financial-items';

export const minDueDateAmount = 0;
export const maxDueDateAmount = 5;

export enum InvoicePaymentStatus {
  PAID = 'paid',
  DUE = 'due',
  PAST_DUE = 'past-due',
  PROMISE_TO_PAY = 'promise-to-pay',
  NONE = '',
}

export enum TranslationKeys {
  ClosedInvoicePaid = 'ClosedInvoicePaid',
  ClosedInvoicePaidOut = 'ClosedInvoicePaidOut',
  AutomaticPaymentInvoice = 'AutomaticPaymentInvoice',
  ManualPaymentInvoice = 'ManualPaymentInvoice',
  PayTodayWarning = 'PayTodayWarning',
  PayWithinXDays = 'PayWithinXDays',
  RefundPaymentInvoice = 'RefundPaymentInvoice',
  DeferredPayment = 'DeferredPayment',
  PaymentInProgress = 'PaymentInProgress',
  PaymentArrangementOpen = 'PaymentArrangementOpen',
  PaymentArrangementCleared = 'PaymentArrangementCleared',
  PaymentArrangementDeactivated = 'PaymentArrangementDeactivated',
}

export interface InstallmentValue extends Installment {
  planId: string;
  type: 'installment';
  documents: Document[];
  linkedInvoices: InvoiceForInstallment[];
}

export type CombinedFinancialItem = InstallmentValue | FinancialItem;
