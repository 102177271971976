import type { UploadableFile, UploadedFile } from '@innogy/core/file-upload';
import type { CollectionState } from '@innogy/utils/state';
import {
  createInitialCollectionState,
  removeFromCollectionState,
  updateCollectionState,
} from '@innogy/utils/state';
import type { ActionCreator, ReducerTypes } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { addFileAction, removeFileAction } from './file-input.actions';

export interface FileState {
  files: CollectionState<UploadableFile | UploadedFile>;
}

export const createFileReducer = (
  controlIds: string[],
  ...additionalOns: ReducerTypes<
    CollectionState<UploadableFile | UploadedFile>,
    readonly ActionCreator[]
  >[]
) => {
  return createReducer(
    createInitialCollectionState<UploadableFile | UploadedFile>(),
    on(addFileAction, (state, action) => {
      if (!controlIds.includes(action.controlId)) {
        return state;
      }
      return updateCollectionState(state, action.referenceId, {
        entry: { file: action.file },
      });
    }),
    on(removeFileAction, (state, action) => {
      if (!controlIds.includes(action.controlId)) {
        return state;
      }
      return removeFromCollectionState(state, action.referenceId);
    }),
    ...additionalOns
  );
};
