import { CommonModule, DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import type { Routes, UrlSegment } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ConfigLoaderService } from '@innogy/config';
import { NotFound, ServerError } from '@innogy/core/models';
import {
  JssRouteComponent,
  jssRouteMatcherFactory,
  JssRouteResolver,
} from '@innogy/jss';
import { UtilsModule } from '@innogy/utils/deprecated';
import { PlatformService } from '@innogy/core/platform';
import { StoreModule } from '@ngrx/store';
import { DEFAULT_SCROLL_OFFSET_VERTICAL } from '@innogy/shared/core/constants';

import { NotFoundContainerComponent } from './not-found-container/not-found-container.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';

export function jssRouteMatcher(url: UrlSegment[]) {
  // DO NOT USE THE "GLOBAL_CONFIG" ANYWHERE ELSE. ITS MEANT ONLY FOR THIS SPECIFIC PART OF THE WHOLE APPLICATION.
  return jssRouteMatcherFactory(
    url,
    ConfigLoaderService.GLOBAL_CONFIG.sitecore || { apiKey: '' }
  );
}

export const routes: Routes = [
  { path: NotFound, component: NotFoundContainerComponent },
  { path: ServerError, component: ServerErrorComponent },
  {
    // matcher is effectively a catch-all route, see docs https://angular.io/api/router/UrlMatcher
    matcher: jssRouteMatcher,
    component: JssRouteComponent,
    resolve: {
      jssState: JssRouteResolver,
    },
    runGuardsAndResolvers: 'always',
  },
];

export function contentReadyEvent(
  document: Document,
  platformService: PlatformService
): () => void {
  return () => {
    if (platformService.isClient()) {
      // This event is needed for analytics (and for the cookie banner)
      document.dispatchEvent(new Event('ContentReady'));
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    /**
     * NOTE: 'anchorScrolling' in 'RouterModule' is not enabled, we handle this manually in <wl-setup-anchor-scrolling>
     **/
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, DEFAULT_SCROLL_OFFSET_VERTICAL],
    }),
    StoreModule,
    UtilsModule,
  ],
  exports: [RouterModule, ServerErrorComponent, NotFoundContainerComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: contentReadyEvent,
      deps: [DOCUMENT, PlatformService],
      multi: true,
    },
  ],
  declarations: [
    ServerErrorComponent,
    NotFoundComponent,
    NotFoundContainerComponent,
  ],
})
export class CoreRoutingModule {}
