import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { isDefined } from '@essent/core-utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { InnogyComponentRendering } from '@innogy/jss/models';
import { getIsExperienceEditorActive } from '@innogy/jss';
import { Store } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import type { Observable } from 'rxjs';
import { iif, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import {
  FormStep,
  selectFormStepShouldCollapse,
  selectIsStepReadonly,
} from '../+state';
import { finishCollapseStepAnimationAction } from '../+state/progressive-form-step';
import { ProgressiveFormBodyDirective } from '../progressive-form-body.directive';
import { ProgressiveFormSummaryDirective } from '../progressive-form-summary.directive';
import { ProgressiveFormService } from '../progressive-form.service';

@Component({
  selector:
    'wl-progressive-form-step-wrapper[progressiveFormSelectorKey][formState]',
  templateUrl: './progressive-form-step-wrapper.component.html',
})
export class ProgressiveFormStepWrapperComponent {
  @Input() rendering?: InnogyComponentRendering | any;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() formState?: FormGroupState<any> | null;
  @Input() stepNumber?: number;
  @Input() stepName?: string;
  @Input() toolName?: string;
  @Input() submitButtonText?: string;
  @Input() submitButtonAutomationId?: string;
  @Input() submitButtonIcon?: IconProp;
  @Input() disableSubmitButton?: boolean | null;
  @Input() progressiveFormSelectorKey!: string;
  @Input() showWhenFutureStep = true;
  /**
   * Deprecated: use useStepConfig and set the step config in the state using setFormConfig action (formSteps paramater)
   */
  @Input() isReadonly = false;

  //Techdebt: remove the need for useStepConfig when all progressive forms are using this instead of readonly.
  @Input() useStepConfig = false;
  @Input() step?: FormStep;
  @Input() stepKey!: string;
  @Input() hideSummary = false;
  @Input() showSubmitButton = true;
  @Input() showSeperator = true;
  @Input() showProgression = true;
  @Input() showWhenSkipped = false;

  @ContentChild(ProgressiveFormBodyDirective)
  formBody?: ProgressiveFormBodyDirective;
  @ContentChild(ProgressiveFormSummaryDirective)
  formSummary?: ProgressiveFormSummaryDirective;

  @Output() submitFormStep = new EventEmitter();

  constructor(
    private readonly store$: Store,
    private readonly progressiveFormService: ProgressiveFormService
  ) {}

  //Techdebt: remove this when all forms use config and create a proper Viewmodel instead of all these seperate observable methods
  isFormCollapsed$(): Observable<boolean> {
    if (this.useStepConfig) {
      return this.store$
        .select(selectFormStepShouldCollapse(this.stepKey))
        .pipe(filter(isDefined));
    }

    return this.isCurrentFormStep$.pipe(
      map((current) => !current || this.isReadonly)
    );
  }

  //Techdebt: remove this when all forms use config and create a proper Viewmodel instead of all these seperate observable methods
  isSummaryCollapsed$(): Observable<boolean> {
    if (this.useStepConfig) {
      return this.store$
        .select(selectFormStepShouldCollapse(this.stepKey))
        .pipe(
          filter(isDefined),
          map((step) => !step)
        );
    }

    return this.isCurrentFormStep$.pipe(
      map((current) => current && !this.isReadonly)
    );
  }
  //Techdebt: remove this when all forms use config and create a proper Viewmodel instead of all these seperate observable methods
  isReadonly$(): Observable<boolean> {
    if (this.useStepConfig) {
      return this.store$
        .select(selectIsStepReadonly(this.stepKey))
        .pipe(filter(isDefined));
    }

    return of(this.isReadonly);
  }

  get isInExperienceEditor$() {
    return this.store$.select(getIsExperienceEditorActive);
  }

  get isDisabled(): boolean {
    return this.disableSubmitButton || !!this.formState?.isDisabled;
  }

  get isValidAndSubmitted() {
    return this.formState?.isValid && this.formState?.isSubmitted;
  }

  get currentStepIndex$() {
    return this.progressiveFormService.currentStepPresentableNumber$(
      this.progressiveFormSelectorKey,
      this.formState?.id
    );
  }

  get totalSteps$() {
    return this.progressiveFormService.totalSteps$(
      this.progressiveFormSelectorKey
    );
  }

  get isSkipped$() {
    return this.progressiveFormService.isSkipped$(
      this.progressiveFormSelectorKey,
      this.formState?.id
    );
  }

  get isCurrentFormStep$(): Observable<boolean> {
    const isCurrentFormStep$ = this.progressiveFormService
      ?.currentActiveFormStepId$(this.progressiveFormSelectorKey)
      .pipe(map((formId) => this.formState?.id === formId));

    return this.isInExperienceEditor$.pipe(
      mergeMap((isInXp) => iif(() => isInXp, of(true), isCurrentFormStep$))
    );
  }

  get isCurrentEditedFormStep$(): Observable<boolean> {
    return this.progressiveFormService.isEditingFormStep$(
      this.progressiveFormSelectorKey,
      this.formState?.id
    );
  }

  get canBeEdited$(): Observable<boolean> | undefined {
    return this.progressiveFormService
      .isEditingPreviouslySubmittedForm$(this.progressiveFormSelectorKey)
      .pipe(
        map((isEditing = false) => !isEditing && !!this.formState?.isSubmitted)
      );
  }

  get isFutureFormStep$() {
    if (!this.formState) {
      return false;
    }
    return this.progressiveFormService?.isFutureStep$(
      this.progressiveFormSelectorKey,
      this.formState.id
    );
  }

  setEditingFormStep() {
    if (!this.formState) {
      return;
    }
    this.store$.dispatch(
      this.progressiveFormService.openFormStep({
        formId: this.progressiveFormSelectorKey,
        stepId: this.formState.id,
        stepFormState: this.formState,
        isEditing: true,
      })
    );
  }

  submitForm() {
    if (!this.formState) {
      return;
    }
    this.store$.dispatch(
      this.progressiveFormService.submitFormStep({
        formId: this.progressiveFormSelectorKey,
        formState: this.formState,
      })
    );
    this.submitFormStep.emit();
  }

  cancelEditingFormStep() {
    if (!this.formState) {
      return;
    }
    this.store$.dispatch(
      this.progressiveFormService.cancelEditingFormStep({
        formId: this.progressiveFormSelectorKey,
        stepId: this.formState.id,
      })
    );
  }

  formCollapseAnimationFinished(collapsed: boolean) {
    if (!this.formState) {
      return;
    }
    this.store$.dispatch(
      finishCollapseStepAnimationAction({
        formId: this.progressiveFormSelectorKey,
        stepId: this.formState?.id,
        collapsed,
      })
    );
  }
}
