import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideBootstrapEffects } from '@innogy/utils/deprecated';
import { StoreModule } from '@ngrx/store';

import {
  contractingReducer,
  contractingSelectorKey,
  QuotationReactionEffects,
} from './+state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(contractingSelectorKey, contractingReducer),
  ],
  providers: [provideBootstrapEffects([QuotationReactionEffects])],
})
export class ContractingStoreModule {}
