import { Component, Input } from '@angular/core';
import type { JssNavItem } from '@innogy/jss/models';
import { Setting } from '@innogy/settings';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: [
    './top-navigation.component.essent.scss',
    './top-navigation.component.ed.scss',
  ],
})
export class TopNavigationComponent {
  @Input()
  navItems?: JssNavItem[];

  @Input()
  openNavigation?: Setting<LinkField>;

  @Input()
  serviceNavigationTitle?: string;

  @Input()
  serviceNavigationLink?: Setting<LinkField>;

  @Input()
  serviceNavigationActive?: boolean;

  @Input()
  isLoggedIn?: boolean;

  @Input()
  loginUrl?: string;

  @Input()
  loginUrlLabel?: string;
}
