import { Inject, Injectable } from '@angular/core';
import { createOnlineAccount } from '@essent/customer';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import { ofFormSubmitAction } from '@innogy/utils/deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import {
  recaptchaResolved,
  submitCreateOnlineAccount,
} from './create-online-account.actions';
import { CREATE_ONLINE_ACCOUNT_FORM_ID } from './create-online-account.reducer';
import { getCreateOnlineAccountFormState } from './create-online-account.selectors';

@Injectable()
export class CreateOnlineAccountEffects {
  private readonly formState$ = this.store$.pipe(
    select(getCreateOnlineAccountFormState)
  );

  public submitCreateOnlineAccount = createEffect(() =>
    this.actions$.pipe(
      ofType(submitCreateOnlineAccount),
      concatLatestFrom(() => [this.formState$]),
      filter(([_, formState]) => formState.isValid),
      map(([{ recaptchaResponse }, formState]) =>
        createOnlineAccount({
          payload: {
            body: {
              email: formState.value.email,
              ibanKey: formState.value.ibanKey,
              accountId: formState.value.accountId,
              dateOfBirth: formState.value.dateOfBirth,
            },
            recaptchaToken: recaptchaResponse,
          },
        })
      )
    )
  );

  public handleRecaptchaResolve = createEffect(() =>
    this.actions$.pipe(
      ofType(recaptchaResolved),
      filter(({ formId }) => formId === CREATE_ONLINE_ACCOUNT_FORM_ID),
      map(({ recaptchaResponse }) =>
        submitCreateOnlineAccount({ recaptchaResponse })
      )
    )
  );

  public handleCreateOnlineAccountFormSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(CREATE_ONLINE_ACCOUNT_FORM_ID),
      filter(() => !this.config.recaptcha.enabled),
      map(() => submitCreateOnlineAccount({}))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}
}
