import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { OrderConfirmationFormValues } from '@innogy/become-a-customer/state';
import type { Field } from '@sitecore-jss/sitecore-jss-angular';

import { OrderFulfillmentStepComponent } from '../order-fulfillment-step.component';
import { ORDER_CONFIRMATION_STEP_INDEX } from '../constants';

@Component({
  selector: 'wl-order-fulfillment-confirmation[progressiveFormState]',
  templateUrl: './order-fulfillment-confirmation.component.html',
  styleUrls: [],
})
export class OrderFulfillmentConfirmationComponent
  extends OrderFulfillmentStepComponent<OrderConfirmationFormValues>
  implements OnInit
{
  @Input() offer?: Offer | null;
  @Output() openAOMModal = new EventEmitter<Offer>();

  ngOnInit(): void {
    this.progressiveFormCardContent = {
      title: this.fields.CheckCardTitle?.value,
      subtitle: this.fields.CheckCardSubtitle?.value,
      submitButtonText: this.fields.CheckCardSubmitButton?.value,
      stepNumber: ORDER_CONFIRMATION_STEP_INDEX,
    };
  }

  get disclaimers(): DisclaimerLink[] {
    const disclaimers: DisclaimerLink[] = [
      {
        key: this.fields?.GeneralTermsAndConditions,
        href: this.offer?.generalTermsAndConditionsUrl || '',
        automationId:
          'order-fulfillment-check-condidions-general-terms-and-conditions',
      },
      {
        key: this.fields?.QualityCriteria,
        href: this.offer?.qualityCriteriaUrl || '',
        automationId: 'order-fulfillment-check-conditions-quality-criteria',
      },
      {
        key: this.fields?.ProductConditions,
        href: this.offer?.productConditionsUrl || '',
        automationId: 'order-fulfillment-check-conditions-product',
      },
    ];
    if (this.offer?.actionConditionsUrl) {
      disclaimers.push({
        key: this.fields?.CampaignConditions,
        href: this.offer?.actionConditionsUrl || '',
        automationId: 'order-fulfillment-check-conditions-campaign',
      });
    }

    return disclaimers;
  }

  openAOMModalClick(e: Event) {
    if (!this.offer) {
      throw new Error('No offer found to open AOM Modal with.');
    }
    e.preventDefault();
    this.openAOMModal.emit(this.offer);
  }
}

interface DisclaimerLink {
  key: Field;
  href: string;
  automationId: string;
}
