<div class="question-scroll-anchor" #currentQuestionAnchor></div>
<section class="pt-5 pb-5" [class.bg-question]="!(step % 2)">
  <div class="container text-center">
    <div class="chevron-down d-flex flex-column align-items-center">
      <fa-icon
        *ngIf="step > 1"
        icon="chevron-down"
        size="2x"
        class="d-flex align-items-center justify-content-center"
        [class.bg-question]="!(step % 2)"
        [class.bg-white]="step % 2"
      ></fa-icon>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="d-none d-sm-block pt-5 pb-5"><!-- Spacer --></div>
        <p class="h3">{{ title }}</p>
        <div
          class="row justify-content-center"
          *ngIf="!isFinalStep && !showOptionsBelowDescription"
        >
          <wl-how-to-questionnaire-option
            class="col-12 custom-col-xs-6 my-1"
            *ngFor="let option of options; let i = index"
            [rendering]="option"
            (selected)="onOptionSelected($event, i)"
            [active]="selectedOption === i"
          ></wl-how-to-questionnaire-option>
        </div>
        <wl-rich-text-wrapper
          class="text-start"
          [field]="description"
          [rendering]="rendering"
          ><div></div
        ></wl-rich-text-wrapper>
        <div
          class="row justify-content-center mt-2"
          *ngIf="!isFinalStep && showOptionsBelowDescription"
        >
          <wl-how-to-questionnaire-option
            class="col-12 custom-col-xs-6 my-1"
            *ngFor="let option of options; let i = index"
            [rendering]="option"
            (selected)="onOptionSelected($event, i)"
            [active]="selectedOption === i"
          ></wl-how-to-questionnaire-option>
        </div>
        <div class="mt-5 mb-5" *ngIf="ctaLink && (ctaTxt || ctaLink.text)">
          <a
            class="btn btn-primary"
            *wlGenericLink="ctaLink"
            wlTrackLink
            [trackLinkInfo]="{
              rendering: rendering,
              field: ctaTxt || ctaLink.text,
              linkurl: ctaLink.href,
              eventType: 'button-click',
              type: 'button'
            }"
            wlTrackImpression="button-impression"
            trackImpressionMode="button"
            [trackImpressionName]="ctaTxt || ctaLink.text"
            [trackImpressionLinkurl]="ctaLink.href"
            [trackImpressionInfo]="rendering"
          >
            {{ ctaTxt || ctaLink.text }}
          </a>
        </div>
        <div *ngIf="step > 1">
          <button
            class="btn btn-link text-primary"
            (click)="backClicked.emit(isFinalStep)"
          >
            {{
              isFinalStep ? backToBeginningButtonText : backToPreviousButtonText
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-none d-sm-block pt-3 pb-3"><!-- Spacer --></div>
</section>

<div class="question-scroll-anchor" #nextQuestionAnchor></div>
<wl-how-to-questionnaire-question
  *ngIf="nextQuestion"
  [rendering]="nextQuestion"
  [containerRendering]="containerRendering"
  [questions]="questions"
  [backToPreviousButtonText]="backToPreviousButtonText"
  [backToBeginningButtonText]="backToBeginningButtonText"
  [step]="step + 1"
  (backClicked)="onBackClicked($event)"
></wl-how-to-questionnaire-question>
