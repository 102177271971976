import type { InnogyComponentRendering } from '@innogy/jss/models';
import type { ProgressiveFormStateImplementor } from '@innogy/progressive-ngrx-forms';
import {
  activateProgressiveFormStepOnSubmissionOf,
  createProgressiveFormGroupState,
  createProgressiveNgrxFormReducerWithFormStateUpdate,
} from '@innogy/progressive-ngrx-forms';
import type { Action } from '@ngrx/store';
import { updateGroup, validate } from 'ngrx-forms';
import { requiredTrue } from 'ngrx-forms/validation';

import type { OrderState } from '../order.state';
import { ORDER_PAYMENT_FORM_ID } from '../payment';

export const ORDER_CONFIRMATION_FORM_ID: keyof OrderState =
  'orderConfirmationForm';
export const conditionsAcceptedFormControlID = `${ORDER_CONFIRMATION_FORM_ID}.conditionsAccepted`;
export const actionMailAcceptedFormControlID = `${ORDER_CONFIRMATION_FORM_ID}.actionMailAccepted`;
export const informationMailAcceptedFormControlID = `${ORDER_CONFIRMATION_FORM_ID}.informationMailAccepted`;

export interface OrderConfirmationFormValues {
  conditionsAccepted: boolean;
  actionMailAccepted?: boolean;
  informationMailAccepted?: boolean;
}

export interface OrderConfirmationState
  extends ProgressiveFormStateImplementor<OrderConfirmationFormValues> {
  rendering?: InnogyComponentRendering | any;
}

export const initialOrderConfirmationProgressiveFormState =
  createProgressiveFormGroupState<OrderConfirmationFormValues>(
    ORDER_CONFIRMATION_FORM_ID,
    {
      conditionsAccepted: false,
      actionMailAccepted: undefined,
      informationMailAccepted: undefined,
    }
  );

export const initialOrderConfirmationState: OrderConfirmationState = {
  progressiveForm: initialOrderConfirmationProgressiveFormState,
  rendering: undefined,
};

const validateForm = (state: OrderConfirmationState) => {
  return updateGroup<OrderConfirmationFormValues>({
    conditionsAccepted: validate(requiredTrue),
  })(state.progressiveForm.formState);
};

const _reducer = createProgressiveNgrxFormReducerWithFormStateUpdate(
  initialOrderConfirmationState,
  validateForm,
  activateProgressiveFormStepOnSubmissionOf(ORDER_PAYMENT_FORM_ID)
);

export function orderConfirmationReducer(
  state: OrderConfirmationState = initialOrderConfirmationState,
  action: Action
): OrderConfirmationState {
  return _reducer(state, action);
}
