import { getQueryParameterHistory } from '@innogy/jss';
import { selectUrl } from '@innogy/shared/state';
import { createSelector } from '@ngrx/store';

export const selectChannelRecognitionParams = createSelector(
  selectUrl,
  getQueryParameterHistory,
  (route, params) => {
    return {
      route,
      params,
    };
  }
);
