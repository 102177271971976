import { NgModule } from '@angular/core';
import { DefaultComponentModule } from '@innogy/core/modules/components';

import { LoadMaintenanceModeComponent } from './load-maintenance-mode/load-maintenance-mode.component';
import { MaintenanceModeComponent } from './maintenance-mode/maintenance-mode.component';

@NgModule({
  imports: [DefaultComponentModule],
  declarations: [LoadMaintenanceModeComponent, MaintenanceModeComponent],
  exports: [LoadMaintenanceModeComponent, MaintenanceModeComponent],
})
export class MaintenanceModeModule {}
