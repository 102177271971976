<button
  (click)="toggleOffCanvas()"
  class="navbar-toggler off-canvas-button off-canvas-button--open"
  type="button"
  aria-label="Menu openen"
>
  <span class="off-canvas-button-text" wlCSCapture>{{ openButtonTitle }} </span>
  <fa-icon
    class="off-canvas-button-icon"
    size="lg"
    [icon]="icon | faIcon"
  ></fa-icon>
</button>

<div
  [ngClass]="[
    direction | collapseDirection,
    backgroundColor ? 'bg-' + backgroundColor : ''
  ]"
  [class.show]="open"
  class="off-canvas-collapse"
>
  <!-- configure grid layout and positioning in css -->
  <div
    class="off-canvas-header-grid"
    [class.off-canvas-header-grid__subitems]="showBackButton"
  >
    <div class="off-canvas-header-grid--back">
      <button
        *ngIf="showBackButton"
        (click)="back.emit()"
        type="button"
        class="navbar-toggler off-canvas-button off-canvas-button--back"
        wlAutomationId="back"
      >
        <fa-icon icon="angle-left" size="lg"></fa-icon>
        <span class="header-button__label">{{ 'Back' | translate }}</span>
      </button>
    </div>

    <div class="off-canvas-header-grid__toggle">
      <button
        (click)="toggleOffCanvas()"
        type="button"
        class="navbar-toggler off-canvas-button off-canvas-button--close"
        wlAutomationId="close"
        aria-label="Menu sluiten"
      >
        <span class="header-button__label">{{ 'Close' | translate }}</span>
        <fa-icon icon="xmark" size="lg"></fa-icon>
      </button>
    </div>

    <div class="off-canvas-header-grid__title">
      <p *ngIf="showTitleCollapsed" class="h4 off-canvas__title" wlCSCapture>
        {{ title }}
      </p>
    </div>

    <div class="off-canvas-header-grid__subtitle">
      <ng-content select="subtitle"></ng-content>
    </div>
  </div>

  <ng-content></ng-content>
</div>

<div
  (click)="toggleOffCanvas()"
  [class.show]="open"
  class="d-block d-lg-none backdrop"
></div>
