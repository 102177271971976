import type { ModuleWithProviders } from '@angular/core';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CoreLoaderModule } from '@innogy/core/loader';
import { IS_SITECORE_SERVER } from '@innogy/jss/models';
import * as Sentry from '@sentry/angular';
import type { NgProgressConfig } from 'ngx-progressbar';
import { NG_PROGRESS_CONFIG } from 'ngx-progressbar';

export interface ClientConfig {
  loaderColor: string;
}

@NgModule({
  imports: [CoreLoaderModule],
  exports: [CoreLoaderModule],
})
export class CoreModulesCommonClientModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModulesCommonClientModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModulesCommonClientModule is imported more than once.'
      );
    }
  }

  static forRoot(
    config: ClientConfig
  ): ModuleWithProviders<CoreModulesCommonClientModule> {
    return {
      ngModule: CoreModulesCommonClientModule,
      providers: [
        {
          provide: NG_PROGRESS_CONFIG,
          useValue: {
            spinner: false,
            thick: true,
            color: config.loaderColor,
          } as NgProgressConfig,
        },
        {
          provide: IS_SITECORE_SERVER,
          useValue: false,
        },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler(),
        },
      ],
    };
  }
}
