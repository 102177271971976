import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import type {
  ComponentRendering,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

import {
  DEFAULT_ERROR_FIELD,
  getFunctionalErrorMessage,
} from '../error-handling/functional-error-handling';

/**
 * Transforms an HttpErrorResponse into a TextField containing the error message corresponding to the error code
 */
@Pipe({
  name: 'functionalError',
})
export class FunctionalErrorPipe implements PipeTransform {
  /**
   *
   * @param error HttpErrorResponse received
   * @param rendering Component rendering for current component
   * @param defaultKey Optional, the default error message field key, defaults to "UnknownError"
   */
  transform(
    error?: HttpErrorResponse,
    rendering?: ComponentRendering,
    defaultKey = DEFAULT_ERROR_FIELD
  ): TextField | undefined {
    return getFunctionalErrorMessage(error, rendering, defaultKey);
  }
}
