import { CalculateModalComponent } from '@innogy/become-a-customer/calculate';
import {
  RevertFormState,
  SaveFormState,
} from '@innogy/become-a-customer/shared';
import type { TypedComponentRendering } from '@innogy/jss/models';
import type { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import type { Store } from '@ngrx/store';

export const openAdjustCalculationModal = (
  modalService: NgbModal,
  store: Store,
  rendering?: TypedComponentRendering<any>
) => {
  const modalRef = modalService.open(CalculateModalComponent, {
    centered: true,
    keyboard: true,
  });
  modalRef.componentInstance.rendering = rendering;
  modalRef.result.catch((result) => {
    if (result === 0 || result === 'dismiss') {
      store.dispatch(RevertFormState());
    }
  });

  store.dispatch(SaveFormState());
};
