import type { OnInit } from '@angular/core';
import { Component, ElementRef, Input } from '@angular/core';
import { PlatformService } from '@innogy/core/platform';

import { ContentPattern } from '../../models';

@Component({
  selector: 'wl-partial-brand-pattern',
  templateUrl: './brand-pattern.component.html',
  styleUrls: ['./brand-pattern.ed.scss'],
})
export class BrandPatternComponent implements OnInit {
  @Input() pattern: ContentPattern = 'white';

  constructor(
    private readonly elementRef: ElementRef,
    private readonly platformService: PlatformService
  ) {}

  primaryColor?: string;
  highlightColor?: string;

  primaryOpacity = 1;
  highlightOpacity = 1;

  ngOnInit() {
    if (this.platformService.isClient()) {
      this.setColorScheme(this.pattern);
    }
  }

  _getPropValue(propertyName: string) {
    return getComputedStyle(this.elementRef.nativeElement).getPropertyValue(
      propertyName
    );
  }

  // We don't want to include this function in coverage reports as it
  // is highly specific and non-functional.
  /* istanbul ignore next */
  setColorScheme(componentPattern: ContentPattern) {
    const whiteColor = this._getPropValue('--pattern-white-color');
    const brandLightColor = this._getPropValue('--pattern-brand-light-color');
    const brandDarkColor = this._getPropValue('--pattern-brand-dark-color');
    const brandAltColor = this._getPropValue('--pattern-brand-alt-color');
    const translucentOpacity = parseFloat(
      this._getPropValue('--pattern-translucent-opacity')
    );

    switch (componentPattern) {
      case 'brand': {
        this.primaryColor = brandDarkColor;
        this.highlightColor = brandDarkColor;
        break;
      }
      case 'brand-alt': {
        this.primaryColor = brandAltColor;
        this.highlightColor = brandAltColor;
        break;
      }
      case 'translucent': {
        this.primaryColor = whiteColor;
        this.highlightColor = whiteColor;
        this.primaryOpacity = translucentOpacity;
        this.highlightOpacity = 0;
        break;
      }
      case 'mixed-white-brand': {
        this.primaryColor = whiteColor;
        this.highlightColor = brandLightColor;
        break;
      }
      case 'mixed-white-brand-alt': {
        this.primaryColor = whiteColor;
        this.highlightColor = brandAltColor;
        break;
      }
      case 'mixed-brand-alt-brand': {
        this.primaryColor = brandAltColor;
        this.highlightColor = brandLightColor;
        break;
      }
      case 'white':
      default: {
        this.primaryColor = whiteColor;
        this.highlightColor = whiteColor;
        break;
      }
    }
  }
}
