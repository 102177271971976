import type { Document, FinancialItem } from '@essent/financial-items';
import {
  DocumentType,
  FinancialItemStatus,
  PaymentMethod,
} from '@essent/financial-items';
import { differenceInDays, isPast } from 'date-fns';

import type { InstallmentValue, CombinedFinancialItem } from './invoice.model';
import { maxDueDateAmount, minDueDateAmount } from './invoice.model';

export function isInstallmentValue(
  item: CombinedFinancialItem
): item is InstallmentValue {
  return (item as InstallmentValue).planId != null;
}

export function canDownloadInvoice(
  invoice?: FinancialItem,
  documentId?: string
) {
  if (invoice != null) {
    return documentId != null;
  }
  return false;
}

export const hasDocuments = (
  invoice?: FinancialItem
): invoice is FinancialItem => invoice != null && invoice.documents != null;

export function getInvoiceAttachment(
  invoice?: FinancialItem
): Document | undefined {
  if (!hasDocuments(invoice)) {
    return undefined;
  }

  return invoice?.documents.filter(
    (obj) => obj.type === DocumentType.INVOICE
  )[0];
}

export function getOtherAttachments(
  invoice?: FinancialItem
): Document[] | undefined {
  if (!hasDocuments(invoice)) {
    return undefined;
  }

  return invoice?.documents.filter((obj) => obj.type !== DocumentType.INVOICE);
}

export const isFinancialItem = (
  item: CombinedFinancialItem
): item is FinancialItem => (item as FinancialItem).creationDate != null;

export const isCleared = (item: CombinedFinancialItem) =>
  item.status === FinancialItemStatus.CLEARED;

export const isPromiseToPay = (item: CombinedFinancialItem) =>
  item.promiseToPay === true;

export const isInDeferral = (item: CombinedFinancialItem) => {
  if (item && isFinancialItem(item)) {
    const deferralDate = item.deferralDate;

    if (deferralDate && !isPast(new Date(deferralDate))) {
      return deferralDate;
    }
  }

  return false;
};

export const daysUntilPayment = (item: CombinedFinancialItem) =>
  differenceInDays(new Date(item.dueDate), new Date());

export const isPastDue = (item: CombinedFinancialItem) =>
  daysUntilPayment(item) < minDueDateAmount;

export const isOnDue = (item: CombinedFinancialItem) =>
  daysUntilPayment(item) >= minDueDateAmount &&
  daysUntilPayment(item) <= maxDueDateAmount &&
  item.paymentMethod === PaymentMethod.MANUAL_TRANSFER;

export const isCreditInvoice = (item: CombinedFinancialItem) =>
  !isInstallmentValue(item) && item.totalAmount < 0;
