import { Component, Input } from '@angular/core';
import { EnergyType } from '@essent/new-customer';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { InnogyComponentRendering } from '@innogy/jss/models';

@Component({
  selector: 'wl-offer-breakdown',
  templateUrl: './offer-breakdown.component.html',
})
export class OfferBreakdownComponent {
  @Input()
  public offer?: Offer;
  @Input()
  public rendering?: InnogyComponentRendering | any;
  @Input()
  public hideIncentiveWhenUavailable = false;

  public energyType = EnergyType;
}
