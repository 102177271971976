import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import {
  confirmStoreProductSelectionAction,
  onStoreFunnelNavigationAction,
  selectStoreFunnelSettings,
  selectStoreProductAction,
} from '../store-funnel';

@Injectable()
export class StoreProductOverviewEffects {
  funnelSettings$ = this.store$.select(selectStoreFunnelSettings);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store
  ) {}

  public readonly navigateToOrderPageOnSelectProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectStoreProductAction, confirmStoreProductSelectionAction),
      concatLatestFrom(() => this.funnelSettings$),
      map(([_, { orderPage }]) =>
        onStoreFunnelNavigationAction({ page: orderPage })
      )
    )
  );
}
