import type { IconName } from '@fortawesome/free-brands-svg-icons';
import type {
  AddressFormRendering,
  NameFormFields,
} from '@innogy/common-ui/forms';
import type {
  InnogyComponentRendering,
  ScCheckboxField,
  ScList,
  ScSingleLineSetting,
} from '@innogy/jss/models';
import type {
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

import type {
  ScBaseFormInputItem,
  ScBaseMultiFieldFormInput,
  ScBaseSingleFieldFormInput,
  ScInputTrackingConfig,
  ScMultiFieldFormInput,
  ScTrackedBaseFormInput,
} from './generic-form-base-input.model';

export interface ScFormInputType {
  value:
    | 'dropdown'
    | 'radio'
    | 'text'
    | 'address'
    | 'checkbox'
    | 'checkboxSet'
    | 'email'
    | 'name'
    | 'payment'
    | 'phone'
    | 'widget';
}

export const fixedApiFieldKeyFields = [
  'postalCode',
  'communicationNumber',
  'houseNumberAddition',
  'street',
  'city',
  'initials',
  'insertion',
  'lastName',
] as const;

export type fixedApiFieldKeyValues =
  | 'postcode'
  | 'huisnummer'
  | 'huisnummerExt'
  | 'straat'
  | 'plaats'
  | 'voorletter'
  | 'tussenvoegsel'
  | 'achternaam';

export type FixedApiFieldKeyFields = (typeof fixedApiFieldKeyFields)[number];

export type ScFormInput =
  | ScDropDownFormInput
  | ScRadioFormInput
  | ScAddressFormInput
  | ScCheckboxFormInput
  | ScCheckboxSetFormInput
  | ScTextFormInput
  | ScEmailFormInput
  | ScNameFormInput
  | ScPhoneFormInput
  | ScPaymentFormInput;

// --------------------------------------------  Address --------------------------------------------
export interface ScAddressFormInput
  extends ScBaseMultiFieldFormInput,
    AddressFormRendering {
  PostalCodeExclusion: TextField;
  PostalCodeExcludedError: TextField;
}
// --------------------------------------------  Checkbox --------------------------------------------
export interface ScCheckboxFormInput extends ScTrackedBaseFormInput {
  Label: TextField;
  RichTextLabel: RichTextField;
  SummaryValueAffirmation: TextField;
  SummaryValueRejection: TextField;
}
// -------------------------------------------- Dropdown --------------------------------------------
export interface ScDropDownFormInputItem extends ScBaseFormInputItem {
  Icon: ScSingleLineSetting<IconName>;
}
export interface ScDropDownFormInput
  extends ScTrackedBaseFormInput,
    ScMultiFieldFormInput {
  Icon: ScSingleLineSetting<IconName>;
  Items: ScList<{ fields: ScDropDownFormInputItem }>;
}
// -------------------------------------------- Email --------------------------------------------
export interface ScEmailFormInput
  extends ScBaseSingleFieldFormInput,
    ScInputTrackingConfig {
  IsValidation: ScCheckboxField;
  ShouldValidateInput: ScList<{ fields: ScEmailFormInput }>;
  IsEmailError: TextField;
  IsEqualError: TextField;
}
// -------------------------------------------- Name --------------------------------------------
export interface ScNameFormInput
  extends ScBaseMultiFieldFormInput,
    NameFormFields {}
// -------------------------------------------- Payment --------------------------------------------
export interface ScPaymentFormInput
  extends ScBaseSingleFieldFormInput,
    ScInputTrackingConfig {
  IsIBANError: TextField;
}
// -------------------------------------------- Phone --------------------------------------------
export interface ScPhoneFormInput
  extends ScBaseSingleFieldFormInput,
    ScInputTrackingConfig {
  IsPhoneError: TextField;
}
// -------------------------------------------- Radio --------------------------------------------
export interface ScRadioFormInputItem extends ScBaseFormInputItem {
  Tooltip: TextField;
  TrackingLabel: TextField;
  Disabled: ScCheckboxField;
  SummaryLabel: TextField;
}

export interface ScRadioFormInput
  extends ScTrackedBaseFormInput,
    ScMultiFieldFormInput {
  Items: ScList<Item & { fields: ScRadioFormInputItem }>;
  Inline: ScCheckboxField;
}

// -------------------------------------------- Widget --------------------------------------------
export type WidgetRendering<TFields extends Record<string, unknown> = {}> =
  InnogyComponentRendering & {
    fields: TFields & {
      ComponentDefinition: TextField;
    };
  };

export interface ScWidgetInput {
  Type: ScFormInputType;
  Widget: WidgetRendering;
}

// -------------------------------------------- Text --------------------------------------------
export type ScTextFormInput = ScTrackedBaseFormInput;

// -------------------------------------------- checkboxSet --------------------------------------------
export interface ScCheckboxSetInputItem extends ScBaseFormInputItem {
  Tooltip: TextField;
  TrackingLabel: TextField;
}

export interface ScCheckboxSetFormInput
  extends ScTrackedBaseFormInput,
    ScMultiFieldFormInput {
  Items: ScList<Item & { fields: ScCheckboxSetInputItem }>;
  Name: TextField;
}
