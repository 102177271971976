<ng-container
  *ngTemplateOutlet="role === 'link' ? linkTemplate : buttonTemplate"
></ng-container>

<ng-template #buttonTemplate>
  <button
    (click)="onClick($event)"
    [type]="type"
    [disabled]="isDisabled"
    [ngClass]="computedStyle"
    [class.block]="block"
    [class.active]="active"
    [class.disabled]="disabled"
    [class.loading]="loading"
    [class.icon-only]="false"
    [class.has-icon]="false"
    [class.btn-sm]="size === 'small'"
    [class.button--full-width]="fullWidth"
    [attr.aria-label]="ariaLabel || ariaLabelFallback"
    [attr.role]="role"
    #buttonElement
  >
    <ng-container *ngTemplateOutlet="contentSlot"></ng-container>
  </button>
</ng-template>
<ng-template #linkTemplate>
  <a
    *uiLink="{ value: { href: href, target: target, url: href } }"
    (click)="onClick($event)"
    [target]="target"
    [type]="type"
    [attr.disabled]="isDisabled"
    [attr.anchor]="anchor"
    [ngClass]="computedStyle"
    [class.block]="block"
    [class.active]="active"
    [class.disabled]="disabled"
    [class.loading]="loading"
    [class.icon-only]="false"
    [class.has-icon]="false"
    [class.btn-sm]="size === 'small'"
    [class.button--full-width]="fullWidth"
    [attr.aria-label]="ariaLabel || ariaLabelFallback"
    [attr.role]="role"
  >
    <ng-container *ngTemplateOutlet="contentSlot"></ng-container>
  </a>
</ng-template>

<ng-template #loadingTemplate>
  <fa-icon icon="spinner-third" [spin]="true"></fa-icon>
</ng-template>

<ng-template #contentSlot>
  <ng-container *ngIf="!loading; else loadingTemplate">
    <ng-content></ng-content>
    <ng-content select="fa-icon"></ng-content>
  </ng-container>
</ng-template>
