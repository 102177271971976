<section class="offers">
  <wl-commodity-offer-header
    class="header"
    [headerTitle]="content?.componentTitle"
    alignment="vertical"
  />
  <wl-commodity-offer-calculation-based-on
    class="consumption"
    variant="narrow"
    [label]="content?.calculationBasedOnLabel"
    [address]="supplyAddress"
    [consumptions]="consumptionValues"
    [electricityLabel]="'ElectricityUsageUnit' | translate"
    [gasLabel]="'GasUsageUnit' | translate"
    [adjustCalculationLabel]="content?.adjustCalculationLabel"
    (adjustCalculation)="adjustCalculation.emit()"
  />
  <div class="segmented-control">
    <span class="h6 d-block mb-1">{{ content?.segmentedControlLabel }}</span>
    <wl-filter
      *ngIf="segments && segments.length > 0; else segmentedControlPlaceholder"
      [entries]="segments"
      [selected]="initiallySelectedSegment"
      (entrySelected)="showOffer($event)"
      [styleIsStretched]="true"
    />
  </div>
  <div class="offer-card">
    <ng-container [ngSwitch]="brand">
      <ng-container *ngSwitchCase="'essent'">
        <essent-consumer-acquisition-commodity-offer
          *ngIf="shownOffer; else essentCardPlaceholder"
          [offer]="shownOffer"
          [content]="content"
          variant="card"
          [showMemberGetMember]="showMemberGetMember"
          (selectOffer)="selectOffer.emit(shownOffer)"
          (openMoreInfo)="openMoreInfo.emit(shownOffer)"
          (viewTariffs)="viewTariffs.emit(shownOffer)"
        />
      </ng-container>
      <ng-container *ngSwitchCase="'ed'">
        <ed-consumer-acquisition-commodity-offer
          *ngIf="shownOffer; else edCardPlaceholder"
          [offer]="shownOffer"
          [content]="content"
          variant="card"
          [showMemberGetMember]="showMemberGetMember"
          (selectOffer)="selectOffer.emit(shownOffer)"
          (openMoreInfo)="openMoreInfo.emit(shownOffer)"
          (viewTariffModal)="viewTariffs.emit(shownOffer)"
        />
      </ng-container>
    </ng-container>
  </div>
  <footer>
    <wl-rich-text-wrapper [field]="{ value: content?.componentFootNote }">
      <div></div>
    </wl-rich-text-wrapper>
  </footer>
</section>

<ng-template #segmentedControlPlaceholder>
  <div class="segmented-control__placeholder" aria-hidden="true"></div>
</ng-template>

<ng-template #essentCardPlaceholder>
  <essent-consumer-acquisition-commodity-offer
    variant="card"
    [showLoadingState]="true"
  />
</ng-template>

<ng-template #edCardPlaceholder>
  <ed-consumer-acquisition-commodity-offer
    variant="card"
    [showLoadingState]="true"
  />
</ng-template>
