import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';

@Pipe({
  name: 'daysUntilToday',
})
export class DaysUntilTodayPipe implements PipeTransform {
  public transform(date?: string): number | undefined {
    if (date != null) {
      return differenceInCalendarDays(new Date(date), new Date());
    }

    return undefined;
  }
}
