import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type {
  IconName,
  IconPrefix,
  IconProp,
} from '@fortawesome/fontawesome-svg-core';
import type {
  ComponentRendering,
  Field,
} from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

function isScField(obj: unknown): obj is Field {
  return (
    (obj as Field)?.value !== undefined &&
    typeof (obj as Field)?.value === 'string'
  );
}

/**
 * Given a sitecore icon setting
 * returns the icon as IconProp
 */
@Pipe({
  name: 'scIcon',
})
export class ScIconPipe implements PipeTransform {
  transform(
    icon: ComponentRendering | Item | Field<string> | undefined
  ): IconProp | undefined {
    // In case of flattened JSS GraphQL response
    if (isScField(icon)) {
      return iconTransform(icon.value);
    }
    if (icon && icon?.fields?.['value']) {
      const iconStr = (icon?.fields?.['value'] as Field<string>).value;
      return iconTransform(iconStr);
    }
    return undefined;
  }
}

/**
 * Given an icon string
 * returns the icon as IconProp
 */
@Pipe({
  name: 'faIcon',
})
export class FaIconPipe implements PipeTransform {
  transform(iconStr?: string): IconProp | undefined {
    return iconTransform(iconStr);
  }
}

export const iconTransform = (iconStr?: string): IconProp | undefined => {
  if (!!iconStr) {
    const icon = iconStr.split(' ');
    if (icon.length === 1) {
      // Icon is defined as '<name>' to use default theme for brand e.g. 'bolt'.
      // NOTE: It could be that the format is all wrong here, e.g. 'fab|facebook' is used instead of 'fab facebook'.
      // However, FontAwesomeModule wouldn't be able to display the icon and will throw an error.
      return icon[0] as IconName;
    }
    if (icon.length === 2) {
      // Icon is defined as '<prefix> <name>' to enable override of theme, e.g. 'fab facebook'.
      return [icon[0] as IconPrefix, icon[1] as IconName];
    }
  }
  return undefined;
};
