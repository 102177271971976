<div class="inswitchinhouse-container mb-2">
  <div class="w-100">
    <wl-dropdown
      class="w-100"
      [dropdownItems]="items"
      [formsControl]="formState?.controls?.inswitchInhouse"
      [label]="fields?.DropdownLabel?.value"
      [floatingLabel]="false"
      [placeholder]="fields?.DropdownPlaceholder?.value"
      [omitValidationClasses]="['is-valid']"
      [wlTrackValueChange]="{
        step: stepNumber,
        rendering: rendering,
        label: 'situatie',
        inputLabel: selectedSituationLabel,
        sendValue: true,
        trackFocus: true,
        stepname: 'leveringsgegevens'
      }"
    />
    <wl-input-error
      *ngIf="formState?.controls?.inswitchInhouse | isInvalid"
      wlAutomationId="dropdown-selection-required-error"
      stepname="leveringsgegevens"
      [step]="stepNumber"
      [messageField]="fields?.DropdownSelectionRequiredError"
      messageDescription="selectie situatie"
      [rendering]="rendering"
    />
  </div>
  <ng-container *ngIf="selectedInswitchInhouseOption | async as selected">
    <div
      *ngIf="selected.showStartDateInput"
      class="d-flex flex-column gap-1 w-100"
    >
      <fieldset>
        <legend class="h6 fw-bold">{{ fields?.InswitchLabel?.value }}</legend>
        <p *ngIf="selected.inswitchText?.value as startDateExplanation">
          {{ startDateExplanation }}
        </p>
        <div *ngIf="startDateOptionControlEnabled && switchingSupplier">
          <wl-radio
            [radioItems]="radioButtonDescriptions"
            [formsControl]="formState?.controls?.startDateOption"
            wlAutomationId="start-date-option-radio-buttons"
          />
          <wl-input-error
            *ngIf="formState?.controls?.startDateOption | isInvalid"
            [messageField]="noStartDateOptionChosenErrorMessage"
          />
        </div>
      </fieldset>
      <div
        *ngIf="
          !startDateOptionControlEnabled ||
          !switchingSupplier ||
          optedForCustomStartDate
        "
      >
        <wl-datepicker
          [rendering]="rendering"
          stepname="leveringsgegevens"
          [step]="stepNumber"
          fieldname="datum overstap"
          [formsControl]="formState?.controls?.selectedStartDate"
          [placeholder]="fields?.TransitionDatePlaceholder?.value"
          [label]="fields?.TransitionDateLabel?.value"
          [startValidDate]="startDateRange.start"
          [endValidDate]="startDateRange.end"
          [tooltip]="selected.startDateInputTooltip ?? ''"
          wlAutomationId="startdatepicker"
          [labelFloating]="!labelsShownAboveFields"
          class="start-date"
        />
        <small *ngIf="selected.startDateInputHint as startDateHint">
          {{ startDateHint }}
        </small>
        <wl-input-error
          *ngIf="formState?.controls?.selectedStartDate | isInvalid"
          wlAutomationId="transition-date-required-error"
          stepname="leveringsgegevens"
          [step]="stepNumber"
          [messageField]="fields?.TransitionDateRequiredError"
          messageDescription="datum overstap"
          [rendering]="rendering"
        />
      </div>
      <wl-checkbox
        *ngIf="startDateConfirmationRequired"
        [rendering]="rendering"
        stepname="leveringsgegevens"
        [step]="stepNumber"
        fieldname="start levering binnen 14 dagen"
        [formsControl]="formState?.controls?.confirmInhouseTerm"
        [label]="fields?.InhouseTermLabel?.value"
        [tooltip]="fields?.InhouseTermTooltip?.value"
      />
    </div>
    <ng-container
      *ngIf="
        !startDateOptionControlEnabled || !switchingSupplier || startDateChosen
      "
    >
      <wl-info-card
        *ngIf="selected.infoMessage?.value"
        [message]="selected.infoMessage"
        [buttonLink]="selected.infoMessageButtonLink"
        [backgroundGrey]="true"
        wlAutomationId="information-box"
      />
    </ng-container>
    <small
      *ngIf="
        startDateOptionControlEnabled && switchingSupplier && startDateChosen
      "
      [innerHTML]="helpText"
      wlAutomationId="help-text"
    >
    </small>
  </ng-container>
</div>
