import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { BaseOfferFields } from '@innogy/become-a-customer/shared/interfaces';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { TypedComponentRendering } from '@innogy/jss/models';
import { getFieldValue } from '@innogy/jss/utils';

@Component({
  selector: 'wl-offer-more-info-block[rendering][offer]',
  templateUrl: './offer-more-info-block.component.html',
})
export class OfferMoreInfoBlockComponent implements OnInit {
  @Input() rendering!: TypedComponentRendering<BaseOfferFields>;
  @Input() offer!: Offer;
  @Input() moreInfoButtonClick?: (offer: Offer) => unknown;

  moreInfoBlock: any = {};

  ngOnInit(): void {
    this.moreInfoBlock.title = this.rendering.fields.moreInfoTitle;
    this.moreInfoBlock.message = this.rendering.fields.moreInfoMessage;
    this.moreInfoBlock.buttonText = getFieldValue(
      this.rendering,
      'moreInfoButtonText',
      undefined
    );
  }
}
