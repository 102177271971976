<div
  class="card card--info overflow-hidden d-flex"
  [class.shadow-sm]="shadow"
  [wlAutomationId]="automationId"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionName]="trackImpressionName"
  [class.card--bordered]="border"
  [class.card--info]="type === 'info'"
  [class.card--success]="type === 'success'"
  [class.card--warning]="type === 'warning'"
  [class.card--danger]="type === 'danger'"
>
  <div
    class="card-body d-flex"
    [class.card-body--info]="type === 'info'"
    [class.card-body--success]="type === 'success'"
    [class.card-body--warning]="type === 'warning'"
    [class.card-body--danger]="type === 'danger'"
    [class.p-1]="mode === 'single-line'"
    [class.bg-light]="backgroundGrey"
    [class.align-items-center]="mode === 'single-line'"
  >
    <fa-icon
      *ngIf="type === 'info'"
      class="icon icon--info text-primary me-1"
      icon="circle-info"
    >
    </fa-icon>
    <fa-icon
      *ngIf="type === 'warning'"
      class="icon icon--info text-warning me-1"
      icon="exclamation-circle"
    ></fa-icon>
    <fa-icon
      *ngIf="type === 'danger'"
      class="icon icon--info text-danger me-1"
      icon="exclamation-triangle"
    >
    </fa-icon>
    <fa-icon
      *ngIf="type === 'success'"
      class="icon icon--info text-success me-1"
      icon="check-circle"
    >
    </fa-icon>

    <div class="info-card__content">
      <div
        *ngIf="title?.value || titleTranslationKey"
        [wlAutomationId]="automationId + '-title'"
      >
        <ng-container *ngIf="title">
          <p *scText="title" class="info-card__title mb-0"></p>
        </ng-container>
        <p *ngIf="titleTranslationKey" class="info-card__title mb-0">
          {{ titleTranslationKey | translate }}
        </p>
      </div>
      <div
        *ngIf="message?.value || messageTranslationKey"
        [wlAutomationId]="automationId + '-description'"
        [class.mb-2]="!!title?.value"
      >
        <ng-container *ngIf="message">
          <p class="text-small text-start" *wlGenericRichText="message"></p>
        </ng-container>
        <p
          class="text-small text-start"
          *ngIf="messageTranslationKey"
          [innerHTML]="
            messageTranslationKey | translate : messageTranslationParams
          "
        ></p>
      </div>
      <div
        *ngIf="buttonLink?.value?.href && !hijackButton"
        class="info-card__button"
      >
        <a
          [ngClass]="{
            'btn btn-sm': buttonType !== 'link',
            'btn-primary': buttonType === 'button',
            'btn-outline-primary': buttonType === 'button-outline',
            'card-link': buttonType === 'link'
          }"
          [wlAutomationId]="automationId + '-button-link'"
          wlTrackImpression="button-impression"
          [trackImpressionInfo]="rendering"
          [trackImpressionName]="buttonLink?.value.text"
          trackImpressionMode="button"
          wlTrackLink
          [trackLinkInfo]="{
            rendering: rendering,
            field: buttonLink?.value,
            linkurl: buttonLink?.value?.href,
            eventType: buttonType === 'button' ? 'button-click' : 'link-click'
          }"
          *wlGenericLink="buttonLink; extras: navigationExtras"
        ></a>
      </div>
      <div *ngIf="hijackButton && !!buttonText" class="info-card__button">
        <button
          type="button"
          class="btn btn-sm text-start"
          [ngClass]="{
            'btn-primary': buttonType === 'button',
            'btn-outline-primary': buttonType === 'button-outline',
            'btn-link p-0 border-0': buttonType === 'link'
          }"
          [wlAutomationId]="automationId + '-button'"
          wlTrackImpression="button-impression"
          [trackImpressionInfo]="rendering"
          [trackImpressionName]="buttonLink?.value?.text || buttonText"
          trackImpressionMode="button"
          wlTrackLink
          [trackLinkInfo]="{
            rendering: rendering,
            field: buttonLink?.value?.text || buttonText,
            linkurl: buttonLink?.value?.href,
            eventType: buttonType === 'link' ? 'link-click' : 'button-click'
          }"
          (click)="buttonClick.emit($event)"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
