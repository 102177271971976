import type { PostOrderRequestBody } from '@innogy/eplus/temporary-core-modules';
import type {
  GenericFormToGenericFormSubmitSettings,
  MetadataUserDefinedProp,
} from '@innogy/sitecore-forms/models';
import { CONTROL_METADATA_KEY } from '@innogy/sitecore-forms/models';
import type { FormGroupControls } from 'ngrx-forms';
import { isBoxed, unbox } from 'ngrx-forms';

export function extractGenericFormValuesFromFormControls<
  TFormControls extends Record<string, unknown>
>(formGroupControls: FormGroupControls<TFormControls>): Record<string, string> {
  const formValues: { [key: string]: string } = {};

  Object.values(formGroupControls).forEach((control) => {
    const controlMetaData: MetadataUserDefinedProp | undefined =
      control.userDefinedProperties[CONTROL_METADATA_KEY];
    const requestFieldKey = controlMetaData?.apiFieldKey;

    const getFieldValue = (control: any) => {
      const value = isBoxed(control.value)
        ? unbox(control.value)
        : control.value;
      return Array.isArray(value) ? value.join(',') : value;
    };

    // If there is a key defined for this control
    if (requestFieldKey) {
      const valuesAtKey = formValues[requestFieldKey];
      // Concatenate values on top of the existing values from controls with an identical key
      if (valuesAtKey) {
        formValues[requestFieldKey] = `${valuesAtKey},${getFieldValue(
          control
        )}`;
        // Add a new entry to the formValues object
      } else {
        formValues[requestFieldKey] = getFieldValue(control);
      }
    }
  });

  return formValues;
}

export function genericFormToGenericFormSubmit<
  TFormControls extends Record<string, unknown>
>(
  settings: GenericFormToGenericFormSubmitSettings,
  formControls: FormGroupControls<TFormControls>,
  additionalFormValues?: Record<string, string>
): PostOrderRequestBody {
  const formValues = {
    ...extractGenericFormValuesFromFormControls(formControls),
    ...additionalFormValues,
  };
  const { formSettingsPath, environment, referrer, e2eTrackingId, gclid } =
    settings;
  return {
    bron: 'Online',
    omgeving: environment,
    referrer,
    formSettingsPath,
    formValues,
    e2eTrackingId,
    gclid,
  };
}
