import type { EnergyType } from '@essent/common';
import { createBaseAction } from '@essent/common';
import type { DynamicPricesResponse } from '@essent/tariffs';

import type { ChangeDatePayload } from '../models';

export const initDynamicPrices = createBaseAction(
  '[Dynamic Prices] Init Dynamic prices'
);

export const updateDynamicPrices = createBaseAction(
  '[Dynamic Prices] Update Dynamic prices'
);

export const changeSelectedDate = createBaseAction<ChangeDatePayload>(
  '[Dynamic Prices] Change selected date'
);

export const setSelectedEnergyType = createBaseAction<EnergyType>(
  '[Dynamic Prices] Set selected energy type'
);

export const setSelectedDate = createBaseAction<string>(
  '[Dynamic Prices] Set selected date'
);

export const setSelectedActionId = createBaseAction<string>(
  '[Dynamic Prices] Set selected actionId'
);

export const setSelectedHour = createBaseAction<number>(
  '[Dynamic Prices] Set selected hour'
);

export const setDynamicPricesSuccess = createBaseAction<DynamicPricesResponse>(
  '[Dynamic Prices] Set dynamic prices response'
);
