import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@innogy/config';
import { provideBootstrapEffects } from '@innogy/utils/deprecated';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducers, settingsSelectorKey } from './+state/settings.selectors';
import { SettingsEffects } from './+state/settings.effects';
import { SettingsService } from './settings.service';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule,
    StoreModule.forFeature(settingsSelectorKey, reducers),
    EffectsModule.forFeature([]),
  ],
  providers: [provideBootstrapEffects([SettingsEffects]), SettingsService],
})
export class SettingsModule {}
