import { openGenericModal } from '@innogy/common-ui/modals';
import type { GenericProductData } from '@innogy/common-ui/models';
import type { EnvironmentConfig } from '@innogy/config/models';
import { generateAnalyticsE2EId } from '@innogy/core/analytics';
import type { SharedEplusFunnelSettingsInterface } from '@innogy/eplus/models';
import type { Actions } from '@ngrx/effects';
import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';
import type { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { handleNavigationAction, handlePrivateErrorAction } from '../../+state';
import type {
  FunnelEffects,
  RequiredFunnelActions,
} from './funnel.effects.model';

/**
 * If any funnel-step (other than the starting-step) is trying to initialize the funnel,
 * it will navigate to the startPage so that the starting-step can pick this up.
 *
 * It is possible for 2 funnel-components to be on the startPage, in this case we don't navigate
 * away and will only actually "setFunnelInitializedAction" once from the StartStep
 */
const initializeFunnelStep =
  <
    TStep extends string,
    TState extends SharedEplusFunnelSettingsInterface,
    TProduct extends GenericProductData
  >(
    actions$: Actions,
    funnelSettings$: Observable<TState>,
    isXpEditorActive$: Observable<boolean>,
    startPageKey: keyof TState,
    startingStep: TStep,
    actions: RequiredFunnelActions<TStep, TState, TProduct>
  ) =>
  () =>
    createEffect(() =>
      actions$.pipe(
        ofType(actions.initializeFunnelStepAction),
        concatLatestFrom(() => [funnelSettings$, isXpEditorActive$]),
        filter(
          ([action, funnelSettings, isXpEditorActive]) =>
            !isXpEditorActive &&
            (!funnelSettings.initialized ||
              action.funnelSettings?.id !== funnelSettings.id)
        ),
        map(([{ step, funnelSettings }]) => {
          if (!funnelSettings) {
            return actions.handleFunnelPrivateErrorAction({
              message: `FunnelSettings are not defined on ${step}`,
            });
          } else if (!funnelSettings[startPageKey]) {
            return actions.handleFunnelPrivateErrorAction({
              message: `${String(
                startPageKey
              )} is not defined on FunnelSettings`,
            });
          } else if (step !== startingStep) {
            return actions.onFunnelNavigationAction({
              page: funnelSettings[startPageKey] as unknown as LinkField,
            });
          }

          return actions.setFunnelInitializedAction({ funnelSettings });
        })
      )
    );

const mintTrackingId =
  <
    TStep extends string,
    TState extends SharedEplusFunnelSettingsInterface,
    TProduct extends GenericProductData
  >(
    actions$: Actions,
    config: EnvironmentConfig,
    actions: RequiredFunnelActions<TStep, TState, TProduct>
  ) =>
  () =>
    createEffect(() =>
      actions$.pipe(
        ofType(actions.setFunnelInitializedAction),
        map(() =>
          actions.mintTrackingIdAction({
            id: generateAnalyticsE2EId(config),
          })
        )
      )
    );

const handleFunnelGenericError =
  <
    TStep extends string,
    TState extends SharedEplusFunnelSettingsInterface,
    TProduct extends GenericProductData
  >(
    actions$: Actions,
    funnelSettings$: Observable<TState>,
    actions: RequiredFunnelActions<TStep, TState, TProduct>
  ) =>
  () =>
    createEffect(() =>
      actions$.pipe(
        ofType(actions.handleFunnelGenericErrorAction),
        concatLatestFrom(() => funnelSettings$),
        map(([_, funnelSettings]) => {
          if (!funnelSettings.genericErrorModal) {
            return actions.handleFunnelPrivateErrorAction({
              message: 'genericErrorModal is not defined on FunnelSettings',
            });
          }
          return openGenericModal(funnelSettings.genericErrorModal);
        })
      )
    );

const onFunnelNavigation =
  <
    TStep extends string,
    TState extends SharedEplusFunnelSettingsInterface,
    TProduct extends GenericProductData
  >(
    actions$: Actions,
    actions: RequiredFunnelActions<TStep, TState, TProduct>
  ) =>
  () =>
    createEffect(() =>
      actions$.pipe(
        ofType(actions.onFunnelNavigationAction),
        map(handleNavigationAction)
      )
    );

const handleFunnelPrivateError =
  <
    TStep extends string,
    TState extends SharedEplusFunnelSettingsInterface,
    TProduct extends GenericProductData
  >(
    actions$: Actions,
    actions: RequiredFunnelActions<TStep, TState, TProduct>
  ) =>
  () =>
    createEffect(() =>
      actions$.pipe(
        ofType(actions.handleFunnelPrivateErrorAction),
        map(handlePrivateErrorAction)
      )
    );

export const createFunnelEffectCreators = <
  TStep extends string,
  TState extends SharedEplusFunnelSettingsInterface,
  TProduct extends GenericProductData
>(
  actions$: Actions,
  funnelSettings$: Observable<TState>,
  isXpEditorActive$: Observable<boolean>,
  startPageKey: keyof TState,
  startingStep: TStep,
  config: EnvironmentConfig,
  actions: RequiredFunnelActions<TStep, TState, TProduct>
): FunnelEffects => ({
  initializeFunnelStepEffectCreator: initializeFunnelStep(
    actions$,
    funnelSettings$,
    isXpEditorActive$,
    startPageKey,
    startingStep,
    actions
  ),
  mintTrackingIdEffectCreator: mintTrackingId(actions$, config, actions),
  handleFunnelGenericErrorEffectCreator: handleFunnelGenericError(
    actions$,
    funnelSettings$,
    actions
  ),
  handleFunnelPrivateErrorEffectCreator: handleFunnelPrivateError(
    actions$,
    actions
  ),
  onFunnelNavigationEffectCreator: onFunnelNavigation(actions$, actions),
});
