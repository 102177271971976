import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformService } from '@innogy/core/platform';
import { WINDOW } from '@innogy/utils/dom';
import { CookieService } from 'ngx-cookie-service';

// Verify if a new cookie name is actually needed before enabling on P.
export const CONSENT_COOKIE_NAME = 'consentLevel';

/**
 * Enum that represents the different levels of consent an end-user can give.
 * These consent levels are to be assigned to the consent cookie.
 */
export const CONSENT_LEVELS = {
  UNDEFINED: 0,
  DEFAULT: 1,
  TRACKING: 2,
} as const;

export const CONSENT_REASON = {
  ACCEPT: 'accept',
  REJECT: 'reject',
} as const;

type CONSENT_REASON_KEYS = keyof typeof CONSENT_REASON;
export type CONSENT_REASON_VALUES =
  (typeof CONSENT_REASON)[CONSENT_REASON_KEYS];

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  readonly #cookieService = inject(CookieService);
  readonly #platformService = inject(PlatformService);
  readonly #window = inject(WINDOW);
  readonly #router = inject(Router);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #consentTriggerKey = 'prompt-cookie-consent';

  public isCookieConsentPromptRequired() {
    const isClient = this.#platformService.isClient();
    const isWhitelistedPage =
      this.#window.document.URL.match(/cookies|privacyverklaring/g) !== null;
    const hasConsent = this.hasConsent();

    return isClient && !isWhitelistedPage && !hasConsent;
  }

  /**
   * Instructs the application that cookie consent is required so that other components can react to it.
   */
  public markCookieConsentIsRequired() {
    this.#setCookieConsentParam(true);
  }

  /**
   * Checks whether or not an end-user has opted in to allowing tracking cookies.
   * @returns boolean indicating whether or not tracking cookies are allowed on this device.
   */
  public allowsTrackingCookies() {
    return this.#getCookieConsentLevel() === CONSENT_LEVELS.TRACKING;
  }

  /**
   * Opts the end-user into the usage of tracking cookies on this device.
   */
  public setConsentLevel(result: CONSENT_REASON_VALUES) {
    if (result === CONSENT_REASON.ACCEPT) {
      this.#setCookieConsentLevel(CONSENT_LEVELS.TRACKING);
    } else {
      this.#setCookieConsentLevel(CONSENT_LEVELS.DEFAULT);
    }
    // now consent is given, we can clear the param that flags consent as being required.
    this.#setCookieConsentParam(null);
    this.#applySettings();
  }

  /**
   * Determines whether or not we should show a cookiewall.
   * @returns a boolean indicating whether or not a cookie wall should be displayed.
   */
  public shouldShowCookiewall() {
    if (
      new URLSearchParams(this.#window.location.search).get(
        this.#consentTriggerKey
      ) !== null
    ) {
      return true;
    }
    return false;
  }

  public hasConsent() {
    return this.#getCookieConsentLevel() > CONSENT_LEVELS.UNDEFINED;
  }

  /**
   * Get the cookie consent.
   *
   * @returns Consent, 0 if not existing
   */
  #getCookieConsentLevel() {
    let cookieLevel = this.#cookieService.get(CONSENT_COOKIE_NAME);
    cookieLevel =
      cookieLevel.length > 0
        ? cookieLevel
        : CONSENT_LEVELS.UNDEFINED.toString();
    const level = parseInt(cookieLevel, 10);
    return Number.isNaN(level) ? CONSENT_LEVELS.UNDEFINED : level;
  }

  #setCookieConsentLevel(level: number, durationInDays = 365) {
    const domain = this.#getDomainName();
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + durationInDays);
    this.#cookieService.set(CONSENT_COOKIE_NAME, level.toString(10), {
      path: '/',
      domain,
      expires: exdate,
      secure: true,
    });
  }

  #getDomainName() {
    const { location } = this.#window;
    if (['localhost', '127.0.0.1'].includes(location.hostname)) {
      return location.hostname;
    }
    return this.#getSubDomainName(location.host);
  }

  #getSubDomainName(host: string) {
    const domainParts = host.split('.');
    if (domainParts.length > 2) {
      domainParts.shift();
    }
    return `.${domainParts.join('.')}`;
  }

  #setCookieConsentParam(value: boolean | null) {
    const urlTree = this.#router.createUrlTree([], {
      relativeTo: this.#activatedRoute,
      queryParams: { [this.#consentTriggerKey]: value },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });

    this.#router.navigateByUrl(urlTree);
  }

  /**
   * @Important this is a workaround that compensates for the set-up of our Adobe Launch integration.
   * It is currently not possible to update the configuration of a user's consent settings without forcing a complete reload of the suite.
   * To do so, we enforce a full page reload.
   *
   * This is to be deprecated as soon as server-side tracking is implemented.
   */
  async #applySettings() {
    await Promise.resolve();
    this.#window.location.reload();
  }
}
