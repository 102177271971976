<wl-partial-media-section
  [class.media-section--reserve-spacing]="reserveVerticalSpacing"
  [mediaPosition]="settings?.itemAlignment"
  [mediaScale]="settings?.itemSize < 6 ? 'sm' : 'md'"
  [theme]="settings?.theme"
  [pattern]="settings?.pattern"
>
  <wl-partial-image
    [src]="image?.image?.src"
    [alt]="image?.image?.alt"
    [ratio]="'4/3'"
    [objectPosition]="image?.position"
    [objectFit]="image?.objectFit"
  >
  </wl-partial-image>
  <wl-partial-structured-content
    [layoutButtonsInline]="true"
    [theme]="contentTheme"
  >
    <fa-icon
      *ngIf="settings?.icon?.name"
      slot="icon"
      class="icon-md"
      [icon]="settings?.icon?.name"
    >
    </fa-icon>
    <ng-container slot="heading" *ngIf="content?.heading?.text?.value">
      <h3 class="h3 mb-0" *scText="content.heading.text"></h3>
    </ng-container>
    <ng-container slot="subheading" *ngIf="content?.subheading?.text?.value">
      <h4 class="intro" *scText="content.subheading.text"></h4>
    </ng-container>
    <ng-container slot="paragraph" *ngIf="content?.text?.text?.value">
      <ng-container *wlGenericRichText="content.text.text">
        <p class="mb-0"></p>
      </ng-container>
    </ng-container>
    <ng-container
      slot="button-primary"
      *ngIf="content?.buttons?.primary?.link?.href"
    >
      <wl-partial-button
        role="link"
        [theme]="primaryButtonTheme"
        [fullWidth]="true"
        [context]="settings.context"
        [ngClass]="content?.buttons?.primary?.link?.class"
        [href]="content.buttons.primary.link.href"
        [wlTrackLink]="content.buttons.primary.link.text"
        [trackLinkInfo]="{
          rendering: rendering,
          field: content.buttons.primary.link.text,
          eventType: 'button-click'
        }"
      >
        {{ content.buttons.primary.link.text }}
      </wl-partial-button>
    </ng-container>
    <ng-container
      slot="button-secondary"
      *ngIf="content?.buttons?.secondary?.link?.href"
    >
      <wl-partial-button
        role="link"
        theme="tertiary"
        size="small"
        [fullWidth]="true"
        [ngClass]="content?.buttons?.secondary?.link?.class"
        [href]="content.buttons.secondary.link.href"
        [wlTrackLink]="content.buttons.secondary.link.text"
        [trackLinkInfo]="{
          rendering: rendering,
          field: content.buttons.secondary.link.text,
          eventType: 'button-click'
        }"
      >
        {{ content.buttons.secondary.link.text }}
      </wl-partial-button>
    </ng-container>
  </wl-partial-structured-content>
</wl-partial-media-section>
