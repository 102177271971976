import type {
  JssRadioItem,
  LinkFieldValue,
  ScCheckboxField,
  ScSingleLineSetting,
} from '@innogy/jss/models';
import type { LinkField, TextField } from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

export const mockJssRadioItem = (value: string): JssRadioItem<string> => ({
  id: `id-${value}`,
  url: `/${value}`,
  name: value,
  displayName: `id-${value}`,
  fields: {
    trackingLabel: {
      value: `trackingLabel-${value}`,
    },
    value: {
      value,
    },
    label: {
      value: `label-${value}`,
    },
    tooltip: {
      value: `tooltip-${value}`,
    },
    sublabel: {
      value: `sublabel-${value}`,
    },
    disabled: {
      value: false,
    },
  },
});

export const mockRadioList = (values: string[] = []) =>
  values.map(mockJssRadioItem);

export const mockTextField = (value = 'goat'): TextField => ({ value });
export const mockCheckboxField = (value = true): ScCheckboxField => ({ value });

/**
 * @usageNotes
 * ```ts
 * mockLinkField()
 * ```
 * ```ts
 * mockLinkField('this-will-set-the-href')
 * ```
 * ```ts
 * mockLinkField({
 *   href: 'set specific props like this',
 *   text: 'set specific props like this',
 * })
 * ```
 */
export const mockLinkField = (
  href: string | Partial<LinkFieldValue> = 'goat'
): LinkField => ({
  value: typeof href === 'string' ? { href } : href,
});
export const mockScSingleLineSetting = <T extends string>(
  value: T
): ScSingleLineSetting<T> => ({
  name: value,
  fields: {
    value: { value },
  },
});

export const mockRenderingItem = <T extends Record<string, any>>(
  name: string,
  fields?: T
): Item & Record<string, any> => ({
  id: `id-${name}`,
  url: `/${name}`,
  name: `name-${name}`,
  displayName: `displayName-${name}`,
  fields: {
    ...(fields ?? { [name]: name }),
  } as T,
});

export const mockRichTextField = mockTextField;

export const mockGroupedDroplink = mockRenderingItem;

export const mockMultiListField = (
  values: { name: string; fields: object }[] = [
    { name: 'goat', fields: { value: 'goat' } },
  ]
) => values.map((value) => mockRenderingItem(value.name, value.fields));
