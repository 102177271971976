<div class="footer">
  <div class="container">
    <div
      class="footer-grid"
      [class.footer-grid--app-buttons-hidden]="!showAppButtons"
    >
      <div class="footer-grid__logo">
        <wl-footer-brand></wl-footer-brand>
      </div>

      <div class="footer-grid__placeholder-left">
        <div
          [rendering]="rendering"
          sc-placeholder
          name="main-footer-container-left"
        ></div>
      </div>

      <div class="footer-grid__app-buttons">
        <wl-app-buttons
          *ngIf="showAppButtons"
          trackingName="footernavigation"
          [titleField]="fields.appButtonsTitle"
          [androidLink]="fields.appButtonsAndroidLink"
          [androidLinkTrackingField]="fields.appButtonsAndroidLinkTrackingField"
          [iosLink]="fields.appButtonsIosLink"
          [iosLinkTrackingField]="fields.appButtonsIosLinkTrackingField"
        ></wl-app-buttons>
      </div>

      <div class="footer-grid__socials">
        <wl-footer-socials [rendering]="rendering"></wl-footer-socials>
      </div>

      <div class="footer-grid__placeholder-bottom">
        <div
          sc-placeholder
          name="bottom-links-container"
          [rendering]="rendering"
        ></div>
      </div>

      <div class="footer-grid__placeholder-right">
        <div
          sc-placeholder
          name="main-footer-container-right"
          [rendering]="rendering"
        ></div>
      </div>

      <div class="footer-grid__copyright">
        <span *scText="fields.copyright"></span>
      </div>

      <hr class="footer-grid__divider footer-grid__divider--1" />
      <hr class="footer-grid__divider footer-grid__divider--2" />
      <hr class="footer-grid__divider footer-grid__divider--3" />
      <hr class="footer-grid__divider footer-grid__divider--4" />
      <hr class="footer-grid__divider footer-grid__divider--5" />
    </div>
  </div>
</div>
