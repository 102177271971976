import { Component, Input } from '@angular/core';
import type { IconName } from '@fortawesome/pro-regular-svg-icons';
import type { ScLinkField } from '@innogy/jss/models';
import { getFieldValue } from '@innogy/jss/utils';
import { InnogyComponentRendering } from '@innogy/jss/models';

enum Social {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Youtube = 'youtube',
  LinkedIn = 'linkedin',
  Instagram = 'instagram',
}

@Component({
  selector: 'wl-footer-socials',
  templateUrl: './footer-socials.component.html',
  styleUrls: [
    './footer-socials.component.ed.scss',
    './footer-socials.component.essent.scss',
    './footer-socials.component.ew.scss',
  ],
})
export class FooterSocialsComponent {
  @Input()
  rendering?: InnogyComponentRendering;

  socials: Social[] = Object.values(Social);
  socialIcons: { [key in Social]: IconName } = {
    [Social.Facebook]: 'facebook-f',
    [Social.Twitter]: 'x-twitter',
    [Social.Youtube]: 'youtube',
    [Social.LinkedIn]: 'linkedin-in',
    [Social.Instagram]: 'instagram',
  };

  get hasLinks() {
    return this.socials.some((social) => this.getLink(social)?.href);
  }

  get title() {
    /**
     * For the person that wants to rename this to "socialTitle", please note that this "Social Title" field
     * is part of both the "Footer" and "FooterWithSocialTitle" templates in Sitecore. Both of these templates
     * will load the same footer-container.component.ts. The "FooterWithSocialTitle" template is deprecated,
     * but is currently still used by Energiewonen because replacing a footer component with another one in
     * Sitecore is nearly impossible.
     */
    return getFieldValue<string>(this.rendering, 'Social Title', '');
  }

  getLink(social: Social): ScLinkField | undefined {
    return getFieldValue<ScLinkField>(this.rendering, social) as ScLinkField;
  }
}
