import { DynamicTariffGroupTypes } from '@essent/tariffs';
import { addDays, format } from 'date-fns';
import { EnergyType } from '@essent/common';
import type { DynamicPricesDate } from '@essent/tariffs/dynamic-prices/interfaces/dynamic-prices.interface';
import { UnitOfMeasurement } from '@integration/base-models';

export const dynamicPricesByDateResponseMock = (
  inputDate: string
): DynamicPricesDate => {
  const date = format(inputDate, 'yyyy-MM-dd');
  return {
    date,
    electricity: {
      energyType: EnergyType.ELECTRICITY,
      unitOfMeasurement: UnitOfMeasurement.KWH,
      tariffs: [
        {
          startDateTime: `${date}T00:00:00.000`,
          endDateTime: `${date}T01:00:00.000`,
          totalAmount: -0.05307260601519799,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.092,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0111,
            },
          ],
        },
        {
          startDateTime: `${date}T01:00:00.000`,
          endDateTime: `${date}T02:00:00.000`,
          totalAmount: 0.010510944556327076,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0417,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0022,
            },
          ],
        },
        {
          startDateTime: `${date}T02:00:00.000`,
          endDateTime: `${date}T03:00:00.000`,
          totalAmount: -0.008205763727491027,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0565,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0017000000000000001,
            },
          ],
        },
        {
          startDateTime: `${date}T03:00:00.000`,
          endDateTime: `${date}T04:00:00.000`,
          totalAmount: -0.026164576002740247,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0707,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0055000000000000005,
            },
          ],
        },
        {
          startDateTime: `${date}T04:00:00.000`,
          endDateTime: `${date}T05:00:00.000`,
          totalAmount: 0.24837279516019817,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1462,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.052199999999999996,
            },
          ],
        },
        {
          startDateTime: `${date}T05:00:00.000`,
          endDateTime: `${date}T06:00:00.000`,
          totalAmount: 0.21882324976957046,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.12279999999999999,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.046,
            },
          ],
        },
        {
          startDateTime: `${date}T06:00:00.000`,
          endDateTime: `${date}T07:00:00.000`,
          totalAmount: 0.07633718004869045,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.0103,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.016,
            },
          ],
        },
        {
          startDateTime: `${date}T07:00:00.000`,
          endDateTime: `${date}T08:00:00.000`,
          totalAmount: 0.2673609879354094,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1613,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.056100000000000004,
            },
          ],
        },
        {
          startDateTime: `${date}T08:00:00.000`,
          endDateTime: `${date}T09:00:00.000`,
          totalAmount: 0.3565492737460288,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.2316,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.07490000000000001,
            },
          ],
        },
        {
          startDateTime: `${date}T09:00:00.000`,
          endDateTime: `${date}T10:00:00.000`,
          totalAmount: 0.2682616857085653,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.162,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.056299999999999996,
            },
          ],
        },
        {
          startDateTime: `${date}T10:00:00.000`,
          endDateTime: `${date}T11:00:00.000`,
          totalAmount: 0.5695907479788193,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.4,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.11960000000000001,
            },
          ],
        },
        {
          startDateTime: `${date}T11:00:00.000`,
          endDateTime: `${date}T12:00:00.000`,
          totalAmount: 0.41872129085054993,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.2808,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.08789999999999999,
            },
          ],
        },
        {
          startDateTime: `${date}T12:00:00.000`,
          endDateTime: `${date}T13:00:00.000`,
          totalAmount: 0.26819425495996474,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.16190000000000002,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.056299999999999996,
            },
          ],
        },
        {
          startDateTime: `${date}T13:00:00.000`,
          endDateTime: `${date}T14:00:00.000`,
          totalAmount: 0.1311634638214249,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.0537,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0275,
            },
          ],
        },
        {
          startDateTime: `${date}T14:00:00.000`,
          endDateTime: `${date}T15:00:00.000`,
          totalAmount: 0.32404453939283456,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.20600000000000002,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.068,
            },
          ],
        },
        {
          startDateTime: `${date}T15:00:00.000`,
          endDateTime: `${date}T16:00:00.000`,
          totalAmount: 0.08577869513265833,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.0178,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.018000000000000002,
            },
          ],
        },
        {
          startDateTime: `${date}T16:00:00.000`,
          endDateTime: `${date}T17:00:00.000`,
          totalAmount: 0.24776905286697523,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1458,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.052000000000000005,
            },
          ],
        },
        {
          startDateTime: `${date}T17:00:00.000`,
          endDateTime: `${date}T18:00:00.000`,
          totalAmount: 0.1164403384249254,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.04190000000000001,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0245,
            },
          ],
        },
        {
          startDateTime: `${date}T18:00:00.000`,
          endDateTime: `${date}T19:00:00.000`,
          totalAmount: 0.23036437181801328,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.132,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0484,
            },
          ],
        },
        {
          startDateTime: `${date}T19:00:00.000`,
          endDateTime: `${date}T20:00:00.000`,
          totalAmount: 0.005043613897375412,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0461,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0011,
            },
          ],
        },
        {
          startDateTime: `${date}T20:00:00.000`,
          endDateTime: `${date}T21:00:00.000`,
          totalAmount: 0.28656619265599337,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1764,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0602,
            },
          ],
        },
        {
          startDateTime: `${date}T21:00:00.000`,
          endDateTime: `${date}T22:00:00.000`,
          totalAmount: -0.08621817590558557,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.11810000000000001,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0181,
            },
          ],
        },
        {
          startDateTime: `${date}T22:00:00.000`,
          endDateTime: `${date}T23:00:00.000`,
          totalAmount: 0.1764754901086039,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.0894,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0371,
            },
          ],
        },
        {
          startDateTime: `${date}T23:00:00.000`,
          endDateTime: `${format(
            addDays(inputDate, 1),
            'yyyy-MM-dd'
          )}T00:00:00.000`,
          totalAmount: 0.1764754901086039,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.0894,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0371,
            },
          ],
        },
      ],
      maxAmount: 0.2340435753803702,
      averageAmount: 0.1720435753803702,
      minAmount: -0.003661238053027791,
    },
    gas: {
      energyType: EnergyType.GAS,
      unitOfMeasurement: UnitOfMeasurement.M3,
      tariffs: [
        {
          startDateTime: `${date}T00:00:00.000`,
          endDateTime: `${date}T01:00:00.000`,
          totalAmount: 0.21675378832062175,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1213,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0455,
            },
          ],
        },
        {
          startDateTime: `${date}T01:00:00.000`,
          endDateTime: `${date}T02:00:00.000`,
          totalAmount: -0.05023822938360599,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.08960000000000001,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0106,
            },
          ],
        },
        {
          startDateTime: `${date}T02:00:00.000`,
          endDateTime: `${date}T03:00:00.000`,
          totalAmount: -0.040137320020885464,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0817,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0084,
            },
          ],
        },
        {
          startDateTime: `${date}T03:00:00.000`,
          endDateTime: `${date}T04:00:00.000`,
          totalAmount: 0.25075358263978265,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1481,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0527,
            },
          ],
        },
        {
          startDateTime: `${date}T04:00:00.000`,
          endDateTime: `${date}T05:00:00.000`,
          totalAmount: 0.24219603201401896,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1413,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0509,
            },
          ],
        },
        {
          startDateTime: `${date}T05:00:00.000`,
          endDateTime: `${date}T06:00:00.000`,
          totalAmount: 0.22164911506083676,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1251,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.04650000000000001,
            },
          ],
        },
        {
          startDateTime: `${date}T06:00:00.000`,
          endDateTime: `${date}T07:00:00.000`,
          totalAmount: 0.28199501347045536,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1728,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0592,
            },
          ],
        },
        {
          startDateTime: `${date}T07:00:00.000`,
          endDateTime: `${date}T08:00:00.000`,
          totalAmount: 0.25725724972683234,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1533,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.054000000000000006,
            },
          ],
        },
        {
          startDateTime: `${date}T08:00:00.000`,
          endDateTime: `${date}T09:00:00.000`,
          totalAmount: 0.25741251609752547,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1533,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0541,
            },
          ],
        },
        {
          startDateTime: `${date}T09:00:00.000`,
          endDateTime: `${date}T10:00:00.000`,
          totalAmount: 0.3041664284741686,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.19030000000000002,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0639,
            },
          ],
        },
        {
          startDateTime: `${date}T10:00:00.000`,
          endDateTime: `${date}T11:00:00.000`,
          totalAmount: 0.5697977888848468,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.40009999999999996,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.1197,
            },
          ],
        },
        {
          startDateTime: `${date}T11:00:00.000`,
          endDateTime: `${date}T12:00:00.000`,
          totalAmount: 0.5255594368944333,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.3652,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.1104,
            },
          ],
        },
        {
          startDateTime: `${date}T12:00:00.000`,
          endDateTime: `${date}T13:00:00.000`,
          totalAmount: 0.4419979027961296,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.2992,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0928,
            },
          ],
        },
        {
          startDateTime: `${date}T13:00:00.000`,
          endDateTime: `${date}T14:00:00.000`,
          totalAmount: 0.325251377619973,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.207,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0683,
            },
          ],
        },
        {
          startDateTime: `${date}T14:00:00.000`,
          endDateTime: `${date}T15:00:00.000`,
          totalAmount: 0.030326462399580638,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.026099999999999998,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0064,
            },
          ],
        },
        {
          startDateTime: `${date}T15:00:00.000`,
          endDateTime: `${date}T16:00:00.000`,
          totalAmount: 0.20683785125450682,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.1134,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0434,
            },
          ],
        },
        {
          startDateTime: `${date}T16:00:00.000`,
          endDateTime: `${date}T17:00:00.000`,
          totalAmount: 0.08566676323068365,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.0177,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.018000000000000002,
            },
          ],
        },
        {
          startDateTime: `${date}T17:00:00.000`,
          endDateTime: `${date}T18:00:00.000`,
          totalAmount: 0.013559908737870963,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0392,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0028000000000000004,
            },
          ],
        },
        {
          startDateTime: `${date}T18:00:00.000`,
          endDateTime: `${date}T19:00:00.000`,
          totalAmount: 0.25869657189929124,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.15439999999999998,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.054299999999999994,
            },
          ],
        },
        {
          startDateTime: `${date}T19:00:00.000`,
          endDateTime: `${date}T20:00:00.000`,
          totalAmount: 0.05994405737812589,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0027,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0126,
            },
          ],
        },
        {
          startDateTime: `${date}T20:00:00.000`,
          endDateTime: `${date}T21:00:00.000`,
          totalAmount: -0.0005715917302460127,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.050499999999999996,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0001,
            },
          ],
        },
        {
          startDateTime: `${date}T21:00:00.000`,
          endDateTime: `${date}T22:00:00.000`,
          totalAmount: 0.01820956249847242,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0356,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0038,
            },
          ],
        },
        {
          startDateTime: `${date}T22:00:00.000`,
          endDateTime: `${date}T23:00:00.000`,
          totalAmount: 0.11111675199665545,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: 0.0383,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: 0.0235,
            },
          ],
        },
        {
          startDateTime: `${date}T23:00:00.000`,
          endDateTime: `${format(addDays(date, 1), 'yyyy-MM-dd')}T00:00:00.000`,
          totalAmount: -0.003661238053027791,
          groups: [
            {
              description: 'Marktprijs',
              type: DynamicTariffGroupTypes.MARKET_PRICE,
              amount: -0.0529,
            },
            {
              description: 'Inkoopvergoeding',
              type: DynamicTariffGroupTypes.PURCHASING_FEE,
              amount: 0.02,
            },
            {
              description: 'Verkoopvergoeding',
              type: DynamicTariffGroupTypes.SELLING_FEE,
              amount: 0.03,
            },
            {
              description: 'Belasting',
              type: DynamicTariffGroupTypes.TAX,
              amount: -0.0008,
            },
          ],
        },
      ],
      maxAmount: 0.29196214331864134,
      averageAmount: 0.19196214331864134,
      minAmount: 0.018180419387317705,
    },
  };
};
