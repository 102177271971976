import { onNgrxFormsControlId } from '@innogy/utils/deprecated';
import type { ActionCreator, ReducerTypes } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import type { FormGroupState, KeyValue } from 'ngrx-forms';
import { MarkAsSubmittedAction, onNgrxForms } from 'ngrx-forms';

import { validateNgrxState } from './validate-ngrx-state';

export function createNgrxFormReducer<
  TState extends { formState: FormGroupState<TFormValues> },
  TFormValues extends KeyValue
>(
  initialState: TState,
  controlId: string,
  validateForm: (state: TState) => FormGroupState<TFormValues>,
  ...additionalOns: ReducerTypes<TState, readonly ActionCreator[]>[]
) {
  return createReducer(
    initialState,
    onNgrxForms(),
    onNgrxFormsControlId(MarkAsSubmittedAction, controlId, (state) => {
      return validateNgrxState(state, validateForm);
    }),
    ...additionalOns
  );
}
