import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@innogy/config';
import type { Observable } from 'rxjs';
import { toCamelcase } from '@essent/core-utils';

import type { UserInfoResponse } from './account.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  loadUserInfo(): Observable<UserInfoResponse> {
    return this.http
      .get(`${this.appConfigService.basePath}/auth/userinfo`)
      .pipe(toCamelcase<UserInfoResponse>());
  }

  constructor(
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService
  ) {}
}
