import type { Injector } from '@angular/core';
import type { UrlTree } from '@angular/router';
import type { Action } from '@ngrx/store';
import type {
  GuardInput,
  JssCanActivate,
} from '@sitecore-jss/sitecore-jss-angular';
import type { Observable } from 'rxjs';
import { combineLatest, of, race } from 'rxjs';
import { delay, filter, map, switchMap, take } from 'rxjs/operators';

import { GuardService } from './guard.service';

export type GuardType = boolean | string | string[] | UrlTree;

export abstract class BaseGuard implements JssCanActivate {
  public readonly GUARD_TIMEOUT = 10000;
  protected readonly guardService: GuardService;

  private readonly timeout$ = of(true).pipe(delay(this.GUARD_TIMEOUT));

  private readonly waitForStateLoaded = (input: GuardInput) =>
    this.isStateLoading(input).pipe(
      filter((isLoading) => isLoading !== true),
      map((isLoading) => (isLoading === false ? true : isLoading)),
      take(1)
    );

  private readonly isStateLoaded = (input: GuardInput) =>
    combineLatest([
      this.isStateLoading(input),
      this.guardService.isExperienceEditorOrServerActive(),
    ]).pipe(
      map(([isLoading, experienceEditorOrServerActive]) =>
        experienceEditorOrServerActive ? false : isLoading
      ),
      map((isLoading: GuardType): [boolean, GuardType] => [
        isLoading !== true,
        isLoading === false ? true : isLoading,
      ]),
      take(1)
    );

  protected abstract isStateLoading(input: GuardInput): Observable<GuardType>;
  protected abstract getDispatchActions(input: GuardInput): Action | Action[];

  public hasStateLoaded(input: GuardInput) {
    return this.isStateLoaded(input).pipe(
      switchMap(([isLoaded, loadedInfo]) => {
        if (isLoaded) {
          return of(loadedInfo);
        }

        const actions = this.getDispatchActions(input);
        const arrayActions: Action[] = Array.isArray(actions)
          ? actions
          : [actions];

        this.guardService.dispatchActions(arrayActions);

        return this.waitForStateLoaded(input);
      })
    );
  }

  constructor(protected readonly injector: Injector) {
    this.guardService = this.injector.get(GuardService);
  }

  public canActivate(input: GuardInput): Observable<GuardType> {
    return race(this.timeout$, this.hasStateLoaded(input));
  }
}
