import type { PipeTransform } from '@angular/core';
import { Inject, Optional, Pipe } from '@angular/core';
import urlJoin from 'url-join';

@Pipe({
  name: 'deployUrl',
})
export class DeployUrlPipe implements PipeTransform {
  constructor(
    @Optional() @Inject('DEPLOY_URL') private readonly deployUrl?: string
  ) {}

  transform(location: string): string {
    return urlJoin(this.deployUrl ?? '', location);
  }
}
