import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@innogy/utils/deprecated';
import { TranslateModule } from '@ngx-translate/core';

import { HandleEmptyValueComponent } from './handle-empty-value/handle-empty-value.component';
import { HandleEmptyValueDirective } from './handle-empty-value/handle-empty-value.directive';

@NgModule({
  imports: [CommonModule, TranslateModule, UtilsModule],
  declarations: [HandleEmptyValueComponent, HandleEmptyValueDirective],
  exports: [HandleEmptyValueDirective],
})
export class SharedSitecoreHelpersModule {}
