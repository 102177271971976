import {
  APP_BASE_HREF,
  CommonModule,
  registerLocaleData,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNL from '@angular/common/locales/nl';
import type { ModuleWithProviders } from '@angular/core';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonCoreModule, RequestStrategy } from '@essent/common';
import { ABTestingLazyImports } from '@innogy/ab-testing/lazy';
import { AccountModule } from '@innogy/account';
import { ApiClientModule } from '@innogy/api-client';
import { ChatbotIadvizeModule } from '@innogy/chatbot/iadvize';
import { commonUiLazyImports } from '@innogy/common-ui/lazy';
import { CONFIGMODULE_CONFIG, ConfigModule } from '@innogy/config';
import { ConfigStoreModule } from '@innogy/config/store';
import { CoreAbTestingModule } from '@innogy/core/ab-testing';
import {
  CoreAnalyticsModule,
  NotFoundAnalyticsEnhancer,
  TrackImpressionEnhancer,
} from '@innogy/core/analytics';
import { CoreConsentModule } from '@innogy/core/consent';
import { CoreExperienceEditorUtilsModule } from '@innogy/core/experience-editor-utils';
import { InitializeModule } from '@innogy/core/initialize';
import { CoreLoadFontsModule } from '@innogy/core/load-fonts';
import { CorePlatformModule } from '@innogy/core/platform';
import { CoreRoutingModule } from '@innogy/core/routing';
import { DebugModule, ErrorComponent } from '@innogy/debug';
import { FunctionalErrorsModule } from '@innogy/functional-errors';
import { JssModule as InnogyJssModule } from '@innogy/jss';
import { MaintenanceModeModule } from '@innogy/maintenance-mode';
import { FooterContainerComponent } from '@innogy/navigation';
import { NavigationStoreModule } from '@innogy/navigation/store';
import { SettingsModule } from '@innogy/settings';
import { MemberGetMemberModule } from '@innogy/shared/member-get-member';
import { SharedResponsiveToolsModule } from '@innogy/shared/responsive-tools';
import {
  extModules,
  metaReducers,
  reducers,
  runtimeChecks,
} from '@innogy/shared/state';
import { UtilsModule } from '@innogy/utils/deprecated';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { TransferHttpCacheInterceptor } from './transfer-http-cache-interceptor';
registerLocaleData(localeNL, 'nl');

export interface CoreModulesConfig {
  appName: string;
  basePath: string;
}

@NgModule({
  imports: [
    CorePlatformModule,
    CommonCoreModule.forRoot({
      defaultRequestStrategy: RequestStrategy.IGNORE,
    }),
    BrowserModule.withServerTransition({ appId: 'innogy-app' }),
    CoreAbTestingModule,
    CoreAnalyticsModule.forRoot([
      NotFoundAnalyticsEnhancer,
      TrackImpressionEnhancer,
    ]),
    EffectsModule.forRoot([]),
    LazyLoadImageModule,
    SharedResponsiveToolsModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks,
    }),
    ConfigStoreModule,
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      stateKey: 'router',
    }),
    HttpClientModule,
    CoreExperienceEditorUtilsModule,
    NavigationStoreModule,
    ChatbotIadvizeModule,
    MemberGetMemberModule,
    MaintenanceModeModule,
    ...extModules,
  ],
  exports: [
    AccountModule,
    ApiClientModule,
    BrowserModule,
    CommonModule,
    ConfigModule,
    CoreAbTestingModule,
    CoreConsentModule,
    CoreAnalyticsModule,
    CoreLoadFontsModule,
    CoreRoutingModule,
    DebugModule,
    HttpClientModule,
    InitializeModule,
    InnogyJssModule,
    ReactiveFormsModule,
    FunctionalErrorsModule,
    SettingsModule,
    ChatbotIadvizeModule,
    MemberGetMemberModule,
    UtilsModule,
    MaintenanceModeModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: APP_BASE_HREF, useValue: '' },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TransferHttpCacheInterceptor,
    },
  ],
})
export class CoreModulesCommonModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModulesCommonModule
  ) {
    if (parentModule) {
      throw new Error('CoreModulesCommonModule is imported more than once.');
    }
  }

  static forRoot(
    config: CoreModulesConfig
  ): ModuleWithProviders<CoreModulesCommonModule> {
    return {
      ngModule: CoreModulesCommonModule,
      providers: [
        {
          provide: CONFIGMODULE_CONFIG,
          useValue: {
            appName: config.appName,
            basePath: config.basePath,
          } as CoreModulesConfig,
        },
      ],
    };
  }
}

export const COMMON_JSS_IMPORTS = [
  { name: 'DebugError', type: ErrorComponent },
  { name: 'Footer', type: FooterContainerComponent },
];

export const COMMON_JSS_LAZY_IMPORTS = [
  ...commonUiLazyImports,
  ...ABTestingLazyImports,
];
