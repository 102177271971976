<div
  *ngIf="!hasAffirmativeFeedback && !hasDenyingFeedback"
  class="px-2 py-2 d-flex justify-content-start align-items-center rounded quick-feedback-background"
>
  <p class="quick-feedback-question me-3 mb-0">
    {{ title }}
  </p>
  <div class="quick-feedback-buttons">
    <button
      wlAutomationId="quick-feedback-affirm"
      wlTrackFeedback
      [trackFeedbackInfo]="{
        rendering: {
          componentName: question
        },
        type: 'faq',
        name: question,
        feedback: affirmativeText,
        question: 'CFS'
      }"
      (click)="affirm()"
      wlTrackImpression="contentblock-impression"
      [trackImpressionInfo]="settings"
      class="px-1 py-2-3 quick-feedback-button quick-feedback-button__positive"
    >
      <fa-icon [icon]="affirmativeIcon | scIcon" class="me-1"></fa-icon>
      <span>{{ affirmativeText }}</span>
    </button>
    <button
      wlAutomationId="quick-feedback-deny"
      wlTrackFeedback
      [trackFeedbackInfo]="{
        rendering: {
          componentName: question
        },
        type: 'faq',
        name: question,
        feedback: denyingText,
        question: 'CFS'
      }"
      (click)="deny()"
      class="px-1 py-2-3 quick-feedback-button quick-feedback-button__negative"
    >
      <fa-icon [icon]="denyingIcon | scIcon" class="me-1"></fa-icon>
      <span>{{ denyingText }}</span>
    </button>
  </div>
</div>

<div
  *ngIf="hasAffirmativeFeedback"
  class="quick-feedback-background px-2 py-2 rounded"
>
  <p class="mb-0">{{ affirmativeFeedbackText }}</p>
</div>

<div
  *ngIf="hasDenyingFeedback"
  class="quick-feedback-background px-2 py-2 rounded"
>
  <p *ngIf="denyingFeedbackText" class="mb-0">{{ denyingFeedbackText }}</p>
  <hr />
  <a
    class="quick-feedback-contact mt-2"
    href="{{ contactUrl }}"
    wlTrackFeedback
    [trackFeedbackInfo]="{
      rendering: {
        componentName: question
      },
      type: 'faq',
      name: title,
      feedback: 'contact',
      question: 'escape'
    }"
  >
    <fa-icon [icon]="contactIcon | scIcon" class="me-1"></fa-icon>
    <span>{{ contactText }}</span>
  </a>
</div>
