import type { Gender as OrderPersonal } from '@essent/common';
import {
  putOrganisationDetailsSuccess,
  putPersonalDetailsSuccess,
} from '@essent/new-customer';
import { getRenderingValue } from '@innogy/become-a-customer/shared';
import type { InnogyComponentRendering } from '@innogy/jss/models';
import type { ProgressiveFormStateImplementor } from '@innogy/progressive-ngrx-forms';
import {
  activateFormStep,
  createProgressiveFormGroupState,
  createProgressiveNgrxFormReducer,
  markAsSubmittable,
  markAsUnsubmittable,
  markAsyncTasksAsCompleted,
  wrapReducerWithProgressiveFormsUpdate,
} from '@innogy/progressive-ngrx-forms';
import {
  formatDate,
  getDateOffset,
  isDate,
  isDateInRange,
  isEmailAddress,
  isInitials,
  isKvk,
  isLastName,
  isPhoneNumber,
  isPrefix,
  useValidatorIf,
  validateSequential,
} from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { on } from '@ngrx/store';
import { SetValueAction, onNgrxFormsAction, updateGroup } from 'ngrx-forms';
import {
  equalTo,
  maxLength,
  required,
  requiredFalse,
} from 'ngrx-forms/validation';

import { addOrderRenderingAction, initOrderAction } from '../order.actions';
import type { OrderState } from '../order.state';

export const ORDER_PERSONAL_FORM_ID: keyof OrderState = 'orderPersonalForm';
export const companyNameFormControlId = `${ORDER_PERSONAL_FORM_ID}.companyName`;
export const kvkNumberFormControlId = `${ORDER_PERSONAL_FORM_ID}.kvkNumber`;
export const genderFormControlId = `${ORDER_PERSONAL_FORM_ID}.gender`;
export const initialsFormControlId = `${ORDER_PERSONAL_FORM_ID}.initials`;
export const prefixFormControlId = `${ORDER_PERSONAL_FORM_ID}.prefix`;
export const lastNameFormControlId = `${ORDER_PERSONAL_FORM_ID}.lastName`;
export const dateOfBirthFormControlId = `${ORDER_PERSONAL_FORM_ID}.dateOfBirth`;
export const businessClientFormControlId = `${ORDER_PERSONAL_FORM_ID}.businessClient`;
export const emailFormControlId = `${ORDER_PERSONAL_FORM_ID}.email`;
export const emailValidationFormControlId = `${ORDER_PERSONAL_FORM_ID}.emailValidation`;
export const phoneFormControlId = `${ORDER_PERSONAL_FORM_ID}.phone`;

export interface OrderPersonalFormValues {
  companyName: string;
  kvkNumber: string;
  gender: OrderPersonal | '';
  initials: string;
  prefix?: string;
  lastName: string;
  dateOfBirth: string;
  businessClient: boolean;
  email: string;
  emailValidation: string;
  phone: string;
}

export interface OrderPersonalState
  extends ProgressiveFormStateImplementor<OrderPersonalFormValues> {
  rendering?: InnogyComponentRendering | any;
}

export const initialOrderPersonalProgressiveFormState =
  createProgressiveFormGroupState<OrderPersonalFormValues>(
    ORDER_PERSONAL_FORM_ID,
    {
      companyName: '',
      kvkNumber: '',
      gender: '',
      initials: '',
      prefix: '',
      lastName: '',
      dateOfBirth: '',
      businessClient: false,
      email: '',
      emailValidation: '',
      phone: '',
    }
  );

export const initialOrderPersonalState: OrderPersonalState = {
  progressiveForm: initialOrderPersonalProgressiveFormState,
  rendering: undefined,
};

const validateForm = (formState: OrderPersonalState) => {
  const businessFieldsRequired = getRenderingValue(
    'EnableBusinessFields',
    false
  )(formState);
  const personalFieldsRequired = getRenderingValue(
    'EnablePersonalFields',
    false
  )(formState);
  const businessCheckboxRequired = getRenderingValue(
    'EnableBusinessCheckbox',
    false
  )(formState);

  const formattedDate = updateGroup<OrderPersonalFormValues>({
    dateOfBirth: formatDate,
  })(formState.progressiveForm.formState);

  return updateGroup<OrderPersonalFormValues>({
    companyName: validateSequential(
      useValidatorIf(required, businessFieldsRequired),
      maxLength(50)
    ),
    kvkNumber: validateSequential(
      useValidatorIf(required, businessFieldsRequired),
      useValidatorIf(isKvk, businessFieldsRequired)
    ),
    businessClient: validateSequential(
      useValidatorIf(requiredFalse, businessCheckboxRequired)
    ),
    gender: validateSequential(
      useValidatorIf(required, personalFieldsRequired)
    ),
    initials: validateSequential(
      useValidatorIf(required, personalFieldsRequired),
      isInitials,
      // Supplying more than 5 initials (10 characters including dots) breaks the API <3
      maxLength(10)
    ),
    prefix: validateSequential(isPrefix),
    lastName: validateSequential(
      useValidatorIf(required, personalFieldsRequired),
      isLastName,
      maxLength(50)
    ),
    dateOfBirth: validateSequential(
      useValidatorIf(required, personalFieldsRequired),
      isDate,
      useValidatorIf(
        isDateInRange('01-01-1900', getDateOffset(0, 0, -18)),
        personalFieldsRequired
      )
    ),
    email: validateSequential(required, isEmailAddress),
    emailValidation: validateSequential(
      required,
      isEmailAddress,
      equalTo(formState.progressiveForm.formState.value.email)
    ),
    phone: validateSequential(
      required,
      isPhoneNumber,
      // Max length of 12 characters is enforced by BAC
      maxLength(12)
    ),
  })(formattedDate);
};

const _reducer = createProgressiveNgrxFormReducer(
  initialOrderPersonalState,
  validateForm,
  on(addOrderRenderingAction, (state, action) => ({
    ...state,
    rendering: action.rendering,
  })),
  on(initOrderAction, (state) => ({
    ...state,
    progressiveForm: activateFormStep(state.progressiveForm),
  })),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (action.controlId !== businessClientFormControlId) {
      return state;
    }
    if (action.value === false) {
      return {
        ...state,
        progressiveForm: markAsSubmittable(state.progressiveForm),
      };
    }
    return {
      ...state,
      progressiveForm: markAsUnsubmittable(state.progressiveForm),
    };
  }),
  on(putPersonalDetailsSuccess, (state) => ({
    ...state,
    progressiveForm: markAsyncTasksAsCompleted(state.progressiveForm),
  })),
  on(putOrganisationDetailsSuccess, (state) => ({
    ...state,
    progressiveForm: markAsyncTasksAsCompleted(state.progressiveForm),
  }))
);

const _wrappedReducer = wrapReducerWithProgressiveFormsUpdate(
  _reducer,
  (state) => validateForm(state)
);

export function orderPersonalReducer(
  state: OrderPersonalState = initialOrderPersonalState,
  action: Action
): OrderPersonalState {
  return _wrappedReducer(state, action);
}
