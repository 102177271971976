import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type {
  FilterEntry,
  SegmentedControlEntry,
} from '@innogy/common-ui/utility-components';
import { isDefined } from '@essent/core-utils';
import { ContractType } from '@innogy/core/analytics';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wl-commodity-offer-filter',
  templateUrl: './commodity-offer-filter.component.html',
  styleUrls: [
    './commodity-offer-filter.component.ed.scss',
    './commodity-offer-filter.component.essent.scss',
  ],
})
export class CommodityOfferFilterComponent {
  @Input() offers?: Offer[];
  @Input() selectedContractType?: ContractType;
  @Input() availableContractTypes?: ContractType[];

  @Output() changeContractType = new EventEmitter<ContractType>();

  public readonly translateService = inject(TranslateService);

  mapToEntry(value: ContractType): FilterEntry<ContractType> {
    const translatedContractTypes = {
      [ContractType.vast]: this.translateService.instant('Fixed'),
      [ContractType.dynamic]: this.translateService.instant('Dynamic'),
      [ContractType.flexibel]: this.translateService.instant('Flexible'),
    };

    return {
      id: value.toLocaleLowerCase(),
      value,
      text: translatedContractTypes[value],
    };
  }

  get entries(): FilterEntry<ContractType>[] {
    return (
      this.availableContractTypes
        ?.map((view) => this.mapToEntry(view))
        .filter(isDefined) ?? []
    );
  }

  get selectedEntry(): SegmentedControlEntry<ContractType> | undefined {
    return (
      this.selectedContractType && this.mapToEntry(this.selectedContractType)
    );
  }
}
