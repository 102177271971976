import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BecomeACustomerUiModule } from '@innogy/become-a-customer/ui';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { UtilsModule } from '@innogy/utils/deprecated';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { NgrxFormsModule } from 'ngrx-forms';
import { CommonUiSalesModule } from '@innogy/common-ui/sales';

import { UsageQuestionnaireContainerComponent } from './usage-questionnaire-container/usage-questionnaire-container.component';
import { UsageQuestionnaireSMEComponent } from './usage-questionnaire-sme/usage-questionnaire-sme.component';

const exportedComponents = [
  UsageQuestionnaireContainerComponent,
  UsageQuestionnaireSMEComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CoreAnalyticsModule,
    CoreExperienceEditorModule,
    CommonUiUtilityComponentsModule,
    CommonUiFormsModule,
    JssModule,
    UtilsModule,
    NgbModalModule,
    CommonUiSharedModule,
    NgrxFormsModule,
    BecomeACustomerUiModule,
    CommonUiSalesModule,
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
})
export class BecomeACustomerUsageQuestionnaireComponentModule {}
