import type { SubmitPropositionProps } from '@innogy/become-a-customer/shared/interfaces';
import { createAction, props } from '@ngrx/store';

import { BAC_ACTION_PREFIX } from '../constants';

export const submitPropositionAction = createAction(
  `${BAC_ACTION_PREFIX} Submit proposition`,
  props<SubmitPropositionProps>()
);

export const submitPropositionHorizontalAction = createAction(
  `${BAC_ACTION_PREFIX} Submit proposition horizontal`,
  props<SubmitPropositionProps>()
);
