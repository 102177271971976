import { Inject, Injectable } from '@angular/core';
import {
  API_CLIENT,
  mapApiClientRequestToApiActions,
  type ApiClient,
} from '@innogy/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { getProspectApiActions, getProspect } from './lead-form.actions';

@Injectable()
export class LeadFormProspectEffects {
  constructor(
    private readonly actions$: Actions,
    @Inject(API_CLIENT) private readonly apiClient: ApiClient
  ) {}

  getProspect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProspect),
      mapApiClientRequestToApiActions((action) => ({
        apiActions: getProspectApiActions,
        request: () =>
          this.apiClient.GET('/emobility/partners/prospect/v1', {
            params: {
              query: { verificationCode: action.verificationCode },
            },
          }),
      }))
    )
  );
}
