import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { submitPropositionAction } from '@innogy/become-a-customer/state';
import { openGenericLightbox } from '@innogy/common-ui/modals';
import type { Store } from '@ngrx/store';

export const moreInfoButtonClick = (store$: Store, offer: Offer) => () =>
  store$.dispatch(
    openGenericLightbox({
      title: offer.moreInfoTitle || offer.productTitle,
      url: offer.moreInfoUrl,
      tracking: { componentName: 'meer info lightbox' },
      cta: {
        text: offer.moreInfoCtaButtonText,
        onClick: () => store$.dispatch(submitPropositionAction({ offer })),
      },
    })
  );
