import type { ActionCreator } from '@ngrx/store/src/models';
import type { OnReducer, ReducerTypes } from '@ngrx/store/src/reducer_creator';

type ExtractActionTypes<Creators extends readonly ActionCreator[]> = {
  [Key in keyof Creators]: Creators[Key] extends ActionCreator<infer T>
    ? T
    : never;
};

/**
 * @description
 * Conditionally associates actions with a given state change function.
 * A state change function must be provided as the last parameter.
 *
 * @param args a boolean condition, followed by an array of `ActionCreator`'s followed by a state change function.
 *
 * @returns an association of action types with a state change function.
 *
 * @usageNotes
 * ```ts
 * conditionalOn(sessionIsValid, AuthApiActions.loginSuccess, (state, { user }) => ({ ...state, user }))
 * ```
 */
export function conditionalOn<
  State,
  OptCreators extends readonly (ActionCreator | undefined)[]
>(
  ...args: [
    condition: boolean,
    ...creators: OptCreators,
    reducer: OnReducer<State extends infer S ? S : never, ActionCreator[]>
  ]
): ReducerTypes<State, ActionCreator[]> {
  const condition = args.shift() as boolean;
  const reducer = args.pop() as OnReducer<any, ActionCreator[]>;

  if (!condition) {
    return { reducer, types: [] };
  }

  const types = (args as unknown as ActionCreator[])
    .filter((c) => !!c)
    .map((creator) => creator.type) as unknown as ExtractActionTypes<
    ActionCreator[]
  >;
  return { reducer, types };
}
