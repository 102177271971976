import { createSelector } from '@ngrx/store';
import { Status, IncomingPaymentMethod } from '@essent/common';
import {
  getEmobilityContractGroupId,
  getSelectedContractGroupId,
  getSelectedOrFirstContractGroup,
} from '@innogy/core/contract';
import {
  getCustomerHasError,
  getIsLoggedOut,
  isCustomerSME,
} from '@innogy/account';

import { getPaymentDetailsState } from './index';
import { getUpdateError } from './update-payment-method.selectors';
import type {
  PaymentDetailsVM,
  PaymentDetailsWithInfoVM,
} from '../payment-details.model';
import type { State } from './payment-details.reducer';

export const getDetailsState = createSelector(
  getPaymentDetailsState,
  (feature) => feature?.details
);

export const getDetailsError = createSelector(
  getDetailsState,
  (state: State) => state.error
);

export const getDetails = createSelector(
  getDetailsState,
  (state: State) => state.details
);

export const getDetailsAddressId = createSelector(
  getDetailsState,
  (state: State) => state.contractGroupId
);

export const getDetailsStatus = createSelector(
  getDetailsState,
  (state: State) => state?.status
);

export const getHasPaymentDetailsError = createSelector(
  getDetailsStatus,
  (status) => status === Status.ERROR
);

export const getIsDetailsLoading = createSelector(
  getDetailsStatus,
  (status) => status === Status.IDLE || status === Status.PENDING
);

export const getPaymentMethod = createSelector(
  getDetails,
  (details) => details?.paymentDetails?.incomingPaymentMethod
);

export const getIsAutomaticPayment = createSelector(
  getPaymentMethod,
  (method) => method === IncomingPaymentMethod.DIRECT_DEBIT || false
);

export const getHasPaymentDatePreferred = createSelector(
  getDetails,
  (details) =>
    details != null && 'dayOfPayment' in details.paymentDetails.paymentChoice
);

export const getIsDetailsSelectedContractId = createSelector(
  getDetailsAddressId,
  getSelectedContractGroupId,
  (contractGroupId, selectedContractGroupId) =>
    contractGroupId === selectedContractGroupId
);

export const getIsPaymentDetailsLoading = createSelector(
  getIsDetailsLoading,
  getIsDetailsSelectedContractId,
  (isLoading, isDetailsSelectedContractId) =>
    isLoading || !isDetailsSelectedContractId
);

export const getIsEmobilityDetailsSelectedContractId = createSelector(
  getDetailsAddressId,
  getEmobilityContractGroupId,
  (contractGroupId, emobilityContractGroupId) =>
    contractGroupId === emobilityContractGroupId
);
export const getIsEmobilityPaymentDetailsLoading = createSelector(
  getIsDetailsLoading,
  getIsEmobilityDetailsSelectedContractId,
  (isLoading, isEmobilityDetailsSelectedContractId) =>
    isLoading || !isEmobilityDetailsSelectedContractId
);
export const getDetailsAndStates = createSelector(
  getDetails,
  getIsPaymentDetailsLoading,
  getHasPaymentDetailsError,
  getHasPaymentDatePreferred,
  getSelectedContractGroupId,
  getIsAutomaticPayment,
  isCustomerSME,
  (
    details,
    showLoading,
    showError,
    hasPaymentDatePreferred,
    selectedContractGroupId,
    hasAutomaticPayment,
    isSMECustomer
  ): PaymentDetailsVM => {
    const showDetails = !showError && !showLoading;

    return {
      details,
      hasPaymentDatePreferred,
      hasAutomaticPayment,
      isSMECustomer,
      showError,
      showLoading,
      showDetails,
      selectedContractGroupId,
    };
  }
);

export const getEmobilityDetailsAndStates = createSelector(
  getDetails,
  getIsEmobilityPaymentDetailsLoading,
  getHasPaymentDetailsError,
  getHasPaymentDatePreferred,
  getEmobilityContractGroupId,
  getIsAutomaticPayment,
  isCustomerSME,
  (
    details,
    showLoading,
    showError,
    hasPaymentDatePreferred,
    selectedContractGroupId,
    hasAutomaticPayment,
    isSMECustomer
  ): PaymentDetailsVM => {
    const showDetails = !showError && !showLoading;
    return {
      details,
      hasPaymentDatePreferred,
      hasAutomaticPayment,
      isSMECustomer,
      showLoading,
      showError,
      showDetails,
      selectedContractGroupId,
    };
  }
);
export const getPaymentDetailsWithInfo = createSelector(
  getDetailsAndStates,
  getSelectedOrFirstContractGroup,
  getIsLoggedOut,
  getUpdateError,
  getCustomerHasError,
  (
    paymentMethodVM,
    selectedContractGroup,
    isLoggedOut,
    updateError,
    customerHasError
  ): PaymentDetailsWithInfoVM => {
    const showError = paymentMethodVM.showError || customerHasError;
    const showLoading = paymentMethodVM.showLoading;
    const showContent = paymentMethodVM.showDetails;
    return {
      updateError,
      showError,
      showLoading,
      showContent,
      isLoggedOut,
      paymentDetails: paymentMethodVM.details,
      contractGroup: selectedContractGroup,
    };
  }
);
