import { Component, Input } from '@angular/core';
import type { InnogyComponentRendering } from '@innogy/jss/models';
import { LinkField, TextField } from '@sitecore-jss/sitecore-jss-angular';
import { NavigationExtras } from '@angular/router';
import { AnalyticsActionTypes } from '@innogy/core/analytics';

@Component({
  selector: 'wl-success-page',
  templateUrl: './success-page.component.html',
})
export class SuccessPageComponent {
  @Input() public rendering?: InnogyComponentRendering | any;
  @Input() public automationId?: string;
  @Input() public title?: TextField;
  @Input() public successTitle?: TextField;
  @Input() public successMessage?: TextField;
  @Input() public successSubMessage?: TextField;
  @Input() public successLink?: LinkField;
  @Input() public backLink?: LinkField;
  @Input() public step = 1;
  @Input() public stepName?: string;
  @Input() public navigationExtras: NavigationExtras = {};
  @Input() public trackLastStep = true;
  @Input() public e2eTrackingId?: string | null;

  get extras(): NavigationExtras {
    return {
      queryParamsHandling: 'merge',
      queryParams: { returnUrl: null },
      ...this.navigationExtras,
    };
  }

  protected readonly AnalyticsActionTypes = AnalyticsActionTypes;
}
