import type { SolarPanelsProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import { createAction, props } from '@ngrx/store';

export const PREFIX = '[Solar result overview]';

export const solarPanelsResultOverviewSubmitAction = createAction(
  `${PREFIX} Submit`,
  props<{ product: SolarPanelsProductWithCalculations }>()
);

export const solarPanelsResultOverviewShowLoaderAction = createAction(
  `${PREFIX} ShowLoader`
);

export const solarPanelsResultOverviewHideLoaderAction = createAction(
  `${PREFIX} HideLoader`
);
