<div class="offer-card-header">
  <div *ngIf="highlight" class="offer-card-header__highlight-label">
    {{ highlight }}
  </div>

  <wl-commodity-offer-label
    *ngIf="incentive && showPriceTag"
    [style]="'incentive'"
    [label]="incentive"
    class="offer-card-header__incentive-label"
  ></wl-commodity-offer-label>

  <div class="d-flex flex-row align-items-center">
    <div class="flex-grow-1 d-flex flex-column">
      <h4 class="offer-card-header__title">{{ duration }}</h4>
      <span class="offer-card-header__subtitle">{{ productTitle }}</span>
    </div>

    <ng-container *ngIf="showPriceTag">
      <ng-container *ngTemplateOutlet="priceTag"></ng-container>
    </ng-container>
    <ng-container *ngIf="!showPriceTag">
      <ng-container *ngTemplateOutlet="incentiveSticker"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #priceTag>
  <div class="d-flex flex-column align-items-end me-1">
    <h2 class="offer-card-header__budget-bill-amount mb-0">
      {{ price ?? '-' | roundedCurrency }}
    </h2>
    <span class="offer-card-header__budget-bill-label">{{
      priceDurationLabel
    }}</span>
  </div>
</ng-template>

<ng-template #incentiveSticker>
  <div *ngIf="incentive" class="offer-card-header__incentive-sticker">
    {{ incentive }}
  </div>
</ng-template>
