import type { CollectionState } from '@innogy/utils/state';
import {
  createInitialCollectionState,
  getCollectionEntry,
  updateCollectionState,
} from '@innogy/utils/state';
import { createReducer, on } from '@ngrx/store';
import uniq from 'lodash/uniq';

import * as actions from './progressive-form.actions';
import type { ProgressiveFormState } from './progressive-form.model';

export const initialState: ProgressiveFormState = {
  previousStepId: undefined,
  currentStepId: undefined,
  naturalNextStepId: undefined,
  isEditingPreviouslySubmittedForm: false,
  scrollToInitialStep: false,
  stepFormStateBackup: undefined,
  formSteps: [],
  skippedFormSteps: [],
  openFormSteps: [],
  completedFormSteps: [],
  progressOnValidSubmit: true,
};

export type ProgressiveFormCollectionState =
  CollectionState<ProgressiveFormState>;
export const initialProgressiveFormCollectionState: ProgressiveFormCollectionState =
  createInitialCollectionState();

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
export const progressiveFormReducer = createReducer(
  initialProgressiveFormCollectionState,
  on(actions.setFormConfig, (state, action) => {
    const formId = action.formId;
    const entry = getCollectionEntry(state, formId);
    return updateCollectionState(state, formId, {
      entry: {
        ...initialState,
        ...entry,
        formSteps: action.formSteps,
        scrollToInitialStep: action.scrollToInitialStep,
        progressOnValidSubmit: action.progressOnValidSubmit,
        useFormStepConfig: action.formStepsEntities ? true : undefined,
      },
    });
  }),
  on(actions.setActiveFormStep, (state, action) => {
    //Techdebt: do not base logic on state in reducer that can be passed in payload of action
    // Now its (unneccesarily) complex

    const formId = action.formId;
    const entry = getCollectionEntry(state, formId);
    const nextStepId = action.stepId;
    const skippedFormSteps = [
      ...(entry?.skippedFormSteps ?? []),
      ...(entry?.completedFormSteps ?? []),
    ];
    const defaultNextStepId = entry?.openFormSteps[0]
      ? entry?.openFormSteps[0]
      : getUnskippedStepId(nextStepId, entry?.formSteps, skippedFormSteps);
    const nextStepIdConsideringSkippedSteps = action.isEditing
      ? nextStepId
      : defaultNextStepId;
    const previousStepId = getPreviousStepId(entry, nextStepId);
    const naturalNextStepId = getNaturalNextStepId(
      nextStepIdConsideringSkippedSteps,
      entry?.formSteps,
      skippedFormSteps
    );
    return updateCollectionState(state, formId, {
      entry: {
        ...initialState,
        ...entry,
        previousStepId,
        currentStepId: nextStepIdConsideringSkippedSteps,
        naturalNextStepId: action.isEditing
          ? previousStepId
          : naturalNextStepId,
        isEditingPreviouslySubmittedForm: action.isEditing ?? false,
        stepFormStateBackup: action.stepFormState,
      },
    });
  }),
  on(actions.openFormStep, (state, action) => {
    const entry = getCollectionEntry(state, action.formId);
    return updateCollectionState(state, action.formId, {
      entry: {
        ...initialState,
        ...entry,
        naturalNextStepId: action.stepId,
      },
    });
  }),
  on(actions.setEditing, (state, action) => {
    const entry = getCollectionEntry(state, action.formId);
    return updateCollectionState(state, action.formId, {
      entry: {
        ...initialState,
        ...entry,
        isEditingPreviouslySubmittedForm: action.editing,
      },
    });
  }),
  on(actions.resetProgressiveForm, (state, action) => {
    // if no formId is present, don't try to fetch a collection entry
    // as it will result in an unhandled error.
    if (!action.formId) {
      return state;
    }
    const entry = getCollectionEntry(state, action.formId);
    if (!entry) {
      return state;
    }
    return updateCollectionState(state, action.formId, {
      entry: {
        ...initialState,
        formSteps: entry.formSteps,
        scrollToInitialStep: entry.scrollToInitialStep,
      },
    });
  }),
  on(actions.updateCompletedFormSteps, (state, action) => {
    return updateArraySteps(state, action, 'completedFormSteps');
  }),
  on(actions.updateSkipFormSteps, (state, action) => {
    return updateArraySteps(state, action, 'skippedFormSteps');
  })
);

const verifySkippedSteps = (
  stepId: number,
  formSteps: string[],
  skippedFormSteps: string[]
) => {
  let naturalNextStepIndex = stepId;
  while (skippedFormSteps.includes(formSteps[naturalNextStepIndex])) {
    naturalNextStepIndex++;
  }
  return naturalNextStepIndex < formSteps.length
    ? formSteps[naturalNextStepIndex]
    : undefined;
};

export const getUnskippedStepId = (
  stepId?: string,
  formSteps?: string[],
  skippedFormSteps?: string[]
): string | undefined => {
  if (!stepId || !formSteps || !skippedFormSteps) {
    return;
  }
  const naturalNextStepIndex = formSteps.findIndex((cur) => cur === stepId);
  return verifySkippedSteps(naturalNextStepIndex, formSteps, skippedFormSteps);
};

export const getNaturalNextStepId = (
  stepId?: string,
  formSteps?: string[],
  skippedFormSteps?: string[]
): string | undefined => {
  if (!stepId || !formSteps || !skippedFormSteps) {
    return;
  }
  const naturalNextStepIndex = formSteps.findIndex((cur) => cur === stepId) + 1;
  return verifySkippedSteps(naturalNextStepIndex, formSteps, skippedFormSteps);
};

export const getPreviousStepId = (
  state: ProgressiveFormState | undefined,
  stepId: string
) =>
  state && state.currentStepId !== stepId ? state.currentStepId : undefined;

function updateArraySteps(
  state: ProgressiveFormCollectionState,
  action: { formId: string; state: boolean; stepIds: string[] },
  field: 'skippedFormSteps' | 'completedFormSteps'
) {
  const entry = {
    ...initialState,
    ...getCollectionEntry(state, action.formId),
  };
  let formSteps;
  if (action.state) {
    formSteps = uniq([...entry[field], ...action.stepIds]);
  } else {
    formSteps = entry[field].filter(
      (step: string) => !action.stepIds.includes(step)
    );
  }

  let updatedEntry: ProgressiveFormState = {
    ...entry,
    [field]: formSteps,
  };
  const completedFormSteps = updatedEntry.completedFormSteps.filter(
    (step) => !updatedEntry.skippedFormSteps.includes(step)
  );
  const openFormSteps = entry.formSteps.filter(
    (step) =>
      !completedFormSteps.includes(step) &&
      !updatedEntry.skippedFormSteps.includes(step)
  );

  if (updatedEntry.isEditingPreviouslySubmittedForm) {
    updatedEntry = {
      ...updatedEntry,
      naturalNextStepId: getNaturalNextStepId(
        updatedEntry.currentStepId,
        updatedEntry.formSteps,
        [...updatedEntry.skippedFormSteps, ...updatedEntry.completedFormSteps]
      ),
    };
  }
  updatedEntry = {
    ...updatedEntry,
    completedFormSteps,
    openFormSteps,
  };

  return updateCollectionState(state, action.formId, {
    entry: updatedEntry,
  });
}
