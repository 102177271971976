import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { EffectsModule } from '@ngrx/effects';
import { UtilsModule } from '@innogy/utils/deprecated';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { GenericLightboxEffects } from './+state/generic-lightbox.effects';
import { GenericLightboxComponent } from './generic-lightbox.component';

const EFFECTS = [GenericLightboxEffects];

@NgModule({
  declarations: [GenericLightboxComponent],
  imports: [
    CommonModule,
    CommonUiSharedModule,
    JssModule,
    CommonUiUtilityComponentsModule,
    UtilsModule,
    CoreAnalyticsModule,
    EffectsModule.forFeature(EFFECTS),
  ],
})
export class GenericLightboxModule {}
