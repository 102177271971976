export interface IAdvize {
  navigate: (path: string) => any;
  activate: (
    self: (iAdvize: IAdvize) => any
  ) => Promise<IAdvizeAuthenticationOption>;
  logout: () => void;
  get: () => void;
  help: () => void;
  off: () => any;
  on: () => any;
}

/**
 * @see https://docs.iadvize.dev/technologies/web-and-mobile-sdk/javascript-web-sdk/reference
 */
export interface IAdvizeInterface {
  push: (reference: (iAdvize: IAdvize) => any) => any;
  config: {
    language: string;
    platformDomain: string;
    sid: string;
    useExplicitCookiesConsent: boolean;
  };
}

/**
 * @see https://help.iadvize.com/hc/en-gb/articles/6043078724626
 */
export interface IAdvizeAuthenticationOption {
  authenticationOption: {
    type: IAdvizeAuthenticationType;
    token?: string;
  };
}

export enum IAdvizeAuthenticationType {
  SECURED_AUTHENTICATION = 'SECURED_AUTHENTICATION',
  ANONYMOUS = 'ANONYMOUS',
  SIMPLE_AUTHENTICATION = 'SIMPLE_AUTHENTICATION',
}
