<wl-experience-editor>
  <ng-template wlPreview>
    <span> <strong>Bewerk deze video:</strong> </span>
    <ng-container *ngIf="video$ | async as video">
      <img [src]="video?.imageUrl" class="img-fluid mx-auto d-block" />
    </ng-container>
  </ng-template>

  <ng-template wlLive>
    <!-- Wait for video url to load, otherwise video source is set to null -->
    <ng-container *ngIf="video$ | async as video">
      <wl-video
        [video]="video"
        wlTrackImpression="contentblock-impression"
        [trackImpressionInfo]="rendering"
        [trackImpressionContext]="context"
      ></wl-video>
    </ng-container>
  </ng-template>
</wl-experience-editor>
