import { Component, Input, type OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  type InnogyComponentRendering,
  type ScCheckboxField,
} from '@innogy/jss/models';
import { getDropTreeValue, getFieldValue } from '@innogy/jss/utils';
import type { RichTextField } from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

export interface InformationBarComponentRendering
  extends InnogyComponentRendering {
  showComponent?: ScCheckboxField;
  message?: RichTextField;
  pagesToShow?: InnogyComponentRendering[];
}

/**
 * Design: @see https://www.figma.com/design/22GesGj6scRTytPn2pzx4K/Essent-Web-Components?node-id=133-8409&t=XlU4nBEy4B9gKXp5-4
 * Template: Innogy/General/Information Bar/Information Bar
 */
@Component({
  selector: 'wl-information-bar',
  templateUrl: './information-bar.component.html',
  styleUrls: [
    './information-bar.component.essent.scss',
    './information-bar.component.ed.scss',
  ],
})
export class InformationBarComponent implements OnInit {
  @Input({ required: true })
  rendering!: InformationBarComponentRendering;

  @Input()
  showComponent = false;

  get showComponentField() {
    return getFieldValue(this.rendering, 'showComponent', false);
  }

  ngOnInit(): void {
    this.showComponent = this.showComponentField && this.currentPageIsEnabled;
  }

  get currentPageIsEnabled() {
    const pages = getDropTreeValue<Item[]>(
      this.rendering?.fields,
      'pagesToShow',
      []
    );

    // Map over the retrieved pages to get their URLs
    // If the URL is undefined, use an empty string and remove leading/trailing slashes
    const pagePaths = pages.map((page) =>
      (page.url ?? '').replace(/^\/|\/$/g, '')
    );

    // Parse the current URL using the router
    const urlTree = this.router.parseUrl(this.router.url);

    // Extract the primary route segments from the URL tree
    // If primary route segments exist, join them to form the full path
    // If no primary route segments exist, use an empty string
    const pageUrl = urlTree.root.children['primary']
      ? urlTree.root.children['primary']?.segments
          .map((segment) => segment.path)
          .join('/')
      : '';

    // Check if the constructed pageUrl exists in the array of pagePaths
    return pagePaths.some((path) => path === pageUrl);
  }

  constructor(private readonly router: Router) {}
}
