import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { UtilsModule } from '@innogy/utils/deprecated';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { GenericErrorModalComponent } from './generic-error-modal.component';
import { OpenModalDirective } from './open-modal.directive';

const directives = [OpenModalDirective];
@NgModule({
  declarations: [GenericErrorModalComponent, ...directives],
  imports: [
    CoreAnalyticsModule,
    JssModule,
    UtilsModule,
    CommonModule,
    CommonUiSharedModule,
    CommonUiUtilityComponentsModule,
  ],
  exports: [...directives],
})
export class GenericErrorModalModule {}
