import type {
  GenericModalPayloadMapping,
  GenericModalPosition,
  GenericModalProps,
  GenericModalSitecoreSources,
  GenericModalSources,
} from '@innogy/common-ui/shared/interfaces';
import { getDroplinkValue, getFieldValue } from '@innogy/jss/utils';

/**
 * @param source
 * @param modalProperties - The modal properties defined in sitecore
 * @param mapAdditionalPayload
 */
export const mapGenericModalProperties = (
  source: GenericModalSources,
  modalProperties: GenericModalSitecoreSources | undefined,
  mapAdditionalPayload?: GenericModalPayloadMapping[]
): GenericModalProps => ({
  source,
  title: getFieldValue(modalProperties, 'Title', ''),
  // Passing the modal body as Sitecore field allows analytics to track links in RichText
  body: getFieldValue(modalProperties, 'Body', ''),
  primaryButtonText: getFieldValue(modalProperties, 'PrimaryButtonText'),
  primaryButtonStyle: getDroplinkValue(modalProperties, 'PrimaryButtonStyle'),
  secondaryButtonText: getFieldValue(modalProperties, 'SecondaryButtonText'),
  secondaryButtonStyle: getDroplinkValue(
    modalProperties,
    'SecondaryButtonStyle'
  ),
  position: getDroplinkValue(modalProperties, 'Position') as
    | GenericModalPosition
    | undefined,
  tracking: {
    componentName: getFieldValue(
      modalProperties,
      'ComponentName',
      'unknown modal'
    ),
    primaryButtonTrackingField: getFieldValue(
      modalProperties,
      'PrimaryButtonName'
    ),
    secondaryButtonTrackingField: getFieldValue(
      modalProperties,
      'SecondaryButtonName'
    ),
  },
  additionalPayload: (mapAdditionalPayload || []).reduce(
    (acc, { from, to }) => ({
      ...acc,
      [to]: getFieldValue(modalProperties, from),
    }),
    {}
  ),
});
