import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { TrackToolStep } from '@innogy/core/analytics';
import { FALLBACK_STEP_NAME, FALLBACK_TOOL_NAME } from '@innogy/core/analytics';
import { ProgressiveFormService } from '@innogy/shared/progressive-form';
import type { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { createSelector, Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import type {
  ProgressiveFormStepConfig,
  ProgressiveFormFormState,
} from '@innogy/shared/progressive-form/sitecore/models';
import { ScDynamicProgressiveFormConfig } from '@innogy/shared/progressive-form/sitecore/models';
import { generateFormIdFromUUID } from '@innogy/shared/forms';

import { GenericProgressiveFormService } from '../../../services/generic-progressive-form.service';
import { submitGenericProgressiveFormAction } from '../../../store';

@Component({
  selector: 'wl-progressive-form-container[config]',
  templateUrl: './progressive-form-container.component.html',
})
export class ProgressiveFormContainerComponent implements OnInit {
  @Input() config!: ScDynamicProgressiveFormConfig;

  formSteps: (ProgressiveFormStepConfig & {
    formState: Observable<Record<string, unknown>>;
    selectorKey: string;
  })[] = [];

  /**
   * @returns unique identifier for the provided form ending with 6 characters of the sitecore itemId
   */
  get progressiveFormSelectorKey() {
    const formId = generateFormIdFromUUID(this.config.uid);
    return `generic-progressive-form-${formId}`;
  }

  progressiveFormSelector?: MemoizedSelector<
    Record<string, unknown>,
    ProgressiveFormFormState,
    DefaultProjectorFn<ProgressiveFormFormState>
  >;

  constructor(
    private readonly genericProgressiveFormService: GenericProgressiveFormService,
    private readonly progressiveFormService: ProgressiveFormService,
    private readonly store: Store
  ) {}

  private registerForm() {
    return this.genericProgressiveFormService.registerForm(
      this.config,
      this.progressiveFormSelectorKey
    );
  }

  private parseFormSteps() {
    const formStateForFormStep = (formStep: ProgressiveFormStepConfig) => {
      const selector = createSelector(
        this.progressiveFormSelector as any,
        (state) =>
          (state as any)[
            this.genericProgressiveFormService.getFormStepId(formStep)
          ].form.formState
      );
      return this.store.select(selector);
    };

    return this.config.formSteps.map((formStep) => ({
      ...formStep,
      formState: formStateForFormStep(formStep),
      selectorKey: `${
        this.progressiveFormSelectorKey
      }.${this.genericProgressiveFormService.getFormStepId(formStep)}`,
    }));
  }

  private initializeProgressiveForms() {
    this.store.dispatch(
      this.progressiveFormService.setFormConfigAction({
        formId: this.progressiveFormSelectorKey,
        formSteps: this.formSteps.map((formStep) =>
          this.genericProgressiveFormService.getFormStepId(formStep)
        ),
        progressOnValidSubmit: true,
        scrollToInitialStep: true,
      })
    );
    this.store.dispatch(
      this.progressiveFormService.initProgressiveForm({
        formId: this.progressiveFormSelectorKey,
      })
    );
  }

  extractFieldsFromStep({ fields }: ProgressiveFormStepConfig) {
    return { fields } as any;
  }

  ngOnInit(): void {
    this.progressiveFormSelector = this.registerForm();

    this.formSteps = this.parseFormSteps();
    this.initializeProgressiveForms();
  }

  getTrackStepConfig(
    formStep: ProgressiveFormStepConfig,
    index: number
  ): TrackToolStep {
    return {
      step: index,
      stepName: formStep.fields.StepName.value ?? FALLBACK_STEP_NAME,
      toolName: this.config.toolName.value ?? FALLBACK_TOOL_NAME,
    };
  }

  submittedAtIndex(index: number) {
    if (index === this.formSteps.length - 1) {
      const formId = this.progressiveFormSelectorKey;
      const toolName = this.config.toolName.value || FALLBACK_TOOL_NAME;
      this.store.dispatch(
        submitGenericProgressiveFormAction({
          formId,
          toolName,
        })
      );
    }
  }
}
