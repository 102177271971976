import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { provideBootstrapEffects } from '@innogy/utils/deprecated';

import { configSelectorKey } from './+state/config.selectors';
import { configReducer } from './+state/config.reducer';
import { ConfigEffects } from './+state/config.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(configSelectorKey, configReducer),
  ],
  providers: provideBootstrapEffects([ConfigEffects]),
})
export class ConfigStoreModule {}
