<ng-container *ngIf="placement$ | async as placement">
  <wl-breadcrumb
    *ngIf="fields?.EnableBreadcrumbs?.value"
    [overlap]="fields?.Overlap?.value"
    [greyBackground]="fields?.GreyBackground?.value"
    [whiteText]="fields?.WhiteText?.value"
    [includeCurrentPage]="fields?.IncludeCurrentPage?.value"
    [routes]="routes"
    wlTrackImpression="navigation-impression"
    trackImpressionMode="any"
    [trackImpressionInfo]="{
      name: 'breadcrumb',
      placement: placement
    }"
  ></wl-breadcrumb>
</ng-container>
