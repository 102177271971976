<div class="app-buttons__title" *ngIf="titleField?.value">
  <span class="fw-bold" *scText="titleField"></span>
</div>
<div class="d-flex">
  <a class="app-buttons__link" *wlGenericLink="androidLink">
    <img
      [src]="'assets/images/app-button-android.svg' | deployUrl"
      [alt]="androidLink.value?.text"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: { componentName: trackingName },
        field: androidLinkTrackingField?.value || androidLink.value?.text,
        linkurl: androidLink.value?.href,
        type: 'button',
        eventType: 'link-click'
      }"
    />
  </a>
  <a class="app-buttons__link" *wlGenericLink="iosLink">
    <img
      [src]="'assets/images/app-button-ios.svg' | deployUrl"
      [alt]="iosLink.value?.text"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: { componentName: trackingName },
        field: iosLinkTrackingField?.value || iosLink.value?.text,
        linkurl: iosLink.value?.href,
        type: 'button',
        eventType: 'link-click'
      }"
    />
  </a>
</div>
