import { Inject, Injectable } from '@angular/core';
import { TEST_SUBMISSION_EMAIL } from '@innogy/become-a-customer/shared/testing';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import { WINDOW } from '@innogy/utils/dom';

import type {
  MGMBuyerInfo,
  MGMWidgetConfig,
  MGMWindowAdditions,
} from './model';

@Injectable({
  providedIn: 'root',
})
export class MemberGetMemberService {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) readonly config: EnvironmentConfig,
    @Inject(WINDOW) private readonly windowRef: Window & MGMWindowAdditions
  ) {}

  public ping(buyerInfo: MGMBuyerInfo) {
    if (!this.windowRef.mgmcoPing?.send) {
      throw new Error(
        'mgmcoPing could not be found on the window object. Is the ping.js script loaded?'
      );
    }

    const isTestEnvironment = !['prd', 'staging'].includes(
      this.config.environment
    );
    if (isTestEnvironment) {
      buyerInfo.name = 'test';
      buyerInfo.email = TEST_SUBMISSION_EMAIL;
    }

    this.windowRef.mgmcoPing.send(buyerInfo);
  }

  public initWidget(widgetConfig: MGMWidgetConfig) {
    if (!this.windowRef.MGMCo?.init) {
      throw new Error(
        'MGMCo could not be found on the window object. Is the app.js script loaded?'
      );
    }
    this.windowRef.MGMCo.init(widgetConfig);
  }
}
