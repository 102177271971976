<ng-container *ngIf="progressiveFormState">
  <div
    class="card rounded p-3 mb-2 simple-prog-form-card"
    [progressiveFormStep]="progressiveFormState"
    [id]="progressiveFormState.id"
    [wlAutomationId]="progressiveFormState.id + '-form-card'"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="progressive-form-header-area">
        <h3 class="h4 my-0 d-flex align-items-center" wlCSCapture>
          <fa-icon
            *ngIf="progressiveFormState | showProgressiveFormsIcon"
            class="me-1"
            icon="check-circle"
          ></fa-icon>
          <!-- the input content.title comes as string -->
          <span *wlGenericRichText="{ value: content?.title }"></span>
        </h3>
        <span *ngIf="totalSteps && !progressiveFormState.isInert" class="me-1">
          ({{ content?.stepNumber }}/{{ totalSteps }})
        </span>
      </div>
      <div>
        <div
          *ngIf="progressiveFormState | showProgressiveFormsEditButton"
          class="ms-2"
          [wlAutomationId]="progressiveFormState.id + '-edit-button'"
          wlCSCapture
        >
          <button
            class="btn btn-sm btn-outline-primary d-none d-sm-block"
            [editProgressiveFormStep]="progressiveFormState"
          >
            {{ 'Change' | translate }}
          </button>
          <button
            class="btn btn-sm btn-icon btn-outline-primary d-sm-none"
            [editProgressiveFormStep]="progressiveFormState"
          >
            <fa-icon icon="pen"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-container>
      <p *ngIf="progressiveFormState.isActive" class="my-0" wlCSCapture>
        {{ content?.subtitle }}
      </p>
      <hr
        *ngIf="progressiveFormState | showProgressiveFormsSeparator"
        class="my-2"
        wlCSCapture
      />
    </ng-container>
    <div class="simple-progressive-form-card--form">
      <ng-container [ngTemplateOutlet]="formRef"></ng-container>
    </div>
    <div
      class="simple-progressive-form-card--summary"
      [ngbCollapse]="!(progressiveFormState | showProgressiveFormsSummary)"
    >
      <div [wlAutomationId]="progressiveFormState.id + '-summary'">
        <ng-container *ngTemplateOutlet="summaryRef ?? emptyRef"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #formRef>
  <ng-container
    *ngIf="progressiveFormState | showProgressiveFormsForm"
    [wlTrackStep]="{
      rendering: { componentName: trackingConfig?.toolName ?? '' },
      step: trackingConfig?.step ?? 0,
      stepname: trackingConfig?.stepName,
      toolName: trackingConfig?.toolName
    }"
  ></ng-container>
  <form
    *ngIf="progressiveFormState"
    novalidate
    [ngrxFormState]="progressiveFormState.formState"
    [focusOnFirstChildControl]="progressiveFormState"
    [enableFocusing]="enableAutomaticFocusing"
    [ngbCollapse]="!(progressiveFormState | showProgressiveFormsForm)"
    (shown)="scrollIntoViewIfEnabled()"
  >
    <div class="mb-4" [wlAutomationId]="progressiveFormState.id + '-content'">
      <ng-container *ngTemplateOutlet="contentRef ?? emptyRef"></ng-container>
    </div>
    <ng-container
      *ngTemplateOutlet="
        progressiveFormState.isUnsubmitted ? pristineButton : editingButtons
      "
    ></ng-container>
  </form>
</ng-template>

<ng-template #emptyRef></ng-template>

<ng-template #pristineButton>
  <button
    *ngIf="progressiveFormState"
    type="submit"
    class="btn btn-primary progressive-form--submit-button"
    [submitProgressiveFormStep]="progressiveFormState"
    [asyncSubmission]="asyncSubmission"
    (submitted)="submitted.emit('submit')"
    [wlAutomationId]="progressiveFormState.id + '-submit-button'"
    wlCSCapture
  >
    {{ content?.submitButtonText }}
    <fa-icon
      [icon]="content?.submitButtonIcon ?? 'arrow-down'"
      *ngIf="!progressiveFormState.hasPendingAsyncActions"
    ></fa-icon>
    <fa-icon
      icon="spinner-third"
      [spin]="true"
      *ngIf="progressiveFormState.hasPendingAsyncActions"
    ></fa-icon>
  </button>
</ng-template>

<ng-template #editingButtons>
  <ng-container *ngIf="progressiveFormState">
    <button
      type="submit"
      class="btn btn-primary progressive-form--submit-button"
      [submitProgressiveFormStep]="progressiveFormState"
      [asyncSubmission]="asyncSubmission"
      [updateFormState]="true"
      (submitted)="submitted.emit('edit')"
      [wlAutomationId]="progressiveFormState.id + '-update-button'"
      wlCSCapture
    >
      {{ 'SaveChanges' | translate }}
      <fa-icon
        [icon]="content?.submitButtonIcon ?? 'arrow-down'"
        *ngIf="!progressiveFormState.hasPendingAsyncActions"
      ></fa-icon>
      <fa-icon
        icon="spinner-third"
        [spin]="true"
        *ngIf="progressiveFormState.hasPendingAsyncActions"
      ></fa-icon>
    </button>
    <button
      type="button"
      class="btn btn-link progressive-form--submit-button mt-2 mt-sm-0"
      [class.pe-none]="progressiveFormState.hasPendingAsyncActions"
      [restoreProgressiveFormStep]="progressiveFormState"
      [wlAutomationId]="progressiveFormState.id + '-revert-button'"
      wlCSCapture
    >
      {{ 'CancelChanges' | translate }}
    </button>
  </ng-container>
</ng-template>
