import {
  getCustomer,
  getCustomerClear,
  getCustomerError,
  getCustomerSuccess,
} from '@essent/customer';
import {
  createStateStatusReducer,
  getError,
  getLoading,
  getStatus,
} from '@innogy/utils/state';

export const { reducer, initialState } = createStateStatusReducer({
  updateAction: getCustomer,
  clearAction: getCustomerClear,
  errorAction: getCustomerError,
  successAction: getCustomerSuccess,
});

export type State = typeof initialState;

export const getCustomerInfo = (state: State) => state.data;
export const isCustomerLoading = (state: State) => getLoading(getStatus(state));
export const hasCustomerError = (state: State) => getError(getStatus(state));
