import { Injectable } from '@angular/core';
import { generateFormIdFromUUID } from '@innogy/shared/forms';
import {
  ProgressiveFormFormState,
  ProgressiveFormStepConfig,
  ScDynamicProgressiveFormConfig,
  ScProgressiveGenericFormStepRendering,
} from '@innogy/shared/progressive-form/sitecore/models';
import {
  CreateGenericFormReducerConfig,
  createGenericFormReducerFromScForm,
} from '@innogy/sitecore-forms/store';
import {
  ActionReducerMap,
  Store,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { FormGroupControls, FormGroupState } from 'ngrx-forms';

import {
  createAddressEffects,
  getAddressFormControlIds,
} from '@innogy/common-ui/forms';
import { Actions, EffectSources } from '@ngrx/effects';
import {
  registerSCProgressiveFormAction,
  resetGenericProgressiveFormAction,
} from '../store/generic-progressive-form.actions';

@Injectable({
  providedIn: 'root',
})
export class GenericProgressiveFormService {
  private reducerForFormStep(
    formStep: ProgressiveFormStepConfig,
    rootFormId: string
  ) {
    const form = (formStep as unknown as ScProgressiveGenericFormStepRendering)
      .fields.Form;
    const config: CreateGenericFormReducerConfig = {
      resetOnAction: {
        action: resetGenericProgressiveFormAction,
        payloadShouldEqual: rootFormId,
      },
      context: 'embedded',
    };
    return createGenericFormReducerFromScForm(
      form,
      this.getFormStepId(formStep),
      config
    );
  }

  selectorForForm(formId: string) {
    return createFeatureSelector<ProgressiveFormFormState>(formId);
  }

  private registerReducer(
    config: ScDynamicProgressiveFormConfig,
    registerUnderKey: string
  ) {
    const reducers = config.formSteps.reduce((acc, formStep) => {
      acc[this.getFormStepId(formStep)] = this.reducerForFormStep(
        formStep,
        registerUnderKey
      );
      return acc;
    }, {} as ActionReducerMap<ProgressiveFormFormState>);
    const reducer = combineReducers(reducers);

    this.store$.addReducer(registerUnderKey, reducer);
  }

  /**
   * Return the identifier of the given formStep.
   *
   * @param formStep the form step to extract an ID from
   */
  public getFormStepId(formStep: ProgressiveFormStepConfig) {
    return generateFormIdFromUUID(formStep.id);
  }

  /**
   * Registers a dynamic progressive form under the root reducer and returns a selector to this reducer.
   * @param config the configuration to parse into the dynamic progressive form reducer.
   * @param registerUnderKey the key under which to register the form's main reducer
   * @returns a selector that accesses the form's main reducer.
   */
  public registerForm(
    config: ScDynamicProgressiveFormConfig,
    registerUnderKey: string
  ) {
    this.store$.dispatch(
      registerSCProgressiveFormAction({ formId: registerUnderKey })
    );
    this.registerReducer(config, registerUnderKey);
    this.registerAddressFormEffects(config, registerUnderKey);

    return this.selectorForForm(registerUnderKey);
  }

  private registerAddressFormEffects(
    config: ScDynamicProgressiveFormConfig,
    registerUnderKey: string
  ) {
    const addressFormStepKey = this.getFormStepKeyWithAddress(config);
    if (addressFormStepKey) {
      this.effectSources$.addEffects(
        createAddressEffects(
          this.actions$,
          this.store$,
          getAddressFormControlIds(addressFormStepKey),
          createSelector(
            this.selectorForForm(registerUnderKey),
            (state: any) => state[addressFormStepKey].form.formState
          )
        )
      );
    }
  }

  /**
   * Return the formStepId of the step that contains an address input type
   *
   * @param config the configuration of the form
   * @returns the id of the form step or null
   */
  private getFormStepKeyWithAddress(config: ScDynamicProgressiveFormConfig) {
    for (const step of config.formSteps) {
      const hasAddressType = step.fields.Form.fields.Inputs.some(
        (input) => input.fields.Type.value === 'address'
      );

      if (hasAddressType) return this.getFormStepId(step);
    }
    return null;
  }

  /**
   * Flattens the formControls in the underlying forms of the form state to 1 object.
   * @param formState Parent form state
   * @returns flattened controls in form state
   */
  public flattenFormGroupControls = <
    T extends { [key: string]: { form: { formState: FormGroupState<any> } } }
  >(
    formState: T
  ) => {
    let flattenedFormControls = {};

    for (const value of Object.values(formState)) {
      flattenedFormControls = {
        ...flattenedFormControls,
        ...value.form.formState.controls,
      };
    }

    return flattenedFormControls as FormGroupControls<T>;
  };

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly effectSources$: EffectSources
  ) {}
}
