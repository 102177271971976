<div class="d-flex flex-column gap-1">
  <wl-checkbox
    *ngIf="showDoubleMeter && !content.smartMeterCheckboxShownAsRadioButtons"
    [formsControl]="controls?.doubleMeter"
    [label]="content.doubleMeterCheckboxLabel ?? ''"
    [rendering]="rendering"
    [showValidation]="false"
    [tooltip]="content.doubleMeterCheckboxTooltip"
    fieldname="dubbele meter"
    stepname="invullen"
    wlAutomationId="smart-meter-checkbox"
  />
  <wl-checkbox
    [formsControl]="controls?.hasSolarPanel"
    [label]="content.solarPanelCheckboxLabel ?? ''"
    [rendering]="rendering"
    [showValidation]="false"
    [tooltip]="
      !content.estimationToolIsShown
        ? content.solarPanelCheckboxTooltip
        : undefined
    "
    fieldname="zonnepanelen"
    stepname="invullen"
  />
</div>
