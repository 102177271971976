<div class="d-flex offer-durations">
  <p *ngIf="offerDurationTitle">{{ offerDurationTitle }}</p>
  <ng-container *ngIf="durations && durations.length > 0">
    <!-- temp specific filter for ED until Essent has a new design for this -->
    <wl-filter
      *ngIf="brand === 'ed'"
      [entries]="filterEntries(durations)"
      [selected]="selectedFilterEntry(durations)"
      wlCSCapture
      (entrySelected)="changeDuration.emit($event)"
    ></wl-filter>
    <!-- temp specific filter for Essent until it has a new design (CTR-240) -->
    <div
      class="offer-duration d-flex"
      *ngIf="brand === 'essent'"
      wlAutomationId="filter-button"
    >
      <button
        *ngFor="let duration of durations; trackBy: trackByDuration"
        class="btn offer-duration__button me-1 py-1 px-2"
        wlAutomationId="offer-duration-button-{{ duration.duration }}"
        [ngClass]="{
          'offer-duration__button--active': isSelectedOffer(duration)
        }"
        (click)="changeDuration.emit(duration)"
      >
        {{ duration.durationTitle }}
      </button>
    </div>
  </ng-container>
</div>
