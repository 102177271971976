import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  type CalculateComponentRendering,
  CalculateComponentContent,
} from '@innogy/become-a-customer/shared/interfaces';
import { stringNumberConverterForLocale } from '@innogy/utils/deprecated';
import { FormGroupControls, FormGroupState } from 'ngrx-forms';
import type { TypedComponentRendering } from '@innogy/jss/models';

@Component({
  selector: 'wl-calculate-form-usage',
  templateUrl: './calculate-form-usage.component.html',
  styleUrls: [
    './calculate-form-usage.component.essent.scss',
    './calculate-form-usage.component.ed.scss',
  ],
})
export class CalculateFormUsageComponent {
  @Input({ required: true }) content!: CalculateComponentContent;
  @Input() rendering?: TypedComponentRendering<CalculateComponentRendering>;
  @Input() controls?: FormGroupControls<FullCalculateFormValues>;
  @Input() formState?: FormGroupState<FullCalculateFormValues>;

  @Output() public calculateOpenModal = new EventEmitter<boolean>();

  public readonly _stringNumberConverter =
    stringNumberConverterForLocale('nl-NL');
}
