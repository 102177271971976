<fieldset class="d-flex flex-column gap-1">
  <legend class="d-flex justify-content-between align-items-center mb-0">
    <span class="h6 fw-bold mb-0">
      {{ content.usageTitle }}
    </span>
    <ng-container *ngIf="!content.estimateConsumptionButtonShownBeneathFields">
      <ng-container *ngTemplateOutlet="estimateConsumptionButton" />
    </ng-container>
  </legend>
  <ng-container
    *ngIf="controls?.doubleMeter?.value; else electricityConsumptionField"
  >
    <div
      class="d-flex gap-1"
      [class.flex-column]="
        content.electricityConsumptionFieldsVerticallyStacked
      "
      wlAutomationId="electricity-consumption-fields"
    >
      <div>
        <wl-input-group
          icon="sun"
          [suffix]="content.electricityUsageUnit"
          [valid]="controls?.electricityUsageNormal | isValid"
          [invalid]="controls?.electricityUsageNormal | isInvalid"
        >
          <wl-number
            wlAutocompleteDisabled
            [formsControl]="controls?.electricityUsageNormal"
            [label]="content.electricityUsageNormalLabel ?? ''"
            [placeholder]="content.electricityUsageNormalPlaceholder ?? ''"
            [rendering]="rendering"
            stepname="invullen"
            fieldname="stroomverbruik jaar piek"
            [step]="0"
            class="flex-grow-1"
          />
        </wl-input-group>
        <ng-container
          *ngTemplateOutlet="
            errorMessages;
            context: {
              formControl: controls?.electricityUsageNormal,
              errorTrackingDescription: 'stroomverbruik jaar piek',
            }
          "
        />
      </div>
      <div>
        <wl-input-group
          icon="moon"
          [suffix]="content.electricityUsageUnit"
          [valid]="controls?.electricityUsageOffPeak | isValid"
          [invalid]="controls?.electricityUsageOffPeak | isInvalid"
        >
          <wl-number
            wlAutocompleteDisabled
            [formsControl]="controls?.electricityUsageOffPeak"
            [label]="content.electricityUsageOffPeakLabel ?? ''"
            [placeholder]="content.electricityUsageOffPeakPlaceholder ?? ''"
            [rendering]="rendering"
            stepname="invullen"
            fieldname="stroomverbruik jaar dal"
            [step]="0"
            class="flex-grow-1"
          />
        </wl-input-group>
        <ng-container
          *ngTemplateOutlet="
            errorMessages;
            context: {
              formControl: controls?.electricityUsageOffPeak,
              errorTrackingDescription: 'stroomverbruik jaar dal',
            }
          "
        />
      </div>
    </div>
  </ng-container>
  <div *ngIf="!content.gasHidden" wlAutomationId="gas-field">
    <wl-input-group
      icon="fire-flame"
      [suffix]="content.gasUsageUnit"
      [valid]="controls?.gasUsage | isValid"
      [invalid]="controls?.gasUsage | isInvalid"
    >
      <wl-number
        wlAutocompleteDisabled
        [formsControl]="controls?.gasUsage"
        [label]="content.gasUsageLabel ?? ''"
        [placeholder]="content.gasUsagePlaceholder ?? ''"
        [rendering]="rendering"
        stepname="invullen"
        fieldname="gasverbruik jaar"
        [step]="0"
        class="flex-grow-1"
      />
    </wl-input-group>
    <ng-container
      *ngTemplateOutlet="
        errorMessages;
        context: {
          formControl: controls?.gasUsage,
          errorTrackingDescription: 'gasverbruik jaar',
        }
      "
    />
  </div>
  <ng-container *ngIf="content.estimateConsumptionButtonShownBeneathFields">
    <ng-container *ngTemplateOutlet="estimateConsumptionButton" />
  </ng-container>
</fieldset>

<ng-template #electricityConsumptionField>
  <div>
    <wl-input-group
      icon="plug"
      [suffix]="content.electricityUsageUnit"
      [valid]="controls?.electricityUsage | isValid"
      [invalid]="controls?.electricityUsage | isInvalid"
      wlAutomationId="electricity-consumption-field"
    >
      <wl-number
        wlAutocompleteDisabled
        [formsControl]="controls?.electricityUsage"
        [label]="content.electricityUsageLabel ?? ''"
        [placeholder]="content.electricityUsagePlaceholder ?? ''"
        [rendering]="rendering"
        stepname="invullen"
        fieldname="stroomverbruik jaar"
        [step]="0"
        class="flex-grow-1"
      />
    </wl-input-group>
    <ng-container
      *ngTemplateOutlet="
        errorMessages;
        context: {
          formControl: controls?.electricityUsage,
          errorTrackingDescription: 'stroomverbruik jaar',
        }
      "
    />
  </div>
</ng-template>

<ng-template
  #errorMessages
  let-control="formControl"
  let-description="errorTrackingDescription"
>
  <ng-container *ngIf="control | isInvalid">
    <wl-input-error
      *ngIf="control?.errors?.requiredNumber"
      [label]="content.requiredError"
      [rendering]="rendering"
      [messageDescription]="description"
      stepname="invullen"
      wlAutomationId="empty-numeric-input-error"
    />
    <wl-input-error
      *ngIf="control?.errors?.isInt"
      [label]="content.invalidNumberError"
      [rendering]="rendering"
      [messageDescription]="description"
      stepname="invullen"
      wlAutomationId="input-is-not-integer-error"
    />
    <wl-input-error
      *ngIf="control?.errors?.noInputsFilled"
      [label]="content.noInputsFilledError"
      [rendering]="rendering"
      [messageDescription]="description"
      stepname="invullen"
      wlAutomationId="empty-form-error"
    />
  </ng-container>
</ng-template>

<ng-template #estimateConsumptionButton>
  <button
    (click)="calculateOpenModal.emit(content.gasHidden)"
    type="button"
    class="btn btn-link p-0"
    [ngClass]="{
      'align-self-start py-2':
        content.estimateConsumptionButtonShownBeneathFields
    }"
    wlAutomationId="calculate-form-usage.openQuestionnaire"
  >
    {{ content.estimateUsageLinkText }}
  </button>
</ng-template>
