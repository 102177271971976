import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { INITIAL_OFFER_LOADING, LAZY_OFFER_LOADING } from '../../offer';
import {
  callGetOffersAction,
  loadOffersetAction,
  loadNonDefaultDurationsAction,
  setActiveOffersByDurationAction,
  appendOffersAction,
} from './offers.actions';
import { getOffersState } from './offers.selectors';

@Injectable()
export class OffersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store
  ) {}

  offersState$ = this.store$.pipe(select(getOffersState));

  loadDefaultDurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOffersetAction),
      map((action) =>
        callGetOffersAction({
          offerSet: action.offerset,
          durationFilter: 'default_duration',
          actionId: action.setAsActive
            ? INITIAL_OFFER_LOADING
            : LAZY_OFFER_LOADING,
        })
      )
    )
  );

  /**
   * Load all non_default durations automatically when default_durations are loaded
   */
  loadNonDefaultDurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNonDefaultDurationsAction),
      map(({ offerset }) =>
        callGetOffersAction({
          offerSet: offerset,
          durationFilter: 'non_default_durations',
          actionId: LAZY_OFFER_LOADING,
        })
      )
    )
  );

  /**
   * The appendOffers action might include offers with a duration that is currenlty active. This effect return an action to make
   * sure that the active offers in the state are refreshed.
   */
  refreshActiveOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appendOffersAction),
      concatLatestFrom(() => this.offersState$),
      filter(([_, offersState]) => {
        return !!offersState.activeDuration && !!offersState.activeOfferset;
      }),
      map(([_, offersState]) => {
        const activeDuration = offersState.activeDuration;
        const activeOfferset = offersState.activeOfferset;

        return setActiveOffersByDurationAction({
          duration: activeDuration,
          offerset: activeOfferset,
        });
      })
    )
  );
}
