import type { MaintenanceModeFlags } from '@innogy/maintenance-mode/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { MaintenanceModeState } from './maintenance-mode.reducer';
import {
  getMaintenanceModeData,
  isMaintenanceModeStateLoading,
} from './maintenance-mode.reducer';

export const maintenanceModeSelectorKey = 'maintenanceMode';

export const selectMaintenanceModeState =
  createFeatureSelector<MaintenanceModeState>(maintenanceModeSelectorKey);

export const selectMaintenanceModeData = createSelector(
  selectMaintenanceModeState,
  (state) => getMaintenanceModeData(state)?.data
);

export const isMaintenanceModeLoaded = createSelector(
  selectMaintenanceModeState,
  (state) => !isMaintenanceModeStateLoading(state)
);

export const isMaintenanceModeLoading = createSelector(
  selectMaintenanceModeState,
  (state) => isMaintenanceModeStateLoading(state)
);

export const isMaintenanceModeEnabled = (flag: MaintenanceModeFlags) =>
  createSelector(
    selectMaintenanceModeData,
    (data) => data?.[flag]?.enabled || false
  );
