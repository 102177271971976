import type { HttpErrorResponse } from '@angular/common/http';
import { ensureArray } from '@essent/core-utils';
import type {
  ComponentRendering,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export interface FunctionalError {
  code: string;
  message: string;
}

// TODO: Currently there are two ways of handling functional errors, this is brought to the attention of capability leads.
// Up until there is a decision AND all API's are migrated, there is a dual phase necessary.
export interface SingleErrorResponse extends HttpErrorResponse {
  error: {
    error: FunctionalError;
  };
}

export interface MultipeErrorsResponse extends HttpErrorResponse {
  error: {
    error: [FunctionalError];
  };
}

export type FunctionalErrorResponse =
  | SingleErrorResponse
  | MultipeErrorsResponse;

export const DEFAULT_ERROR_FIELD = 'UnknownError';

export const isFunctionalErrorOfType = (
  code: string,
  httpError?: FunctionalErrorResponse
) => {
  const errors = ensureArray(httpError?.error?.error);

  return errors.filter((error) => error.code === code).length > 0;
};

/**
 * Gets the functional error code from HttpErrorResponse
 * @param httpError http error
 */
export function getFunctionalErrorCode(httpError?: FunctionalErrorResponse) {
  const errors = ensureArray(httpError?.error?.error);

  return errors[0]?.code;
}

/**
 * Retrieves the functional error message from component rendering based on HttpErrorResponse code,
 * if the returned error code doesn't exist the value of the defaultErrorField will be used.
 *
 * @param rendering Component rendering
 * @param errorResponse HttpErrorResponse
 * @param defaultErrorFieldKey Field key for default error message
 */
export function getFunctionalErrorMessage(
  errorResponse?: HttpErrorResponse,
  rendering?: ComponentRendering,
  defaultErrorFieldKey = DEFAULT_ERROR_FIELD
) {
  if (rendering == null || rendering.fields == null) {
    return undefined;
  }

  const defaultErrorField = rendering.fields[defaultErrorFieldKey] as
    | TextField
    | undefined;

  const functionalErrorCode = getFunctionalErrorCode(errorResponse) || '';
  const functionalErrorField = rendering.fields[functionalErrorCode] as
    | TextField
    | undefined;

  return functionalErrorField || defaultErrorField;
}
