import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import type { Observable } from 'rxjs';
import { API_PREFIX } from '@innogy/config';

import type { SolarPanelsProductWithCalculations } from '../../models';

@Injectable()
export class GetSolarPanelsProductsService
  implements BaseService<any, SolarPanelsProductWithCalculations[]>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${
    this.apiUrl || ''
  }/eplus/solarpanels/products/v${this.apiVersion}`;

  public constructor(
    @Optional()
    @Inject(API_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(): Observable<SolarPanelsProductWithCalculations[]> {
    const headers = { Accept: 'application/json' };

    return this.httpClient.get<SolarPanelsProductWithCalculations[]>(
      this.endpoint,
      { headers }
    );
  }
}
