import type { Action } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import type {
  FormGroupState,
  FormState,
  KeyValue,
  ProjectFn2,
} from 'ngrx-forms';
import {
  createFormGroupState,
  markAsDirty,
  MarkAsSubmittedAction,
  markAsTouched,
  onNgrxForms,
  ResetAction,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';

import { onNgrxFormsControlId } from './on-ngrx-forms-control-id';

export interface ExtendReducerProjectorArgs<T extends KeyValue> {
  formId: string;
  extendingValidators: FormGroupValidatorMap<T>;
  baseInitialState: T;
}

export interface ExtendReducerFn<
  K extends Record<string, unknown>,
  T extends K
> {
  (args: ExtendReducerProjectorArgs<T>): (
    args: ExtendReducerFnArgs<T>
  ) => ExtendedReducer<T>;
}

export type FormGroupValidatorMap<
  T extends KeyValue,
  K = Record<string, unknown>
> = {
  [key in keyof Omit<T, keyof K>]?: ProjectFn2<
    FormState<T[key]>,
    FormGroupState<T>
  >;
};

type InternalValidatorMap<T extends KeyValue> = {
  [key in keyof T]?: ProjectFn2<FormState<T[key]>, FormGroupState<T>>;
};

interface ExtendReducerFnArgs<T extends KeyValue> {
  formId: string;
  validators: InternalValidatorMap<T>;
  baseInitialState: T;
}

export interface ExtendedReducer<T extends KeyValue> {
  extendedReducer: (
    state: FormGroupState<T> | undefined,
    action: Action
  ) => FormGroupState<T>;
  initialExtendedFormGroupState: FormGroupState<T>;
  extendedControlIds: any;
}

export const extendFormReducer = <
  K extends { [key: string]: any },
  T extends K
>({
  formId,
  validators,
  baseInitialState,
}: ExtendReducerFnArgs<T>): ExtendedReducer<T> => {
  const inferFormControlIds = (_formId: string) => {
    return Object.keys(baseInitialState).reduce(
      (ac, k) => ({ ...ac, [k]: `${_formId}.${k}` }),
      {} as { [key in keyof T]: string }
    );
  };

  const initialExtendedFormGroupState = createFormGroupState<T>(
    formId,
    baseInitialState
  );

  const validateForm = (state: FormGroupState<T>) =>
    updateGroup<T>(state, validators);

  const reducer = createReducer(
    initialExtendedFormGroupState,
    onNgrxForms(),
    onNgrxFormsControlId(
      ResetAction,
      formId,
      () => initialExtendedFormGroupState
    ),
    onNgrxFormsControlId(MarkAsSubmittedAction, formId, (state) =>
      markAsTouched(markAsDirty(state))
    )
  );

  const extendedControlIds = inferFormControlIds(formId);

  function extendedReducer(
    state: FormGroupState<T> = initialExtendedFormGroupState,
    action: Action
  ): FormGroupState<T> {
    const wrappedReducer = wrapReducerWithFormStateUpdate(
      reducer,
      (_state) => _state,
      (_, _state) => validateForm(_state)
    );

    return wrappedReducer(state, action);
  }

  return {
    extendedReducer,
    initialExtendedFormGroupState,
    extendedControlIds,
  };
};
