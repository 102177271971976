<div class="position-relative h-100">
  <ng-container *ngIf="variant === 'card'">
    <wl-commodity-offer-card
      [offer]="offer"
      [showLoadingState]="showLoadingState"
    >
      <ed-commodity-offer-header
        [duration]="offer?.durationTitle"
        [productTitle]="offer?.productTitle"
        [productSubTitle]="offer?.productSubtitle"
        [incentive]="offer?.incentiveTitle"
        [highlight]="offer?.isHighlightedLabel"
        [showPriceTag]="false"
      ></ed-commodity-offer-header>

      <div class="d-flex flex-column flex-grow-1 mt-2">
        <wl-commodity-offer-price-tag
          [offer]="offer"
          [content]="content"
          class="mb-3"
        ></wl-commodity-offer-price-tag>

        <ng-container *ngTemplateOutlet="offerSharedContent"></ng-container>
      </div>
    </wl-commodity-offer-card>
  </ng-container>
  <ng-container *ngIf="variant === 'accordion'">
    <wl-commodity-offer-accordion
      [offer]="offer"
      [opened]="active"
      [showLoadingState]="showLoadingState"
    >
      <ng-template #accordionHeader>
        <ed-commodity-offer-header
          [duration]="offer?.durationTitle"
          [productTitle]="offer?.productTitle"
          [productSubTitle]="offer?.productSubtitle"
          [priceDurationLabel]="content?.priceDurationLabel"
          [price]="offer?.expectedMonthlyAmount"
          [incentive]="offer?.incentiveTitle"
          [highlight]="offer?.isHighlightedLabel"
          [showPriceTag]="true"
        ></ed-commodity-offer-header>
      </ng-template>
      <ng-template #accordionBody>
        <div class="d-flex flex-column mt-n3 mx-n2">
          <hr class="mt-0" />
          <wl-commodity-offer-budgetbill-proposal
            [offer]="offer"
            [content]="content"
            class="mb-3"
          ></wl-commodity-offer-budgetbill-proposal>

          <ng-container *ngTemplateOutlet="offerSharedContent"></ng-container>
        </div>
      </ng-template>
    </wl-commodity-offer-accordion>
  </ng-container>
</div>

<ng-template #offerSharedContent>
  <ed-commodity-product-breakdown
    [offer]="offer"
    [content]="content"
    [showMemberGetMember]="showMemberGetMember"
  ></ed-commodity-product-breakdown>
  <div class="flex-grow-1"></div>
  <ed-commodity-vertical-offer-dropdown
    [offer]="offer"
    [content]="content"
    (viewTariffs)="viewTariffModal.emit()"
    [expanded]="showTariffs"
    (expand)="openTariffDropdown.emit()"
    (collapse)="closeTariffDropdown.emit()"
    class="mb-2"
  ></ed-commodity-vertical-offer-dropdown>

  <div class="d-flex flex-column">
    <button
      class="btn btn-sales-primary w-100"
      (click)="selectOffer.emit()"
      wlAutomationId="offer-submit-button"
      wlCSCapture
    >
      {{ content?.offerSubmitButtonLabel }}
    </button>
    <div
      class="d-flex justify-content-center"
      *ngIf="content?.moreInfoPriceAndConditionLinkLabel"
    >
      <button
        class="btn p-0 border-0 btn-link mt-2 mb-1"
        (click)="openMoreInfo.emit()"
        wlAutomationId="info-card-button"
        wlCSCapture
      >
        {{ content?.moreInfoPriceAndConditionLinkLabel }}
      </button>
    </div>
  </div>
</ng-template>
