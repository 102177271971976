import type { SolarPanelsSuitabilitySimpleAnswer } from '@innogy/eplus/models';
import { SolarPanelsSuitabilityRoofType } from '@innogy/eplus/models';
import { wrappedFormReducer } from '@innogy/shared/forms';
import type {
  SolarPanelCriteria,
  SolarPanelsCalculationParameters,
} from '@innogy/eplus/temporary-core-modules';
import { useValidatorIf } from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  SetValueAction,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import {
  hideSolarPanelsSuitabilityCheckFormAction,
  provideCriteriaAndCalculationParametersToSuitabilityCheckAction,
  resetSuitabilityFormAction,
  showSolarPanelsSuitabilityCheckFormAction,
} from './solar-panels-suitability-check.actions';

export const solarPanelsSuitabilityCheckKey = 'solarPanelSuitabilityCheck';
export const solarPanelsSuitabilityCheckFormId =
  'solarPanelSuitabilityCheckForm';

export const asbestosControlId = `${solarPanelsSuitabilityCheckFormId}.asbestos`;
export const roofTypeControlId = `${solarPanelsSuitabilityCheckFormId}.roofType`;
export const renewedRoofWithinTimeLimitControlId = `${solarPanelsSuitabilityCheckFormId}.renewedRoofWithinTimeLimit`;

export interface SolarPanelsSuitabilityCheckFormValues {
  asbestos?: SolarPanelsSuitabilitySimpleAnswer;
  roofType?: SolarPanelsSuitabilityRoofType;
  renewedRoofWithinTimeLimit?: SolarPanelsSuitabilitySimpleAnswer;
}

export type SolarPanelsSuitabilityCheckFormState =
  FormGroupState<SolarPanelsSuitabilityCheckFormValues>;

export interface SolarPanelsSuitabilityCheckState {
  isVisible: boolean;
  formState: SolarPanelsSuitabilityCheckFormState;
  questions: {
    asbestos: boolean;
    roofType: boolean;
    renovationDate: boolean;
  };
  roofMaxAge: number;
  criteria?: SolarPanelCriteria;
  calculationParameters?: SolarPanelsCalculationParameters;
}

const initialFormValues = {
  asbestos: undefined,
  roofType: undefined,
  renewedRoofWithinTimeLimit: undefined,
};
export const initialSolarPanelSuitabilityCheckFormState =
  createFormGroupState<SolarPanelsSuitabilityCheckFormValues>(
    solarPanelsSuitabilityCheckFormId,
    initialFormValues
  );

export const initialSolarPanelsSuitabilityCheckState = {
  formState: initialSolarPanelSuitabilityCheckFormState,
  isVisible: false,
  questions: {
    asbestos: false,
    roofType: true,
    renovationDate: false,
  },
  roofMaxAge: NaN,
  criteria: undefined,
  calculationParameters: undefined,
};

export const validateSuitabilityCheck = (
  state: SolarPanelsSuitabilityCheckState
) => {
  return updateGroup<SolarPanelsSuitabilityCheckFormValues>({
    asbestos: validate(useValidatorIf(required, state.questions.asbestos)),
    roofType: validate(useValidatorIf(required, state.questions.roofType)),
    renewedRoofWithinTimeLimit: validate(
      useValidatorIf(required, state.questions.renovationDate)
    ),
  })(state.formState);
};

const reducer = createReducer<SolarPanelsSuitabilityCheckState>(
  initialSolarPanelsSuitabilityCheckState,
  onNgrxForms(),
  on(resetSuitabilityFormAction, () => initialSolarPanelsSuitabilityCheckState),
  on(showSolarPanelsSuitabilityCheckFormAction, (state) => ({
    ...state,
    isVisible: true,
  })),
  on(hideSolarPanelsSuitabilityCheckFormAction, (state) => ({
    ...state,
    isVisible: false,
  })),
  on(
    provideCriteriaAndCalculationParametersToSuitabilityCheckAction,
    (state, action) => ({
      ...state,
      questions: {
        ...state.questions,
        asbestos: action.criteria.mightContainAsbestos,
      },
      criteria: action.criteria,
      calculationParameters: action.calculationParameters,
    })
  ),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (action.controlId === roofTypeControlId) {
      return {
        ...state,
        questions: {
          ...state.questions,
          renovationDate: shouldCheckRoofRenovationDate(
            action.value as SolarPanelsSuitabilityRoofType,
            state.criteria
          ),
        },
        roofMaxAge: inferRoofMaxAge(
          action.value as SolarPanelsSuitabilityRoofType,
          state.calculationParameters
        ),
      };
    }
    return { ...state };
  })
);

const _wrappedReducer = wrappedFormReducer(reducer, validateSuitabilityCheck);
export const solarPanelsSuitabilityCheckReducer = (
  state: SolarPanelsSuitabilityCheckState = initialSolarPanelsSuitabilityCheckState,
  action: Action
): SolarPanelsSuitabilityCheckState => _wrappedReducer(state, action);

function shouldCheckRoofRenovationDate(
  roofType: SolarPanelsSuitabilityRoofType | undefined,
  criteria: SolarPanelCriteria | undefined
) {
  if (roofType === undefined) {
    return false;
  }
  if (roofType === SolarPanelsSuitabilityRoofType.SLOPED_ROOF_TILES) {
    return !criteria?.immediatelySuitableIfSlopedRoofWithRoofTiles;
  }
  if (roofType === SolarPanelsSuitabilityRoofType.FLAT_ROOF_BITUMEN) {
    return !criteria?.immediatelySuitableIfFlatRoofWithBitumen;
  }
  return false;
}

function inferRoofMaxAge(
  roofType: SolarPanelsSuitabilityRoofType | undefined,
  calculationParameters: SolarPanelsCalculationParameters | undefined
) {
  if (roofType === SolarPanelsSuitabilityRoofType.SLOPED_ROOF_TILES) {
    return calculationParameters?.slopedRoofTilesMaximumAge ?? NaN;
  }
  if (roofType === SolarPanelsSuitabilityRoofType.FLAT_ROOF_BITUMEN) {
    return calculationParameters?.flatRoofBitumenMaximumAge ?? NaN;
  }
  return NaN;
}
