import { Injectable } from '@angular/core';
import { createEffect, ofType, concatLatestFrom, Actions } from '@ngrx/effects';
import { filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { postHeatpumpsConfirmation } from '@innogy/eplus/temporary-core-modules';
import { MarkAsyncTasksAsPendingAction } from '@innogy/progressive-ngrx-forms';

import { heatpumpsOrderConfirmationFormId } from './heatpumps-order-form.reducer';
import { selectHeatpumpsFunnelSettings } from '../heatpumps-funnel';
import { selectHeatpumpsOrderFormViewModel } from './heatpumps-order-form.selectors';

@Injectable()
export class HeatpumpsOrderFormEffects {
  private readonly funnelSettings$ = this.store.select(
    selectHeatpumpsFunnelSettings
  );
  private readonly orderFormState$ = this.store.select(
    selectHeatpumpsOrderFormViewModel
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<any>
  ) {}

  public readonly onSubmitHeatpumpsOrderForm$ = createEffect(() =>
    this.actions.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === heatpumpsOrderConfirmationFormId),
      concatLatestFrom(() => [this.funnelSettings$, this.orderFormState$]),
      map(([_action, _funnelSettings, formData]) => {
        const controls = {
          ...formData.addressProgressiveFormState.formState.controls,
          ...formData.confirmationProgressiveFormState.formState.controls,
          ...formData.personalDetailsProgressiveFormState.formState.controls,
        };

        return postHeatpumpsConfirmation({
          payload: {
            bron: 'Online',
            omgeving: 'TEST',
            referrer: '',
            formSettingsPath: '',
            formValues: controls,
            e2eTrackingId: '',
            gclid: '',
          },
        });
      })
    )
  );
}
