import { Component, Input } from '@angular/core';
import { AnalyticsActionTypes } from '@innogy/core/analytics';
import { FunctionalErrorItemRendering } from '@innogy/functional-errors/store';

@Component({
  selector: 'wl-functional-error',
  templateUrl: './functional-error.component.html',
})
export class FunctionalErrorComponent {
  @Input()
  errorItem?: FunctionalErrorItemRendering;

  @Input()
  componentName = 'defaultComponent';

  @Input()
  automationId = 'default';

  @Input()
  AnalyticsActionTypes = AnalyticsActionTypes;

  @Input()
  backgroundGrey = false;

  @Input()
  shadow = true;

  get rendering(): FunctionalErrorItemRendering | undefined {
    if (this.errorItem) {
      return {
        ...this.errorItem,
        componentName: this.componentName,
      };
    }
    return undefined;
  }
}
