import { isNotNullish } from '@innogy/utils/rxjs';
import { AgreementType } from '@integration/base-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import uniq from 'lodash/uniq';
import { selectQueryParam } from '@innogy/shared/state';

import * as fromContract from './contract.reducer';
import type { ContractState } from './index';
import { contractsSelectorKey } from './index';

export const getContractState =
  createFeatureSelector<ContractState>(contractsSelectorKey);

export const getContractGroupsState = createSelector(
  getContractState,
  (feature) => {
    return feature?.[contractsSelectorKey];
  }
);

export const getContractGroups = createSelector(
  getContractGroupsState,
  fromContract.getGroups
);

export const getEmobilityContractGroups = createSelector(
  getContractGroups,
  (groups) =>
    groups?.filter(
      (group) =>
        group.type === AgreementType.EMOBILITY ||
        group.type === AgreementType.REIMBURSEMENT
    )
);

export const getSelectedEmobilityContractType = createSelector(
  selectQueryParam('emob-contract-type'),
  (type) => type
);

export const getEmobilityContractGroupId = createSelector(
  getEmobilityContractGroups,
  getSelectedEmobilityContractType,
  (groups, emobContractType) =>
    groups?.find(
      (group) => group.type?.toUpperCase() === emobContractType?.toUpperCase()
    )?.id || groups?.find((group) => group.type === AgreementType.EMOBILITY)?.id
);

export const getContractGroupsHasError = createSelector(
  getContractGroupsState,
  fromContract.getHasError
);

export const getContractGroupsIsLoading = createSelector(
  getContractGroupsState,
  fromContract.getIsLoading
);

export const getAllAgreementTypes = createSelector(
  getContractGroups,
  (contractGroups): AgreementType[] | undefined => {
    return contractGroups?.map((result) => result.type).filter(isNotNullish);
  }
);

export const getAgreementTypes = createSelector(
  getAllAgreementTypes,
  (agreementTypes): AgreementType[] | undefined => {
    return agreementTypes != null ? uniq(agreementTypes) : undefined;
  }
);

export const getFirstContractGroup = createSelector(
  getContractGroups,
  (groups) => (groups != null ? groups[0] : undefined)
);

export const getSelectedContractGroupId = createSelector(
  getContractGroupsState,
  fromContract.getSelectedId
);

export const getSelectedContractGroup = createSelector(
  getContractGroups,
  getSelectedContractGroupId,
  (groups, id) =>
    groups != null ? groups.find((group) => group.id === id) : undefined
);
export const getSelectedOrFirstContractGroup = createSelector(
  getSelectedContractGroup,
  getFirstContractGroup,
  (selected, first) => selected || first
);

export const getSelectedOrFirstContractGroupId = createSelector(
  getSelectedOrFirstContractGroup,
  (group) => (group != null ? group.id : undefined)
);

export const hasMultipleContractGroups = createSelector(
  getContractGroups,
  (groups) => groups != null && groups.length > 1
);

export const getContractGroupsVM = createSelector(
  getContractGroups,
  getSelectedOrFirstContractGroupId,
  hasMultipleContractGroups,
  getContractGroupsHasError,
  (contractGroups, selectedContractGroupId, hasMultiple, hasError) => ({
    contractGroups,
    selectedContractGroupId,
    showContractGroupsSelection: hasMultiple,
    hasError,
  })
);
