import { on, type Action } from '@ngrx/store';
import {
  SetValueAction,
  box,
  onNgrxFormsAction,
  setValue,
  updateGroup,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { getRenderingValue } from '@innogy/become-a-customer/shared';
import type { InnogyComponentRendering } from '@innogy/jss/models';
import {
  activateProgressiveFormStepOnSubmissionOf,
  createProgressiveFormGroupState,
  createProgressiveNgrxFormReducerWithFormStateUpdate,
  markAsSubmittable,
  markAsUnsubmittable,
  markAsyncTasksAsCompleted,
  type ProgressiveFormStateImplementor,
} from '@innogy/progressive-ngrx-forms';
import {
  formatDateToYearMonthDay,
  isPostalCode,
  requiredNumber,
  setValueWithReset,
  useValidatorIf,
  validateSequential,
  validateSequentialIf,
} from '@innogy/utils/deprecated';
import {
  getInstallationAddressesSuccess,
  type InstallationAddress,
} from '@essent/installation-address';
import { putAdditionalInformationSuccess } from '@essent/new-customer';

import {
  SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID,
  addOrderRenderingAction,
} from '../order.actions';
import type { OrderState } from '../order.state';
import { ORDER_PERSONAL_FORM_ID } from '../personal';
import { earliestPossibleStartDate } from './inswitch-inhouse.helpers';
import type { OrderAddressFormValues } from './order-address.model';
import { validateInswitchInhouse } from './inswitch-inhouse.validator';

export const ORDER_ADDRESS_FORM_ID: keyof OrderState = 'orderAddressForm';

export const CORRESPONDENCE_ADDRESS_ACTION_ID = 'CorrespondenceAddress';
export const ADDRESS_VERIFICATION_ERROR_ID = 'addressVerified';

export const useAsCorrespondenceAddressFormControlId = `${ORDER_ADDRESS_FORM_ID}.useAsCorrespondenceAddress`;
export const inswitchInhouseFormControlId = `${ORDER_ADDRESS_FORM_ID}.inswitchInhouse`;
export const residenceOrWorkplaceFormControlId = `${ORDER_ADDRESS_FORM_ID}.residenceOrWorkplace`;
export const postalCodeCorrespondenceFormControlId = `${ORDER_ADDRESS_FORM_ID}.postalCodeCorrespondence`;
export const houseNumberCorrespondenceFormControlId = `${ORDER_ADDRESS_FORM_ID}.houseNumberCorrespondence`;
export const houseNumberAdditionCorrespondenceFormControlId = `${ORDER_ADDRESS_FORM_ID}.houseNumberAdditionCorrespondence`;
export const startDateOptionFormControlId = `${ORDER_ADDRESS_FORM_ID}.startDateOption`;

export interface OrderAddressState
  extends ProgressiveFormStateImplementor<OrderAddressFormValues> {
  rendering?: InnogyComponentRendering | any;
  correspondenceAddress?: InstallationAddress[];
}

export const initialOrderAddressProgressiveFormState =
  createProgressiveFormGroupState<OrderAddressFormValues>(
    ORDER_ADDRESS_FORM_ID,
    {
      useAsCorrespondenceAddress: true,
      postalCodeCorrespondence: '',
      houseNumberCorrespondence: NaN,
      houseNumberAdditionCorrespondence: null,
      careOfFullName: undefined,
      residenceOrWorkplace: undefined,
      inswitch: undefined,
      selectedStartDate: '',
      confirmInhouseTerm: false,
      inswitchInhouse: box(undefined),
      startDateOption: undefined,
    }
  );

export const initialOrderAddressState: OrderAddressState = {
  progressiveForm: initialOrderAddressProgressiveFormState,
  rendering: undefined,
  correspondenceAddress: undefined,
};

const validateFormGroupState = (state: OrderAddressState) => {
  const businessFieldsRequired = getRenderingValue(
    'EnableBusinessFields',
    false
  )(state);

  const startDateOptionControlEnabled = getRenderingValue(
    'StartDateOptionControlEnabled',
    false
  )(state);

  const useCustomCorrespondenceAddress =
    !state.progressiveForm.formState.controls.useAsCorrespondenceAddress
      ?.value ?? false;

  const inswitchInhouseValidatedState = validateInswitchInhouse(
    state.progressiveForm.formState
  )(state.progressiveForm.formState);

  let validatedForm = updateGroup<OrderAddressFormValues>({
    residenceOrWorkplace: validateSequential(required),
    postalCodeCorrespondence: validateSequential(
      useValidatorIf(
        required,
        businessFieldsRequired && useCustomCorrespondenceAddress
      ),
      useValidatorIf(
        (value) => isPostalCode(value ?? ''),
        businessFieldsRequired && useCustomCorrespondenceAddress
      )
    ),
    houseNumberCorrespondence: validateSequential(
      useValidatorIf(
        requiredNumber,
        businessFieldsRequired && useCustomCorrespondenceAddress
      )
    ),
  })(inswitchInhouseValidatedState);

  if (startDateOptionControlEnabled) {
    validatedForm = updateGroup<OrderAddressFormValues>({
      startDateOption: validateSequentialIf(
        state.progressiveForm.formState.value.inswitchInhouse?.value?.reason ===
          'other_supplier'
      )(required),
    })(validatedForm);
  }

  return validatedForm;
};

const _reducer = createProgressiveNgrxFormReducerWithFormStateUpdate(
  initialOrderAddressState,
  validateFormGroupState,
  on(addOrderRenderingAction, (state, action) => ({
    ...state,
    rendering: action.rendering,
  })),
  activateProgressiveFormStepOnSubmissionOf(ORDER_PERSONAL_FORM_ID),
  on(getInstallationAddressesSuccess, (state, action) => {
    const installationAddressState: any = {};
    if (action.actionId === CORRESPONDENCE_ADDRESS_ACTION_ID) {
      installationAddressState.correspondenceAddress = action.payload;
    }

    return {
      ...state,
      ...installationAddressState,
    };
  }),
  on(putAdditionalInformationSuccess, (state, action) => {
    if (action.actionId !== SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID) {
      return state;
    }
    return {
      ...state,
      progressiveForm: markAsyncTasksAsCompleted(state.progressiveForm),
    };
  }),

  onNgrxFormsAction(SetValueAction, (state, action) => {
    let {
      progressiveForm: { formState },
    } = state;

    if (
      action.controlId === startDateOptionFormControlId &&
      action.value === 'earliestPossible'
    ) {
      const earliestPossibleStartDateString = formatDateToYearMonthDay(
        earliestPossibleStartDate(
          formState.controls.startDateOption?.userDefinedProperties.segment
        )
      );
      const newFormState = setValue(formState, {
        ...formState.value,
        selectedStartDate: earliestPossibleStartDateString,
      });
      return {
        ...state,
        progressiveForm: {
          ...state.progressiveForm,
          formState: newFormState,
        },
      };
    }

    // Set confirmInhouseTerm to false and reset transitionDate when new value for inswitchInhouse dropdown was set.
    if (action.controlId === inswitchInhouseFormControlId) {
      formState = updateGroup<OrderAddressFormValues>({
        confirmInhouseTerm: (confirmInhouseTerm) =>
          setValueWithReset(confirmInhouseTerm, false),
        selectedStartDate: (startDate) => setValueWithReset(startDate, ''),
      })(formState);

      const disableFunnelAdvancement =
        formState.value.inswitchInhouse?.value?.disableFunnelAdvancement;

      state.progressiveForm = disableFunnelAdvancement
        ? markAsUnsubmittable(state.progressiveForm)
        : markAsSubmittable(state.progressiveForm);

      return {
        ...state,
        progressiveForm: {
          ...state.progressiveForm,
          formState,
        },
      };
    }

    return state;
  })
);

export function orderAddressReducer(
  state: OrderAddressState = initialOrderAddressState,
  action: Action
): OrderAddressState {
  return _reducer(state, action);
}
