import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { ClientEnvironmentConfig } from '@innogy/config/models';
import type { Action } from '@ngrx/store';

import { configLoaded } from './config.actions';

@Injectable({ providedIn: 'root' })
export class ConfigEffects {
  constructor(
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: ClientEnvironmentConfig
  ) {}

  ngrxOnInitEffects(): Action {
    return configLoaded({ config: this.environmentConfig });
  }
}
