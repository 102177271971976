<div
  class="row"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [trackImpressionContext]="context"
>
  <div class="col-12">
    <div
      class="h3 text-brand text-md-center"
      *scText="rendering?.fields?.Title"
    ></div>

    <!--Desktop-->
    <div class="row d-none d-md-flex">
      <div class="h6 col-6" *scText="rendering?.fields?.CategoryTitle"></div>
      <div class="h6 col-6" *scText="rendering?.fields?.ItemsTitle"></div>
    </div>
    <div class="row d-none d-md-flex">
      <div class="col-6">
        <div
          class="py-1"
          *ngFor="let category of rendering?.fields?.Categories"
        >
          <wl-icon-card-button
            wlAutomationId="icon-card-button"
            wlTrackImpression="contentblock-impression"
            [iconName]="category.fields?.Icon | scIcon"
            [title]="category.fields?.LabelName?.value"
            context="taxonomy"
            variant="small"
            alignment="left"
            [showNext]="true"
            [highlighted]="selectedCategory === category"
            (clicked)="setSelectedCategory(category)"
            wlTrackLink
            [trackLinkInfo]="{
              rendering: category,
              field: category?.fields?.LabelName?.value,
              eventType: 'button-click'
            }"
          ></wl-icon-card-button>
        </div>
      </div>
      <div class="col-6">
        <div
          class="py-1"
          *ngFor="let categoryItem of selectedCategory?.fields?.Items"
        >
          <wl-icon-card-button
            wlAutomationId="icon-card-button"
            [iconName]="categoryItem.fields?.icon | scIcon"
            [title]="categoryItem.fields?.title.value"
            (clicked)="navigateToLink(categoryItem.fields?.link?.value?.href)"
            context="taxonomy"
            variant="small"
            alignment="left"
            wlTrackLink
            [trackLinkInfo]="{
              rendering: categoryItem,
              field: categoryItem?.fields?.title?.value,
              linkurl: categoryItem?.fields?.link?.value?.href,
              eventType: 'button-click'
            }"
          ></wl-icon-card-button>
        </div>
      </div>
    </div>

    <!--Mobile-->
    <div class="d-flex d-md-none">
      <wl-taxonomy-accordion
        class="w-100"
        [rendering]="rendering"
        (navigateToLink)="navigateToLink($event)"
      ></wl-taxonomy-accordion>
    </div>
  </div>
</div>
