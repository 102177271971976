import type { UploadableFile, UploadedFile } from '@innogy/core/file-upload';
import { createAction, props } from '@ngrx/store';
import type { LinkFieldValue } from '@innogy/jss/models';

export const loadReimbursements = createAction('[Reimbursements] Load');

export const startFileUpload = createAction(
  '[Reimbursement] File upload start',
  props<{ payload: (UploadableFile | UploadedFile)[] }>()
);

export const uploadFilesSuccess = createAction(
  '[Reimbursement] File upload success',
  props<{ payload: string[] }>()
);

export const setReimbursementSuccessPage = createAction(
  '[Reimbursement] Set success page',
  props<{ payload: LinkFieldValue }>()
);
