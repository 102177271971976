<div class="order-container">
  <div class="row">
    <div class="col-lg-8 order-2 order-lg-1">
      <wl-order-fulfillment-container
        [rendering]="rendering"
      ></wl-order-fulfillment-container>
    </div>
    <div
      class="col-lg-4 order-lg-2"
      [class]="orderOverviewBottomMobile ? 'order-3' : 'order-1'"
    >
      <div
        sc-placeholder
        *ngIf="rendering"
        name="order-overview-card"
        class="sticky-lg-top sticky-lg-offset mb-2"
        [rendering]="rendering"
        [inputs]="
          (xpEditorActive$ | async)
            ? xpMockOrder
            : {
                offer: (offer$ | async),
                durationTitle: (duration$ | async)?.durationTitle,
                consumptionValuesVM: (consumptionValuesVM$ | async),
                electricityOverview: (electricityOverview$ | async),
                gasOverview: (gasOverview$ | async)
              }
        "
        [outputs]="outputs"
      ></div>
    </div>
  </div>
</div>
