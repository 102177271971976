import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TextInterpolationPipe } from './text-interpolation/text-interpolation.pipe';

export const EXPORTS = [TextInterpolationPipe];

@NgModule({
  imports: [CommonModule],
  exports: [...EXPORTS],
  declarations: [...EXPORTS],
})
export class JssUtilsModule {}
