<wl-progressive-form-step-wrapper
  [title]="rendering.fields?.PaymentDetailsStepTitle?.value"
  [subtitle]="rendering.fields?.PaymentDetailsStepSubTitle?.value"
  [formState]="formState"
  [rendering]="rendering"
  [stepNumber]="step"
  [stepName]="stepName"
  [submitButtonText]="rendering.fields?.PaymentDetailsNextButtonText?.value"
  [submitButtonIcon]="
    rendering.fields?.PaymentDetailsNextButtonIcon?.fields?.value?.value
  "
  submitButtonAutomationId="submit-payment-details"
  [progressiveFormSelectorKey]="progressiveFormSelectorKey"
  [isReadonly]="isReadonly"
>
  <ng-template wlProgressiveFormBody>
    <div class="row g-1 mb-2">
      <wl-text
        class="w-100"
        wlAutocompleteDisabled
        [rendering]="rendering"
        [stepname]="stepName"
        [step]="step"
        fieldname="iban"
        [sendValue]="false"
        [valueConverter]="_uppercaseConverter"
        [formsControl]="formState?.controls?.iban"
        [placeholder]="rendering.fields?.IbanPlaceholder?.value"
        [label]="rendering.fields?.IbanLabel?.value"
      ></wl-text>
      <ng-container *ngIf="formState.controls.iban | isInvalid">
        <wl-input-error
          wlAutomationId="iban-required-error"
          [messageField]="rendering.fields?.IbanRequiredError"
          messageDescription="iban"
          [rendering]="rendering"
          [stepname]="stepName"
          [step]="step"
        ></wl-input-error>
      </ng-container>
    </div>
  </ng-template>
  <ng-template wlProgressiveFormSummary>
    <div wlCSMask>
      <p class="h6 mb-0 mt-1" *scText="rendering.fields?.IbanLabel"></p>
      {{ formState.value.iban }}
    </div>
  </ng-template>
</wl-progressive-form-step-wrapper>
