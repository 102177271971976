<ngb-pagination
  [disabled]="disabled"
  [boundaryLinks]="boundaryLinks"
  [directionLinks]="directionLinks"
  [ellipses]="ellipses"
  [rotate]="rotate"
  [collectionSize]="collectionSize"
  [maxSize]="(deviceMaxSize$ | async) || 1"
  [pageSize]="(devicePageSize$ | async) || 1"
  [(page)]="page"
>
  <ng-template #previous ngbPaginationPrevious>
    <fa-icon
      *ngIf="!overrides.prevText || (isMobile$ | async)"
      class="icon"
      icon="arrow-left"
    ></fa-icon>
    <ng-container *ngIf="overrides.prevText && (isMobile$ | async) === false">{{
      overrides.prevText
    }}</ng-container>
  </ng-template>
  <ng-template #next ngbPaginationNext>
    <fa-icon
      *ngIf="!overrides.nextText || (isMobile$ | async)"
      class="icon"
      icon="arrow-right"
    ></fa-icon>
    <ng-container *ngIf="overrides.nextText && (isMobile$ | async) === false">{{
      overrides.nextText
    }}</ng-container>
  </ng-template>
  <ng-container *ngIf="showPaginationSummary$ | async">
    <ng-template #pages ngbPaginationPages let-page let-pages="pages">
      <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
        <span>{{
          overrides.summaryText
            | interpolate
              : {
                  mode: 'rich_text',
                  variables: { page: page, pages: pages.length }
                }
        }}</span>
      </li>
    </ng-template>
  </ng-container>
</ngb-pagination>
