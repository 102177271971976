import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@innogy/utils/dom';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { PlatformService } from '@innogy/core/platform';

import type { Alloy, AlloyPayload, AlloySendEventResponse } from '../types';

export const SDK_INSTANCE_NAME = 'alloy';

declare global {
  interface Window {
    [SDK_INSTANCE_NAME]?: Alloy;
  }
}

/**
 * This service is a facade for adobe web sdk in our angular application.
 * It takes care of lazy initialization of the SDK and proxies all other calls that outside services need
 * access to.
 */
@Injectable({
  providedIn: 'root',
})
export class AdobeWebSDKService {
  #sdk?: Alloy;

  readonly #window = inject(WINDOW);
  readonly #sdkConfig = inject(ENVIRONMENT_CONFIG).adobeWebSDK;
  readonly #platform = inject(PlatformService);

  public isEnabled() {
    return (this.#sdkConfig.enabled && this.#platform.isClient()) ?? false;
  }

  /* istanbul ignore next */
  public async sendEvent(payload: AlloyPayload) {
    return (await this.sdk)(
      'sendEvent',
      payload
    ) as Promise<AlloySendEventResponse>;
  }

  /* istanbul ignore next */
  public async applyPropositions(payload: AlloyPayload) {
    return (await this.sdk)('applyPropositions', payload);
  }

  /* istanbul ignore next */
  private get sdk(): Promise<Alloy> {
    if (!this.#sdk) {
      return this.configureSDK();
    }
    return Promise.resolve(this.#sdk);
  }

  /* istanbul ignore next */
  private async configureSDK() {
    if (this.isEnabled()) {
      // We use a CJS import here because adobe does not expose typings and
      // using the MJS version causes immense problems in our project setup;
      // importing this way can localize the references to alloy within this library.
      const { createInstance } = await require('@adobe/alloy');
      this.#sdk = createInstance({ name: SDK_INSTANCE_NAME }) as Alloy;
      this.#sdk('configure', {
        personalizationStorageEnabled: false,
        context: ['web', 'device', 'environment', 'placeContext'],
        targetMigrationEnabled: false,
        thirdPartyCookiesEnabled: true,
        idMigrationEnabled: true,
        clickCollectionEnabled: true,
        downloadLinkQualifier:
          '\\.(exe|zip|wav|mp3|mov|mpg|avi|wmv|pdf|doc|docx|xls|xlsx|ppt|pptx)$',
        debugEnabled: false,
        // We are allowed to opt-in to consent for sending events to the adobe Web SDK by default.
        // Filtering of what events are to be forwarded and not to be forwarded is to be done in the edge configuration.
        // @see https://experienceleague.adobe.com/en/docs/experience-platform/web-sdk/commands/setconsent
        // @see https://business.gov.nl/regulation/cookies/
        defaultConsent: 'in',
        datastreamId: this.#sdkConfig.datastreamId,
        edgeDomain: 'edge.adobedc.net',
        edgeBasePath: 'ee',
        orgId: '0E57467352783B530A490D45@AdobeOrg',
        onBeforeEventSend: (content: Record<string, any>) => {
          if (content.xdm.web?.webReferrer) {
            delete content.xdm.web.webReferrer.URL;
          }
        },
      });
      // We have to manually link the sdk to the global window namespace to prevent issues in
      // the adobe web sdk hooks. Can't make this shit up. :')
      this.#window[SDK_INSTANCE_NAME] = this.#sdk;
      return this.#sdk;
    } else {
      throw new Error(
        'You are attempting to load the Adobe Web SDK while it is not allowed to be enabled on this platform.'
      );
    }
  }
}
