<div class="modal-header">
  <a href="/" ngbAutofocus>
    <img
      [src]="'assets/logo.svg?v=2' | deployUrl"
      class="brand-logo w-100"
      alt="logo"
    />
  </a>
</div>
<div class="modal-body">
  <div>
    <h5>Jij bepaalt wat je met ons wilt delen</h5>
    <p>
      {{ brandName }} gebruikt functionele, analytische en tracking cookies om
      je gebruikerservaring te verbeteren. Zo kunnen we je zo goed mogelijk
      helpen met al je energiezaken.
    </p>
    <div>
      <p>
        <strong
          >We gebruiken altijd functionele en analytische cookies voor het
          volgende:</strong
        >
      </p>
      <p class="microcopy">
        <fa-icon icon="browser" class="pe-1"></fa-icon>Een veilige en
        goedwerkende website
      </p>
      <p class="microcopy">
        <fa-icon icon="chart-simple" class="pe-1"></fa-icon>Anonieme analyse van
        websitegebruik
      </p>
    </div>
    <div>
      <p>
        <strong
          >Als je voor ‘Cookies accepteren’ kiest, gebruiken we ook tracking
          cookies voor het volgende:</strong
        >
      </p>
      <p class="microcopy">
        <fa-icon icon="chart-simple" class="pe-1"></fa-icon>Uitvragen en
        analyseren van je persoonlijke gebruikerservaring
      </p>
      <p class="microcopy">
        <fa-icon icon="chart-simple" class="pe-1"></fa-icon>Inzicht in je
        gebruikersgedrag om onze online diensten te verbeteren
      </p>
      <p class="microcopy">
        <fa-icon icon="tag" class="pe-1"></fa-icon>Voor jou gepersonaliseerde
        berichten, inclusief acties en aanbiedingen
      </p>
      <p class="microcopy">
        <fa-icon icon="check" class="pe-1"></fa-icon>Voor jou gepersonaliseerde
        advertenties op andere websites en social media
      </p>
    </div>
    <p>
      Als je op ‘cookies accepteren’ klikt, ga je akkoord met alle cookies. Klik
      je op ‘cookies weigeren’, dan plaatsen we alleen de functionele en
      analytische cookies.
      <a href="/cookies"
        >Via cookie-instellingen kun je op ieder moment je keuze wijzigen of je
        toestemming intrekken</a
      >.
    </p>
    <p>
      Lees meer in onze <a href="/privacyverklaring">privacyverklaring</a> en
      <a href="/cookies">cookieverklaring.</a>
    </p>
  </div>
</div>
<div class="p-2 modal-footer d-flex justify-content-start">
  <div class="d-flex gap-2 flex-column flex-md-row flex-grow-1">
    <button
      wlAutomationId="cookiewall-accept"
      class="btn btn-primary"
      (click)="accept()"
    >
      Cookies accepteren
    </button>
    <button
      wlAutomationId="cookiewall-reject"
      class="btn btn-primary"
      (click)="reject()"
    >
      Cookies weigeren
    </button>
  </div>
</div>
