import { Component, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';

@Component({
  selector: 'wl-footer-brand',
  templateUrl: './footer-brand.component.html',
  styleUrls: [
    './footer-brand.component.essent.scss',
    './footer-brand.component.ed.scss',
    './footer-brand.component.ew.scss',
  ],
})
export class FooterBrandComponent {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  get basePath() {
    return this.config.basePath || '/';
  }

  get brand() {
    return this.config.brand;
  }
}
