import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import { JssNavItem } from '@innogy/jss/models';

@Component({
  selector: 'wl-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: [
    './navbar-item.component.essent.scss',
    './navbar-item.component.ed.scss',
  ],
})
export class NavbarItemComponent {
  @Input()
  language?: string;

  @Input()
  navItem?: JssNavItem;

  @Input()
  type: NavBarItemType = 'nav-item';

  @Output()
  clicked = new EventEmitter<JssNavItem>();

  constructor(@Inject(ENVIRONMENT_CONFIG) readonly config: EnvironmentConfig) {}
}

type NavBarItemType = 'dropdown' | 'nav-item';
