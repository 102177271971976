import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
  type OnInit,
} from '@angular/core';
import { TypedComponentRendering } from '@innogy/jss/models';
import type { TextField } from '@sitecore-jss/sitecore-jss-angular';
import { PlatformService } from '@innogy/core/platform';
import type { AlloyProposition } from '@innogy/core/ab-testing';
import { JssPlatformService } from '@innogy/jss/platform';

import { ABTestingService } from '../services/ab-testing.service';
import type { ComponentTestingVariant } from '../ab-testing.model';

export type ComponentTestingFields = {
  TestIdentifier: TextField;
};

@Component({
  selector: 'wl-component-testing-container',
  templateUrl: './component-testing-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentTestingContainerComponent implements OnInit {
  @Input() rendering?: TypedComponentRendering<ComponentTestingFields>;

  constructor(
    private readonly abTestingService: ABTestingService,
    private readonly cd: ChangeDetectorRef,
    private readonly platform: PlatformService,
    private readonly jssPlatformService: JssPlatformService
  ) {}

  testVariant?: ComponentTestingVariant;
  trackingData?: { shouldTrack: boolean; payload: AlloyProposition[] };

  async ngOnInit() {
    // In the case of XP-editor, load with default settings.
    if (this.jssPlatformService.isEditorActive()) {
      this.testVariant = 'A';
      this.trackingData = {
        shouldTrack: false,
        payload: [],
      };
      this.cd.markForCheck();
    } else if (this.platform.isClient()) {
      return this.setAssignedVariant();
    }
    return void 0;
  }

  /**
   * Sets the assigned variant for the A/B test based on the provided test name.
   */
  private async setAssignedVariant() {
    const testName = this.rendering?.fields.TestIdentifier?.value;

    if (!testName) {
      throw new Error(
        'No test name is provided to the A/B testing component, test cannot be instantiated.'
      );
    }

    const { variant, assignmentResolved, trackingPayload } =
      await this.abTestingService.getAssignedVariant(testName, 'A', ['A', 'B']);

    this.testVariant = variant;
    this.trackingData = {
      shouldTrack: assignmentResolved,
      payload: trackingPayload,
    };

    // Mark the component for change detection.
    this.cd.markForCheck();
  }

  public variantRendered(trackRender: boolean) {
    if (this.trackingData?.shouldTrack && trackRender) {
      this.abTestingService.trackAssignment(this.trackingData.payload);
    }
  }
}
