import { EnergyType, Status } from '@essent/common';
import {
  getDynamicPrices,
  getDynamicPricesByDate,
  getDynamicPricesByDateClear,
  getDynamicPricesByDateError,
  getDynamicPricesByDateSuccess,
  getDynamicPricesClear,
  getDynamicPricesError,
} from '@essent/tariffs';
import type { CollectionState } from '@innogy/utils/state';
import { createInitialCollectionState } from '@innogy/utils/state';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import { getHours } from 'date-fns';
import type { DynamicPricesDate } from '@essent/tariffs/dynamic-prices/interfaces/dynamic-prices.interface';

import {
  setDynamicPricesSuccess,
  setSelectedActionId,
  setSelectedDate,
  setSelectedEnergyType,
  setSelectedHour,
} from '../actions';
import { formatDate } from '../utils/format-date.util';
import { dynamicPricesLoadingEntity } from './dynamic-prices-loading-entity';

export type DynamicPricesDataState = CollectionState<DynamicPricesDate> & {
  selectedEnergyType: EnergyType;
  selectedDate: string;
  selectedActionId: string;
  selectedHour: number;
};

const selectedEnergyType = EnergyType.ELECTRICITY;
const selectedHour = getHours(new Date());
const selectedDate = formatDate(new Date());
export const loadingActionId = 'loading';

export const initialDynamicPricesState: DynamicPricesDataState = {
  ...createInitialCollectionState(),
  entities: {
    loading: dynamicPricesLoadingEntity,
  },
  selectedEnergyType,
  selectedDate,
  selectedHour,
  selectedActionId: loadingActionId,
};

const actionIdFallback = (actionId: string | undefined) =>
  actionId ?? 'unknown';

const _reducer = createReducer(
  initialDynamicPricesState,
  on(getDynamicPrices, (state, action) => {
    const actionId = actionIdFallback(action.actionId);
    const pendingEntities = {
      ...state.entities,
      [actionId]: {
        status: Status.PENDING,
        id: actionId,
      },
    };
    return {
      ...state,
      entities: pendingEntities,
      ids: Object.keys(pendingEntities),
    };
  }),
  on(setDynamicPricesSuccess, (state, action) => {
    const entities = {
      ...state.entities,
      ...Object.fromEntries(
        action.payload.prices.map((price) => {
          const date = formatDate(price.date);
          return [
            date,
            {
              status: Status.SUCCESS,
              id: date,
              entry: price,
            },
          ];
        })
      ),
    };
    return {
      ...state,
      entities,
      ids: Object.keys(entities),
    };
  }),
  on(getDynamicPricesError, (state, action) => {
    const actionId = actionIdFallback(action.actionId);
    const pendingEntities = {
      ...state.entities,
      [actionId]: {
        status: Status.ERROR,
        id: actionId,
      },
    };
    return {
      ...state,
      entities: pendingEntities,
      ids: Object.keys(pendingEntities),
    };
  }),
  on(getDynamicPricesByDate, (state, action) => {
    const actionId = actionIdFallback(action.actionId);
    const pendingEntities = {
      ...state.entities,
      [actionId]: {
        status: Status.PENDING,
        id: actionId,
      },
    };
    return {
      ...state,
      entities: pendingEntities,
      ids: Object.keys(pendingEntities),
    };
  }),
  on(getDynamicPricesByDateSuccess, (state, action) => {
    const actionId = actionIdFallback(action.actionId);
    const entities = {
      ...state.entities,
      [actionId]: {
        status: Status.SUCCESS,
        id: actionId,
        entry: action.payload,
      },
    };
    return {
      ...state,
      entities,
      ids: Object.keys(entities),
    };
  }),
  on(getDynamicPricesByDateError, (state, action) => {
    const actionId = actionIdFallback(action.actionId);
    const failureActionEntities = {
      ...state.entities,
      [actionId]: {
        status: Status.ERROR,
        id: actionId,
        error: action.payload,
      },
    };
    return {
      ...state,
      entities: failureActionEntities,
      ids: Object.keys(failureActionEntities),
    };
  }),
  on(
    getDynamicPricesClear,
    getDynamicPricesByDateClear,
    () => initialDynamicPricesState
  ),
  on(setSelectedDate, (state, action) => ({
    ...state,
    selectedDate: action.payload,
  })),
  on(setSelectedActionId, (state, action) => ({
    ...state,
    selectedActionId: action.payload,
  })),
  on(setSelectedHour, (state, action) => ({
    ...state,
    selectedHour: action.payload,
  })),
  on(setSelectedEnergyType, (state, action) => ({
    ...state,
    selectedEnergyType: action.payload,
  }))
);

export function dynamicPricesReducer(
  state = initialDynamicPricesState,
  action: Action
) {
  return _reducer(state, action);
}
