import type {
  AbTestData,
  ActualSitecoreRouteData,
  InnogyComponentRendering,
  InnogyLayoutServiceData,
  InnogyRouteData,
} from '@innogy/jss/models';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

const enhanceDatasources = (
  rendering: ActualSitecoreRouteData | ComponentRendering,
  abTestData: AbTestData[]
) => {
  const finalPlaceholder = rendering.placeholders || {};

  Object.values(finalPlaceholder).forEach((value) => {
    for (let i = 0; i < value.length; i++) {
      const enhancedPlaceholder = value[i] as
        | InnogyRouteData
        | InnogyComponentRendering;

      enhancedPlaceholder.position = i + 1;

      gatherAbTestData(enhancedPlaceholder, abTestData);

      if ('dataSource' in enhancedPlaceholder) {
        enhanceDatasources(enhancedPlaceholder, abTestData);
      }
    }
  });

  return rendering;
};

/**
 * Mutates the route to add a position in the placeholder to a component.
 * Gathers Sitecore AB test data from component params and adds the result to context.
 */
export const transformLayoutModel = (layout: InnogyLayoutServiceData) => {
  if (layout.route == null) {
    return;
  }
  const abTestData: AbTestData[] = [];
  enhanceDatasources(layout.route, abTestData);
  layout.context.abTestData = abTestData;
};

function gatherAbTestData(
  enhancedPlaceholder: InnogyRouteData | InnogyComponentRendering,
  abTestData: AbTestData[]
) {
  if ('params' in enhancedPlaceholder) {
    if (enhancedPlaceholder.params?.['multiVariateTestId'] != null) {
      abTestData.push({
        testId: enhancedPlaceholder.params['multiVariateTestId'],
        testName: enhancedPlaceholder.params['multiVariateTestName'],
        testDataSourceId:
          enhancedPlaceholder.params['multiVariateTestDataSourceId'],
        testDataSourceName:
          enhancedPlaceholder.params['multiVariateTestDataSourceName'],
        testVariantName:
          enhancedPlaceholder.params['multiVariateTestVariantName'],
      });
    }
  }
}
