import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ABTestingModule,
  ComponentTestingContainerComponent,
} from '@innogy/ab-testing';
import { provideLazyLoad } from '@innogy/jss/utils';

@NgModule({
  imports: [
    CommonModule,
    ABTestingModule,
    provideLazyLoad({
      ComponentTesting: ComponentTestingContainerComponent,
    }),
  ],
})
export class ABTestingLazyModule {}
