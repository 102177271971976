import { createNamedApi } from '@essent/common';

import { PostHeatpumpsConfirmationService } from './post-heatpumps-confirmation.service';

export const postHeatpumpsConfirmationApi = createNamedApi(
  '[PostHeatpumpsConfirmationState] Post Heatpumps Confirmation',
  PostHeatpumpsConfirmationService
);

export const {
  postHeatpumpsConfirmation,
  postHeatpumpsConfirmationSuccess,
  postHeatpumpsConfirmationClear,
  postHeatpumpsConfirmationError,
  postHeatpumpsConfirmationActions,
} = postHeatpumpsConfirmationApi;
