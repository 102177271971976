import { Injectable, Injector } from '@angular/core';
import { loginSuccessTrackerCookie } from '@innogy/common';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { isNotNullish } from '@innogy/utils/rxjs';
import { Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { sha256 } from 'js-sha256';
import { CookieService } from 'ngx-cookie-service';
import { filter, map, tap } from 'rxjs/operators';

import { PageInfoService } from './page-info.service';
import { getSiteContext } from './page-name';

@Injectable({
  providedIn: 'root',
})
export class LoginToolstepLaststepService {
  public readonly trackLoginLaststep = (accountId: string) =>
    getSiteContext(this.store$, this.injector.get(ENVIRONMENT_CONFIG)).pipe(
      map((context) => {
        const config = this.injector.get(ENVIRONMENT_CONFIG);
        const siteAbbreviation =
          this.pageInfoService.getSiteContextAbbreviation(config);
        const componentName = 'login';
        const clientName =
          config.services != null && config.services.identityProvider != null
            ? config.services.identityProvider.clientName
            : 'unknown';

        return {
          action: 'tool-laststep',
          properties: {
            name: `${siteAbbreviation} ${componentName.toLowerCase()}`,
            placement: `${context}|${componentName}`.toLowerCase(),
            step: 1,
            stepname: 'login laststep',
            result: {
              clientid: sha256(`${config.brand}_${accountId}`),
              clientname: clientName,
            },
          },
        };
      }),
      map((event) => {
        const lengthCookieName = `${loginSuccessTrackerCookie}-length`;
        const cookieName = `${loginSuccessTrackerCookie}-0`;
        const cookie = this.cookieService.get(cookieName);
        if (cookie === 'true') {
          this.cookieService.delete(cookieName);
          this.cookieService.delete(lengthCookieName);
          return event;
        } else {
          return undefined;
        }
      }),
      filter(isNotNullish),
      tap((event) => this.angulartics2.eventTrack.next(event))
    );

  constructor(
    private readonly store$: Store<any>,
    private readonly injector: Injector,
    private readonly pageInfoService: PageInfoService,
    private readonly cookieService: CookieService,
    private readonly angulartics2: Angulartics2
  ) {}
}
