import type { NgrxValueConverter } from 'ngrx-forms';

import { isValidNumber, throwLocaleError } from '../../numbers/numeric.utils';
import type { Locale } from '../../typings';
import { valueToNumber } from './value-to-number';

/**
 * Converts a number to a string representation for the provided locales
 *   "nl-NL"
 *     e.g. 1234567.89 -> '1234567,89' (dots are omitted)
 *   "en-US"
 *     e.g. 1234567.89 -> '1234567.89' (commas are omitted)
 * @param value
 * @param locale
 */
const convertNumberToString = (
  value: number,
  locale: Locale = 'nl-NL'
): string => {
  if (!isValidNumber(value)) {
    return '';
  }
  switch (locale) {
    case 'en-US':
      return value.toString(10);
    case 'nl-NL':
      return value.toLocaleString(locale).replace('.', '');
    default:
      return throwLocaleError(locale);
  }
};

/**
 * Converts a string representation of a number to a number for the provided locales
 *   "nl-NL"
 *     e.g. '1.234.567,89' -> 1234567.89
 *     e.g. '1234567,89' -> 1234567.89
 *   "en-US"
 *     e.g. '1,234,567.89' -> 1234567.89
 *     e.g. '1234567.89' -> 1234567.89
 * @param value
 * @param locale
 */
const convertStringToNumber = (
  value: string | undefined,
  locale: Locale = 'nl-NL'
): number | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }
  switch (locale) {
    case 'en-US':
      return valueToNumber(value);
    case 'nl-NL':
      return valueToNumber(value?.replace(/\./g, '').replace(',', '.'));
    default:
      return throwLocaleError(locale);
  }
};

export const stringNumberConverterForLocale = (
  locale: Locale = 'nl-NL'
): NgrxValueConverter<string, number | undefined> => ({
  convertStateToViewValue: (value: number) =>
    convertNumberToString(value, locale),
  convertViewToStateValue: (value: string) =>
    convertStringToNumber(value, locale),
});

/**
 * @deprecated use stringNumberConverterForLocale('nl-NL') instead of this one
 */
export const stringNumberConverter: NgrxValueConverter<
  string,
  number | undefined
> = stringNumberConverterForLocale('en-US');
