import { Inject, Injectable } from '@angular/core';
import { IS_SITECORE_SERVER } from '@innogy/jss/models';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-angular';

@Injectable({
  providedIn: 'root',
})
export class JssPlatformService {
  constructor(
    @Inject(IS_SITECORE_SERVER) private readonly isSitecoreServer: boolean
  ) {}

  isEditorActive() {
    return this.isEditorClientActive() || this.isEditorServerActive();
  }

  isEditorClientActive() {
    return isEditorActive();
  }

  isEditorServerActive() {
    return this.isSitecoreServer;
  }
}
