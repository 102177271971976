<ng-container *ngIf="item | navItemToJssRoute : language as route">
  <div class="dropdown__wrapper nav-item nav-link">
    <ng-container
      *ngIf="item.path; then triggerWithLink; else triggerWithoutLink"
    ></ng-container>

    <div *ngIf="item && item.children" class="dropdown__menu-wrapper">
      <ul role="menu" class="dropdown__menu list-no-default-spacing">
        <li
          role="menuitem"
          *ngFor="let child of item.children"
          class="dropdown-item dropdown__custom-item"
        >
          <ng-container *ngIf="child.path">
            <a
              class="dropdown-item-link"
              *wlGenericLink="{ value: { href: child.path } }"
              wlCSCapture
            >
              {{ child.name }}
            </a>
          </ng-container>
          <ng-container *ngIf="!child.path">
            <a class="dropdown-item-link">
              {{ child.name }}
            </a>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>

  <ng-template #triggerWithLink>
    <a
      role="button"
      class="dropdown__toggle"
      aria-haspopup="true"
      [title]="item.name"
      [attr.aria-expanded]="opened"
      (focus)="open()"
      (blur)="close()"
      (mouseenter)="open()"
      (mouseleave)="close()"
      (click)="clicked.emit(item)"
      *wlGenericLink="{ value: { href: item?.path } }"
      wlCSCapture
    >
      {{ item.name }}
      <fa-icon [icon]="'angle-down'"></fa-icon>
    </a>
  </ng-template>
  <ng-template #triggerWithoutLink>
    <button
      role="button"
      class="dropdown__toggle dropdown__toggle--button"
      aria-haspopup="true"
      [title]="item.name"
      [attr.aria-expanded]="opened"
      (focus)="open()"
      (blur)="close()"
      (mouseenter)="open()"
      (mouseleave)="close()"
      (click)="clicked.emit(item)"
    >
      {{ item.name }}
      <fa-icon [icon]="'angle-down'"></fa-icon>
    </button>
  </ng-template>
</ng-container>
