import { Component, Input } from '@angular/core';
import { getFieldValue, getTreeListValues } from '@innogy/jss/utils';
import { InnogyComponentRendering } from '@innogy/jss/models';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';

@Component({
  selector: 'wl-how-to-questionnaire-container',
  templateUrl: './how-to-questionnaire-container.component.html',
})
export class HowToQuestionnaireContainerComponent {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  get backToPreviousButtonText(): string | undefined {
    return getFieldValue<string>(this.rendering, 'BackToPreviousButtonText');
  }

  get backToBeginningButtonText(): string | undefined {
    return getFieldValue<string>(this.rendering, 'BackToBeginningButtonText');
  }

  get firstQuestion(): InnogyComponentRendering | undefined {
    return this.rendering?.fields?.FirstQuestion as
      | InnogyComponentRendering
      | undefined;
  }

  get questions(): InnogyComponentRendering[] {
    return getTreeListValues<InnogyComponentRendering>(
      this.rendering?.fields,
      'Questions'
    );
  }
}
