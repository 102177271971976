import type { Gender } from '@essent/common';
import { SegmentType } from '@essent/common';
import type { Customer } from '@essent/customer';
import { getCustomerSuccess } from '@essent/customer';
import {
  createNgrxFormReducer,
  wrappedFormReducer,
} from '@innogy/shared/forms';
import {
  getDateOffset,
  isDateInRange,
  isEmailAddress,
  isKvk,
  isLastName,
  isPhoneNumber,
  useValidatorIf,
  validateSequential,
  validateSequentialIf,
} from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  setValue,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { maxLength, notEqualTo, required } from 'ngrx-forms/validation';
import { getProspectApiActions } from './lead-form.actions';
import type { GetExternalLeadResponse } from '@integration/api-client';

export enum CustomerType {
  Household = 'Household',
  SelfEmployed = 'SelfEmployed',
  Other = 'Other',
}

export const CustomerTypeSegmentTypeMap = {
  [CustomerType.Household]: SegmentType.HH,
  [CustomerType.SelfEmployed]: SegmentType.SOHO,
  [CustomerType.Other]: undefined,
} as const;

export const minimumDate = getDateOffset(0, 0, -100);
export const maximumDate = getDateOffset(0, 0, -18);

export interface CustomerDetailsFormState {
  customerType: `${CustomerType}`;
  salutation: `${Gender}` | undefined;
  initials: string;
  prefix: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  dateOfBirth: string;
  businessDetails: {
    organizationName: string;
    chamberOfCommerceNumber: string;
    interestedInReimbursement: boolean;
    emailAddress: string;
    phoneNumber: string;
  };
}

export interface CustomerDetailsState {
  formState: FormGroupState<CustomerDetailsFormState>;
  customer?: Customer;
}

export const customerDetailsFormId = 'emobilityLeadFormCustomerDetailsFormId';
export const customerTypeControlId = `${customerDetailsFormId}.customerType`;
export const customerEmailControlId = `${customerDetailsFormId}.emailAddress`;
export const customerBusinessEmailControlId = `${customerDetailsFormId}.businessDetails.emailAddress`;

export const customerDetailsFormState =
  createFormGroupState<CustomerDetailsFormState>(customerDetailsFormId, {
    customerType: CustomerType.Household,
    salutation: undefined,
    initials: '',
    prefix: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: '',
    emailAddress: '',
    businessDetails: {
      organizationName: '',
      chamberOfCommerceNumber: '',
      interestedInReimbursement: false,
      emailAddress: '',
      phoneNumber: '',
    },
  });

export const initialCustomerDetailsState: CustomerDetailsState = {
  formState: customerDetailsFormState,
  customer: undefined,
};

function getCustomerTypeFromSegment(
  customerSegment: `${SegmentType}`
): CustomerType {
  return customerSegment === SegmentType.SOHO
    ? CustomerType.SelfEmployed
    : CustomerType.Household;
}

function shouldValidateUniqueEmail(
  customerType: `${CustomerType}`,
  customerSegment?: `${SegmentType}`
) {
  return (
    (customerSegment === SegmentType.HH &&
      customerType === CustomerType.SelfEmployed) ||
    (customerSegment === SegmentType.SOHO &&
      customerType === CustomerType.Household)
  );
}

function getEmail(customer: Customer, segmentType: SegmentType) {
  return customer.customerSegment === segmentType
    ? customer.correspondence?.email ?? ''
    : '';
}

function getPhoneNumber(customer: Customer, segmentType: SegmentType) {
  return customer.customerSegment === segmentType
    ? customer.correspondence?.phone?.phoneNumber ?? ''
    : '';
}

function getProspectEmailAddress(prospect: GetExternalLeadResponse) {
  return prospect?.businessDetails ? prospect.personalDetails.emailAddress : '';
}

function getProspectPhoneNumber(prospect: GetExternalLeadResponse) {
  return prospect?.businessDetails
    ? prospect.personalDetails?.phoneNumber || ''
    : '';
}

export const validateAndUpdateCustomerDetailsForm = (
  state: CustomerDetailsState
) => {
  const customerType = state.formState.value.customerType;
  const isSelfEmployed = customerType === CustomerType.SelfEmployed;
  const isHouseHold = customerType === CustomerType.Household;

  return updateGroup(state.formState, {
    customerType: validate(required),
    salutation: validate(required),
    initials: validateSequential(required, maxLength(10)),
    lastName: validate(required, isLastName),
    dateOfBirth: validateSequentialIf(isHouseHold)(
      required,
      isDateInRange(minimumDate, maximumDate)
    ),
    phoneNumber: validateSequentialIf(isHouseHold)(required, isPhoneNumber),
    emailAddress: validateSequentialIf(isHouseHold)(
      required,
      isEmailAddress,
      useValidatorIf(
        notEqualTo(state.customer?.correspondence?.email),
        shouldValidateUniqueEmail(customerType, state.customer?.customerSegment)
      )
    ),

    businessDetails: updateGroup({
      organizationName: validateSequentialIf(isSelfEmployed)(required),
      chamberOfCommerceNumber: validateSequentialIf(isSelfEmployed)(
        required,
        isKvk
      ),
      interestedInReimbursement: validateSequentialIf(isSelfEmployed)(required),
      phoneNumber: validateSequentialIf(isSelfEmployed)(
        required,
        isPhoneNumber
      ),
      emailAddress: validateSequentialIf(isSelfEmployed)(
        required,
        isEmailAddress,
        useValidatorIf(
          notEqualTo(state.customer?.correspondence?.email),
          shouldValidateUniqueEmail(
            customerType,
            state.customer?.customerSegment
          )
        )
      ),
    }),
  });
};

const _reducer = createNgrxFormReducer(
  initialCustomerDetailsState,
  customerDetailsFormId,
  validateAndUpdateCustomerDetailsForm,
  on(getCustomerSuccess, (state, action) => ({
    ...state,
    customer: action.payload,
    formState: setValue(state.formState, {
      customerType: getCustomerTypeFromSegment(action.payload.customerSegment),
      initials: action.payload.person?.initials ?? '',
      prefix: action.payload.person?.prefix ?? '',
      lastName: action.payload.person?.lastName ?? '',
      dateOfBirth: action.payload.person?.dateOfBirth ?? '',
      salutation: action.payload.person?.gender ?? undefined,
      emailAddress: getEmail(action.payload, SegmentType.HH),
      phoneNumber: getPhoneNumber(action.payload, SegmentType.HH),
      businessDetails: {
        organizationName: action.payload.organization?.organisationName ?? '',
        chamberOfCommerceNumber:
          action.payload.organization?.chamberOfCommerceNumber ?? '',
        interestedInReimbursement:
          state.formState.value.businessDetails.interestedInReimbursement,
        emailAddress: getEmail(action.payload, SegmentType.SOHO),
        phoneNumber: getPhoneNumber(action.payload, SegmentType.SOHO),
      },
    }),
  })),
  // eslint-disable-next-line complexity
  on(getProspectApiActions.successAction, (state, action) => ({
    ...state,
    formState: setValue(state.formState, {
      customerType: action.payload?.businessDetails
        ? CustomerType.SelfEmployed
        : CustomerType.Household,
      initials: action.payload.personalDetails?.initials ?? '',
      prefix: action.payload.personalDetails?.prefix ?? '',
      lastName: action.payload.personalDetails?.lastName ?? '',
      dateOfBirth: action.payload.personalDetails?.dateOfBirth ?? '',
      salutation: action.payload.personalDetails.gender ?? undefined,
      emailAddress: action.payload.personalDetails.emailAddress ?? '',
      phoneNumber: action.payload.personalDetails?.phoneNumber ?? '',
      businessDetails: {
        organizationName:
          action.payload?.businessDetails?.organizationName ?? '',
        chamberOfCommerceNumber:
          action.payload?.businessDetails?.chamberOfCommerceNumber ?? '',
        interestedInReimbursement:
          action.payload?.businessDetails?.interestedInReimbursement ?? false,
        emailAddress: getProspectEmailAddress(action.payload),
        phoneNumber: getProspectPhoneNumber(action.payload),
      },
    }),
  }))
);

const _wrappedReducer = wrappedFormReducer(
  _reducer,
  validateAndUpdateCustomerDetailsForm
);

export function customerDetailsReducer(
  state: CustomerDetailsState = initialCustomerDetailsState,
  action: Action
): CustomerDetailsState {
  return _wrappedReducer(state, action);
}
