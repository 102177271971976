import { getRouterSelectors } from '@ngrx/router-store';

import type { State } from './lib/app-state/state';

export {
  metaReducers,
  reducers,
  routerSelectorKey,
  runtimeChecks,
} from './lib/app-state/state';
export type { State } from './lib/app-state/state';
export { extModules } from './lib/build-specifics';

export const {
  selectQueryParam,
  selectCurrentRoute,
  selectQueryParams,
  selectRouteData,
  selectRouteParam,
  selectRouteParams,
  selectUrl,
} = getRouterSelectors<State>((state) => state.router);
