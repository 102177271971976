import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import { getSiteContext } from '@innogy/core/analytics';
import type { State } from '@innogy/jss';
import type { InnogyComponentRendering } from '@innogy/jss/models';
import { getFieldValue, getItemLinkFieldValue } from '@innogy/jss/utils';
import { Store } from '@ngrx/store';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';

import type { ButtonModel } from '../button.model';

@Component({
  selector: 'wl-button-container',
  templateUrl: './button-container.component.html',
  styleUrls: ['./button-container.component.scss'],
})
export class ButtonContainerComponent implements OnInit {
  @Input()
  rendering?: InnogyComponentRendering | any;

  config?: ButtonModel;
  placement$ = getSiteContext(this.store$, this.environmentConfig);

  constructor(
    private readonly store$: Store<State>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {}

  ngOnInit() {
    this.config = this.renderingToModel(this.rendering);
  }

  public renderingToModel(
    rendering: InnogyComponentRendering | undefined
  ): ButtonModel {
    if (rendering == null || rendering.fields == null) {
      return {};
    }

    const text = getFieldValue<string>(rendering.fields, 'text');
    const cssClass = getItemLinkFieldValue(rendering.fields, 'class');
    const icon = getFieldValue<string>(rendering.fields, 'icon');
    const url = getFieldValue<LinkField>(rendering.fields, 'url');

    return {
      text,
      class: cssClass,
      icon,
      url: url ? url.href : '',
    };
  }
}
