<div
  *ngIf="offer"
  class="d-flex gap-1 justify-content-center"
  [class.justify-content-between]="content?.priceDurationLabelSuffix"
>
  <span>{{
    offer.isDynamicProduct
      ? content?.proposedBudgetBillBeforeForDynamic
      : content?.proposedBudgetBillBefore
  }}</span>

  <div>
    <span class="fw-bold"
      >{{ offer.budgetBillAmount! | roundedCurrency }}
      {{ content?.priceDurationLabel }}</span
    >
    <span *ngIf="content?.priceDurationLabelSuffix" class="d-block text-end">{{
      content?.priceDurationLabelSuffix
    }}</span>
  </div>
  <wl-tooltip
    [tooltip]="{
      description: offer.isDynamicProduct
        ? content?.proposedBudgetBillBeforeTooltipForDynamic
        : content?.proposedBudgetBillBeforeTooltip
    }"
    *ngIf="
      content?.proposedBudgetBillBeforeTooltip ||
      content?.proposedBudgetBillBeforeTooltipForDynamic
    "
  >
  </wl-tooltip>
</div>
