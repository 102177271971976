import { DOCUMENT } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Inject, Renderer2 } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-environment-classes',
  template: '',
})
export class EnvironmentClassesComponent implements OnInit {
  private readonly experienceEditorClass = 'experience_editor';

  constructor(
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  ngOnInit() {
    this.renderer.addClass(this.document.body, this.config.environment);
    this.renderer.addClass(this.document.body, this.config.brand);
    this.renderer.addClass(this.document.body, this.config.type);
    this.renderer.addClass(this.document.body, this.config.segment);
    if (isEditorActive()) {
      this.renderer.addClass(this.document.body, this.experienceEditorClass);
    }
  }
}
