<div class="mb-1">
  <ng-container *ngIf="rendering?.body">
    <p>{{ rendering?.body }}</p>
  </ng-container>
</div>
<form
  novalidate
  [ngrxFormState]="formState"
  [wlTrackStep]="{
    rendering: { componentName: 'verbruik inschatten' },
    step: 0.1,
    stepname: 'verbruik inschatten'
  }"
>
  <div class="mb-2">
    <div class="mb-2">
      <ng-container *ngIf="rendering?.businessTypeLabel">
        <label for="business">{{ rendering?.businessTypeLabel }}</label>
      </ng-container>
      <wl-dropdown
        [wlTrackValueChange]="{
          step: 0.1,
          rendering: { componentName: 'verbruik inschatten' },
          label: 'type pand',
          inputLabel: formControls.businessType?.value ?? '',
          sendValue: true,
          trackFocus: true,
          stepname: 'verbruik inschatten'
        }"
        [dropdownItems]="rendering?.businessType || []"
        [formsControl]="formControls.businessType"
        [placeholder]="rendering?.businessTypePlaceholder"
        [floatingLabel]="false"
        icon="user"
        labelForId="businessType"
        wlAutomationId="usageQuestionnaire.businessType"
      ></wl-dropdown>
    </div>
    <div class="col-12" *ngIf="formControls.businessType | isInvalid">
      <wl-input-error
        [messageField]="rendering?.errorMessage"
        [messageDescription]="rendering?.businessTypePlaceholder"
        [rendering]="{ componentName: 'verbruik inschatten' }"
        stepname="verbruik inschatten"
      ></wl-input-error>
    </div>
  </div>
  <div *ngIf="!gasHidden" class="mb-2" wlAutomationId="energy-type-dropdown">
    <div class="mb-2">
      <ng-container *ngIf="rendering?.supplyTypeOptionsLabel">
        <label for="supplyType">{{ rendering?.supplyTypeOptionsLabel }}</label>
      </ng-container>
      <wl-dropdown
        [wlTrackValueChange]="{
          step: 0.1,
          rendering: { componentName: 'verbruik inschatten' },
          label: 'type aansluiting',
          inputLabel: formControls.supplyType?.value | analyticsMap,
          sendValue: true,
          trackFocus: true,
          stepname: 'verbruik inschatten'
        }"
        [dropdownItems]="rendering?.supplyTypeOptions || []"
        [formsControl]="formControls.supplyType"
        [placeholder]="rendering?.supplyTypeOptionsPlaceHolder"
        [floatingLabel]="false"
        icon="plug"
        labelForId="supplyType"
        wlAutomationId="usageQuestionnaire.supplyType"
      ></wl-dropdown>
    </div>
    <div class="col-12" *ngIf="formControls.supplyType | isInvalid">
      <wl-input-error
        [messageField]="rendering?.errorMessage"
        [messageDescription]="rendering?.supplyTypeOptionsPlaceHolder"
        [rendering]="{ componentName: 'verbruik inschatten' }"
        stepname="verbruik inschatten"
      ></wl-input-error>
    </div>
  </div>
  <input
    type="submit"
    class="btn btn-primary text-center-ed w-100-ed"
    wlAutomationId="usageQuestionnaire.submit"
    [value]="rendering?.submitLabel"
  />
</form>
