import { type RenderingItem } from '@innogy/jss/models';
import {
  type RichTextField,
  type TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export type GenericFormSubmitContractType = 'Koop';
export type GenericFormSubmitContactBranch =
  | 'adviesgesprek'
  | 'Directe Offerte Aanvraag';
export type GenericFormSubmitLeadTracking =
  | 'DirectOther'
  | 'PaidSearch'
  | 'Display'
  | 'Affiliate'
  | 'Email'
  | 'Social'
  | 'OrganicSearch'
  | 'Redirect';

export interface PostFormSubmitBody<T = { [key: string]: unknown }> {
  formSettingsPath: string;
  referrer: string;
  omgeving: string;
  bron: GenericFormSubmitSource;
  formValues: T;
  e2eTrackingId: string;
  gclid: string;
}

export interface ParsedFormSettings {
  flow: GenericFormSubmitFlow;
  confirmation?: Confirmation;
  disclaimer?: Disclaimer;
  defaultFields: GenericFormSubmitDefaultFields;
}

export interface GenericFormSubmitIntegrationItem extends RenderingItem {
  fields: {
    Flow: TextField;
    FormName: TextField;
    FormSubject: TextField;
    DbSubject: TextField;
    Referentie: TextField;
    Product: TextField;
    Remark: TextField;
    Branch: TextField;
    CampagneId: TextField;
    SubChannel: TextField;
    Temperature: TextField;
    EmailTo: TextField;
    MailSubject: TextField;
    EmailFrom: TextField;
    EmailContent: RichTextField;
    DisclaimerCategory: TextField;
    DisclaimerContent: RichTextField;
  };
}

export interface GenericFormSubmitIntegrationSettings {
  flow: GenericFormSubmitFlow;
  formName: string;
  formSubject: string;
  dbSubject: string;
  referentie: string;
  emailTo?: string;
  mailSubject?: string;
  emailFrom?: string;
  emailContent?: string;
  disclaimerCategory?: string;
  disclaimerContent?: string;
  product?: string;
  remark?: string;
  branch?: string;
  campagneId?: string;
  subChannel?: string;
  temperature?: string;
}

export interface GenericFormSubmitBody<T = GenericFormSubmitDefaultFields> {
  flow: GenericFormSubmitFlow;
  confirmation?: Confirmation;
  disclaimer?: Disclaimer;
  fields: T & GenericFormSubmitDefaultFields;
}

export enum GenericFormSubmitFlow {
  InHome = 'Inhome',
  Database = 'Database',
  Email = 'Email',
}

export type GenericFormSubmitSource = 'Online';

export interface Confirmation {
  email_from?: string;
  content?: string;
}

export interface Disclaimer {
  category?: string;
  content?: string;
}

interface FormField {
  label: string;
  hidden?: boolean;
  value: string;
}

export interface GenericFormSubmitDefaultFields {
  [key: string]: FormField | string | undefined;
  form_name: FormField | string;
  form_subject: FormField | string;
  db_subject: FormField | string;
  omgeving: FormField | string;
  REFERRER: FormField | string;
  mail_subject?: FormField | string;
  email_to?: FormField | string;
  Referentie?: FormField | string;
}

export interface GenericFormSubmitPersonalDetailsFields {
  aanhef?: FormField | string;
  achternaam?: FormField | string;
  emailprive?: FormField | string;
  huisnummer?: FormField | string;
  huisnummerExt?: FormField | string;
  plaats?: FormField | string;
  postcode?: FormField | string;
  tussenvoegsels?: string;
  telefoonprivevast?: FormField | string;
  voorletter?: FormField | string;
  rekeningnummer?: FormField | string;
  straat?: FormField | string;
}

/**
 * Fields shared between the SolarLeadForm and the InsulationLeadForm
 */
export interface GenericFormSubmitLeadFormFields {
  campagneid?: FormField | string;
  referentie?: FormField | string;
  subkanaal?: FormField | string;
  temperatuur?: FormField | string;
  gewensttypecontract?: FormField | string;
  vestiging?: FormField | string;
  product?: FormField | string;
  opmerking?: FormField | string;
}

export interface GenericFormSubmitAnalyticsFields {
  Option3?: FormField | string; // represent the e2e tracking ID
  Option5?: FormField | string; // represents the google click ID (gclid)
}

/**
 * Fields specific for the SolarLeadForm
 */
export interface GenericFormSubmitSolarPanelLeadFormFields
  extends GenericFormSubmitPersonalDetailsFields,
    GenericFormSubmitLeadFormFields,
    GenericFormSubmitDefaultFields {
  brand?: FormField | string;
  bron?: FormField | string;
  leadtracking?: FormField | string;
  api_energy?: FormField | string;
  api_investmentCosts?: FormField | string;
  api_kwp?: FormField | string;
  api_m2?: FormField | string;
  api_m2Max?: FormField | string;
  api_nrOfPanels?: FormField | string;
  api_panelType?: FormField | string;
  api_profitPerYear?: FormField | string;
  api_yearlyYield?: FormField | string;
}

export interface GenericFormSubmitInsulationLeadFormFields
  extends GenericFormSubmitPersonalDetailsFields,
    GenericFormSubmitLeadFormFields,
    GenericFormSubmitDefaultFields {
  brand?: FormField | string;
  bron?: FormField | string;
  leadtracking?: FormField | string;
}
