import type { InnogyComponentRendering } from '@innogy/jss/models';
import { getDroplinkValue, getFieldValue } from '@innogy/jss/utils';
import type { ImageField, LinkField } from '@sitecore-jss/sitecore-jss-angular';

export interface ImageSettings {
  image: ImageField;
  position?: string;
  aspectRatio?: string;
  alignment?: string;
  objectFit?: string;
  link?: LinkField;
}

export const getImageSettingsFromRendering = (
  rendering: InnogyComponentRendering
): ImageSettings => ({
  image: getFieldValue(rendering, 'Image', {}) as ImageField,
  position: getDroplinkValue(rendering.fields, 'ImagePosition'),
  aspectRatio: getDroplinkValue(rendering.fields, 'ImageAspectRatio'),
  alignment: getDroplinkValue(rendering.fields, 'ImageAlignment'),
  objectFit: getDroplinkValue(rendering.fields, 'ObjectFit'),
  link: rendering.fields?.ImageLink as LinkField,
});
