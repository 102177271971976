import type { HttpErrorResponse } from '@angular/common/http';
import { createBaseAction } from '@essent/common';
import type { MaintenanceModeResponse } from '@innogy/maintenance-mode/interfaces';
import { createAction, union } from '@ngrx/store';

export const enum MaintenanceModeActionTypes {
  GET = '[MaintenanceMode] GET',
  SUCCESS = '[MaintenanceMode] SUCCESS',
  ERROR = '[MaintenanceMode] ERROR',
  CLEAR = '[MaintenanceMode] CLEAR',
}

export const loadMaintenanceMode = createBaseAction(
  MaintenanceModeActionTypes.GET
);

export const loadMaintenanceModeSuccess =
  createBaseAction<MaintenanceModeResponse>(MaintenanceModeActionTypes.SUCCESS);

export const loadMaintenanceModeError = createBaseAction<HttpErrorResponse>(
  MaintenanceModeActionTypes.ERROR
);

export const loadMaintenanceModeClear = createAction(
  MaintenanceModeActionTypes.CLEAR
);

const actions = union({
  loadMaintenanceMode,
  loadMaintenanceModeSuccess,
  loadMaintenanceModeError,
  loadMaintenanceModeClear,
});

export type MaintenanceModeActionsUnion = typeof actions;
