import { DOCUMENT } from '@angular/common';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import type { MetaDataItem } from '@essent/new-customer';
import { putMetaDataActions } from '@essent/new-customer';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import { PlatformService } from '@innogy/core/platform';
import { ChannelRecognitionService } from '@innogy/shared/channel-recognition';
import { DomService, ScriptInjectOn } from '@innogy/utils/deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import type { Action } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  mergeMap,
  skipWhile,
  tap,
} from 'rxjs/operators';

import { createPutMetaDataPayload } from '../bac/5.meta-data';
import { setShouldExtendTimeToLiveAction } from '../bac/6.extend-time-to-live';
import { setFlowIdAction } from '../bac/flow-id';
import {
  getSaleId,
  loadMGMPingScriptAction,
  setMGMSaleIdAction,
} from '../bac/member-get-member';
import { getPropositionOffer } from '../bac/offers';
import {
  getPartnerId,
  setDefaultPartnerIdAction,
  setPartnerIdAction,
} from '../bac/partner-id';
import { getFunnelSettings } from '../funnel';
import { initOrderAction } from '../order';
import {
  channelRecognitionDoneAction,
  noDefaultPartnerIdAvailableErrorAction,
  performChannelRecognitionAction,
} from './channel-recognition-actions';
import { selectChannelRecognitionParams } from './channel-recognition.selectors';

@Injectable()
export class ChannelRecognitionEffects {
  constructor(
    private readonly channelRecognitionService: ChannelRecognitionService,
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly errorHandler: ErrorHandler,
    private readonly platformService: PlatformService,
    private readonly domService: DomService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENVIRONMENT_CONFIG) readonly config: EnvironmentConfig
  ) {}
  private readonly channelRecognitionParams$ = this.store$.select(
    selectChannelRecognitionParams
  );
  private readonly getPropositionOffer$ =
    this.store$.select(getPropositionOffer);
  private readonly funnelSettings$ = this.store$.select(getFunnelSettings);
  private readonly partnerId$ = this.store$.select(getPartnerId);
  private readonly saleId$ = this.store$.select(getSaleId);

  public readonly onPerformChannelRecognition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performChannelRecognitionAction),
      concatLatestFrom(() => [
        this.funnelSettings$,
        this.channelRecognitionParams$,
        this.partnerId$,
        this.saleId$,
      ]),
      filter(
        ([, { disableChannelRecognition }]) =>
          this.platformService.isClient() && !disableChannelRecognition
      ),
      mergeMap(
        ([
          ,
          ,
          { params, route },
          retrievedPartnerIdFromMetaData,
          retrievedMGMSaleIdFromMetaData,
        ]) =>
          this.channelRecognitionService
            .getPartnerId$({
              params,
              path: route,
              referrer: this.document.referrer,
            })
            .pipe(
              concatMap((determinedPartnerId) => {
                const partnerId =
                  determinedPartnerId || retrievedPartnerIdFromMetaData;
                const mgmSaleId =
                  params.mgmco_saleid || retrievedMGMSaleIdFromMetaData;

                return [
                  // When a partnerId is determined by the channelRecognitionService, we can extend the flowId lifetime
                  setShouldExtendTimeToLiveAction({
                    extendTimeToLive: !!determinedPartnerId,
                  }),
                  channelRecognitionDoneAction({ partnerId, mgmSaleId }),
                ];
              })
            )
      )
    )
  );

  public readonly onChannelRecognitionDone$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(channelRecognitionDoneAction)),
      this.actions$.pipe(ofType(setFlowIdAction)),
    ]).pipe(
      skipWhile(([, { flowId }]) => !flowId),
      mergeMap(([{ partnerId, mgmSaleId }, { flowId }]) => {
        if (partnerId) {
          const actions: Action[] = [setPartnerIdAction({ partnerId })];
          const metaPayload: MetaDataItem[] = [
            { name: 'partner_id', value: partnerId },
          ];

          if (mgmSaleId) {
            actions.push(setMGMSaleIdAction({ saleId: mgmSaleId }));
            actions.push(loadMGMPingScriptAction());
            //TODO remove cast to any for mgmco_saleid when angular 13 is live
            metaPayload.push({ name: 'mgmco_saleid' as any, value: mgmSaleId });
          }

          actions.push(
            putMetaDataActions.requestAction({
              payload: createPutMetaDataPayload(flowId, metaPayload),
            })
          );

          return actions;
        }

        return [setDefaultPartnerIdAction()];
      })
    )
  );

  public readonly onSetDefaultPartnerId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDefaultPartnerIdAction),
      concatLatestFrom(() =>
        this.channelRecognitionService.getDefaultPartnerId$()
      ),
      mergeMap(([_, partnerId]) =>
        partnerId
          ? [setPartnerIdAction({ partnerId })]
          : [noDefaultPartnerIdAvailableErrorAction()]
      )
    )
  );

  public readonly onNoDefaultPartnerIdAvailableError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(noDefaultPartnerIdAvailableErrorAction),
        tap(() =>
          this.errorHandler.handleError('No default partnerID available')
        )
      ),
    { dispatch: false }
  );

  public readonly overridePartnerIdWhenChannelRecognitionDisabled$ =
    createEffect(() =>
      this.actions$.pipe(
        ofType(initOrderAction),
        concatLatestFrom(() => [
          this.getPropositionOffer$,
          this.funnelSettings$,
        ]),
        filter(
          ([, , { disableChannelRecognition }]) => disableChannelRecognition
        ),
        map(([, activeOffer]) =>
          setPartnerIdAction({ partnerId: activeOffer?.partnerId || '' })
        )
      )
    );

  public readonly loadMGMPingScript$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMGMPingScriptAction),
        tap(() => {
          this.domService.injectScript({
            src: this.config.mgm.pingUrl,
            injectOnPlatform: ScriptInjectOn.CLIENT_ONLY,
            async: true,
          });
        })
      ),
    { dispatch: false }
  );
}
