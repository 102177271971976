import { Inject, Injectable } from '@angular/core';
import type { InstallationAddress } from '@essent/address';
import type { Gender } from '@essent/common';
import type { ConfirmationConsent, FlowId } from '@essent/new-customer';
import {
  AdditionalInformationType,
  ConsentStatus,
  IncomingPaymentMethod,
  PreferredChannel,
  putAdditionalInformation,
  putAdditionalInformationSuccess,
  putBudgetBill,
  putBudgetBillSuccess,
  putConfirmation,
  putConfirmationSuccess,
  putCorrespondenceDetails,
  putCorrespondenceDetailsSuccess,
  putOrganisationDetails,
  putPaymentDetails,
  putPaymentDetailsSuccess,
  putPersonalDetails,
  putStartDate,
} from '@essent/new-customer';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { ClientEnvironmentConfig } from '@innogy/config/models';
import { MarkAsyncTasksAsPendingAction } from '@innogy/progressive-ngrx-forms';
import { MemberGetMemberService } from '@innogy/shared/member-get-member';
import {
  LocationService,
  isValidatedSubmitAction,
  normalizeDateString,
} from '@innogy/utils/deprecated';
import { ofActionId } from '@innogy/utils/rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ClearAsyncErrorAction, StartAsyncValidationAction } from 'ngrx-forms';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { electronicFormatIBAN } from 'ibantools';

import {
  setContractWithOtherSupplierAction,
  setResidentialAction,
} from '../bac/15.additional-information/additional-information.actions';
import { getSupplyAddressState } from '../bac/2.supply-address';
import { getConsumptionValuesVM } from '../bac/7.consumption';
import { getFlowId, noFlowIdErrorAction } from '../bac/flow-id';
import { getIsCustomer } from '../bac/is-customer';
import {
  getMemberGetMemberPayload,
  getSaleId,
  hasMemberGetMember,
} from '../bac/member-get-member';
import { getPropositionOffer } from '../bac/offers';
import { getPartnerId } from '../bac/partner-id';
import { getFunnelSettings } from '../funnel';
import { getOffer } from '../get-offer/get-offer.actions';
import {
  ORDER_ADDRESS_FORM_ID,
  getOrderAddressFormState,
  getSelectedCorrespondenceInstallationAddressState,
} from './address';
import {
  ORDER_CONFIRMATION_FORM_ID,
  getOrderConfirmationFormState,
} from './confirmation';
import {
  ORDER_REFRESH_OFFER_ACTION_ID,
  SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID,
  redirectToSuccessPageAction,
  resetOrderConditionsAction,
} from './order.actions';
import { getOrderState } from './order.selector';
import type { OrderState } from './order.state';
import { ORDER_PAYMENT_FORM_ID, getOrderPaymentFormState } from './payment';
import { ORDER_PERSONAL_FORM_ID, getOrderPersonalFormState } from './personal';

@Injectable()
export class OrderFormSubmitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: ClientEnvironmentConfig,
    private readonly location: LocationService,
    private readonly memberGetMemberService: MemberGetMemberService
  ) {}

  private readonly funnelSettings$ = this.store$.select(getFunnelSettings);
  private readonly flowId$ = this.store$.pipe(select(getFlowId));
  private readonly partnerId$ = this.store$.pipe(select(getPartnerId));
  private readonly mgmSaleId$ = this.store$.pipe(select(getSaleId));
  private readonly hasMemberGetMember$ = this.store$.pipe(
    select(hasMemberGetMember)
  );
  private readonly memberGetMemberPayload$ = this.store$.pipe(
    select(getMemberGetMemberPayload(this.environmentConfig.brand))
  );
  private readonly selectedOffer$ = this.store$.pipe(
    select(getPropositionOffer)
  );
  private readonly personalDetailsFormValues$ = this.store$.pipe(
    select(getOrderPersonalFormState)
  );
  private readonly addressDetailsFormValues$ = this.store$.pipe(
    select(getOrderAddressFormState)
  );
  private readonly paymentDetailsFormValues$ = this.store$.pipe(
    select(getOrderPaymentFormState)
  );
  private readonly confirmationFormValues$ = this.store$.pipe(
    select(getOrderConfirmationFormState)
  );
  private readonly selectedCorrespondenceInstallationAddress$ =
    this.store$.pipe(select(getSelectedCorrespondenceInstallationAddressState));
  consumptionValuesVM$ = this.store$.pipe(select(getConsumptionValuesVM));
  getSupplyAddressState$ = this.store$.pipe(select(getSupplyAddressState));
  propositionOffer$ = this.store$.pipe(select(getPropositionOffer));
  isCustomer$ = this.store$.pipe(select(getIsCustomer));

  private readonly RESET_CONDITIONS_LIST = [
    ORDER_PERSONAL_FORM_ID,
    ORDER_ADDRESS_FORM_ID,
    ORDER_CONFIRMATION_FORM_ID,
    ORDER_PAYMENT_FORM_ID,
  ];

  private readonly SEGMENT = this.environmentConfig.segment;

  // In order to efficiently test this we need to use the mockStore in this file's spec.
  // This is currently technical debt, hence this effect is ignored in the coverage report.
  /* istanbul ignore next */
  public resetConditions$ = createEffect(() =>
    this.actions$.pipe(
      isValidatedSubmitAction(
        getOrderState,
        this.RESET_CONDITIONS_LIST,
        'formState'
      )(this.store$),
      mergeMap(() => [resetOrderConditionsAction()])
    )
  );

  /**
   * Step 2
   * Personal details card - Essent/Energiedirect Consumer
   */
  public readonly submitPersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === ORDER_PERSONAL_FORM_ID),
      filter(() => this.SEGMENT !== 'zakelijk'),
      concatLatestFrom(() => [this.flowId$, this.personalDetailsFormValues$]),
      switchMap(([, flowId, personalDetailsForm]) => {
        if (flowId) {
          return [
            putPersonalDetails({
              payload: {
                metaData: {
                  flowId,
                },
                payload: {
                  prefix: personalDetailsForm.value?.prefix || undefined,
                  initials: personalDetailsForm.value.initials,
                  lastName: personalDetailsForm.value.lastName,
                  dateOfBirth: normalizeDateString(
                    formatDate(personalDetailsForm.value.dateOfBirth)
                  ),
                  gender: personalDetailsForm.value.gender as Gender,
                },
              },
            }),
          ];
        }
        return [noFlowIdErrorAction()];
      })
    )
  );

  /**
   * Step 2.1
   * Submit company details (SME only)
   */
  public readonly submitOrganisationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === ORDER_PERSONAL_FORM_ID),
      filter(() => this.SEGMENT === 'zakelijk'),
      concatLatestFrom(() => [this.flowId$, this.personalDetailsFormValues$]),
      switchMap(([_, flowId, personalDetailsForm]) => {
        if (flowId) {
          return [
            putOrganisationDetails({
              payload: {
                metaData: {
                  flowId,
                },
                payload: {
                  organisationName: personalDetailsForm.value.companyName,
                  chamberOfCommerceNumber: personalDetailsForm.value.kvkNumber,
                },
              },
            }),
          ];
        }
        return [noFlowIdErrorAction()];
      })
    )
  );

  /**
   * Step 3.1
   * Submit correspondence details. This effect also saves the phone number provided
   * by the ORDER_PERSONAL_FORM.
   */
  public readonly submitCorrespondenceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) =>
        [ORDER_ADDRESS_FORM_ID, ORDER_PERSONAL_FORM_ID].includes(
          action.stepId as keyof OrderState
        )
      ),
      concatLatestFrom(() => [
        this.flowId$,
        this.personalDetailsFormValues$,
        this.addressDetailsFormValues$,
        this.selectedCorrespondenceInstallationAddress$,
      ]),
      // If the address-details-form is not touched yet the effect is triggered by the
      // ORDER_PERSONAL_FORM. There is no need to save the default form values.
      filter(([, , , addressdetailsForm]) => addressdetailsForm.isTouched),
      switchMap(
        ([
          _,
          flowId,
          personalDetailsForm,
          addressDetailsForm,
          selectedCorrespondenceInstallationAddress,
        ]) => {
          if (flowId) {
            const { useAsCorrespondenceAddress } = addressDetailsForm.value;

            const useSupplyAddress = this.shouldUseAsCorrespondenceAddress(
              useAsCorrespondenceAddress
            );
            const correspondenceAddress = this.formatCorrespondenceAddress(
              useSupplyAddress,
              selectedCorrespondenceInstallationAddress,
              addressDetailsForm.value.careOfFullName
            );

            return [
              putCorrespondenceDetails({
                actionId: SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID,
                payload: {
                  metaData: {
                    flowId,
                  },
                  payload: {
                    phone: personalDetailsForm.value.phone,
                    email: personalDetailsForm.value.email,
                    preferredChannel: PreferredChannel.EMAIL,
                    useSupplyAddress,
                    correspondenceAddress,
                  },
                },
              }),
            ];
          }
          return [noFlowIdErrorAction()];
        }
      )
    )
  );

  /**
   * Step 4.1
   * Address details card
   * Submit additional information
   */
  public readonly submitAdditionalInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putCorrespondenceDetailsSuccess),
      ofActionId(SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID),
      concatLatestFrom(() => [this.flowId$, this.addressDetailsFormValues$]),
      switchMap(([_, flowId, addressDetailsFormValues]) => {
        const { residenceOrWorkplace, inswitchInhouse } =
          addressDetailsFormValues.value;

        if (flowId) {
          const inswitchInhouseValue = inswitchInhouse?.value?.type;
          const isInswitch = inswitchInhouseValue === 'inswitch';

          return [
            setResidentialAction({ value: residenceOrWorkplace }),
            setContractWithOtherSupplierAction({
              value: isInswitch,
            }),
            putAdditionalInformation({
              actionId: SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID,
              payload: {
                metaData: {
                  flowId,
                },
                payload: [
                  {
                    name: AdditionalInformationType.RESIDENTIAL,
                    value: residenceOrWorkplace,
                  },
                  {
                    name: AdditionalInformationType.CONTRACT_WITH_ANOTHER_SUPPLIER,
                    value: isInswitch,
                  },
                ],
              },
            }),
          ];
        }
        return [noFlowIdErrorAction()];
      })
    )
  );

  /**
   * Step 4.2
   * Address details card
   * Submit start date
   */
  public readonly submitStartDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putAdditionalInformationSuccess),
      ofActionId(SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID),
      concatLatestFrom(() => [
        this.flowId$,
        this.addressDetailsFormValues$,
        this.selectedOffer$,
      ]),
      switchMap(([_, flowId, addressDetailsFormValues, activeOffer]) => {
        const { selectedStartDate } = addressDetailsFormValues.value;

        if (!flowId) {
          return [noFlowIdErrorAction()];
        }

        if (activeOffer) {
          const commodityStartDatePayload = activeOffer.commodities.map(
            (commodity) => ({
              energyType: commodity,
              startDate: selectedStartDate,
            })
          );

          return [
            putStartDate({
              actionId: SUBMIT_CORRESPONDENCE_DETAILS_ACTION_ID,
              payload: {
                metaData: {
                  flowId,
                },
                payload: commodityStartDatePayload,
              },
            }),
          ];
        }

        return [];
      })
    )
  );

  /**
   * Step 4.3
   * Address details card
   * Refresh offers when additional information has been changed successfully
   */
  public readonly refreshOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putAdditionalInformationSuccess),
      filter((action) => action.actionId === ORDER_REFRESH_OFFER_ACTION_ID),
      concatLatestFrom(() => [
        this.flowId$,
        this.propositionOffer$,
        this.isCustomer$,
      ]),
      switchMap(([_, flowId, propositionOffer, isCustomer]) => {
        if (flowId && propositionOffer) {
          return [
            getOffer({
              actionId: ORDER_REFRESH_OFFER_ACTION_ID,
              payload: {
                flowId: flowId as unknown as FlowId,
                campaignId: propositionOffer.campaignId,
                duration: propositionOffer.duration,
                incentiveId: propositionOffer.incentiveId,
                isCustomer,
              },
            }),
          ];
        }
        return [noFlowIdErrorAction()];
      })
    )
  );

  /**
   * Step 5
   * Payment details card
   */
  public readonly submitPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === ORDER_PAYMENT_FORM_ID),
      concatLatestFrom(() => [this.flowId$, this.paymentDetailsFormValues$]),
      filter(
        ([_, __, paymentDetailsFormValues]) =>
          paymentDetailsFormValues.controls.iban.isValid
      ),
      switchMap(([_, flowId, paymentDetailsFormValues]) => {
        if (flowId) {
          return [
            putPaymentDetails({
              payload: {
                metaData: {
                  flowId,
                },
                payload: {
                  iban:
                    electronicFormatIBAN(paymentDetailsFormValues.value.iban) ??
                    paymentDetailsFormValues.value.iban,
                  incomingPaymentMethod: IncomingPaymentMethod.DIRECT_DEBIT,
                  useCorrespondenceAddress: true,
                  paymentTerm: this.SEGMENT === 'zakelijk' ? 14 : undefined,
                },
              },
            }),
            new StartAsyncValidationAction(
              paymentDetailsFormValues.controls.iban.id,
              'ibanBlacklisted'
            ),
          ];
        }
        return [noFlowIdErrorAction()];
      })
    )
  );
  public markAsValidPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putPaymentDetailsSuccess),
      concatLatestFrom(() => [this.paymentDetailsFormValues$]),
      mergeMap(([_, paymentDetailsFormValues]) => {
        return [
          new ClearAsyncErrorAction(
            paymentDetailsFormValues.controls.iban.id,
            'ibanBlacklisted'
          ),
        ];
      })
    )
  );

  /**
   * Step 6
   * Confirmation card
   */
  public readonly submitBudgetBill$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === ORDER_CONFIRMATION_FORM_ID),
      concatLatestFrom(() => [this.flowId$, this.selectedOffer$]),
      switchMap(([_, flowId, activeOffer]) => {
        if (flowId && activeOffer) {
          return [
            putBudgetBill({
              payload: {
                metaData: { flowId },
                payload: {
                  budgetBillAmount: activeOffer.budgetBillAmount ?? NaN,
                },
              },
            }),
          ];
        }
        return [noFlowIdErrorAction()];
      })
    )
  );

  public readonly submitConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putBudgetBillSuccess),
      concatLatestFrom(() => [
        this.flowId$,
        this.partnerId$,
        this.mgmSaleId$,
        this.confirmationFormValues$,
      ]),
      switchMap(([_, flowId, extPartnerId, mgmSaleId, formValues]) => {
        if (flowId) {
          const today = new Date();
          const validFrom = normalizeDateString(
            formatDate(
              `${today.getFullYear()}-${
                today.getMonth() + 1
              }-${today.getDate()}`
            )
          );

          const actionMail: ConfirmationConsent = {
            consentStatus: formValues.controls.actionMailAccepted?.value
              ? ConsentStatus.GIVEN
              : ConsentStatus.REJECTED,
            validFrom,
          };
          const informationMail: ConfirmationConsent = {
            consentStatus: formValues.controls.informationMailAccepted?.value
              ? ConsentStatus.GIVEN
              : ConsentStatus.REJECTED,
            validFrom,
          };

          const putConfirmationPayload = {
            metaData: { flowId },
            payload: {
              sendQuotation: false,
              extPartnerId,
              consents: {
                actionMail,
                informationMail,
              },
            },
          };

          if (mgmSaleId) {
            // TODO remove `any` when the @essent/new-customer is bumped to >= 12.1.0
            (putConfirmationPayload.payload as any).promoter = mgmSaleId;
          }

          return [
            putConfirmation({
              payload: putConfirmationPayload,
            }),
          ];
        }
        return [noFlowIdErrorAction()];
      })
    )
  );

  /**
   * Step 6b
   * Finish the order and check for MemberGetMember or redirect to thank you page
   */
  public readonly finishOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putConfirmationSuccess),
      concatLatestFrom(() => this.hasMemberGetMember$),
      filter(([_, hasMemberGetMember]) => !hasMemberGetMember),
      map(redirectToSuccessPageAction)
    )
  );

  public readonly finishMemberGetMemberOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putConfirmationSuccess),
      concatLatestFrom(() => [
        this.hasMemberGetMember$,
        this.memberGetMemberPayload$,
      ]),
      filter(([_, hasMemberGetMember]) => hasMemberGetMember),
      tap(([_, __, memberGetMemberPayload]) => {
        if (memberGetMemberPayload) {
          this.memberGetMemberService.ping(memberGetMemberPayload);
        }
      }),
      map(redirectToSuccessPageAction)
    )
  );

  /**
   * Step 6d
   * Finish the order and redirect to thank you page
   */
  public readonly redirectToSuccessPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToSuccessPageAction),
        concatLatestFrom(() => this.funnelSettings$),
        tap(([_, settings]) => {
          const redirectUrl = settings.orderSuccesPage;

          return this.location.navigateScLink(redirectUrl, '/');
        })
      ),
    { dispatch: false }
  );

  private shouldUseAsCorrespondenceAddress(
    _useCorrespondenceAddress: boolean | undefined
  ) {
    return _useCorrespondenceAddress !== undefined
      ? _useCorrespondenceAddress
      : true;
  }

  private formatCorrespondenceAddress(
    _useSupplyAddress: boolean,
    address: Partial<InstallationAddress> | undefined,
    careOfFullName?: string
  ) {
    if (_useSupplyAddress) {
      return undefined;
    }
    return {
      city: address?.city || '',
      houseNumber: address?.houseNumber || '',
      postcode: address?.postcode || '',
      street: address?.street || '',
      houseNumberExtension: address?.houseNumberExtension,
      careOfFullName,
    };
  }
}

export function formatDate(date: string): string {
  return date
    .split('-')
    .map((item) => {
      return item.replace(/^[1-9]$/g, '0$&');
    })
    .join('-');
}
