import type { FormGroupState } from 'ngrx-forms';
import { createFormGroupState, updateGroup } from 'ngrx-forms';
import type { Action } from '@ngrx/store';
import { on } from '@ngrx/store';
import { requiredTrue } from 'ngrx-forms/validation';
import {
  createNgrxFormReducer,
  wrappedFormReducer,
} from '@innogy/shared/forms';
import type {
  LeadFormChargeCardConsents,
  LeadFormChargingStationConsents,
} from '@innogy/emobility-lead/services';
import {
  postLeadForm,
  postLeadFormError,
  postLeadFormSuccess,
} from '@innogy/emobility-lead/services';
import { ProductSelection } from '@integration/emobility-api-models';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';
import { Status } from '@essent/common';
import { validateSequentialIf } from '@innogy/utils/deprecated';
import type { HttpErrorResponse } from '@angular/common/http';

import {
  initializeLeadForm,
  setEmobilityLeadFormType,
} from './lead-form.actions';

export const leadConsentsFormId = 'lead-consents';

export interface LeadConsentsFormState {
  formState: FormGroupState<
    LeadFormChargingStationConsents & LeadFormChargeCardConsents
  >;
  thankYouPage?: LinkField;
  postLeadFormStatus: Status;
  postLeadFormError?: HttpErrorResponse;
  leadFormType?: ProductSelection;
  duplicateCaseError: boolean;
  e2eTrackingId: string | undefined;
}

export const initialLeadConsentsFormState = createFormGroupState<
  LeadFormChargingStationConsents & LeadFormChargeCardConsents
>(leadConsentsFormId, {
  acceptedCallFromAdvisor: false,
  acceptedPaymentConditions: false,
  acceptedTermsAndConditions: false,
  acceptedTariffRateChange: false,
  acceptedProductMail: false,
});

export const initialLeadConsentsState: LeadConsentsFormState = {
  formState: initialLeadConsentsFormState,
  thankYouPage: undefined,
  postLeadFormStatus: Status.IDLE,
  postLeadFormError: undefined,
  leadFormType: undefined,
  duplicateCaseError: false,
  e2eTrackingId: undefined,
};

export const validateLeadConsentsForm = (state: LeadConsentsFormState) => {
  return updateGroup<
    LeadFormChargingStationConsents & LeadFormChargeCardConsents
  >({
    acceptedCallFromAdvisor: validateSequentialIf(
      state.leadFormType === ProductSelection.CHARGING_STATION
    )(requiredTrue),
    acceptedPaymentConditions: validateSequentialIf(
      state.leadFormType === ProductSelection.CHARGE_CARD
    )(requiredTrue),
    acceptedTermsAndConditions: validateSequentialIf(
      state.leadFormType === ProductSelection.CHARGE_CARD
    )(requiredTrue),
  })(state.formState);
};

const _reducer = createNgrxFormReducer(
  initialLeadConsentsState,
  leadConsentsFormId,
  validateLeadConsentsForm,
  on(initializeLeadForm, (state, action) => {
    return {
      ...state,
      thankYouPage: action.payload.thankYouPageUrl,
      e2eTrackingId: action.payload.e2eTrackingId,
    };
  }),
  on(postLeadForm, (state) => {
    return {
      ...state,
      postLeadFormStatus: Status.PENDING,
      postLeadFormError: undefined,
      duplicateCaseError: false,
    };
  }),
  on(postLeadFormSuccess, (state) => {
    return {
      ...state,
      postLeadFormStatus: Status.SUCCESS,
      postLeadFormError: undefined,
      duplicateCaseError: false,
    };
  }),
  on(postLeadFormError, (state, action) => {
    return {
      ...state,
      postLeadFormStatus: Status.ERROR,
      postLeadFormError: action.payload,
      duplicateCaseError:
        action.payload.status === 400 &&
        action.payload.error?.code === 'existing_case',
    };
  }),
  on(setEmobilityLeadFormType, (state, action) => {
    return {
      ...state,
      leadFormType: action.proposition,
    };
  })
);

const _wrappedReducer = wrappedFormReducer(_reducer, validateLeadConsentsForm);

export function leadConsentsReducer(
  state: LeadConsentsFormState = initialLeadConsentsState,
  action: Action
): LeadConsentsFormState {
  return _wrappedReducer(state, action);
}
