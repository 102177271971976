import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JssUtilsModule } from '@innogy/jss/utils';
import { UtilsModule } from '@innogy/utils/deprecated';

import { BackgroundDirective } from './background/background.directive';
import { LoadingComponent } from './loading/loading.component';
import {
  ModalBodyDirective,
  ModalComponent,
  ModalFooterDirective,
} from './modal/modal.component';
import { SkeletonLoadingCardComponent } from './skeleton-loading/skeleton-loading-card/skeleton-loading-card.component';
import { SkeletonLoadingContentComponent } from './skeleton-loading/skeleton-loading-content/skeleton-loading-content.component';
import { SkeletonLoadingListComponent } from './skeleton-loading/skeleton-loading-list/skeleton-loading-list.component';
import { SkeletonLoadingRowComponent } from './skeleton-loading/skeleton-loading-row/skeleton-loading-row.component';
import { SvgSkeletonLoaderComponent } from './skeleton-loading/svg-skeleton-loader/svg-skeleton-loader.component';

@NgModule({
  imports: [UtilsModule, CommonModule, FontAwesomeModule, JssUtilsModule],
  declarations: [
    LoadingComponent,
    SkeletonLoadingCardComponent,
    SkeletonLoadingListComponent,
    SkeletonLoadingContentComponent,
    SkeletonLoadingRowComponent,
    ModalComponent,
    ModalBodyDirective,
    ModalFooterDirective,
    SvgSkeletonLoaderComponent,
    BackgroundDirective,
  ],
  exports: [
    LoadingComponent,
    SkeletonLoadingCardComponent,
    SkeletonLoadingListComponent,
    SkeletonLoadingContentComponent,
    SkeletonLoadingRowComponent,
    ModalComponent,
    ModalBodyDirective,
    ModalFooterDirective,
    SvgSkeletonLoaderComponent,
    BackgroundDirective,
  ],
})
export class CommonUiSharedModule {}
