import { DOCUMENT } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, HostListener, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import { PlatformService } from '@innogy/core/platform';
import { Angulartics2 } from 'angulartics2';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomService, ScriptInjectOn } from '@innogy/utils/deprecated';

import { AdobeLaunchService } from '../shared/adobe-launch.service';
import { Angulartics2AdobeLaunchService } from '../shared/angulartics2-adobe-launch.service';

interface TrackingEventPayload {
  event: string;
  info: any;
}

@Component({
  selector: 'wl-analytics-setup',
  template: '',
})
export class AnalyticsSetupComponent implements OnInit, OnDestroy {
  private readonly onDestroy$ = new Subject();

  constructor(
    @Inject(DOCUMENT) readonly document: Document,
    private readonly platformService: PlatformService,
    readonly analytics: Angulartics2AdobeLaunchService,
    readonly launch: AdobeLaunchService,
    @Inject(ENVIRONMENT_CONFIG) readonly config: EnvironmentConfig,
    readonly angulartics: Angulartics2,
    private readonly domService: DomService
  ) {}

  ngOnInit() {
    this.setupLaunchScript();

    if (this.platformService.isClient()) {
      this.analytics
        .startTracking()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();

      this.document.body.dispatchEvent(new CustomEvent('AngularLoaded'));
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  /**
   * Event triggered from Launch to know when launch is ready to receive events
   */
  @HostListener('body:LaunchCoreLoaded', ['$event'])
  onLaunchLoaded() {
    this.launch.launchLoaded();
  }

  /**
   * Third party scripts can trigger this event to integrate data tracking with the
   * angular application, including data tracking enrichment in the angular application.
   */
  @HostListener('body:InnogyTrackingEvent', ['$event'])
  onInnogyEvent(event: CustomEvent<TrackingEventPayload>) {
    this.angulartics.eventTrack.next({
      action: event.detail.event,
      properties: event.detail.info,
    });
  }

  /**
   * Load Adobe launch async
   * @see https://docs.adobelaunch.com/launch-reference/client-side-information/asynchronous-deployment
   */
  private setupLaunchScript() {
    const { enabled, url, localTest } = this.config.adobeLaunch || {};

    if (enabled && url) {
      const { CLIENT_AND_SERVER, SERVER_ONLY } = ScriptInjectOn;

      this.domService.injectScript({
        src: url,
        async: true,
        injectOnPlatform: localTest ? CLIENT_AND_SERVER : SERVER_ONLY,
        target: 'body',
      });
    }
  }
}
