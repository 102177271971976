import { Status } from '@essent/common';
import type { StateStatus } from '@innogy/utils/state';

import type { Settings } from '../settings.model';
import type { SettingsStateActionsUnion } from './settings.actions';
import { SettingsStateActionTypes } from './settings.actions';

export interface State extends StateStatus {
  settings: Settings | null;
}

export const initialState: State = {
  status: Status.IDLE,
  settings: null,
};

export function settingsStateReducer(
  state: State = initialState,
  action: SettingsStateActionsUnion
): State {
  switch (action.type) {
    case SettingsStateActionTypes.LOAD:
      return {
        ...state,
        status: Status.PENDING,
      };
    case SettingsStateActionTypes.LOADED:
      return {
        ...state,
        status: Status.SUCCESS,
        settings: action.payload,
      };
    case SettingsStateActionTypes.HTTP_ERROR:
      return {
        ...state,
        status: Status.ERROR,
        error: action.payload,
        settings: null,
      };
    default:
      return state;
  }
}

export const getStatus = (state: State) => state.status;
export const getSettings = (state: State) => state.settings;
export const getErrors = (state: State) => state.error;
export const getState = (state: State) => state;
