import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, ElementRef, HostBinding } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import type { ProductDuration } from '@essent/new-customer';
import type {
  Offer,
  OfferContentIncentive,
} from '@innogy/become-a-customer/shared/interfaces';
import {
  changeCalculationAction,
  hasMemberGetMember,
  readyToLoadOffers,
  setActiveOffersByDurationAction,
  setActiveOffersetAction,
  showAomModalAction,
  submitPropositionHorizontalAction,
} from '@innogy/become-a-customer/state';
import { openGenericLightbox } from '@innogy/common-ui/modals';
import { getSkeletonMaskFromRendering } from '@innogy/common-ui/shared';
import { componentViewModelSelector } from '@innogy/core/experience-editor-utils';
import { Store } from '@ngrx/store';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';

import { BaseOfferContainerComponent } from '../../base-offer/base-offer-container.component';
import { moreInfoButtonClick } from '../../utils/more-info-button-click';
import { horizontalOfferComponentVm } from './horizontal-offer-container.component.selector';

@Component({
  selector: 'wl-horizontal-offer',
  templateUrl: './horizontal-offer-container.component.html',
  styleUrls: ['./horizontal-offer-container.component.essent.scss'],
})
// onDestroy$ is completed in the Base class on ngOnDestroy
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class HorizontalOfferContainerComponent
  extends BaseOfferContainerComponent
  implements OnInit, AfterViewInit
{
  readyToLoadOffers$ = this.store$.select(readyToLoadOffers);
  hasMemberGetMember$ = this.store$.select(hasMemberGetMember);

  private readonly mockVm = {
    activeOfferset: this.xpMock.offerSet,
    activeOffers: this.xpMock.offers,
    activeDuration: this.xpMock.activeDuration,
    presentDurations: this.xpMock.durations,
    retrievedDurations: this.xpMock.retrievedDurations,
    regularOfferset: 'mock-offerset',
    addOn: this.xpMock.addOn,
    consumptionValues: this.xpMock.consumptionValues,
    supplyAddressVM: this.xpMock.supplyAddress,
  };
  public viewState$ = this.store$.select(
    componentViewModelSelector(horizontalOfferComponentVm, this.mockVm)
  );

  /**
   * NOTE: This "id" is used in "calculate-form-submit.effects.ts" to determine the type of offerPage
   */
  @HostBinding('id')
  public id = 'horizontal-offer';

  constructor(
    protected readonly store$: Store<any>,
    private readonly ref: ElementRef
  ) {
    super(store$);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.readyToLoadOffers$
      .pipe(
        filter((ready) => !!ready),
        take(1),
        tap(() => {
          this.ref.nativeElement.scrollIntoView({
            behavior: 'smooth',
          });
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  get skeletonLoaderMask() {
    return getSkeletonMaskFromRendering(this.rendering);
  }

  get isActiveDurationLoaded$() {
    return this.viewState$.pipe(
      map((viewState) => {
        if (!viewState.activeDuration) {
          return false;
        }

        return viewState.retrievedDurations.includes(viewState.activeDuration);
      })
    );
  }

  onAddOnToggle(
    toggled: boolean,
    regularOfferset: string,
    alternativeOfferset?: string
  ) {
    const offerset =
      toggled && alternativeOfferset ? alternativeOfferset : regularOfferset;

    this.store$.dispatch(
      setActiveOffersetAction({
        offerset,
      })
    );
  }

  setActiveOffersByNameAndDuration(
    duration: ProductDuration,
    offerSetName: string
  ) {
    this.store$.dispatch(
      setActiveOffersByDurationAction({ offerset: offerSetName, duration })
    );
  }

  /**
   * Submit the selected offer and redirect to the order page
   *
   * @param offer Selected offer
   */
  onSubmitProposition(offer: Offer) {
    this.store$.dispatch(
      submitPropositionHorizontalAction({
        offer,
      })
    );
  }

  onCalculationChanged() {
    this.store$.dispatch(changeCalculationAction());
  }

  showTarifs(offer: Offer) {
    this.store$.dispatch(
      showAomModalAction({
        offer,
        showFooter: true,
        rendering: this.rendering,
      })
    );
  }

  onShowMoreIncentiveInfo(incentive: OfferContentIncentive) {
    this.store$.dispatch(
      openGenericLightbox({
        title: incentive.incentiveTitle ?? '',
        tracking: { componentName: 'incentive description modal' },
        url: (incentive.incentiveInfoUrl as SafeResourceUrl) || undefined,
        body: { value: incentive.incentiveFullDescription || undefined },
      })
    );
  }

  moreInfoButtonClick = (offer: Offer) =>
    moreInfoButtonClick(this.store$, offer);
}
