<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [trackingConfig]="{
    toolName: trackStep.toolName,
    step: trackStep.step,
    stepName: trackStep.stepName
  }"
>
  <ng-template #summary>
    <div wlCSMask>
      <p class="mb-0">
        {{ progressiveFormState?.formState?.value?.street }}
        {{ progressiveFormState?.formState?.value?.communicationNumber
        }}{{ progressiveFormState?.formState?.value?.houseNumberAddition }}
      </p>
      <p class="mb-0">
        {{ progressiveFormState?.formState?.value?.postalCode }}
        {{ progressiveFormState?.formState?.value?.city }}
      </p>
    </div>
  </ng-template>
</wl-simple-progressive-form-card>
