import type { State as SharedState } from '@innogy/shared/state';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import * as fromChangeIbanForm from './change-iban-form.reducer';
import * as fromInvoiceAddressForm from './change-invoice-address-form.reducer';
import * as fromChangePaymentDateForm from './change-payment-date-form.reducer';
import * as fromDetails from './payment-details.reducer';
import * as fromUpdatePaymentMethodForm from './update-payment-method-form.reducer';
import * as fromUpdatePaymentMethod from './update-payment-method.reducer';

export const paymentDetailsSelectorKey = 'paymentDetails';

export interface PaymentDetailsState {
  readonly details: fromDetails.State;
  readonly updatePaymentMethod: fromUpdatePaymentMethod.State;
  readonly updatePaymentMethodForm: fromUpdatePaymentMethodForm.State;
  readonly changeIbanForm: fromChangeIbanForm.State;
  readonly changePaymentDateForm: fromChangePaymentDateForm.State;
  readonly changeInvoiceAddressForm: fromInvoiceAddressForm.State;
}

export interface State extends SharedState {
  readonly [paymentDetailsSelectorKey]: PaymentDetailsState;
}

export const reducers: ActionReducerMap<PaymentDetailsState, Action> = {
  details: fromDetails.paymentDetailsReducer,
  updatePaymentMethod: fromUpdatePaymentMethod.updatePaymentMethodReducer,
  updatePaymentMethodForm: fromUpdatePaymentMethodForm.reducer,
  changeIbanForm: fromChangeIbanForm.reducer,
  changePaymentDateForm: fromChangePaymentDateForm.reducer,
  changeInvoiceAddressForm: fromInvoiceAddressForm.reducer,
};

export const getPaymentDetailsState =
  createFeatureSelector<PaymentDetailsState>(paymentDetailsSelectorKey);
