import { Injectable, Injector } from '@angular/core';
import { getContractGroupTypeForRendering } from '@innogy/contracting/store';
import { BaseGuard } from '@innogy/core/ngrx';
import { fetchChargeCards } from '@innogy/emobility/chargecard/store';
import {
  getIsDetailsLoading,
  getPaymentDetailsForSelectedContractGroup,
} from '@innogy/payment-details/store';
import { ProductSelection } from '@integration/emobility-api-models';
import { select, Store } from '@ngrx/store';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';
import { SetValueAction } from 'ngrx-forms';
import type { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs';

import {
  CustomerType,
  customerTypeControlId,
} from './lead-form-customer-details.reducer';
import { getPropositionType } from './lead-form.selectors';

@Injectable({
  providedIn: 'root',
})
export class LeadFormGuard extends BaseGuard {
  protected isStateLoading(): Observable<boolean> {
    return this.store$.pipe(
      select(getIsDetailsLoading),
      withLatestFrom(this.store$.select(getPropositionType)),
      map(([isDetailsLoading, propositionType]) => {
        if (propositionType === ProductSelection.CHARGE_CARD) {
          return isDetailsLoading;
        }
        return false;
      })
    );
  }

  protected getDispatchActions(input: GuardInput) {
    return getPaymentDetailsForSelectedContractGroup({
      contractGroupType: getContractGroupTypeForRendering(input),
    });
  }

  public canActivate(input: GuardInput) {
    this.store$.dispatch(fetchChargeCards());

    if (input.activatedRoute.queryParams['zakelijk'] === 'ja') {
      this.store$.dispatch(
        new SetValueAction(customerTypeControlId, CustomerType.SelfEmployed)
      );
    }

    return super.canActivate(input);
  }

  constructor(
    private readonly store$: Store<any>,
    protected readonly injector: Injector
  ) {
    super(injector);
  }
}
