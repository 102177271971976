<wl-checkbox
  [formsControl]="control"
  [rendering]="{ componentName: trackStep.toolName }"
  [step]="trackStep.step"
  [stepname]="trackStep.stepName"
  [fieldname]="fields.Label?.value"
  [tooltip]="fields.Tooltip?.value"
  [hashValue]="fields.HashValue?.value"
  [sendValue]="fields.SendValue?.value"
  [showValidation]="true"
>
  <ng-container
    *ngIf="fields.RichTextLabel?.value; then richTextLabel; else textLabel"
  ></ng-container>
</wl-checkbox>

<ng-template #richTextLabel>
  <div *wlGenericRichText="fields.RichTextLabel"></div>
</ng-template>

<ng-template #textLabel>
  <div *scText="fields.Label"></div>
</ng-template>
