import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { getItemLinkFieldValue } from '@innogy/jss/utils';
import { InnogyComponentRendering } from '@innogy/jss/models';

@Component({
  selector: 'wl-marketing-heading',
  templateUrl: './marketing-heading.component.html',
  styleUrls: [
    './marketing-heading.component.ed.scss',
    './marketing-heading.component.essent.scss',
  ],
})
export class MarketingHeadingComponent implements OnInit {
  @Input()
  rendering?: InnogyComponentRendering;
  theme = '';

  ngOnInit() {
    if (this.rendering && this.rendering.fields) {
      const themeValue = getItemLinkFieldValue(this.rendering.fields, 'Theme');
      this.theme = themeValue ? `--${themeValue}` : '';
    }
  }
}
