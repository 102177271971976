import { selectQueryParam } from '@innogy/shared/state';
import { isError, isLoading } from '@innogy/utils/state';
import { createSelector } from '@ngrx/store';

import { getCustomerDetailsState } from './feature.selectors';

const getPrices = createSelector(
  getCustomerDetailsState,
  (state) => state.prices
);

const getSelectedPricesEntry = createSelector(
  getPrices,
  selectQueryParam('contract_id'),
  (prices, contractId) => (contractId ? prices.entities[contractId] : undefined)
);

const getSelectedPrices = createSelector(
  getSelectedPricesEntry,
  (entry) => entry?.entry
);

const getFirstPriceCostPerContract = createSelector(
  getSelectedPrices,
  (prices) =>
    prices?.priceDetails?.priceOverview?.[0]?.priceCostsPerContract?.[0]
);

const getContractType = createSelector(
  getFirstPriceCostPerContract,
  (priceCost) => priceCost?.contractType
);

const getSelectedEnergyType = createSelector(
  getFirstPriceCostPerContract,
  (priceCost) => priceCost?.energyType
);

const getCurrentPriceTotals = createSelector(
  getFirstPriceCostPerContract,
  (priceCost) =>
    priceCost?.pricePeriods?.[0]?.consumptionPrice?.totalConsumptionPrice
);

const getCurrentPriceGroups = createSelector(
  getFirstPriceCostPerContract,
  (priceCost) => priceCost?.pricePeriods?.[0]?.priceGroups
);

const getPricesHasError = createSelector(getSelectedPricesEntry, (entry) =>
  isError(entry)
);

export const isCurrectPriceLoading = createSelector(
  getPrices,
  selectQueryParam('contract_id'),
  (prices, contractId) => {
    if (contractId == null) {
      return true;
    }

    const entity = prices.entities[contractId];
    return entity == null || isLoading(entity);
  }
);

export const getPricesWithDetails = createSelector(
  getCurrentPriceGroups,
  getCurrentPriceTotals,
  getSelectedEnergyType,
  getPricesHasError,
  getContractType,
  (priceGroups, totals, energytype, hasError, contractType) => ({
    priceGroups,
    totals,
    energytype,
    contractType,
    hasError,
  })
);
