import type { UrlMatchResult } from '@angular/router';
import { UrlSegment } from '@angular/router';
import type { JSSConfig } from '@innogy/config/models';

import { JssRouteBuilderService } from './jss-route-builder.service';

export function jssRouteMatcherFactory(
  url: UrlSegment[],
  config: JSSConfig
): UrlMatchResult {
  const routeParser = new JssRouteBuilderService(config);
  const route = routeParser.parseRouteUrl(
    url.map((segment) => segment.toString())
  ) as { [key: string]: string };

  // convert JssRoute, server route & language, to route parameters
  const posParams: { [key: string]: UrlSegment } = {};
  Object.keys(route).forEach((key) => {
    posParams[key] = new UrlSegment(route[key], {});
  });

  return {
    consumed: url,
    posParams,
  };
}
