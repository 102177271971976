import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { InnogyComponentRendering } from '@innogy/jss/models';
import type {
  ImageField,
  RenderingField,
} from '@sitecore-jss/sitecore-jss-angular';

export interface SingleFieldExplanationContent {
  title: RenderingField<string> | undefined;
  button: RenderingField<string> | undefined;
  content: RenderingField<string> | undefined;
  image?: RenderingField<ImageField> | undefined;
  imageLabel?: RenderingField<string> | undefined;
}

@Component({
  selector: 'wl-more-info-button[rendering][explanation][giveMoreInfo]',
  templateUrl: './more-info-button.component.html',
  styleUrls: [],
})
export class MoreInfoButtonComponent {
  @Input()
  rendering!: InnogyComponentRendering | undefined;
  @Input()
  explanation?: SingleFieldExplanationContent;
  @Output()
  giveMoreInfo: EventEmitter<SingleFieldExplanationContent> =
    new EventEmitter<SingleFieldExplanationContent>();

  get explanationVisible() {
    return (
      this.explanation?.button?.value &&
      this.explanation?.title?.value &&
      this.explanation?.content?.value
    );
  }
}
