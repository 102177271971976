<div class="mb-3">
  <h4 class="accordion__title mb-2" *ngIf="accordions?.title">
    {{ accordions?.title }}
  </h4>

  <ngb-accordion
    [closeOthers]="false"
    [id]="accordions?.id || 'accordion'"
    class="mb-1"
    [ngClass]="accordions?.class"
  >
    <ngb-panel
      [id]="item.id"
      *ngFor="let item of accordions?.items; trackBy: trackById"
      [cardClass]="enableTransparancy ? 'accordion-item--transparent' : ''"
    >
      <ng-template ngbPanelHeader>
        <button
          class="accordion-button"
          ngbPanelToggle
          wlTrackImpression="accordion-impression"
          [trackImpressionInfo]="getImpressionData(item)"
          wlTrackAccordion
          [trackAccordionInfo]="getClickData(item)"
        >
          <wl-partial-accordion-title
            [title]="item?.question?.value"
          ></wl-partial-accordion-title>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <wl-rich-text-wrapper [rendering]="rendering" [field]="item?.answer">
          <div
            [ngClass]="{ accordion__spacing: !accordions?.hidePrefixIcon }"
            class="accordion__content"
          ></div>
        </wl-rich-text-wrapper>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
