import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import type { ProductDuration, SupplyAddress } from '@essent/new-customer';
import type { GetOfferDuration } from '@innogy/become-a-customer/shared/interfaces';
import {
  OfferSet,
  ConsumptionValuesVM,
  FunnelSettingsAddOn,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import { getSkeletonMaskFromRendering } from '@innogy/common-ui/shared';
import { getFieldValue } from '@innogy/jss/utils';

import { shouldTrackProductChange } from '../utils/offer-tracking';
import { VerticalOfferStickerComponent } from './vertical-offer-sticker/vertical-offer-sticker.component';
import { VerticalOfferComponentRendering } from './vertical-offer.model';

@Component({
  selector: 'wl-vertical-offer[rendering][offer]',
  templateUrl: './vertical-offer.component.html',
  styleUrls: [
    './vertical-offer.component.ed.scss',
    './vertical-offer.component.essent.scss',
  ],
})
export class VerticalOfferComponent implements OnChanges {
  @Input()
  public rendering!: VerticalOfferComponentRendering;
  @Input()
  public durations?: GetOfferDuration[];
  @Input()
  public activeDuration?: ProductDuration | null;
  @Input()
  public offer?: Offer;
  @Input()
  public addOn?: FunnelSettingsAddOn;
  @Input()
  public hasMemberGetMember = false;
  @Input()
  public activeOfferSet?: OfferSet;
  @Input()
  public xpEditorActive?: boolean;
  @Input()
  public consumptionValuesVm?: ConsumptionValuesVM;
  @Input()
  public supplyAddressVm?: Partial<SupplyAddress> | null;
  @Input()
  public moreInfoButtonClick?: (offer: Offer) => unknown;
  @Input()
  public isAddOnChecked?: boolean;
  @Output()
  public changeDuration = new EventEmitter<GetOfferDuration>();
  @Output()
  public toggleAddon = new EventEmitter<boolean>();
  @Output()
  public selectProposition = new EventEmitter<Offer>();
  @Output()
  public openAomModal = new EventEmitter<Offer>();
  @Output()
  public openCalculateModal = new EventEmitter<void>();
  @Output()
  public trackProductChange = new EventEmitter<void>();

  @ViewChild(VerticalOfferStickerComponent)
  sticker!: VerticalOfferStickerComponent;

  ngOnChanges(changes: SimpleChanges): void {
    if (shouldTrackProductChange(changes.offer)) {
      this.trackProductChange.emit();
    }
  }

  durationChanged(event: any) {
    this.changeDuration.emit(event);
  }

  addOnToggled(event: any) {
    const isChecked = event.target.checked;

    this.isAddOnChecked = isChecked;
    this.toggleAddon.emit(isChecked);
  }

  get skeletonLoaderMask() {
    return getSkeletonMaskFromRendering(this.rendering);
  }

  get showHighlightedOffer() {
    return this.offer?.isHighlighted;
  }

  get showDurationTitle(): boolean {
    return !!getFieldValue<string>(this.rendering, 'DurationTitle');
  }

  get showBreakdown() {
    return getFieldValue<boolean>(this.rendering, 'ShowBreakdown', false);
  }

  get showProductAddons() {
    return getFieldValue<boolean>(this.rendering, 'ShowProductAddons', false);
  }

  get showTarrifBreakdownDropdown() {
    return getFieldValue<boolean>(
      this.rendering,
      'ShowTarrifBreakdownDropdown',
      false
    );
  }

  get showTarrifModalButton() {
    return getFieldValue<boolean>(
      this.rendering,
      'ShowTarrifModalButton',
      false
    );
  }

  get showOfferDurations() {
    return (
      this.durations?.length &&
      this.durations?.length > 1 &&
      this.activeDuration
    );
  }

  get showUspList() {
    return this.offer?.usps ? this.offer.usps.length > 0 : false;
  }

  get showOfferSubmitMicro() {
    const fieldValue = getFieldValue<string>(
      this.rendering,
      'OfferSubmitMicro'
    );
    return fieldValue ? fieldValue.length > 0 : false;
  }

  get showMoreInfoBlock() {
    return getFieldValue<boolean>(this.rendering, 'showMoreInfoBlock', false);
  }

  get toggleCompactMode() {
    return getFieldValue<boolean>(this.rendering, 'ToggleCompactMode', false);
  }

  get toggleDisplayFromPrice() {
    return getFieldValue<boolean>(
      this.rendering,
      'ToggleDisplayFromPrice',
      true
    );
  }

  get cardBorderStyle() {
    return this.offer?.isHighlighted ? 'rounded-bottom' : 'rounded';
  }
}
