import { getDroplinkValue } from '@innogy/jss/utils';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';

import { RenderingContractGroupType } from '../lib/interfaces';

export function getContractGroupTypeForRendering(input: GuardInput) {
  return (
    (getDroplinkValue(
      input.rendering?.fields,
      'contractGroupType'
    ) as RenderingContractGroupType) ?? RenderingContractGroupType.COMMODITY
  );
}
