import { getCustomerSegment, getIsLoggedIn } from '@innogy/account';
import { selectIsMijnEnvironment } from '@innogy/config/store';
import { paymentDetailsFormId } from '@innogy/emobility-shared';
import { selectQueryParam, selectQueryParams } from '@innogy/shared/state';
import type { LeadFormProposition } from '@integration/emobility-api-models';
import {
  ProductSelection,
  SalesPartnerLeadData,
} from '@integration/emobility-api-models';
import { createSelector } from '@ngrx/store';
import { SegmentType, Status } from '@essent/common';
import { getSettingsValue } from '@innogy/settings';
import { getCollectionObject } from '@innogy/utils/state';
import { unknownActionId } from '@innogy/core/ngrx';

import { customerDetailsFormId } from './lead-form-customer-details.reducer';
import { installationAddressFormId } from './lead-form.address.reducer';
import { leadConsentsFormId } from './lead-form.consents.reducer';
import {
  getPropositionFromQueryParams,
  getRelevantQueryParams,
} from './lead-form.queryparams';
import type { LeadFormQueryParams } from './lead-form.state';
import { emobilityLeadFormFeature } from './lead-form.state';
import { chargeCardTypeFormId } from './lead-form-charge-card-type.reducer';

/* istanbul ignore next */
export const selectProspectState = createSelector(
  emobilityLeadFormFeature,
  (feature) => getCollectionObject(feature.prospect, unknownActionId)
);

/* istanbul ignore next */
export const selectProspect = createSelector(
  selectProspectState,
  (state) => state?.entry
);

/* istanbul ignore next */
export const selectProspectStatus = createSelector(
  selectProspectState,
  (state) => state?.status
);

/* istanbul ignore next */
export const selectProspectVerificationCode = createSelector(
  selectProspectState,
  (state) => state?.entry?.verificationCode
);

export const selectIsLoadingProspect = createSelector(
  selectProspectStatus,
  (status) => status == null || [Status.IDLE, Status.PENDING].includes(status)
);

export const selectShowProspectExpiredCodeError = createSelector(
  selectProspectState,
  (state) => state?.error?.status === 404
);

export const selectShowProspectGeneralError = createSelector(
  selectProspectState,
  (state) => state?.error?.status === 500
);

export const selectLeadFormCustomerDetails = createSelector(
  emobilityLeadFormFeature,
  (form) => form[customerDetailsFormId]
);
export const selectLeadFormCustomerDetailsFormState = createSelector(
  selectLeadFormCustomerDetails,
  (details) => details?.formState
);

export const selectCustomerDetailsValue = createSelector(
  selectLeadFormCustomerDetailsFormState,
  (formState) => formState.value
);

export const selectLeadFormChargeCardType = createSelector(
  emobilityLeadFormFeature,
  (form) => form[chargeCardTypeFormId]
);

export const selectLeadFormChargeCardTypeFormState = createSelector(
  selectLeadFormChargeCardType,
  (chargeCardType) => chargeCardType.formState
);

export const selectLeadFormChargeCardTypeValue = createSelector(
  selectLeadFormChargeCardTypeFormState,
  (formState) => formState.value
);

export const selectLeadFormAddress = createSelector(
  emobilityLeadFormFeature,
  (form) => form[installationAddressFormId]
);
export const selectLeadFormAddressFormState = createSelector(
  emobilityLeadFormFeature,
  (form) => form[installationAddressFormId].formState
);
export const selectLeadFormAddressFormValue = createSelector(
  selectLeadFormAddressFormState,
  (formState) => formState.value
);
export const selectLeadFormInstallationAddress = createSelector(
  selectLeadFormAddressFormState,
  (form) => form.controls.installationAddressState
);
export const selectLeadFormCorrespondenceAddress = createSelector(
  selectLeadFormAddressFormState,
  (form) => form.controls.correspondenceAddressState
);
export const selectLeadFormPaymentDetails = createSelector(
  emobilityLeadFormFeature,
  (form) => form[paymentDetailsFormId].formState
);

export const selectLeadFormPaymentDetailsValue = createSelector(
  selectLeadFormPaymentDetails,
  (paymentDetails) => paymentDetails.value
);

export const selectLeadFormConsentsRootState = createSelector(
  emobilityLeadFormFeature,
  (form) => form[leadConsentsFormId]
);
export const selectLeadFormConsents = createSelector(
  selectLeadFormConsentsRootState,
  (rootState) => rootState.formState
);

export const selectLeadFormTrackingId = createSelector(
  selectLeadFormConsentsRootState,
  (rootState) => rootState.e2eTrackingId
);

export const selectLeadFormConsentsValue = createSelector(
  selectLeadFormConsents,
  (consents) => consents.value
);

export const getPropositionQueryParams = createSelector(
  selectQueryParams,
  (params): LeadFormQueryParams | undefined => getRelevantQueryParams(params)
);

export const getProposition = createSelector(
  getPropositionQueryParams,
  selectLeadFormChargeCardTypeValue,
  (params, chargeCard): LeadFormProposition | undefined =>
    getPropositionFromQueryParams(params, chargeCard.chargeCardType)
);

export const getPropositionType = createSelector(
  getProposition,
  (proposition) => proposition?.propositionId
);

export const isChargingStation = createSelector(
  getPropositionType,
  (proposition) => proposition === ProductSelection.CHARGING_STATION
);

export const getShowCorrespondenceAddressFormFields = createSelector(
  getIsLoggedIn,
  isChargingStation,
  (isLoggedIn, isChargingStation) => !isLoggedIn && isChargingStation
);

/**
 * This was the simplest way to direct existing customers to the mijn environment,
 * see https://jira.essent.nl/jira/browse/EMOB-1368
 */
export const selectLeadFormEnabled = createSelector(
  selectIsMijnEnvironment,
  selectQueryParam('nieuwe-klant'),
  (isMijnEnvironment, nieuweKlant) => isMijnEnvironment || nieuweKlant === 'ja'
);

/* istanbul ignore next */
export const selectCampaignId = createSelector(
  selectQueryParam('campaign-id'),
  (campaignId) => campaignId
);

/* istanbul ignore next */
export const selectExternalPartnerId = createSelector(
  selectQueryParam('external-partner-id'),
  (externalPartnerId) => externalPartnerId
);

/* istanbul ignore next */
export const selectLeadSegment = createSelector(
  selectQueryParam('lead-segment'),
  (leadSegment) => leadSegment
);

/* istanbul ignore next */
export const selectChannel = createSelector(
  selectQueryParam('channel'),
  (channel) => channel
);

/* istanbul ignore next */
export const selectProductType = createSelector(
  selectQueryParam('product-type'),
  (productType) => productType
);

/* istanbul ignore next */
export const selectServicePartner = createSelector(
  selectQueryParam('service-partner'),
  (servicePartner) => servicePartner
);

/* istanbul ignore next */
export const selectCampaign = createSelector(
  selectQueryParam('campaign'),
  (campaign) => campaign
);

/* istanbul ignore next */
export const selectEmployee = createSelector(
  selectQueryParam('employee'),
  (employee) => employee
);

/* istanbul ignore next */
export const selectStore = createSelector(
  selectQueryParam('store'),
  (store) => store
);

/* istanbul ignore next */
export const selectInvoiceGroup = createSelector(
  selectQueryParam('invoice-group'),
  (invoiceGroup) => invoiceGroup
);

export const selectSalesPartnerLeadData = createSelector(
  selectExternalPartnerId,
  selectLeadSegment,
  selectChannel,
  selectProductType,
  selectServicePartner,
  selectCampaign,
  selectEmployee,
  selectStore,
  selectInvoiceGroup,
  (
    externalPartnerId,
    leadSegment,
    channel,
    productType,
    servicePartner,
    campaign,
    employee,
    store,
    invoiceGroup
  ) => ({
    externalPartnerId,
    data: externalPartnerId
      ? SalesPartnerLeadData.fromPlain({
          leadSegment,
          channel,
          productType,
          servicePartner,
          campaign,
          employee,
          store,
          invoiceGroup,
        })
      : undefined,
  })
);

export const isEmobilitySohoFeatureEnabled = createSelector(
  getSettingsValue<boolean>('featureFlags', 'enableEmobilitySohoCustomers'),
  (enabled) => enabled === true
);

/* istanbul ignore next */
export const isEmobilityProductMailConsentEnabled = createSelector(
  getSettingsValue<boolean>('featureFlags', 'enableProductMailConsent'),
  (enabled) => enabled === true
);

/* istanbul ignore next */
export const isEmobilityChargeCardTypeEnabled = createSelector(
  getSettingsValue<boolean>('featureFlags', 'enableEmobilityChargeCardType'),
  (enabled) => enabled === true
);

export const selectIsAllowedSegment = createSelector(
  getCustomerSegment,
  isEmobilitySohoFeatureEnabled,
  (customerSegment, isSohoFeatureEnabled) => {
    if (customerSegment === undefined) {
      return true;
    }

    const allowedSegments = isSohoFeatureEnabled
      ? [SegmentType.HH, SegmentType.SOHO]
      : [SegmentType.HH];

    return allowedSegments.some((segment) => segment === customerSegment);
  }
);

export const showLeadForm = createSelector(
  selectProspectState,
  selectIsAllowedSegment,
  (prospectState, isAllowedSegment) => {
    const prospectHasError = prospectState?.error;
    return isAllowedSegment && !prospectHasError;
  }
);
