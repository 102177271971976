import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { snakeCase, toHttpParams } from '@essent/common';
import type { Observable } from 'rxjs';
import { API_PUBLIC_PREFIX } from '@innogy/config';

import type {
  GetSolarPaybackRequest,
  GetSolarPaybackResponse,
} from '../../models/solar-payback/request';

@Injectable()
export class GetSolarPaybackService
  implements BaseService<GetSolarPaybackRequest, GetSolarPaybackResponse>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${this.apiUrl || ''}/feh/solarpayback/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: GetSolarPaybackRequest
  ): Observable<GetSolarPaybackResponse> {
    const headers = { Accept: 'application/json' };
    const params = toHttpParams(snakeCase(payload));

    return this.httpClient.get<GetSolarPaybackResponse>(this.endpoint, {
      headers,
      params,
    });
  }
}
