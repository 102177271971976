import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { InnogyComponentRendering } from '@innogy/jss/models';
import {
  getFieldValue,
  getNumericDroplinkValue,
  getTreeListValues,
} from '@innogy/jss/utils';
import type {
  ComponentFields,
  Field,
} from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

@Component({
  selector: 'wl-availability-container',
  templateUrl: './availability-container.component.html',
  styleUrls: [
    './availability-container.component.ed.scss',
    './availability-container.component.essent.scss',
  ],
})
export class AvailabilityContainerComponent implements OnInit {
  public isOpen = false;
  public additionalText = '';
  public currentDate: Date = new Date();

  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  ngOnInit() {
    this.initializeAvailability();
  }

  public get icon(): Item {
    return (
      this.isOpen
        ? this.rendering?.fields?.OpenIcon
        : this.rendering?.fields?.ClosedIcon
    ) as Item;
  }

  private initializeAvailability() {
    this.currentDate = new Date();
    const today = this.getToday();

    if (today !== undefined) {
      this.setOpenState(today);
      this.setAdditionalText(today);
    }
  }

  private getToday(): Day | undefined {
    if (this.specialDays) {
      for (const specialDay of this.specialDays) {
        const date = getFieldValue<string>(specialDay.fields, 'Date');
        if (date && isSameDate(new Date(date), this.currentDate)) {
          return { fields: specialDay.fields, dayType: 'specialDay' };
        }
      }
    }
    if (this.normalDays) {
      for (const normalDay of this.normalDays) {
        const day = getNumericDroplinkValue(
          normalDay.fields as ComponentFields,
          'Day'
        );
        if (day === this.currentDate.getDay()) {
          return { fields: normalDay.fields, dayType: 'specialDay' };
        }
      }
    }
    return undefined;
  }

  private get specialDays(): Item[] {
    return getTreeListValues(this.rendering?.fields, 'SpecialDays');
  }

  private get normalDays(): Item[] {
    return getTreeListValues(this.rendering?.fields, 'NormalDays');
  }

  private setOpenState(today: Day) {
    this.isOpen = isOpen(
      today.fields,
      this.currentDate.getHours() * 60 + this.currentDate.getMinutes()
    );
  }

  private setAdditionalText(today: Day) {
    this.additionalText = getFieldValue<string>(
      this.rendering,
      'AdditionalText',
      ''
    );
    if (this.additionalText.length === 0 && today.dayType === 'specialDay') {
      this.additionalText = getFieldValue<string>(
        today.fields,
        'AdditionalText',
        ''
      );
    }
  }
}

function isSameDate(date: Date, currentDate: Date) {
  return (
    date &&
    currentDate.getDate() === date.getDate() &&
    currentDate.getMonth() === date.getMonth()
  );
}

function isOpen(
  dayFields: { [name: string]: Field | Item | Item[] | undefined },
  currentTime: number
) {
  const open = getFieldValue<string>(dayFields, 'Open');
  const closed = getFieldValue<string>(dayFields, 'Close');
  return (
    open !== undefined &&
    closed !== undefined &&
    currentTime >= parseTimeToMinutes(open) &&
    currentTime <= parseTimeToMinutes(closed)
  );
}

function parseTimeToMinutes(time: string): number {
  if (time.match(/\d{1,2}:\d{2}/)) {
    const hoursAndMinutes = time.split(':');
    return Number(hoursAndMinutes[0]) * 60 + Number(hoursAndMinutes[1]);
  }
  return NaN;
}

interface Day {
  fields: any;
  dayType: 'specialDay' | 'normalDay';
}
