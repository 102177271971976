import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AppConfigService, ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import type { JssDictionary } from '@innogy/jss/models';
import { JSS_DICTIONARY, JssDictionaryResult } from '@innogy/jss/models';
import type { TranslateLoader } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { EMPTY, of } from 'rxjs';
import { map } from 'rxjs/operators';

// eslint-disable-next-line max-classes-per-file
@Injectable()
export class JssTranslationLoaderService implements TranslateLoader {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig,
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService,
    @Optional()
    @Inject(JSS_DICTIONARY)
    protected dictionary: JssDictionaryResult
  ) {}

  getTranslation(lang: string): Observable<JssDictionary> {
    if (this.dictionary) {
      return of(this.dictionary.phrases);
    }

    if (this.config.sitecore == null) {
      return EMPTY;
    }

    const options = {
      params: {
        sc_apikey: this.config.sitecore.apiKey,
        sc_site: this.config.app,
      },
    };

    return this.http
      .get<JssDictionaryResult>(
        `${this.config.sitecore.apiHost}${this.appConfigService.basePath}/sitecore/api/jss/dictionary/${this.config.app}/${lang}`,
        options
      )
      .pipe(map((dictionary) => dictionary.phrases));
  }
}
