import { Injectable } from '@angular/core';
import {
  createAddressEffects,
  getAddressFormControlIds,
} from '@innogy/common-ui/forms';
import { generateFormIdFromUUID } from '@innogy/shared/forms';
import type {
  fixedApiFieldKeyValues,
  ScForm,
  ScFormFormGroupState,
} from '@innogy/sitecore-forms/models';
import {
  createGenericFormReducerFromScForm,
  selectFormStateForForm,
  sitecoreFormsKey,
  summaryValueForField,
} from '@innogy/sitecore-forms/store';
import { Actions, EffectSources } from '@ngrx/effects';
import { Store, createSelector } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';

@Injectable({
  providedIn: 'root',
})
export class GenericFormService {
  registerForm(form: ScForm) {
    const formId = generateFormIdFromUUID(form.id);
    const stateKey = `${sitecoreFormsKey}-${formId}`;

    this.store$.addReducer(
      stateKey,
      createGenericFormReducerFromScForm(form, stateKey, {
        context: 'standalone',
      })
    );

    this.registerAddressFormEffects(stateKey);
  }

  private registerAddressFormEffects(stateKey: string) {
    this.effectSources$.addEffects(
      createAddressEffects(
        this.actions$,
        this.store$,
        getAddressFormControlIds(stateKey),
        createSelector(
          this.selectFormStateForForm(stateKey).selector,
          (state) => state.form.formState
        ) as any
      )
    );
  }

  selectFormStateForForm(formUUID: string) {
    return selectFormStateForForm(formUUID, this.store$);
  }

  // Multifield inputs are all input-based summaries,
  // no custom values are available for these fields
  // so the control values are echoed in the summaries.
  private generateMultifieldMapping(
    formState: FormGroupState<ScFormFormGroupState>
  ): { [key in fixedApiFieldKeyValues]: string | number } {
    return {
      postcode: formState.controls.postalCode?.value ?? '',
      huisnummer: formState.controls.communicationNumber?.value ?? '',
      huisnummerExt: formState.controls.houseNumberAddition?.value ?? '',
      straat: formState.controls.street?.value ?? '',
      plaats: formState.controls.city?.value ?? '',
      voorletter: formState.controls.initials?.value ?? '',
      tussenvoegsel: formState.controls.insertion?.value ?? '',
      achternaam: formState.controls.lastName?.value ?? '',
    };
  }

  matchFieldNameAndSummaryValue(
    form: ScForm,
    formState: FormGroupState<ScFormFormGroupState>
  ) {
    const formFields = form.fields.Inputs;

    const customFieldsMapping = formFields.reduce((acc, field) => {
      acc[field.displayName as string] = summaryValueForField(
        field,
        formState.controls
      );

      return acc;
    }, {} as Record<string, string | number>);

    return {
      ...this.generateMultifieldMapping(formState),
      ...customFieldsMapping,
    };
  }

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly effectSources$: EffectSources
  ) {}
}
