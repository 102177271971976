<label class="toggle" [wlAutomationId]="toggleId">
  <ng-container *ngIf="formControl; then hasControl; else hasNoControl">
  </ng-container>

  <span class="toggle__handle"></span>
  <span class="toggle__label" *wlGenericRichText="labelText"></span>
</label>

<ng-template #hasControl>
  <input
    [wlTrackValueChange]="{
      rendering: rendering,
      label: fieldname,
      stepname: stepname,
      formName: formName,
      sendValue: true,
      inputLabel: formControl.value ? 'true' : 'false'
    }"
    (change)="onChange($event)"
    [id]="toggleId"
    type="checkbox"
    [wlAutomationId]="toggleId + '-toggle'"
    [ngrxFormControlState]="formControl"
    [attr.checked]="formControl.value ? 'checked' : null"
  />
</ng-template>

<ng-template #hasNoControl>
  <input
    [wlTrackValueChange]="{
      rendering: rendering,
      label: fieldname,
      stepname: stepname,
      formName: formName,
      sendValue: true,
      inputLabel: checked.toString()
    }"
    [id]="toggleId"
    type="checkbox"
    (change)="onChange($event)"
    [wlAutomationId]="toggleId + '-toggle'"
    [attr.checked]="checked ? 'checked' : null"
  />
</ng-template>
