import { Component, Input } from '@angular/core';
import type { TariffPeriod } from '@innogy/become-a-customer/shared/interfaces';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-aom-costs-per-unit-without-summer-winter',
  templateUrl: './aom-costs-per-unit-without-summer-winter.component.html',
  styleUrls: [
    './aom-costs-per-unit-without-summer-winter.component.essent.scss',
    './aom-costs-per-unit-without-summer-winter.component.ed.scss',
  ],
})
export class AomCostsPerUnitWithoutSummerWinterComponent {
  @Input() public tariffPeriods?: TariffPeriod[];
  @Input() public energyTitle?: TextField;
  @Input() public deliveryTitle?: TextField;
  @Input() public redeliveryTitle?: TextField;
  @Input() public redeliveryDescription?: TextField;
  @Input() public unit?: string;

  @Input() public fixedDeliveryCosts?: string;
  @Input() public fixedDeliveryCostsLabel?: TextField;

  @Input() public fixedRedeliveryCosts?: string;
  @Input() public fixedRedeliveryCostsLabel?: TextField;
  @Input() public fixedRedeliveryCostsScale?: string;

  get showRedeliveryCosts() {
    return !!this.fixedRedeliveryCostsScale && !!this.fixedRedeliveryCostsLabel;
  }
}
