import { Inject, Injectable } from '@angular/core';
import { DEFAULT_SCROLL_OFFSET_VERTICAL } from '@innogy/shared/core/constants';
import { PlatformService } from '@innogy/core/platform';
import { WINDOW } from '@innogy/utils/dom';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(
    private readonly platformService: PlatformService,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  /**
   * The boolean indicates if the scrollTo actually happened, and the
   * promise will resolve once no more animation is in progress.
   */
  scrollToAnchorAnimated(
    elementId: string,
    behavior: ScrollBehavior = 'smooth'
  ) {
    if (this.platformService.isServer()) {
      return Promise.resolve(true);
    }

    const element = this.document.getElementById(elementId);
    return this.scrollToElementAnimated(element, behavior);
  }

  /**
   * The boolean indicates if the scrollTo actually happened, and the
   * promise will resolve once no more animation is in progress.
   */
  scrollToElementAnimated(
    element?: Element | null,
    behavior: ScrollBehavior = 'smooth'
  ): Promise<boolean> {
    if (element) {
      const yOffset = -Math.abs(DEFAULT_SCROLL_OFFSET_VERTICAL);
      const top =
        element.getBoundingClientRect().top + this.window.scrollY + yOffset;

      this.window.scrollTo({ top, behavior });

      if (behavior === 'smooth') {
        return new Promise((resolve) => setTimeout(() => resolve(true), 100));
      }

      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  }

  getWrappingDynamicRow(element: HTMLElement | null) {
    return element?.closest('wl-dynamic-row');
  }
}
