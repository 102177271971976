import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import type { NgProgressConfig } from 'ngx-progressbar';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';

@NgModule({
  exports: [NgProgressModule, NgProgressHttpModule, NgProgressRouterModule],
})
export class CoreLoaderModule {
  static withConfig(
    config: NgProgressConfig
  ): ModuleWithProviders<CoreLoaderModule> {
    return {
      ngModule: CoreLoaderModule,
      providers: [
        NgProgressModule.withConfig(config).providers || [],
        NgProgressHttpModule,
        NgProgressRouterModule,
      ],
    };
  }
}
