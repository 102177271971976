import { getPaymentDetailsSuccess } from '@essent/financial';
import { validateSequential } from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  ResetAction,
  setValue,
  updateGroup,
  wrapReducerWithFormStateUpdate,
  reset,
  setErrors,
} from 'ngrx-forms';
import { notEqualTo, required } from 'ngrx-forms/validation';

export const CHANGE_PAYMENT_DATE_FORM_ID = 'changePaymentDateForm';
export const paymentDateControlId = `${CHANGE_PAYMENT_DATE_FORM_ID}.paymentDate`;

export interface FormState {
  paymentDate: string;
}

export interface State {
  changePaymentDateFormState: FormGroupState<FormState>;
  initialPaymentDate: string;
}

export const initialFormState: FormState = {
  paymentDate: '',
};

export const initialFormGroupState = createFormGroupState<FormState>(
  CHANGE_PAYMENT_DATE_FORM_ID,
  initialFormState
);

const undefinedPaymentControlValue = '';

export const initialState: State = {
  changePaymentDateFormState: initialFormGroupState,
  initialPaymentDate: undefinedPaymentControlValue,
};

export const validateAndUpdateForms = (state: State) => {
  return updateGroup<FormState>({
    paymentDate: validateSequential(
      required,
      notEqualTo(state.initialPaymentDate)
    ),
  })(state.changePaymentDateFormState);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(ResetAction, (state, action) => {
    if (action.controlId === CHANGE_PAYMENT_DATE_FORM_ID) {
      const initialPaymentDate = state.initialPaymentDate;

      const updatedState = {
        ...initialState,
        changePaymentDateFormState: setErrors(
          reset(state.changePaymentDateFormState),
          {}
        ),
      };
      return {
        ...updatedState,
        changePaymentDateFormState: updateGroup<FormState>({
          paymentDate: setValue(initialPaymentDate),
        })(updatedState.changePaymentDateFormState),
        initialPaymentDate,
      };
    }
    return state;
  }),
  on(getPaymentDetailsSuccess, (state, action) => {
    const planBillDayOfMonth =
      'dayOfPayment' in action.payload.paymentDetails.paymentChoice
        ? action.payload.paymentDetails.paymentChoice.dayOfPayment
        : undefinedPaymentControlValue;
    return {
      ...state,
      initialPaymentDate: `${planBillDayOfMonth}`,
      changePaymentDateFormState: updateGroup<FormState>({
        paymentDate: setValue(`${planBillDayOfMonth}`),
      })(state.changePaymentDateFormState),
    };
  })
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.changePaymentDateFormState,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(state: State = initialState, action: Action): State {
  return wrappedReducer(state, action);
}
