import { generateApiReducer } from '@innogy/core/ngrx';
import {
  getChargeCardsActions,
  postBlockChargeCardActions,
} from '@innogy/emobility/chargecard/services';
import type { CollectionState } from '@innogy/utils/state';
import type {
  ChargeCardsResponse,
  SuccessResponse,
} from '@integration/emobility-api-models';
import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

export const chargeCardSelectorKey = 'emobility-charge-card';

export const chargeCardsKey = 'charge-cards';
export const blockChargeCardKey = 'block-charge-card';

export interface EmobilityChargeCardState {
  [chargeCardsKey]: CollectionState<ChargeCardsResponse>;
  [blockChargeCardKey]: CollectionState<SuccessResponse>;
}

export const emobilityCaseReducers: ActionReducerMap<EmobilityChargeCardState> =
  {
    [chargeCardsKey]: generateApiReducer(getChargeCardsActions),
    [blockChargeCardKey]: generateApiReducer(postBlockChargeCardActions),
  };

export const emobilityCaseFeature =
  createFeatureSelector<EmobilityChargeCardState>(chargeCardSelectorKey);
