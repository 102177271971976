import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type {
  Offer,
  OfferContentIncentive,
} from '@innogy/become-a-customer/shared/interfaces';
import { FunnelSettingsAddOn } from '@innogy/become-a-customer/shared/interfaces';
import { trackProductChangeAction } from '@innogy/become-a-customer/state';
import type { InnogyComponentRendering } from '@innogy/jss/models';
import { Store } from '@ngrx/store';

import { shouldTrackProductChange } from '../../utils/offer-tracking';

// THIS COMPONENT IS SHARED BETWEEN ACQUISITION AND RETENTION
@Component({
  selector: 'wl-horizontal-offer-multiple-cards-container[offers]',
  templateUrl: './horizontal-offer-multiple-cards-container.component.html',
})
export class HorizontalOfferMultipleCardsContainerComponent
  implements OnChanges, OnInit
{
  @Input() isRetention = false; // FIXME: workaround for productTitle - duration retention specific selector magic
  @Input()
  public offers!: Offer[];
  @Input()
  public rendering?: InnogyComponentRendering | any;
  @Input()
  public addOn?: FunnelSettingsAddOn;
  @Input()
  public hasMemberGetMember = false;
  @Input()
  public moreInfoButtonClick?: (offer: Offer) => () => unknown;

  @Output()
  public showTarifs = new EventEmitter<Offer>();
  @Output()
  public addOnToggle = new EventEmitter<boolean>();
  @Output()
  public submitProposition = new EventEmitter<Offer>();
  @Output()
  public showMoreIncentiveInfo = new EventEmitter<OfferContentIncentive>();

  isAddOnChecked = false;

  constructor(private readonly store$: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (shouldTrackProductChange(changes.offers)) {
      this.store$.dispatch(trackProductChangeAction());
    }
  }

  ngOnInit() {
    /**
     * A trackProductChange action is dispatched here, since initial offer-load is not detected in the ngOnChanges cycle.
     */
    this.store$.dispatch(trackProductChangeAction());
  }

  onAddOnToggle(value: boolean) {
    this.addOnToggle.emit(value);
    this.isAddOnChecked = value;
  }

  /**
   * Provide the needed css classes to ensure the first column is displayed in
   * the center. This is achieved by pushing the second column to the first spot.
   *
   * @param columnIndex the zero-based index of the column
   */
  centerFirstColumn(columnIndex: number) {
    return columnIndex === 1 ? 'order-0' : 'order-1';
  }

  getOfferColumnSize(offerAmount: number) {
    return offerAmount > 2 ? 'col-4' : 'col-6';
  }

  trackByOffers(_index: number, item: Offer) {
    return item.offerId;
  }
}
