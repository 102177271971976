import { CurrencyPipe } from '@angular/common';
import { Status } from '@essent/common';
import type { Reimbursement } from '@essent/payment';
import type { DropdownItem } from '@innogy/common-ui/forms';
import { hasActiveSupplyAddress } from '@innogy/supply-address/store';
import type { UploadableFile, UploadedFile } from '@innogy/core/file-upload';
import type { Entries } from '@innogy/utils/state';
import { createSelector } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import { box, unbox } from 'ngrx-forms';

import { getCustomerDetailsState } from './feature.selectors';
import type { ReimbursementFormValues } from './reimbursements.model';
import { allowedFileTypes } from './reimbursements.validators';

const currencyPipe = new CurrencyPipe('nl');

export const getReimbursements = createSelector(
  getCustomerDetailsState,
  (state) => state.reimbursements
);

export const getReimbursementData = createSelector(
  getReimbursements,
  (state) => state.reimbursements.data
);

export const selectForm = createSelector(
  getReimbursements,
  (state) => state.form
);

export const selectReimbursementsCreated = createSelector(
  getReimbursements,
  (state) => state.create.status === Status.SUCCESS
);

export const selectSubmitError = createSelector(
  getReimbursements,
  (state) => state.create.status === Status.ERROR
);

export const getSuccessPage = createSelector(
  getReimbursements,
  (state) => state.successPage
);

export const getFileEntries = createSelector(
  getReimbursements,
  (state) => state.files.entities
);

export const getSelectedFileReferences = createSelector(
  selectForm,
  (form) => form.value.fileReferences
);

export const selectGasAmount = createSelector(
  selectForm,
  (form) => unbox(form.value.gas)?.amount ?? 0
);
export const selectElectricityAmount = createSelector(
  selectForm,
  (form) => unbox(form.value.electricity)?.amount ?? 0
);

export const selectGasAndElectricityAmount = createSelector(
  selectForm,
  (form) => ({
    durationElectricity: unbox(form.value.electricity)?.duration,
    durationGas: unbox(form.value.gas)?.duration,
  })
);

export const selectTotalSelectedAmount = createSelector(
  selectGasAmount,
  selectElectricityAmount,
  (e, g) => e + g
);

export const selectFormOptions = createSelector(
  getReimbursementData,
  (state) => [
    ...(state || []).map((o) => ({
      label: currencyPipe.transform(o.amount, 'EUR', 'symbol', '1.0-0') ?? '',
      value: box({ duration: o.duration, amount: o.amount }),
    })),
  ]
);

export const selectError = createSelector(
  getReimbursements,
  (state) => state.reimbursements.status === Status.ERROR
);

export const selectLoadingState = createSelector(
  getReimbursements,
  (state) =>
    state.reimbursements.status === Status.IDLE ||
    state.reimbursements.status === Status.PENDING
);

export interface ReimbursementsViewModel {
  reimbursements?: Reimbursement[];
  form: FormGroupState<ReimbursementFormValues>;
  files: Entries<UploadableFile | UploadedFile>;
  options: DropdownItem[];
  error: boolean;
  submitError: boolean;
  allowedFileTypes: string[];
  hasActiveSupplyAddress: boolean;
}

export const getReimbursementsAndStates = createSelector(
  getReimbursementData,
  selectForm,
  getFileEntries,
  selectFormOptions,
  selectError,
  selectSubmitError,
  hasActiveSupplyAddress,
  (
    reimbursements,
    form,
    files,
    options,
    error,
    submitError,
    hasActiveAddress
  ): ReimbursementsViewModel => ({
    reimbursements,
    form,
    files,
    options,
    error,
    submitError,
    allowedFileTypes,
    hasActiveSupplyAddress: hasActiveAddress,
  })
);
