import { Component } from '@angular/core';
import { getSitecoreContextWithStatus } from '@innogy/jss';
import { DomService, ScriptInjectOn } from '@innogy/utils/deprecated';
import { isNotNullish } from '@innogy/utils/rxjs';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs';

/**
  VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
  If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
  For XM (CMS-only) apps, this should be removed.
 */
@Component({
  selector: 'wl-visitor-identification',
  templateUrl: './sitecore-identification.component.html',
})
export class VisitorIdentificationComponent {
  visitorIdentificationTimestamp?: number;

  readonly visitorIdentificationTimestamp$ = this.store$.pipe(
    getSitecoreContextWithStatus,
    filter(({ context }) =>
      isNotNullish(context.visitorIdentificationTimestamp)
    ),
    tap(() =>
      this.domService.injectScript({
        src: '/layouts/system/VisitorIdentification.js',
        injectOnPlatform: ScriptInjectOn.CLIENT_ONLY,
        async: true,
      })
    ),
    map(({ context }) => context.visitorIdentificationTimestamp)
  );

  constructor(
    private readonly store$: Store,
    private readonly domService: DomService
  ) {}
}
