import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { InnogyComponentRendering } from '@innogy/jss/models';

@Component({
  selector: 'wl-progressive-form-step-card',
  templateUrl: './progressive-form-step-card.component.html',
  styleUrls: [
    './progressive-form-step-card.component.ed.scss',
    './progressive-form-step-card.component.essent.scss',
  ],
})
export class ProgressiveFormStepCardComponent {
  @Input() rendering?: InnogyComponentRendering | any;
  @Input() title = '';
  @Input() subtitle = '';
  @Input() changeButtonText = '';
  @Input() formStepId?: string;
  @Input() canBeEdited?: boolean;
  @Input() isActive?: boolean;
  @Input() isValid?: boolean;
  @Input() isSubmitted?: boolean;
  @Input() hideSummary?: boolean;
  @Input() isReadonly?: boolean;
  @Input() showWhenFutureStep?: boolean;
  @Input() isFutureStep?: boolean;
  @Input() showSeperator?: boolean;
  @Input() showProgression?: boolean;
  @Input() currentStep?: number;
  @Input() totalSteps?: number;
  @Input() isSkipped?: boolean;
  @Input() showWhenSkipped = false;
  @Output() setEditingFormStep = new EventEmitter<void>();

  get isBodyShown(): boolean | undefined {
    return (
      this.isActive || (this.isValid && this.isSubmitted && !this.hideSummary)
    );
  }

  get isSeperatorShown(): boolean | undefined {
    return this.showSeperator && (this.isBodyShown || this.isReadonly);
  }

  private get hideSkipped(): boolean {
    return !!this.isSkipped && !this.showWhenSkipped;
  }

  private get hideFutureStep(): boolean {
    return !this.showWhenFutureStep && !!this.isFutureStep;
  }

  get showStep(): boolean {
    return !this.hideSkipped && !this.hideFutureStep;
  }

  get changeButtonAutomationId(): string {
    return this.formStepId + '-change-button';
  }

  get progression() {
    return this.currentStep && this.showProgression
      ? `(${this.currentStep}/${this.totalSteps})`
      : '';
  }
}
