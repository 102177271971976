<div class="d-flex flex-column">
  <div class="d-flex">
    <div class="me-2 w-100">
      <ng-container [ngTemplateOutlet]="postalCode"></ng-container>
    </div>
    <div class="me-1">
      <ng-container [ngTemplateOutlet]="houseNumber"></ng-container>
    </div>
    <div>
      <ng-container [ngTemplateOutlet]="addition"></ng-container>
    </div>
  </div>
  <div class="d-flex flex-column">
    <ng-container [ngTemplateOutlet]="errorMessages"></ng-container>
  </div>
</div>

<ng-template #postalCode>
  <wl-text
    wlAutocomplete="postal-code"
    [rendering]="rendering"
    [step]="trackStep.step"
    [stepname]="trackStep.stepName"
    fieldname="postcode"
    [sendValue]="false"
    [valueConverter]="_uppercaseConverter"
    [formsControl]="formState.controls.postalCode"
    [placeholder]="content.PostalCodePlaceholder"
    [label]="floatingLabels ? content.PostalCodeLabel : ''"
    [floatingLabel]="floatingLabels"
  ></wl-text>
</ng-template>
<ng-template #houseNumber>
  <wl-number
    wlAutocomplete="housenumber"
    [rendering]="rendering"
    [step]="trackStep.step"
    [stepname]="trackStep.stepName"
    fieldname="huisnummer"
    [sendValue]="false"
    [formsControl]="formState.controls.communicationNumber"
    [placeholder]="content.HouseNumberPlaceholder"
    [label]="floatingLabels ? content.HouseNumberLabel : ''"
    [floatingLabel]="floatingLabels"
  ></wl-number>
</ng-template>
<ng-template #addition>
  <wl-text
    [rendering]="rendering"
    [stepname]="trackStep.stepName"
    [step]="trackStep.step"
    fieldname="toevoeging"
    [sendValue]="false"
    [formsControl]="formState.controls.houseNumberAddition"
    [label]="floatingLabels ? content.HouseNumberAdditionLabel : ''"
    [placeholder]="content.HouseNumberAdditionPlaceholder"
    [floatingLabel]="floatingLabels"
    wlAutocompleteDisabled
  ></wl-text>
</ng-template>
<ng-template #errorMessages>
  <ng-container *ngIf="formState.controls.postalCode | isInvalid">
    <wl-input-error
      [automationId]="automationId + '-postalcode-required-error'"
      *ngIf="formState.controls.postalCode?.errors?.required"
      [label]="content.PostalCodeRequiredError"
      messageDescription="postcode"
      [rendering]="rendering"
      [stepname]="trackStep.stepName"
    ></wl-input-error>
    <wl-input-error
      [automationId]="automationId + '-postalcode-invalid-error'"
      *ngIf="formState.controls.postalCode?.errors?.isPostalCode"
      [label]="content.PostalCodeNotValidError"
      messageDescription="postcode"
      [rendering]="rendering"
      [stepname]="trackStep.stepName"
    ></wl-input-error>
    <wl-input-error
      [automationId]="automationId + '-postalcode-is-excluded'"
      *ngIf="formState.controls.postalCode?.errors?.postalCodeIsExcluded"
      [label]="content.PostalCodeExcludedError"
      messageDescription="postcode"
      [rendering]="rendering"
      [stepname]="trackStep.stepName"
    ></wl-input-error>
  </ng-container>
  <ng-container *ngIf="formState.controls.communicationNumber | isInvalid">
    <wl-input-error
      [automationId]="automationId + '-housenumber-required-error'"
      *ngIf="formState.controls.communicationNumber"
      [label]="content.HouseNumberRequiredError"
      messageDescription="huisnummer"
      [rendering]="rendering"
      [stepname]="trackStep.stepName"
    ></wl-input-error>
    <wl-input-error
      [automationId]="automationId + '-housenumber-invalid-error'"
      *ngIf="formState.controls.communicationNumber?.errors?.isNumeric"
      [label]="content.HouseNumberNotValidError"
      messageDescription="huisnummer"
      [rendering]="rendering"
      [stepname]="trackStep.stepName"
    ></wl-input-error>
  </ng-container>
  <ng-container *ngIf="formState.controls.houseNumberAddition | isInvalid">
    <wl-input-error
      *ngIf="formState.controls.houseNumberAddition?.errors?.maxLength"
      [automationId]="automationId + '-housenumber-addition-maxlength-error'"
      [rendering]="rendering"
      [label]="content.HouseNumberAdditionMaxLengthError"
    ></wl-input-error>
  </ng-container>
</ng-template>
