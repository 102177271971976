import type { OnChanges } from '@angular/core';
import { Component, ElementRef, Input } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { TrackLinkService } from '@innogy/core/analytics';
import {
  ComponentRendering,
  getFieldValue,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'wl-rich-text-wrapper[field]',
  templateUrl: './rich-text-wrapper.component.html',
  styleUrls: [
    './rich-text-wrapper.component.ed.scss',
    './rich-text-wrapper.component.essent.scss',
    './rich-text-wrapper.component.ew.scss',
  ],
})
export class RichTextWrapperComponent implements OnChanges {
  @Input()
  rendering?: ComponentRendering;

  @Input()
  componentName?: string;

  @Input()
  field?: TextField;

  @Input()
  navigationExtras: NavigationExtras = {
    queryParamsHandling: 'merge',
  };

  constructor(
    private readonly elementRef: ElementRef,
    private readonly trackLinkService: TrackLinkService
  ) {}

  ngOnChanges(): void {
    setTimeout(() => {
      const nativeElement: HTMLElement = this.elementRef.nativeElement;
      this.updateLinks(nativeElement);
    });
  }

  public updateLinks(nativeElement: HTMLElement) {
    const links: NodeListOf<HTMLLinkElement> =
      nativeElement.querySelectorAll('a[href]');
    links.forEach((link) => {
      link.onclick = (event) => {
        this.trackAnalytics(link, event, this);
      };
    });
  }

  public trackAnalytics(
    link: HTMLLinkElement,
    event: Event,
    component: RichTextWrapperComponent
  ) {
    const image = event.target as HTMLImageElement;
    const componentName =
      component.componentName != null
        ? component.componentName
        : this.getComponentName(component.rendering);

    if (link != null) {
      const isButton = link.classList?.contains('btn');
      const eventType =
        image.alt != null ? 'image' : isButton ? 'button' : 'text';

      lastValueFrom(
        component.trackLinkService.trackLink(
          componentName,
          isButton ? 'button-click' : 'link-click',
          image.alt ?? link.innerText,
          eventType,
          link?.href
        )
      );
    }
  }

  getComponentName(rendering: ComponentRendering | undefined) {
    const renderingName =
      rendering != null
        ? getFieldValue<string>(rendering, 'componentName')
        : undefined;
    return renderingName != null ? renderingName : 'unknown-component';
  }
}
