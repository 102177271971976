import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CookiewallContainerComponent } from './cookiewall-container/cookiewall-container.component';
import { CookiewallVariantAComponent } from './cookiewall-variant-a/cookiewall-variant-a.component';
import { CookiewallVariantBComponent } from './cookiewall-variant-b/cookiewall-variant-b.component';

@NgModule({
  imports: [
    CommonModule,
    CookiewallVariantAComponent,
    CookiewallVariantBComponent,
    CookiewallContainerComponent,
  ],
  declarations: [],
  exports: [
    CookiewallVariantAComponent,
    CookiewallVariantBComponent,
    CookiewallContainerComponent,
  ],
})
export class CoreConsentModule {}
