import { Action, ActionReducerMap } from '@ngrx/store';

import * as fromChatting from './chatting.reducer';

export * from './chatting.effects';
export * from './chatting.actions';
export * from './chatting.selectors';
export * from './chatting.interface';
export interface ChattingState {
  readonly chatToken: fromChatting.State;
}

export const reducers: ActionReducerMap<ChattingState, Action> = {
  chatToken: fromChatting.reducer,
};
