import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideBootstrapEffects } from '@innogy/utils/deprecated';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';

import { EcmpHeaderEffects } from './+state/ecmp-header';
import {
  navigationReducer,
  navigationSelectorKey,
} from './+state/navigation.selectors';
import { NavigationEffects } from './+state/navigation.effects';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(navigationSelectorKey, navigationReducer),
  ],
  providers: [provideBootstrapEffects([EcmpHeaderEffects, NavigationEffects])],
})
export class NavigationStoreModule {}
