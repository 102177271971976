import { createFeatureSelector, type ActionReducerMap } from '@ngrx/store';

import type { HeatpumpsFunnelState } from './heatpumps-funnel/heatpumps-funnel.reducer';
import {
  heatpumpsFunnelReducer,
  heatpumpsFunnelKey,
} from './heatpumps-funnel/heatpumps-funnel.reducer';
import type { HeatpumpsOrderFormState } from './heatpumps-order-form/heatpumps-order-form.reducer';
import {
  heatpumpsOrderFormKey,
  heatpumpsOrderFormReducer,
} from './heatpumps-order-form/heatpumps-order-form.reducer';
import type { HeatpumpsWizardState } from './heatpumps-wizard/heatpumps-wizard.reducer';
import {
  heatpumpsWizardKey,
  heatpumpsWizardReducer,
} from './heatpumps-wizard/heatpumps-wizard.reducer';

export const heatpumpsSelectorKey = 'eplus-heatpumps';

export interface HeatpumpsReducerState {
  readonly [heatpumpsFunnelKey]: HeatpumpsFunnelState;
  readonly [heatpumpsOrderFormKey]: HeatpumpsOrderFormState;
  readonly [heatpumpsWizardKey]: HeatpumpsWizardState;
}

export const eplusHeatpumpsFeature =
  createFeatureSelector<HeatpumpsReducerState>(heatpumpsSelectorKey);

export const heatpumpsReducers: ActionReducerMap<HeatpumpsReducerState> = {
  [heatpumpsFunnelKey]: heatpumpsFunnelReducer,
  [heatpumpsOrderFormKey]: heatpumpsOrderFormReducer,
  [heatpumpsWizardKey]: heatpumpsWizardReducer,
};
