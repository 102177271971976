<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [asyncSubmission]="true"
  [trackingConfig]="{
    toolName: rendering.componentName,
    step: stepNumber,
    stepName: 'betaalgegevens'
  }"
>
  <ng-template #content>
    <p *scText="fields?.IbanText"></p>
    <wl-text
      wlAutocompleteDisabled
      [rendering]="rendering"
      stepname="betaalgegevens"
      [step]="stepNumber"
      fieldname="iban"
      [sendValue]="false"
      [valueConverter]="_ibanConverter"
      [formsControl]="formState?.controls?.iban"
      [placeholder]="fields?.IbanPlaceholder?.value"
      [label]="fields?.IbanLabel?.value"
      [floatingLabel]="!labelsShownAboveFields"
    />
    <ng-container *ngIf="formState?.controls?.iban | isInvalid">
      <wl-input-error
        wlAutomationId="transition-date-required-error"
        [messageField]="fields?.IbanRequiredError"
        messageDescription="iban"
        [rendering]="rendering"
        stepname="betaalgegevens"
        [step]="stepNumber"
      ></wl-input-error>
    </ng-container>
    <wl-info-card
      *ngIf="formState?.controls?.iban?.errors?.$ibanBlacklisted"
      [message]="rendering?.fields.IbanBlacklistedError"
      type="danger"
    ></wl-info-card>
  </ng-template>
  <ng-template #summary>
    <p class="h6 mb-0" *scText="fields?.IbanLabel"></p>
    <p wlCSMask>{{ formState?.value?.iban }}</p>
  </ng-template>
</wl-simple-progressive-form-card>
