import type { Params } from '@angular/router';
import {
  ChargeCardType,
  LeadFormProposition,
  ProductSelection,
} from '@integration/emobility-api-models';

import type { LeadFormQueryParams } from './lead-form.state';

export const getRelevantQueryParams = (
  params?: Params
): LeadFormQueryParams | undefined => {
  return params != null
    ? {
        propositie: [
          `${ProductSelection.CHARGING_STATION}`,
          `${ProductSelection.CHARGE_CARD}`,
        ].includes(params.propositie)
          ? params.propositie
          : undefined,
        laadpaal:
          typeof params.laadpaal === 'string' ? params.laadpaal : undefined,
        laadpas: ['ja', 'nee', 'onbekend'].includes(params.laadpas)
          ? params.laadpas
          : undefined,
        zakelijk: ['ja', 'nee', 'onbekend'].includes(params.zakelijk)
          ? params.zakelijk
          : undefined,
      }
    : undefined;
};

export const getPropositionFromQueryParams = (
  params?: LeadFormQueryParams,
  chargeCardType?: ChargeCardType
) => {
  if (!params || !params.propositie) {
    return undefined;
  }
  let chargingStationModel;
  switch (params.laadpaal) {
    case 'nee':
      chargingStationModel = undefined;
      break;
    default:
      chargingStationModel = params.laadpaal;
      break;
  }
  let chargeCard;
  switch (params.laadpas) {
    case 'ja':
      chargeCard = true;
      break;
    case 'nee':
      chargeCard = false;
      break;
    default:
      chargeCard = undefined;
      break;
  }

  return LeadFormProposition.fromPlain<LeadFormProposition>({
    propositionId: params.propositie,
    chargeCard,
    chargeCardType,
    chargingStationModel,
  });
};
