import type { JssRadioItem } from '@innogy/jss/models';

import type { RadioItem } from './radio.model';

export function unpackJSSRadioItem<TValue extends string>(
  item: JssRadioItem<TValue>,
  parentControlId?: string
): RadioItem<TValue> {
  const { fields } = item;
  let value = fields.value.value;
  const valueIdentifier = value.replace(/ /g, '');

  if (value === 'true' || value === 'false') {
    value = JSON.parse(value);
  }

  return {
    id: parentControlId ? `${parentControlId}-${valueIdentifier}` : item.id,
    label: fields.label.value,
    value,
    sublabel: fields.sublabel,
    tooltip: fields.tooltip?.value
      ? {
          description: fields.tooltip.value,
        }
      : undefined,
    trackingLabel: fields.trackingLabel?.value,
    disabled: fields.disabled?.value ?? false,
  };
}
