import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BecomeACustomerUsageQuestionnaireComponentModule } from '@innogy/become-a-customer/usage-questionnaire';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { provideBootstrapEffects, UtilsModule } from '@innogy/utils/deprecated';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { NgrxFormsModule } from 'ngrx-forms';
import { BecomeACustomerUiModule } from '@innogy/become-a-customer/ui';
import { ChannelRecognitionModule } from '@innogy/shared/channel-recognition';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';

import { CalculateContainerComponent } from './calculate-container/calculate-container.component';
import { CalculateFormAddressComponent } from './calculate-form/calculate-form-address/calculate-form-address.component';
import { CalculateFormCheckboxesComponent } from './calculate-form/calculate-form-checkboxes/calculate-form-checkboxes.component';
import { CalculateFormComponent } from './calculate-form/calculate-form.component';
import { CalculateFormMeterComponent } from './calculate-form/calculate-form-meter/calculate-form-meter.component';
import { CalculateFormQuestionnaireTriggerComponent } from './calculate-form/calculate-form-questionnaire-trigger/calculate-form-questionnaire-trigger.component';
import { CalculateFormSolarComponent } from './calculate-form/calculate-form-solar/calculate-form-solar.component';
import { CalculateFormUsageComponent } from './calculate-form/calculate-form-usage/calculate-form-usage.component';
import { UsageQuestionnaireSMEModalComponent } from './usage-questionnaire-sme-modal/usage-questionnaire-sme-modal.component';
import { BacUsageQuestionnaireModalEffects } from './usage-questionnaire-sme-modal/state/bac-usage-questionnaire-modal.effects';
import { CalculateModalComponent } from './calculate-modal/calculate-modal.component';

const exportedComponents = [
  CalculateContainerComponent,
  CalculateFormAddressComponent,
  CalculateFormCheckboxesComponent,
  CalculateFormComponent,
  CalculateFormMeterComponent,
  CalculateFormQuestionnaireTriggerComponent,
  CalculateFormSolarComponent,
  CalculateFormUsageComponent,
  UsageQuestionnaireSMEModalComponent,
  CalculateModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CoreAnalyticsModule,
    CoreExperienceEditorModule,
    CommonUiUtilityComponentsModule,
    CommonUiFormsModule,
    JssModule,
    UtilsModule,
    NgbModalModule,
    CommonUiSharedModule,
    NgrxFormsModule,
    BecomeACustomerUiModule,
    ChannelRecognitionModule,
    BecomeACustomerUsageQuestionnaireComponentModule,
    CommonUiPartialsModule,
    UtilsContentsquareModule,
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
  providers: [provideBootstrapEffects([BacUsageQuestionnaireModalEffects])],
})
export class BecomeACustomerCalculateModule {}
