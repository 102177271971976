import type { FormPersonalDetails } from '@innogy/common-ui/shared/interfaces';
import type { PrefillAction } from '@innogy/shared/forms';
import {
  createNgrxFormReducer,
  onPrefillActionReducer,
  wrappedFormReducer,
} from '@innogy/shared/forms';
import {
  conditionalOn,
  getDateOffset,
  isDateInRange,
  isEmailAddress,
  isLastName,
  isPhoneNumber,
  onNgrxFormsControlId,
  validateSequential,
  validateSequentialIf,
} from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  reset,
  ResetAction,
  setUserDefinedProperty,
  setValue,
  updateGroup,
  updateRecursive,
  validate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { enablePersonalDetailsDateOfBirthAction } from './progressive-personal-details-form.actions';

export const minimumDate = getDateOffset(0, 0, -100);
export const maximumDate = getDateOffset(0, 0, -18);

export interface PersonalDetailsReducerOptions {
  prefillOnAction?: PrefillAction<FormPersonalDetails>;
  showDateOfBirth?: boolean;
}

export interface ProgressivePersonalDetailsFormState {
  formState: FormGroupState<FormPersonalDetails>;
}

export const createInitialPersonalDetailsFormState = (formId: string) =>
  createFormGroupState<FormPersonalDetails>(formId, {
    salutation: undefined,
    initials: '',
    prefix: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    dateOfBirth: '',
  });

export const getInitialPersonalDetailsState = (
  formId: string,
  options: PersonalDetailsReducerOptions = {}
): ProgressivePersonalDetailsFormState => ({
  formState: updateRecursive(
    createInitialPersonalDetailsFormState(formId),
    (state) =>
      setUserDefinedProperty(
        'showDateOfBirth',
        options.showDateOfBirth ?? false
      )(state)
  ),
});

export const validatePersonalDetailsForm = (
  state: ProgressivePersonalDetailsFormState
) => {
  return updateGroup<FormPersonalDetails>({
    salutation: validate(required),
    initials: validate(required),
    lastName: validateSequential(required, isLastName),
    phoneNumber: validateSequential(required, isPhoneNumber),
    emailAddress: validateSequential(required, isEmailAddress),
    dateOfBirth: validateSequentialIf(
      state.formState.userDefinedProperties.showDateOfBirth
    )(required, isDateInRange(minimumDate, maximumDate)),
  })(state.formState);
};

export const resetPersonalDetailsForm =
  (formId: string) => (state: ProgressivePersonalDetailsFormState) => ({
    ...state,
    formState: reset(
      setValue(
        state.formState,
        createInitialPersonalDetailsFormState(formId).value
      )
    ),
  });

const _reducer = (formId: string, options: PersonalDetailsReducerOptions) =>
  createNgrxFormReducer(
    getInitialPersonalDetailsState(formId, options),
    formId,
    validatePersonalDetailsForm,
    onNgrxFormsControlId(ResetAction, formId, resetPersonalDetailsForm(formId)),
    on(enablePersonalDetailsDateOfBirthAction, (state, action) =>
      action.formId === formId
        ? {
            ...state,
            formState: setUserDefinedProperty(
              'showDateOfBirth',
              action.enabled
            )(state.formState),
          }
        : state
    ),
    conditionalOn(
      !!options.prefillOnAction,
      options.prefillOnAction,
      onPrefillActionReducer(validatePersonalDetailsForm)
    )
  );

export function createProgressivePersonalDetailsReducer(
  formId: string,
  options: PersonalDetailsReducerOptions = {}
) {
  const _wrappedReducer = wrappedFormReducer(
    _reducer(formId, options),
    validatePersonalDetailsForm
  );

  return (
    state: ProgressivePersonalDetailsFormState = getInitialPersonalDetailsState(
      formId,
      options
    ),
    action: Action
  ): ProgressivePersonalDetailsFormState => {
    return _wrappedReducer(state, action);
  };
}
