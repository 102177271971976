import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { ROOT_PLACEHOLDER_KEY } from '@innogy/config';
import { Store } from '@ngrx/store';

import type {
  JssStatePlaceholderLoaded,
  JssStatePlaceholderLoading,
} from './jss-route.actions';
import {
  JssStateActionTypes,
  JssStateAllTopLevelPlaceholdersLoaded,
} from './jss-route.actions';
import { getPlaceholdersLoading } from './jss.selectors';

@Injectable()
export class JssPlaceholderEffects {
  placeholdersLoading$ = this.store$.select(getPlaceholdersLoading);

  /**
   * WARNING: Use this effect with caution!
   *          This effect could give false positives when all rendered placeholders are loaded,
   *          but there are still components being lazy-loaded that have their own placeholders.
   *
   * EXAMPLE: It can be used to check if placeholders of top-level Components have been loaded.
   *          Entry                         <-- root
   *              |-- Breadcrumbs
   *              |-- DynamicRowComponent   <-- top-level Component
   *                  |-- Column-1          <-- Component (in placeholder of a top-level component)
   *                  |-- Column-2
   *              |-- Footer
   *
   * NOTE: The JssStateAllTopLevelPlaceholdersLoaded Action may never dispatch if you don't
   *       have any top-level placeholders that set a loading state for their placeholders.
   *       See DynamicRowComponent as an example of how to set a loading state.
   */
  allTopLevelPlaceholdersLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<JssStatePlaceholderLoaded | JssStatePlaceholderLoading>(
        JssStateActionTypes.PLACEHOLDER_LOADING,
        JssStateActionTypes.PLACEHOLDER_LOADED
      ),
      concatLatestFrom(() => this.placeholdersLoading$),
      mergeMap(([{ payload }, placeholdersLoading]) => {
        if (
          payload !== this.rootPlaceholderKey &&
          !Object.keys(placeholdersLoading).length
        ) {
          return [new JssStateAllTopLevelPlaceholdersLoaded()];
        }

        return [];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    @Inject(ROOT_PLACEHOLDER_KEY) public rootPlaceholderKey: string
  ) {}
}
