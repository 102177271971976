import { getIsExperienceEditorActive } from '@innogy/jss';
import type { MemoizedSelector } from '@ngrx/store';
import { createSelector } from '@ngrx/store';

/**
 * Returns a selector for a component view model with data from the store or from
 * mock data, depending on whether the SiteCore Experience Editor is active or not.
 */
export const componentViewModelSelector = <T>(
  viewModelSelector: MemoizedSelector<Record<string, any>, T>,
  viewModelMock: T
) =>
  createSelector(
    viewModelSelector,
    getIsExperienceEditorActive,
    (viewModel, isXpEditorActive) =>
      isXpEditorActive ? viewModelMock : viewModel
  );
