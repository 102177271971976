import type { ProgressiveFormStateImplementor } from '@innogy/progressive-ngrx-forms';
import {
  createProgressiveFormGroupState,
  createProgressiveNgrxFormReducerWithFormStateUpdate,
} from '@innogy/progressive-ngrx-forms';
import type { Action, ActionCreator, ReducerTypes } from '@ngrx/store';
import { updateGroup, validate } from 'ngrx-forms';
import {
  validateSequential,
  isLastName,
  isEmailAddress,
  isPhoneNumber,
} from '@innogy/utils/deprecated';
import { required } from 'ngrx-forms/validation';
import type { Gender } from '@essent/common';

export interface PersonalDetails {
  salutation?: Gender;
  initials: string;
  prefix: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
}

export type OrderPersonalDetailsState =
  ProgressiveFormStateImplementor<PersonalDetails>;

type AdditionalOnFns = ReducerTypes<
  OrderPersonalDetailsState,
  readonly ActionCreator<any, any>[]
>[];

export const initialOrderPersonalDetailsProgressiveFormState = (id: string) =>
  createProgressiveFormGroupState<PersonalDetails>(id, {
    salutation: undefined,
    initials: '',
    prefix: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
  });

export const initialOrderPersonalDetailsState = (
  id: string
): OrderPersonalDetailsState => ({
  progressiveForm: initialOrderPersonalDetailsProgressiveFormState(id),
});

const validateFormGroupState = (state: OrderPersonalDetailsState) => {
  return updateGroup<PersonalDetails>({
    salutation: validate(required),
    initials: validate(required),
    lastName: validateSequential(required, isLastName),
    phoneNumber: validateSequential(required, isPhoneNumber),
    emailAddress: validateSequential(required, isEmailAddress),
  })(state.progressiveForm.formState);
};

const _reducer = (id: string, ...additionalOnFns: AdditionalOnFns) =>
  createProgressiveNgrxFormReducerWithFormStateUpdate(
    initialOrderPersonalDetailsState(id),
    validateFormGroupState,
    ...additionalOnFns
  );

export const EplusOrderPersonalDetailsFormReducer =
  (id: string, ...additionalOnFns: AdditionalOnFns) =>
  (state = initialOrderPersonalDetailsState(id), action: Action) =>
    _reducer(id, ...additionalOnFns)(state, action);
