import { getPaymentDetailsSuccess } from '@essent/financial';
import { IncomingPaymentMethod } from '@essent/common';
import {
  isIBAN,
  useValidatorIf,
  validateSequential,
} from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  reset,
  ResetAction,
  setValue,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { notEqualTo, required } from 'ngrx-forms/validation';

import { isManualPaymentMethod } from '../ngrx-forms/validators/is-manual-payment-method';
import { isPaymentMethodChanged } from '../ngrx-forms/validators/is-payment-method-changed';

export const UPDATE_PAYMENT_METHOD_FORM_ID = 'updatePaymentMethodForm';
export const paymentMethodControlId = `${UPDATE_PAYMENT_METHOD_FORM_ID}.paymentMethod`;
export const paymentMethodIbanControlId = `${UPDATE_PAYMENT_METHOD_FORM_ID}.iban`;

export interface UpdatePaymentMethodFormState {
  iban: string;
  paymentMethod?: `${IncomingPaymentMethod}`;
}

export interface State {
  updatePaymentMethodFormState: FormGroupState<UpdatePaymentMethodFormState>;
  initialIban: string;
  initialPaymentMethod?: `${IncomingPaymentMethod}`;
}

export const initialFormState: UpdatePaymentMethodFormState = {
  iban: '',
  paymentMethod: undefined,
};

export const initialFormGroupState =
  createFormGroupState<UpdatePaymentMethodFormState>(
    UPDATE_PAYMENT_METHOD_FORM_ID,
    initialFormState
  );

export const initialState: State = {
  updatePaymentMethodFormState: initialFormGroupState,
  initialIban: '',
};

const isDirectDebit = (paymentMethod?: `${IncomingPaymentMethod}`) => {
  return paymentMethod === IncomingPaymentMethod.DIRECT_DEBIT;
};

export const validateAndUpdateForms = (state: State) => {
  return updateGroup<UpdatePaymentMethodFormState>({
    iban: validateSequential(
      useValidatorIf(
        required,
        isDirectDebit(state.updatePaymentMethodFormState.value.paymentMethod)
      ),
      useValidatorIf(
        isIBAN,
        isDirectDebit(state.updatePaymentMethodFormState.value.paymentMethod)
      ),
      useValidatorIf(
        notEqualTo(state.initialIban),
        isDirectDebit(state.updatePaymentMethodFormState.value.paymentMethod) &&
          state.initialPaymentMethod ===
            state.updatePaymentMethodFormState.value.paymentMethod
      )
    ),
    paymentMethod: validateSequential(
      required,
      useValidatorIf(
        isPaymentMethodChanged(state.initialPaymentMethod),
        !isDirectDebit(state.updatePaymentMethodFormState.value.paymentMethod)
      ),
      isManualPaymentMethod
    ),
  })(state.updatePaymentMethodFormState);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(ResetAction, (state, action) => {
    if (action.controlId === UPDATE_PAYMENT_METHOD_FORM_ID) {
      const updatePaymentMethodFormState = setValue(
        state.updatePaymentMethodFormState,
        {
          iban: state.initialIban || initialState.initialIban,
          paymentMethod:
            state.initialPaymentMethod || initialState.initialPaymentMethod,
        }
      );

      return {
        ...state,
        updatePaymentMethodFormState: reset(updatePaymentMethodFormState),
      };
    }
    return state;
  }),
  on(getPaymentDetailsSuccess, (state, action) => {
    const updated = setValue(state.updatePaymentMethodFormState, {
      iban: action.payload.paymentDetails.iban ?? '',
      paymentMethod: action.payload.paymentDetails.incomingPaymentMethod,
    });

    return {
      ...state,
      updatePaymentMethodFormState: updated,
      initialIban: updated.value.iban,
      initialPaymentMethod: updated.value.paymentMethod,
    };
  })
);

const _wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.updatePaymentMethodFormState,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(state: State = initialState, action: Action): State {
  return _wrappedReducer(state, action);
}
