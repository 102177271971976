import { Injectable } from '@angular/core';
import type { BaseStructuredData } from '@innogy/shared/structured-data';

import { DomService } from '../dom/dom.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private readonly domService: DomService) {}

  public injectStructuredData(data: BaseStructuredData & Record<string, any>) {
    this.domService.injectScript({
      innerHTML: JSON.stringify(data),
      type: 'application/ld+json',
      async: true,
    });
  }
}
