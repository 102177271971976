import { createAction, props } from '@ngrx/store';
import type { TypedAction } from '@ngrx/store/src/models';

const prefillSingleFieldFormActionType =
  '[single field form] prefillSingleFieldFormAction';
export interface PrefillSingleFieldFormProps {
  controlId: string;
  value: any;
}

export type PrefillSingleFieldFormActionType = PrefillSingleFieldFormProps &
  TypedAction<typeof prefillSingleFieldFormActionType>;

export const prefillSingleFieldFormAction = createAction(
  prefillSingleFieldFormActionType,
  props<PrefillSingleFieldFormProps>()
);
