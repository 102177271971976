import { DOCUMENT } from '@angular/common';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import type { InstallationAddress } from '@essent/installation-address';
import type { EanDetails, GetInstallations } from '@essent/new-customer';
import {
  AdditionalInformationType,
  EnergyType,
  getInstallations as getInstallationsAction,
  getInstallationsSuccess,
  putAdditionalInformation,
  putAdditionalInformationSuccess,
  putConsumption,
  putConsumptionSuccess,
  putEanDetails,
  putEanDetailsSuccess,
  ResponseMessages,
} from '@essent/new-customer';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  buildConsumptionPayload,
  getElectricityPayload,
} from '@innogy/become-a-customer/shared';
import type { PutInitiateFlowResponse } from '@innogy/become-a-customer/shared/interfaces';
import { OfferType } from '@innogy/become-a-customer/shared/interfaces';
import { openGenericModal } from '@innogy/common-ui/modals';
import { GenericModalSources } from '@innogy/common-ui/shared/interfaces';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';
import {
  envSegmentToSegmentType,
  ofFormSubmitAction,
} from '@innogy/utils/deprecated';
import { ensureFlowId } from '@innogy/utils/rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import type { Action } from '@ngrx/store/src/models';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-angular';
import { MarkAsSubmittedAction } from 'ngrx-forms';
import { filter, mergeMap, tap } from 'rxjs/operators';

import {
  putInitiateFlow,
  putInitiateFlowSuccess,
} from '../bac/1.initiate-flow';
import { setResidentialAction } from '../bac/15.additional-information/additional-information.actions';
import { getInstallationDetailsState } from '../bac/3.installation-details';
import {
  consumptionEANMismatchErrorAction,
  performPutConsumptionAction,
  skipToConsumptionOnNonBlockingErrorAction,
} from '../bac/7.consumption';
import {
  getFlowId,
  getShouldExtendTimeToLive,
  noFlowIdErrorAction,
  setFlowIdAction,
} from '../bac/flow-id';
import { getInstallationAddressState } from '../bac/installation-address';
import { clearOffersAction, resetReadyToLoadOffersAction } from '../bac/offers';
import {
  getFunnelSettings,
  openModalFromFunnelSettingsAction,
} from '../funnel';
import {
  bacReadyForOfferAction,
  calculateFormSubmitAction,
  calculateProceedToOfferAction,
  calculationDoneAction,
  emitCalculateLaststepAction,
  setApiCallsFinished,
  setApiCallsPending,
} from './calculate.actions';
import * as CalculateForm from './calculate.reducer';
import {
  getCalculateFormFormState,
  getCalculateRendering,
} from './calculate.selectors';

@Injectable()
export class CalculateFormSubmitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly injector: Injector,
    private readonly errorHandler: ErrorHandler,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig
  ) {}
  private readonly funnelSettings$ = this.store$.select(getFunnelSettings);
  private readonly formValues$ = this.store$.select(getCalculateFormFormState);
  private readonly installationAddress$ = this.store$.select(
    getInstallationAddressState
  );
  private readonly getInstallationDetails$ = this.store$.select(
    getInstallationDetailsState
  );
  private readonly flowId$ = this.store$.select(getFlowId);
  private readonly calculateRendering$ = this.store$.select(
    getCalculateRendering
  );
  private readonly extendTimeToLive$ = this.store$.select(
    getShouldExtendTimeToLive
  );

  /**
   * Submit form only when validation is valid
   */
  public readonly submitCalculateForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateFormSubmitAction),
      concatLatestFrom(() => [this.calculateRendering$, this.formValues$]),
      filter(
        ([, calculateRendering, formValues]) =>
          formValues.isValid &&
          !usageLimitsExceeded(calculateRendering, formValues.value)
      ),
      mergeMap(() => [
        new MarkAsSubmittedAction(CalculateForm.CALCULATE_FORM_ID),
        resetReadyToLoadOffersAction(),
        clearOffersAction(),
      ])
    )
  );

  public readonly showConsumptionLimitExceededModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculateFormSubmitAction),
      concatLatestFrom(() => [this.calculateRendering$, this.formValues$]),
      filter(
        ([, calculateRendering, formValues]) =>
          formValues.isValid &&
          usageLimitsExceeded(calculateRendering, formValues.value)
      ),
      mergeMap(([, , formValues]) => [
        openModalFromFunnelSettingsAction({
          source: GenericModalSources.CONSUMPTION_TRESHOLD_EXCEEDED,
          interpolatablePayload: {
            gasUsage: formValues.value.gasUsage,
            electricityUsage:
              formValues.value.electricityUsage ||
              formValues.value.electricityUsageNormal,
            electricityUsageOffPeak: formValues.value.electricityUsageOffPeak,
          },
        }),
      ])
    )
  );

  /**
   * {@link bacFlowDocumentation https://wiki.essent.nl/confluence/display/agile/BaC+Frontend+Flow+Model}
   *
   * [Step 1] Start BaC flow
   *
   * Put supply address in BAC flow when installationAddress is known and single value.
   */
  public readonly startBACFlowOnSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofFormSubmitAction(CalculateForm.CALCULATE_FORM_ID),
      concatLatestFrom(() => [
        this.formValues$,
        this.installationAddress$,
        this.extendTimeToLive$,
      ]),
      filter(([_, formValues]) => {
        return formValues.isValid;
      }),
      mergeMap(([_, formValues, getInstallationAddress, extendTimeToLive]) => {
        const installationAddress = getInstallationAddress.data?.find(
          (address: InstallationAddress) =>
            address.houseNumberExtension ===
            (formValues.value.houseNumberAddition || undefined)
        );

        const config = this.injector.get(ENVIRONMENT_CONFIG);
        const customerSegment = envSegmentToSegmentType(config.segment);

        return [
          putInitiateFlow({
            payload: {
              /* properties for putInitiateFlow */
              extendTimeToLive,

              /* properties for putSupplyAddress (which is called from within putInitiateFlow) */
              houseNumber: formValues.value.houseNumber?.toString() ?? '',
              postcode: formValues.value.postalCode,
              city: installationAddress?.city,
              street: installationAddress?.street,
              houseNumberExtension:
                formValues.value.houseNumberAddition || undefined,
              customerSegment,
            },
          }),
          setApiCallsPending(),
        ];
      })
    )
  );
  /**
   * Save flowId in state when putInitiateFlow returns with success state
   * and determine sequential actions based on potential messages in the payload, which can either be:
   *
   * [Step 3]: getting the installation details (eans) when no messages are returned
   *
   * OR
   *
   * [Step 6]: putting the consumption when a non-blocking error is returned
   */
  public readonly onPutInitiateFlowSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putInitiateFlowSuccess),
      mergeMap((action) => {
        let actions: Action[] = [
          setFlowIdAction({ flowId: action.payload.metaData.flowId ?? '' }),
        ];
        if (isNonBlockingError(action.payload)) {
          actions = [...actions, skipToConsumptionOnNonBlockingErrorAction()];
        } else {
          actions = [
            ...actions,
            getInstallationsAction({
              payload: { flowId: action.payload.metaData.flowId },
            }),
          ];
        }
        return actions;
      })
    )
  );

  /**
   * [Step 4] Save EAN details
   */
  public readonly putEansIntoBac$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstallationsSuccess),
      concatLatestFrom(() => [
        this.getInstallationDetails$,
        this.flowId$,
        this.formValues$,
      ]),
      mergeMap(([_, getInstallationDetailsResponse, flowId, formValues]) => {
        if (!flowId) {
          return [noFlowIdErrorAction()];
        }
        if (!getInstallationDetailsResponse.data) {
          /**
           * When the putSupplyAddress API returns the message { code: '200', message: 'Flow Id exists' }, no
           * other messages will be returned (e.g. NO_EAN_FOUND), even though this may still be the case. So
           * this flow will be triggered, but the 'getInstallations' API call will not return any data if
           * one of the errors applies.
           *
           * In this case we can skip to consumption ({@see bacFlowDocumentation link at the top of this file})
           */
          return [skipToConsumptionOnNonBlockingErrorAction()];
        }
        const electricity = getElectricityPayload(formValues.value);
        const gas = formValues.value.gasUsage ?? 0;
        const offerTypeFromUsage = inferOfferTypeFromUsage(
          electricity.normal,
          electricity.low,
          gas
        );

        return [
          putEanDetails({
            payload: {
              metaData: {
                flowId,
              },
              payload: buildPutEanDetailsPayload(
                getInstallationDetailsResponse.data,
                offerTypeFromUsage
              ),
            },
          }),
        ];
      })
    )
  );

  /**
   * [Step 6]: Put consumption into BAC when putEanDetails returns with success state,
   * or when step 3 and 4 were skipped due to a message from the putInitiateFlow response
   */
  public readonly putConsumptionIntoBAC$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putEanDetailsSuccess, skipToConsumptionOnNonBlockingErrorAction),
      concatLatestFrom(() => [
        this.flowId$,
        this.formValues$,
        this.getInstallationDetails$,
      ]),
      mergeMap(([_, flowId, formValues, installationDetails]) => {
        if (!flowId) {
          return [noFlowIdErrorAction()];
        }
        if (installationDetails.data) {
          const electricity = getElectricityPayload(formValues.value);
          const gas = formValues.value.gasUsage ?? 0;

          const offerTypeFromUsage = inferOfferTypeFromUsage(
            electricity.normal,
            electricity.low,
            gas
          );

          const installationsCount = inferOfferTypeFromEANs(
            installationDetails.data
          );

          const mismatch = checkOfferTypeMismatch(
            offerTypeFromUsage,
            installationsCount
          );

          if (mismatch) {
            return [
              consumptionEANMismatchErrorAction({
                mismatchOfferType: mismatch,
              }),
            ];
          }
        }

        return [performPutConsumptionAction()];
      })
    )
  );

  public readonly onPerformPutConsumption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(performPutConsumptionAction),
      concatLatestFrom(() => [
        this.flowId$,
        this.getInstallationDetails$,
        this.formValues$,
      ]),
      mergeMap(([, flowId, installations, formValues]) => {
        if (!flowId) {
          return [];
        }
        const offerType = installations.data
          ? inferOfferTypeFromEANs(installations.data)
          : OfferType.BOTH;
        const electricity =
          offerType === OfferType.ELECTRICITY || offerType === OfferType.BOTH
            ? getElectricityPayload(formValues.value)
            : { normal: 0, low: 0, return: 0 };
        const gas =
          (offerType === OfferType.GAS || offerType === OfferType.BOTH) &&
          formValues.value.gasUsage
            ? formValues.value.gasUsage
            : 0;
        return [
          putConsumption({
            payload: {
              metaData: { flowId },
              payload: buildConsumptionPayload(electricity, gas),
            },
          }),
        ];
      })
    )
  );

  /**
   * [Step 6.1]: Temporary solution to make sure the residential function is always set to true. Currently for
   * SME this is not the case. See SOS-5091 for the removal.
   */
  public readonly setResidentialForSME$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putConsumptionSuccess),
      ensureFlowId(this.flowId$),
      mergeMap(([_, flowId]) => {
        if (this.envConfig.app === 'essent-zakelijk') {
          return [
            setResidentialAction({ value: true }),
            putAdditionalInformation({
              actionId: 'TEMP_PUT_RESIDENTIAL_FUNCTION',
              payload: {
                metaData: {
                  flowId,
                },
                payload: [
                  {
                    name: AdditionalInformationType.RESIDENTIAL,
                    value: true,
                  },
                ],
              },
            }),
          ];
        }
        return [setApiCallsFinished(), bacReadyForOfferAction()];
      })
    )
  );

  /**
   * Only dispatch continuing action when actionId is that of the TEMP workaround action
   */
  public readonly continueAfterSettingResidentialForSME$ = createEffect(() =>
    this.actions$.pipe(
      ofType(putAdditionalInformationSuccess),
      filter(({ actionId }) => actionId === 'TEMP_PUT_RESIDENTIAL_FUNCTION'),
      mergeMap(() => {
        return [setApiCallsFinished(), bacReadyForOfferAction()];
      })
    )
  );

  /**
   * Proceed to offer element
   */
  public readonly proceedToOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bacReadyForOfferAction),
      concatLatestFrom(() => this.calculateRendering$),
      mergeMap(([_, rendering]) => {
        const offerElem =
          this.document.getElementById('vertical-offer') ||
          this.document.getElementById('horizontal-offer');
        if (offerElem) {
          if (getFieldValue<boolean>(rendering, 'EnableOfferScroll', false)) {
            offerElem.scrollIntoView({ behavior: 'smooth' });
          }
          this.modalService.dismissAll();
          return [calculationDoneAction(), emitCalculateLaststepAction()];
        } else {
          return [
            calculateProceedToOfferAction(),
            emitCalculateLaststepAction(),
          ];
        }
      })
    )
  );

  /**
   * Redirect to offer page
   */
  public readonly redirectToOffer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(calculateProceedToOfferAction),
        concatLatestFrom(() => this.funnelSettings$),
        tap(([_, { id, offerPage }]) => {
          if (offerPage?.href) {
            this.router.navigate([offerPage.href], {
              queryParams: {
                funnel_id: id,
              },
              queryParamsHandling: 'merge',
            });
          } else {
            this.modalService.dismissAll();
          }
        })
      ),
    { dispatch: false }
  );

  public readonly handleConsumptionEANMismatchError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumptionEANMismatchErrorAction),
      concatLatestFrom(() => this.funnelSettings$),
      mergeMap(([action, funnelSettings]) => {
        const modalSource =
          action.mismatchOfferType === OfferType.ELECTRICITY
            ? GenericModalSources.CONSUMPTION_EAN_MISMATCH_E
            : GenericModalSources.CONSUMPTION_EAN_MISMATCH_G;
        const modalSettings = funnelSettings[modalSource];
        if (!modalSettings) {
          this.errorHandler.handleError(
            `No mismatch-modal was configured for ${action.mismatchOfferType}`
          );
          return [];
        }
        return [
          setApiCallsFinished(),
          openGenericModal({
            ...modalSettings,
          }),
        ];
      })
    )
  );
}

export const buildPutEanDetailsPayload = (
  data: GetInstallations[],
  offerType: OfferType
): EanDetails[] => {
  return [
    {
      connectEan: data
        .filter((item: GetInstallations) => {
          if (offerType === 'Electricity') {
            return item.energyType === EnergyType.ELECTRICITY;
          } else if (offerType === 'Gas') {
            return item.energyType === EnergyType.GAS;
          } else {
            return true;
          }
        })
        .map((item: GetInstallations) => {
          return item.installations[0].connectEan;
        }),
    },
  ];
};

const usageLimitsExceeded = (
  rendering: any,
  {
    doubleMeter,
    gasUsage = 0,
    electricityUsage = 0,
    electricityUsageNormal = 0,
    electricityUsageOffPeak = 0,
  }: FullCalculateFormValues
) => {
  const usageLimitGas = parseInt(
    getFieldValue<string>(rendering, 'UsageLimitGas', '1')
  );
  const usageLimitElectricity = parseInt(
    getFieldValue<string>(rendering, 'UsageLimitElectricity', '1')
  );

  if (gasUsage > usageLimitGas) {
    return true;
  }

  if (!doubleMeter) {
    return electricityUsage > usageLimitElectricity;
  } else {
    return (
      electricityUsageNormal + electricityUsageOffPeak > usageLimitElectricity
    );
  }
};

const inferOfferTypeFromUsage = (
  electricity: number,
  electricityLow: number,
  gas: number
): OfferType => {
  if (!gas) {
    return OfferType.ELECTRICITY;
  } else if (!electricity && !electricityLow) {
    return OfferType.GAS;
  } else {
    return OfferType.BOTH;
  }
};

const isNonBlockingError = (payload: PutInitiateFlowResponse) => {
  return payload.messages?.find(
    (message) =>
      message.message === ResponseMessages.HOUSE_NUMBER_EXTENSION_NOT_FOUND ||
      message.message === ResponseMessages.MULTIPLE_EAN_ELECTRICITY ||
      message.message === ResponseMessages.MULTIPLE_EAN_GAS ||
      message.message === ResponseMessages.NO_EAN_FOUND ||
      message.message === ResponseMessages.CAR_IS_DOWN
  );
};

export const inferOfferTypeFromEANs = (
  installations: GetInstallations[]
): OfferType => {
  const electricityEANs = installations.filter(
    (installation) => installation.energyType === 'electricity'
  ).length;
  const gasEANs = installations.filter(
    (installation) => installation.energyType === 'gas'
  ).length;

  return electricityEANs >= 1 && gasEANs >= 1
    ? OfferType.BOTH
    : electricityEANs >= 1
    ? OfferType.ELECTRICITY
    : OfferType.GAS;
};

const checkOfferTypeMismatch = (
  offerTypeFromUsage: OfferType,
  offerTypeFromEANs: OfferType
): undefined | OfferType => {
  if (
    offerTypeFromEANs === OfferType.ELECTRICITY &&
    (offerTypeFromUsage === OfferType.BOTH ||
      offerTypeFromUsage === OfferType.GAS)
  ) {
    return OfferType.GAS;
  }

  if (
    offerTypeFromEANs === OfferType.GAS &&
    (offerTypeFromUsage === OfferType.BOTH ||
      offerTypeFromUsage === OfferType.ELECTRICITY)
  ) {
    return OfferType.ELECTRICITY;
  }

  return;
};
// eslint-disable-next-line max-lines
