import { Injectable } from '@angular/core';
import {
  getEstimatedConsumptionByHouseTypeError,
  getEstimatedConsumptionByHouseTypeSuccess,
} from '@essent/estimate-consumption';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, mergeMap } from 'rxjs/operators';
import {
  BAC_QUESTIONNAIRE_ID,
  expectedConsumptionDefinedAction,
} from '@innogy/common-ui/sales';

import { getUsageQuestionnaireFormState } from '../../usage-questionnaire';
import { handleBacApiErrorAction } from '../0.errors';

@Injectable()
export class ExpectedConsumptionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  private readonly usageQuestionnaireValues$ = this.store$.pipe(
    select(getUsageQuestionnaireFormState)
  );

  public readonly handleEstimatedConsumptionByHouseTypeSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getEstimatedConsumptionByHouseTypeSuccess),
        filter((action) => action.actionId === BAC_QUESTIONNAIRE_ID),
        concatLatestFrom(() => [this.usageQuestionnaireValues$]),
        mergeMap(
          ([{ payload: expectedConsumption }, formQuestionnaireState]) => {
            const { supplyType } = formQuestionnaireState.value;

            return [
              expectedConsumptionDefinedAction({
                formId: BAC_QUESTIONNAIRE_ID,
                expectedConsumption,
                supplyType,
              }),
            ];
          }
        )
      )
  );

  public readonly handleEstimatedConsumptionByHouseTypeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getEstimatedConsumptionByHouseTypeError),
        mergeMap((action) => {
          return [
            handleBacApiErrorAction({
              message: action.payload.message,
              stepname: 'Order',
            }),
          ];
        })
      )
  );
}
