import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';

import { StepByStepSubVariant } from './list-variant-step-by-step.model';
import { ListVariantBaseComponent } from '../list-variant.base.component';
import { ComposableCardConfig } from '../../../composables/card/card.model';

@Component({
  selector: 'wl-list-variant-step-by-step',
  templateUrl: './list-variant-step-by-step.component.html',
  styleUrls: [
    './list-variant-step-by-step.component.essent.scss',
    './list-variant-step-by-step.component.ed.scss',
  ],
})
export class ListVariantStepByStepComponent
  extends ListVariantBaseComponent
  implements OnInit
{
  @Input() subVariant: StepByStepSubVariant = 'brand';
  @Input() cardConfig?: ComposableCardConfig;

  backgroundColorClass?: string;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.cardConfig) {
      this.backgroundColorClass = this.cardConfig.backgroundColorClass;
      this.cardConfig.backgroundColorClass = '';
    }
  }
}
