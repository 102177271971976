import { Component, Input } from '@angular/core';
import { uppercaseConverter } from '@innogy/utils/deprecated';
import { FormGroupState } from 'ngrx-forms';

import { ProgressivePaymentDetailsFormRendering } from './progressive-payment-details-form.rendering';
import type { FormPaymentDetails } from './state/progressive-payment-details-form.reducer';

@Component({
  selector:
    'wl-progressive-payment-details-form[rendering][step][stepName][formState][progressiveFormSelectorKey]',
  templateUrl: './progressive-payment-details-form.component.html',
  styleUrls: [],
})
export class ProgressivePaymentDetailsFormComponent {
  @Input()
  floatingLabels?: boolean;
  @Input()
  rendering!: ProgressivePaymentDetailsFormRendering;
  @Input()
  stepName!: string;
  @Input()
  step!: number;
  @Input()
  formState!: FormGroupState<FormPaymentDetails>;
  @Input()
  progressiveFormSelectorKey!: string;
  @Input()
  isReadonly = false;

  _uppercaseConverter = uppercaseConverter;
}
