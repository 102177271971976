import { Injectable, Injector } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { tap } from 'rxjs/operators';

import { getSiteContext } from '../shared/page-name';
import type { TrackAccordionInfo } from './track-accordion.model';

@Injectable({
  providedIn: 'root',
})
export class TrackAccordionService {
  trackAccordion(
    componentName: string,
    eventType: string,
    eventInfo: TrackAccordionInfo
  ) {
    const config = this.injector.get(ENVIRONMENT_CONFIG);

    return getSiteContext(this.store$, config).pipe(
      tap((context) => {
        const { subject, name, variant } = eventInfo;
        const placement = `${context}|${componentName}`.toLowerCase();

        this.angulartics.eventTrack.next({
          action: eventType,
          properties: {
            subject,
            name,
            placement,
            ...(variant && { variant }),
          },
        });
      })
    );
  }

  constructor(
    private readonly angulartics: Angulartics2,
    private readonly injector: Injector,
    private readonly store$: Store
  ) {}
}
