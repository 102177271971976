import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
} from '@angular/core';
import type { StreetAddress } from '@essent/new-customer';
import { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';
import type { IconLabel } from '@innogy/common-ui/partials';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { type EnvConfigBrand, EnvironmentConfig } from '@innogy/config/models';

import { getAddressString, getConsumptionIconLabels } from '../utils';

@Component({
  selector: 'wl-commodity-offer-calculation-based-on',
  templateUrl: './commodity-offer-calculation-based-on.component.html',
  styleUrls: [
    './commodity-offer-calculation-based-on.component.ed.scss',
    './commodity-offer-calculation-based-on.component.essent.scss',
  ],
})
export class CommodityOfferCalculationBasedOnComponent
  implements OnInit, OnChanges
{
  @HostBinding('attr.variant')
  @Input()
  variant?: 'wide' | 'narrow' = 'wide';

  @Input() label?: string;

  @Input() electricityLabel?: string;
  @Input() gasLabel?: string;

  @Input() address?: Partial<StreetAddress>; // Partial to account for address not found in BAC

  @Input()
  consumptions?: ConsumptionValuesVM;

  @Input()
  adjustCalculationLabel?: string;

  @Output()
  adjustCalculation = new EventEmitter<void>();

  addressString?: string;
  consumptionIconLabels?: IconLabel[];
  brand: EnvConfigBrand;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig
  ) {
    this.brand = this.envConfig.brand;
  }

  ngOnInit() {
    this.updateValues(this.address, this.consumptions);
  }

  // onChanges, as address and consumption can change after this component was init
  ngOnChanges(changes: SimpleChanges): void {
    const newAddress = changes.address?.currentValue;
    const oldAddress = changes.address?.previousValue;
    const newConsumptions = changes.consumptions?.currentValue;
    const oldConsumptions = changes.consumptions?.previousValue;

    if (newAddress !== oldAddress) {
      this.updateValues(newAddress, oldConsumptions);
    }

    if (newConsumptions !== oldConsumptions) {
      this.updateValues(oldAddress, newConsumptions);
    }
  }

  updateValues(
    address?: Partial<StreetAddress>,
    consumptions?: ConsumptionValuesVM
  ) {
    if (address) {
      this.addressString = getAddressString(address);
    }
    if (consumptions) {
      this.consumptionIconLabels = getConsumptionIconLabels(
        consumptions,
        this.electricityLabel || '',
        this.gasLabel || ''
      );
    }
  }
}
