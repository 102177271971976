import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FunnelSettings } from '@innogy/become-a-customer/shared/interfaces';
import { emptyGenericModal } from '@innogy/common-ui/shared/interfaces';

import { setFunnelInitializedAction, setFunnelSettingsAction } from './';

export type FunnelSettingsState = FunnelSettings;

export const initialState: FunnelSettingsState = {
  id: '',
  initialized: false,
  startPage: { href: '', linktype: '' },
  offerPage: { href: '', linktype: '' },
  orderPage: { href: '', linktype: '' },
  orderSuccesPage: { href: '', linktype: '' },
  offerset: '',
  enabledOrderSteps: [],
  scrollToInitialStep: false,
  disableChannelRecognition: true,
  chatbotFallbackUrl: undefined,
  exitBacSalesFunnelModal: emptyGenericModal(),
  changeResidentialFunctionModal: emptyGenericModal(),
  chatbotModal: emptyGenericModal(),
  bacErrorModal: emptyGenericModal(),
  changeCalculationComponent: undefined,
  usageWizard: undefined,
};

const _reducer = createReducer(
  initialState,
  on(setFunnelInitializedAction, (state) => ({
    ...state,
    initialized: true,
  })),
  on(setFunnelSettingsAction, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
);

export function funnelSettingsReducer(
  state: FunnelSettingsState = initialState,
  action: Action
): FunnelSettingsState {
  return _reducer(state, action);
}
