import { validateNgrxState } from '@innogy/shared/forms';
import type { BusinessType } from '@innogy/become-a-customer/shared/interfaces';
import { validateSequential } from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  MarkAsSubmittedAction,
  onNgrxForms,
  onNgrxFormsAction,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import type { SupplyType } from '@innogy/common-ui/sales';

export const USAGE_QUESTIONNAIRE_SME_FORM_ID =
  'smeCalculateUsageQuestionnaireForm';

export interface FormValues {
  businessType?: BusinessType;
  supplyType?: SupplyType;
}
export const businessTypeControlId = `${USAGE_QUESTIONNAIRE_SME_FORM_ID}.businessType`;
export const supplyTypeControlId = `${USAGE_QUESTIONNAIRE_SME_FORM_ID}.supplyType`;

export interface State {
  formState: FormGroupState<FormValues>;
}

export const initialFormGroupState = createFormGroupState<FormValues>(
  USAGE_QUESTIONNAIRE_SME_FORM_ID,
  {
    businessType: undefined,
    supplyType: undefined,
  }
);

const initialState: State = {
  formState: initialFormGroupState,
};

const validateAndUpdateForms = (state: State) => {
  return updateGroup<FormValues>({
    businessType: validateSequential(required),
    supplyType: validateSequential(required),
  })(state.formState);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(MarkAsSubmittedAction, (state, action) => {
    if (action.controlId === USAGE_QUESTIONNAIRE_SME_FORM_ID) {
      return validateNgrxState(state, validateAndUpdateForms);
    }
    return state;
  })
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.formState,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(state: State = initialState, action: Action): State {
  return wrappedReducer(state, action);
}
