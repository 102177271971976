import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@innogy/config';
import { UtilsModule } from '@innogy/utils/deprecated';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { Angulartics2Module } from 'angulartics2';

import { AccountEffects } from './+state/account.effects';
import {
  accountReducers,
  accountSelectorKey,
  ACCOUNT_FEATURE_REDUCERS,
} from './+state/feature.selectors';
import { AccountService } from './account.service';
import { LoadAccountComponent } from './load-account/load-account.component';

@NgModule({
  imports: [
    HttpClientModule,
    ConfigModule,
    StoreModule.forFeature(accountSelectorKey, ACCOUNT_FEATURE_REDUCERS),
    EffectsModule.forFeature([AccountEffects]),
    UtilsModule,
    Angulartics2Module,
  ],
  providers: [
    AccountService,
    { provide: ACCOUNT_FEATURE_REDUCERS, useValue: accountReducers },
  ],
  declarations: [LoadAccountComponent],
  exports: [LoadAccountComponent],
})
export class AccountModule {}
