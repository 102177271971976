<ng-container>
  <wl-text
    [formsControl]="control"
    [floatingLabel]="floatingLabel"
    [label]="fields.Label?.value"
    [placeholder]="fields.Placeholder?.value"
    [rendering]="{ componentName: trackStep.toolName }"
    [step]="trackStep.step"
    [stepname]="trackStep.stepName"
    [fieldname]="fields.Label?.value"
    [tooltip]="fields.Tooltip?.value"
    [hashValue]="fields.HashValue?.value"
    [sendValue]="fields.SendValue?.value"
    [valueConverter]="_ibanConverter"
  ></wl-text>
</ng-container>
