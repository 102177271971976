import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss/models';

import { IAccordion } from './accordion.model';
import type { IAccordionItems } from './accordionItems.model';

@Component({
  selector: 'wl-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: [
    './accordion.component.ed.scss',
    './accordion.component.essent.scss',
    './accordion.component.ew.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @Input()
  accordions?: IAccordion;

  @Input()
  pageTitle?: string;

  @Input()
  rendering?: InnogyComponentRendering;

  @Input()
  enableTransparancy = true;

  trackById(_: number, item: IAccordionItems): string {
    return item.id;
  }

  getClickData(item: IAccordionItems) {
    return {
      subject: `faq|${this.pageTitle || ''}`,
      name: (item.question && item.question.value) || '',
      placement: this.rendering,
    };
  }

  getImpressionData(item: IAccordionItems) {
    return {
      ...this.getClickData(item),
      open: 'click',
    };
  }
}
