import {
  getPaymentDetailsSuccess,
  patchPaymentDetailsActions,
} from '@essent/financial';
import {
  isNumeric,
  isPostalCode,
  useValidatorIf,
  validateSequential,
} from '@innogy/utils/deprecated';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  updateGroup,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';

import type { ChangeInvoiceAddressFormState } from '../payment-details.model';
import { AddressTypes } from '../payment-details.model';
export const CHANGE_INVOICE_ADDRESS_FORM_ID = 'changeInvoiceAddress';
export const addressTypeControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.addressType`;
export const postalCodeControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.postalCode`;
export const communicationNumberControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.communicationNumber`;
export const houseNumberAdditionControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.houseNumberAddition`;
export const streetControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.street`;
export const cityControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.city`;
export const personOfContactControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.personOfContact`;
export const countryControlId = `${CHANGE_INVOICE_ADDRESS_FORM_ID}.country`;

export const DEFAULT_COUNTRY = 'NL';

export interface State {
  changeInvoiceAddressForm: FormGroupState<ChangeInvoiceAddressFormState>;
  technicalError: boolean;
  initialAddressType: AddressTypes;
}

export const initialFormState: ChangeInvoiceAddressFormState = {
  addressType: AddressTypes.ADDRESS,
  postalCode: '',
  communicationNumber: undefined,
  city: '',
  houseNumberAddition: '',
  street: '',
  country: DEFAULT_COUNTRY,
};

export const initialFormGroupState =
  createFormGroupState<ChangeInvoiceAddressFormState>(
    CHANGE_INVOICE_ADDRESS_FORM_ID,
    initialFormState
  );

export const initialState: State = {
  changeInvoiceAddressForm: initialFormGroupState,
  technicalError: false,
  initialAddressType: AddressTypes.ADDRESS,
};

const isAddress = (state: State) => {
  return (
    state.changeInvoiceAddressForm.value.addressType === AddressTypes.ADDRESS
  );
};

export const validateAndUpdateForms = (state: State) => {
  return updateGroup<ChangeInvoiceAddressFormState>({
    postalCode: validateSequential(required, isPostalCode),
    communicationNumber: validateSequential(required, isNumeric),
    houseNumberAddition: validateSequential(
      useValidatorIf(maxLength(6), isAddress(state))
    ),
    street: validateSequential(useValidatorIf(required, isAddress(state))),
    city: validateSequential(required),
    country: validateSequential(required),
  })(state.changeInvoiceAddressForm);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  on(patchPaymentDetailsActions.errorAction, (state) => {
    return {
      ...state,
      technicalError: true,
    };
  }),
  on(getPaymentDetailsSuccess, (state, action) => {
    const addressType =
      action.payload.invoiceAddress != null &&
      'pobox' in action.payload.invoiceAddress
        ? AddressTypes.POSTALBOX
        : AddressTypes.ADDRESS;
    return {
      ...state,
      initialAddressType: addressType,
    };
  })
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state) => state.changeInvoiceAddressForm,
  (_, state) => validateAndUpdateForms(state)
);

export function reducer(state: State = initialState, action: Action): State {
  return wrappedReducer(state, action);
}
