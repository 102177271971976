import { Inject, Injectable } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  Params,
  RouterStateSnapshot,
} from '@angular/router';
import { WINDOW } from '@innogy/utils/dom';
import { Store, select } from '@ngrx/store';

import type * as fromJss from '../+state';
import { JssStateChangeRoute } from '../+state/jss-route.actions';
import { getIsExperienceEditorClientActive } from '../+state/jss.selectors';

@Injectable()
export class JssRouteResolver {
  constructor(
    private readonly store$: Store<fromJss.State>,
    @Inject(WINDOW) private readonly windowRef: Window | null
  ) {}

  private readonly allowedQueryParams = ['page', 'pagesize'];

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this.store$
      .pipe(select(getIsExperienceEditorClientActive))
      .subscribe((active) => {
        if (active && this.windowRef) {
          const currentLocation =
            this.windowRef.location.pathname + this.windowRef.location.search;
          if (currentLocation !== state.url) {
            this.windowRef.location.assign(state.url);
            return;
          }
        }

        /**
         * Since sending extraQueryParams to the "fetchLayoutData" request is officially not supported
         * by @sitecore-jss, we limit what params are allowed to be passed here to the (currently) 2 queryParams
         * that we need. See CustomRestLayoutService for more details.
         */
        const isAllowedQueryParameter = (param: string) =>
          this.allowedQueryParams.some(
            (_param) => _param.toLocaleLowerCase() === param.toLocaleLowerCase()
          );

        const extraQueryParams: Params = Object.keys(route.queryParams).reduce(
          (params: Params, key) => {
            if (isAllowedQueryParameter(key)) {
              params[key] = route.queryParams[key];
            }
            return { ...params };
          },
          {}
        );

        this.store$.dispatch(
          new JssStateChangeRoute({
            route: route.params['serverRoute'],
            language: route.params['language'],
            extraQueryParams,
          })
        );
      });
  }
}
