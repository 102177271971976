import { Injectable, Injector } from '@angular/core';
import { createOnlineAccountClear } from '@essent/customer';
import { BaseGuard } from '@innogy/core/ngrx';
import { Store } from '@ngrx/store';
import type { GuardInput } from '@sitecore-jss/sitecore-jss-angular';
import { ResetAction } from 'ngrx-forms';
import { of } from 'rxjs';

import { CREATE_ONLINE_ACCOUNT_FORM_ID } from '../+state/create-online-account.reducer';

@Injectable({
  providedIn: 'root',
})
export class CreateOnlineAccountGuard extends BaseGuard {
  override isStateLoading() {
    return of(false);
  }

  override getDispatchActions() {
    return [];
  }

  override canActivate(input: GuardInput) {
    this.store$.dispatch(createOnlineAccountClear());
    this.store$.dispatch(new ResetAction(CREATE_ONLINE_ACCOUNT_FORM_ID));

    return super.canActivate(input);
  }

  constructor(readonly injector: Injector, private readonly store$: Store) {
    super(injector);
  }
}
