import { Component, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: [],
})
export class DateInputComponent {
  @Input()
  placeholder = '';
  @Input()
  label = '';
  @Input()
  fieldname?: string;
  @Input({ required: true }) formsControl!: FormState<string>;
  @Input()
  rendering?: ComponentRendering;
  @Input()
  stepname?: string;
  @Input()
  step?: number;
  @Input()
  sendValue? = true;
  @Input()
  skipDirtyCheck = false;
  @Input() labelFloating = true;
}
