import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { PlatformService } from '@innogy/core/platform';
import { ConfigLoaderService } from '@innogy/config';

import { ExperienceEditorUtilsService } from './experience-editor-utils/experience-editor-utils.service';

async function initializeApp(
  experienceEditorUtilsService: ExperienceEditorUtilsService,
  platformService: PlatformService
) {
  if (platformService.isClient()) {
    return experienceEditorUtilsService.load();
  }
  return undefined;
}

@NgModule({
  imports: [CommonModule, StoreModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          experienceEditorUtilsService: ExperienceEditorUtilsService,
          platformService: PlatformService
        ) =>
        () =>
          initializeApp(experienceEditorUtilsService, platformService),
      deps: [
        ExperienceEditorUtilsService,
        PlatformService,
        ConfigLoaderService,
      ],
      multi: true,
    },
  ],
})
export class CoreExperienceEditorUtilsModule {}
