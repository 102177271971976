import { Component } from '@angular/core';

import type { HeaderSize } from '../../basic-content-settings.model';
import { BasicContentVariantInheritableComponent } from '../basic-content-variant.inheritable.component';

@Component({
  selector: 'wl-basic-content-variant-ng-content-between',
  templateUrl: './basic-content-variant-ng-content-between.component.html',
  styleUrls: ['./basic-content-variant-ng-content-between.component.ed.scss'],
})
export class BasicContentVariantNgContentBetweenComponent extends BasicContentVariantInheritableComponent {
  get headingSize(): HeaderSize {
    return 'h2';
  }
  get subheadingSize(): HeaderSize {
    return 'h3';
  }

  get hasButtons() {
    return (
      this.content?.buttons?.primary?.link?.href ||
      this.content?.buttons?.secondary?.link?.href
    );
  }

  get hasTopItems() {
    return (
      this.content?.heading?.text?.value ||
      this.content?.subheading?.text?.value ||
      this.content?.text?.text?.value
    );
  }

  get hasBottomItems() {
    return this.hasButtons || this.content?.microcopy?.text?.value;
  }
}
