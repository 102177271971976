import {
  EnvConfigEnvironment,
  type EnvironmentConfig,
} from '@innogy/config/models';
import {
  BrowserClient,
  breadcrumbsIntegration,
  dedupeIntegration,
  defaultStackParser,
  getCurrentScope,
  globalHandlersIntegration,
  makeFetchTransport,
  linkedErrorsIntegration,
  setExtra,
  setTag,
  browserTracingIntegration,
  functionToStringIntegration,
  httpContextIntegration,
  inboundFiltersIntegration,
} from '@sentry/angular';
import type { ErrorEvent, EventHint } from '@sentry/angular';

/**
 * Make sure the sample rate is proportional to the rate limit to make there is
 * no rate limiting.
 */
export const SAMPLE_RATE_PROD = 0.02;
/**
 * This regex should match all known environment and endpoint combinations.
 * @example https://mock.mijn.tst.essent.nl https://mijn.tst.essent.nl
 * https://mijn.tst.essent.nl https://mijn.essent.nl
 */
export const TARGETS_PROD = /^https:\/\/[^\s]*\.(essent|energiedirect)\.nl$/;

/**
 * The unique Sentry instance.
 */
let sentryInstance: BrowserClient | undefined;

export const getSentryInstance = () => {
  return sentryInstance;
};

export const setSentryInstance = (instance?: BrowserClient) => {
  sentryInstance = instance;
};

export const initSentry = async (config?: EnvironmentConfig) => {
  // eslint-disable-next-line no-restricted-globals
  const isClient = typeof window !== 'undefined';
  const loadSentry = config?.sentry?.enabled && isClient && !sentryInstance;

  if (loadSentry) {
    const allowUrls = location?.origin ? [location?.origin] : undefined;
    const tracesSampleRate =
      config.environment === EnvConfigEnvironment.Production
        ? SAMPLE_RATE_PROD
        : 1;

    const client = new BrowserClient({
      dsn: config.sentry?.url,
      release: config.version,
      environment: `${config.environment}-${config.app}`,
      allowUrls,
      beforeSend: sentryEventInterceptor,
      tracesSampleRate,
      tracePropagationTargets: ['localhost', TARGETS_PROD],
      stackParser: defaultStackParser,
      transport: makeFetchTransport,
      integrations: [
        inboundFiltersIntegration(),
        functionToStringIntegration(),
        breadcrumbsIntegration(),
        globalHandlersIntegration(),
        linkedErrorsIntegration(),
        dedupeIntegration(),
        httpContextIntegration(),
        browserTracingIntegration(),
      ],
    });

    getCurrentScope().setClient(client);
    client.init();

    setExtra('app', config.app);
    setTag('app', config.app);

    sentryInstance = client;
  }
};

export function sentryEventInterceptor(event: ErrorEvent, _hint?: EventHint) {
  addUserInfoToEvent(event);
  return event;
}

export function getCookie(name: string) {
  const escapedName: string = name.replace(/[[\]{}()|=;+?,.*^$]/gi, '\\$&');
  const cookieRegex = new RegExp(
    `(?:^${escapedName}|;\\s*${escapedName})=(.*?)(?:;|$)`,
    'g'
  );
  // eslint-disable-next-line no-restricted-globals
  const result = cookieRegex.exec(document.cookie);
  return result ? result[1] : undefined;
}

function addUserInfoToEvent(event: ErrorEvent) {
  const id = getCookie('user_id-0')?.replace(/\"/g, '');
  const cookieConsent = Number(getCookie('consentLevel')) || 0;

  event.tags = {
    cookieConsent,
    ...event.tags,
  };

  if (cookieConsent && id) {
    event.user = { id };
  }
}
