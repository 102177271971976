<div class="form-check">
  <input
    *ngIf="formsControl"
    [wlTrackValueChange]="{
      rendering: rendering,
      label: fieldname || '',
      inputLabel: formsControl.value?.toString(),
      sendValue: sendValue,
      hashValue: hashValue,
      trackFocus: false,
      stepname: stepname,
      step: step || 0
    }"
    [id]="formsControl?.id"
    [name]="formsControl?.id"
    [ngrxFormControlState]="formsControl"
    [wlValidationClasses]="showValidation ? formsControl : undefined"
    type="checkbox"
    class="form-check-input"
    wlHasValueClass="has-value"
    [ngrxEnableFocusTracking]="true"
    [wlAutomationId]="formsControl?.id + '-input'"
  />
  <label
    class="form-check-label"
    [for]="formsControl?.id"
    [wlAutomationId]="formsControl?.id"
  >
    {{ label }}
    <ng-content></ng-content>
  </label>
  <wl-tooltip
    class="ms-1"
    *ngIf="tooltip"
    [tooltip]="{ description: tooltip }"
  ></wl-tooltip>
</div>
