<div class="d-block d-sm-inline-block">
  <button
    type="submit"
    wlCSCapture
    [disabled]="isDisabled || submitButtonLoading"
    class="btn btn-primary mt-2 w-100 btn--icon-prefixed submit-button"
    [ngClass]="{ loading: submitButtonLoading }"
    [wlAutomationId]="submitButtonAutomationId"
    (click)="submitProgessiveForm.emit()"
  >
    <ng-container *ngIf="!submitButtonLoading; else submitLoading">
      {{ submitButtonText }}
      <fa-icon
        *ngIf="submitButtonIcon"
        icon="{{ submitButtonIcon }}"
        class="ms-2"
      ></fa-icon>
    </ng-container>
  </button>
</div>
<div *ngIf="isEditingCurrentStep" class="d-block d-sm-inline-block">
  <button
    type="button"
    wlCSCapture
    class="btn btn-link mt-2 w-100"
    (click)="cancelEditingFormStep.emit()"
  >
    {{ 'CancelChanges' | translate }}
  </button>
</div>

<ng-template #submitLoading>
  <fa-icon
    class="icon icon--spinner"
    [spin]="true"
    icon="spinner-third"
  ></fa-icon>
</ng-template>
