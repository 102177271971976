import { createSelector } from '@ngrx/store';
import { isError, isLoading } from '@innogy/utils/state';
import { Status } from '@essent/common';

import { selectTariffsState } from '../../tariffs.state';
import type { CurrentPayload } from '../models';
import { formatDate } from '../utils/format-date.util';

export const selectDynamicPricesState = createSelector(
  selectTariffsState,
  (state) => state.dynamicPrices
);

export const selectDynamicPricesEntities = createSelector(
  selectDynamicPricesState,
  (state) => state.entities
);

export const selectSelectedEnergyType = createSelector(
  selectDynamicPricesState,
  (state) => state.selectedEnergyType
);

export const selectSelectedDate = createSelector(
  selectDynamicPricesState,
  (state) => state.selectedDate
);

export const selectSelectedHour = createSelector(
  selectDynamicPricesState,
  (state) => state.selectedHour
);

export const selectSelectedDynamicPricesActionId = createSelector(
  selectDynamicPricesState,
  (state) => state.selectedActionId
);

export const selectNewDynamicPricesActionId = createSelector(
  selectSelectedDate,
  formatDate
);

export const selectSelectedDynamicPricesEntity = createSelector(
  selectDynamicPricesEntities,
  selectSelectedDynamicPricesActionId,
  (entities, actionId) => entities[actionId]
);

export const selectNewDynamicPricesEntity = createSelector(
  selectDynamicPricesEntities,
  selectNewDynamicPricesActionId,
  (entities, actionId) => entities[actionId]
);

export const selectNewEntityIsSuccess = createSelector(
  selectDynamicPricesEntities,
  selectNewDynamicPricesActionId,
  (entities, newActionId) => entities[newActionId]?.status === Status.SUCCESS
);

export const selectGetDynamicPricesPayload = createSelector(
  selectSelectedEnergyType,
  selectSelectedDate,
  (energyType, date): CurrentPayload => ({
    energyType,
    date,
  })
);

export const selectGetDynamicPricesInfo = createSelector(
  selectNewDynamicPricesActionId,
  selectNewEntityIsSuccess,
  selectGetDynamicPricesPayload,
  (actionId, entityIsSuccess, payload) => ({
    entityIsSuccess,
    payload,
    actionId,
  })
);

export const selectSelectedDynamicPricesEntry = createSelector(
  selectSelectedDynamicPricesEntity,
  selectSelectedEnergyType,
  (entity, energyType) => entity?.entry?.[energyType]
);

export const selectIsDynamicPricesLoading = createSelector(
  selectNewDynamicPricesEntity,
  (entity) => isLoading(entity)
);

export const selectIsNewDynamicPricesEntitySuccess = createSelector(
  selectNewDynamicPricesEntity,
  (entity) => entity?.status === Status.SUCCESS
);

export const selectIsNewDynamicPricesEntityError = createSelector(
  selectNewDynamicPricesEntity,
  (entity) => isError(entity)
);

export const selectIsDynamicPricesError = createSelector(
  selectIsNewDynamicPricesEntityError,
  selectSelectedDynamicPricesEntry,
  (error, entry) => !entry || error
);
