import { Injectable } from '@angular/core';
import { AddressTypes } from '@innogy/customer-details/store';
import type { InsulationProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import {
  getInsulationAddressCheckSuccess,
  postInsulationConfirmation,
  postInsulationConfirmationError,
  postInsulationConfirmationSuccess,
} from '@innogy/eplus/temporary-core-modules';
import { getQueryParameterHistory } from '@innogy/jss';
import { resetProgressiveForm } from '@innogy/shared/progressive-form';
import { selectUrl } from '@innogy/shared/state';
import { mapGenderToSalutation } from '@innogy/utils/deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResetAction } from 'ngrx-forms';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { MarkAsyncTasksAsPendingAction } from '@innogy/progressive-ngrx-forms';

import { selectGetInsulationNewCustomerAddressCheckData } from '../insulation-api';
import { insulationSelectorKey } from '../insulation-form.state';
import {
  clearInsulationFunnelInitializedAction,
  handleInsulationFunnelGenericErrorAction,
  onInsulationFunnelNavigationAction,
  resetInsulationProductSelectionAction,
  selectInsulationFunnelSettings,
} from '../insulation-funnel';
import { hideInsulationProductOverviewAction } from '../insulation-products';
import {
  prefillInsulationOrderFormAddressStateAction,
  resetInsulationOrderFormAction,
} from './insulation-order-form.actions';
import {
  selectInsulationOrderFormAddressFormState,
  selectInsulationOrderFormPersonalDetailsFormState,
} from './insulation-order-form.selectors';
import {
  insulationOrderAddressFormId,
  insulationOrderConfirmationFormId,
  insulationOrderPersonalDetailsFormId,
} from './insulation-order-form.reducer';

@Injectable()
export class InsulationOrderFormEffects {
  funnelSettings$ = this.store$.select(selectInsulationFunnelSettings);
  addressCheckData$ = this.store$.select(
    selectGetInsulationNewCustomerAddressCheckData
  );
  insulationOrderFormPersonalDetails$ = this.store$.select(
    selectInsulationOrderFormPersonalDetailsFormState
  );
  insulationOrderFormAddress$ = this.store$.select(
    selectInsulationOrderFormAddressFormState
  );
  queryParamHistory$ = this.store$.select(getQueryParameterHistory);

  currentUrl$ = this.store$.select(selectUrl);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}

  public readonly prefillInsulationAddressForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInsulationAddressCheckSuccess),
      mergeMap((action) => [
        prefillInsulationOrderFormAddressStateAction({
          payload: {
            addressType: AddressTypes.ADDRESS,
            city: action.payload.address.city,
            houseNumberAddition: action.payload.address.housenumberaddition,
            postalCode: action.payload.address.postalcode,
            communicationNumber: action.payload.address.housenumber,
            street: action.payload.address.street,
          },
        }),
      ])
    )
  );

  public readonly onResetInsulationAddressForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetInsulationOrderFormAction),
      mergeMap(() => [
        new ResetAction(insulationOrderAddressFormId),
        new ResetAction(insulationOrderPersonalDetailsFormId),
      ])
    )
  );

  public readonly onSubmitInsulationOrderForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsyncTasksAsPendingAction>(MarkAsyncTasksAsPendingAction.TYPE),
      filter((action) => action.stepId === insulationOrderConfirmationFormId),
      concatLatestFrom(() => [
        this.funnelSettings$,
        this.insulationOrderFormPersonalDetails$,
        this.insulationOrderFormAddress$,
        this.currentUrl$,
        this.queryParamHistory$,
      ]),
      mergeMap(
        ([
          ,
          funnelSettings,
          personalDetails,
          address,
          currentUrl,
          queryParamHistory,
        ]) => {
          const products = (
            funnelSettings.selectedProducts as InsulationProductWithCalculations[]
          ).map((p) => p.productType.value);
          return [
            postInsulationConfirmation({
              payload: {
                salutation:
                  mapGenderToSalutation(personalDetails.value.salutation) || '',
                initials: personalDetails.value.initials,
                lastNamePrefix: personalDetails.value.prefix,
                lastName: personalDetails.value.lastName,
                emailAddress: personalDetails.value.emailAddress,
                street: address.value.street,
                postalCode: address.value.postalCode,
                houseNumber:
                  address.value.communicationNumber?.toString() || '',
                houseNumberSuffix: address.value.houseNumberAddition,
                city: address.value.city,
                typeOfContract: 'Koop',
                telephoneNumber: personalDetails.value.phoneNumber,
                referrer: currentUrl,
                products,
                leadTracking: 'DirectOther',
                e2eTrackingId: funnelSettings.trackingId ?? '',
                gclid: queryParamHistory.gclid ?? '',
              },
            }),
          ];
        }
      )
    )
  );

  public readonly onSubmitInsulationOrderFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postInsulationConfirmationSuccess),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, funnelSettings]) => !!funnelSettings.successPage),
      mergeMap(([, funnelSettings]) => [
        onInsulationFunnelNavigationAction({
          page: funnelSettings.successPage,
        }),
      ])
    )
  );

  public readonly resetInsulationOrderFormAndProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([{ payload }, funnelSettings]) =>
          payload.routerState.url === funnelSettings.successPage?.href
      ),
      // Even though we wait for navigated events, apparently
      // an extra delay is needed in order to prevent firing tool-step events
      // after form rest on the former page.
      // .5s is chosen arbitrarily, yet seems to work even under 3g conditions.
      delay(500),
      mergeMap(() => [
        resetInsulationProductSelectionAction(),
        hideInsulationProductOverviewAction(),
        resetInsulationOrderFormAction(),
        clearInsulationFunnelInitializedAction(),
        resetProgressiveForm({ formId: insulationSelectorKey }),
      ])
    )
  );

  public readonly onSubmitInsulationOrderFormError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postInsulationConfirmationError),
      tap(({ payload }) => {
        console.error('Error while submitting insulation order', payload.error);
      }),
      map(handleInsulationFunnelGenericErrorAction)
    )
  );
}
