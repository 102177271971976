<ng-container *ngIf="navItem | navItemToJssRoute : language as route">
  <ng-container
    *ngIf="navItem?.path; then itemWithLink; else itemNoLink"
  ></ng-container>

  <ng-template #itemWithLink>
    <a
      class="nav-item nav-link"
      (click)="clicked.emit(navItem)"
      [title]="navItem?.name"
      *wlGenericLink="route"
      wlCSCapture
    >
      {{ navItem?.name }}
    </a>
  </ng-template>
  <ng-template #itemNoLink>
    <a
      class="nav-item nav-link"
      (click)="clicked.emit(navItem)"
      [title]="navItem?.name"
    >
      {{ navItem?.name }}
    </a>
  </ng-template>
</ng-container>
