import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { EnergyType } from '@essent/new-customer';
import { getYearlyCostsLabel } from '@innogy/become-a-customer/shared';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import type { EnvConfigBrand } from '@innogy/config/models';
import { EnvironmentConfig } from '@innogy/config/models';

import { CommodityOfferLabels } from '../../../commodity-offers.model';

@Component({
  selector: 'essent-sme-commodity-product-breakdown',
  templateUrl: './sme-commodity-product-breakdown.component.html',
  styleUrls: ['./sme-commodity-product-breakdown.component.essent.scss'],
})
export class SMECommodityOfferEssentProductBreakdownComponent
  implements OnInit
{
  @Input() offer?: Offer;
  @Input() content?: CommodityOfferLabels;
  @Input() showMemberGetMember = false;
  public energyType = EnergyType;

  brand?: EnvConfigBrand;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig
  ) {}
  ngOnInit() {
    this.brand = this.envConfig.brand;
  }

  selectCommodity(offer: Offer, energyType: EnergyType) {
    return offer.offerOverviews.find(
      (offerOverview) => offerOverview.energyType === energyType
    );
  }

  yearlyCostsLabel(offer: Offer) {
    return getYearlyCostsLabel(
      this.content?.yearlyCostsTotalWithIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalWithoutIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalMultiYearWithIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalMultiYearWithoutIncentiveLabel ?? '',
      offer
    );
  }
}
