import { NgModule } from '@angular/core';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { formStepReducer } from './+state';
import { ProgressiveFormEffects } from './+state/progressive-form.effects';
import { progressiveFormReducer } from './+state/progressive-form.reducer';
import { formStepFeatureKey } from './+state/progressive-form-step';
import { progressiveDisclosureKey } from './+state/progressive-form.selectors';
import { ProgressiveFormStepConfigEffects } from './+state/progressive-form-step-config.effects';

/**
 * @deprecated - please use the `ProgressiveNGRXFormsModule` instead.
 * For information on how to migrate, see `forms/progressive ngrx forms` in the application docs.
 */
@NgModule({
  imports: [
    CommonUiFormsModule,
    EffectsModule.forFeature([
      ProgressiveFormEffects,
      ProgressiveFormStepConfigEffects,
    ]),
    StoreModule.forFeature(progressiveDisclosureKey, progressiveFormReducer),
    StoreModule.forFeature(formStepFeatureKey, formStepReducer),
    UtilsContentsquareModule,
  ],
  declarations: [],
})
export class ProgressiveFormModule {}
