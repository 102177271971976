import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BecomeACustomerSharedModule } from '@innogy/become-a-customer/shared';
import { BecomeACustomerUiModule } from '@innogy/become-a-customer/ui';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { GenericModalModule } from '@innogy/common-ui/modals';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { UtilsModule } from '@innogy/utils/deprecated';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { CommonUiProgressiveFormsModule } from '@innogy/common-ui/progressive-forms';

import { OrderContainerComponent } from './order-container/order-container.component';
import { orderFulfillmentComponents } from './order-fulfillment-container/order-fulfillment-steps';
import { BaseOrderOverviewComponent } from './order-overview/base-order-overview/base-order-overview.component';
import { OfferOverviewBudgetBillComponent } from './order-overview/order-overview-ed/offer-overview-budget-bill/offer-overview-budget-bill.component';
import { OrderOverviewCostComponent as OrderOverviewCostComponentEd } from './order-overview/order-overview-ed/order-overview-cost/order-overview-cost.component';
import { OrderOverviewEdComponent } from './order-overview/order-overview-ed/order-overview-ed.component';
import { OrderOverviewBudgetBillCostComponent } from './order-overview/order-overview-essent/order-overview-budget-bill-cost/order-overview-budget-bill-cost.component';
import { OrderOverviewEssentComponent } from './order-overview/order-overview-essent/order-overview-essent.component';
import { OrderOverviewExpectedCostComponent } from './order-overview/order-overview-essent/order-overview-expected-cost/order-overview-expected-cost.component';
import { OrderOverviewHeaderComponent } from './order-overview/order-overview-essent/order-overview-header/order-overview-header.component';
import { OfferOverviewChangeOfferComponent } from './order-overview/order-overview-shared/offer-overview-change-offer/offer-overview-change-offer.component';
import { OrderOverviewBudgetBillComponent } from './order-overview/order-overview-shared/order-overview-budget-bill/order-overview-budget-bill.component';
import { OrderOverviewConsumptionComponent } from './order-overview/order-overview-shared/order-overview-consumption/order-overview-consumption.component';
import { OrderOverviewCostComponent } from './order-overview/order-overview-shared/order-overview-cost/order-overview-cost.component';
import { OrderOverviewSmeComponent } from './order-overview/order-overview-sme/order-overview-sme.component';
import { OrderOverviewStickerComponent } from './order-overview/order-overview-sme/order-overview-sticker/order-overview-sticker.component';
import { OrderFulfillmentInswitchInhouseComponent } from './order-fulfillment-container/order-fulfillment-steps/order-fulfillment-address/order-fulfillment-inswitch-inhouse/order-fulfillment-inswitch-inhouse.component';

@NgModule({
  imports: [
    CommonModule,
    JssModule,
    UtilsModule,
    FontAwesomeModule,
    CommonUiUtilityComponentsModule,
    CommonUiSharedModule,
    CoreAnalyticsModule,
    TranslateModule,
    BecomeACustomerSharedModule,
    CommonUiFormsModule,
    NgbCollapseModule,
    BecomeACustomerUiModule,
    GenericModalModule,
    UtilsContentsquareModule,
    CommonUiProgressiveFormsModule,
  ],
  declarations: [
    OfferOverviewBudgetBillComponent,
    OfferOverviewChangeOfferComponent,
    OrderContainerComponent,
    OrderOverviewConsumptionComponent,
    OrderOverviewCostComponentEd,
    OrderOverviewCostComponent,
    OrderOverviewEdComponent,
    OrderOverviewSmeComponent,
    OrderOverviewEssentComponent,
    OrderOverviewHeaderComponent,
    OrderOverviewBudgetBillCostComponent,
    OrderOverviewExpectedCostComponent,
    OrderOverviewBudgetBillComponent,
    OrderOverviewStickerComponent,
    ...orderFulfillmentComponents,
    BaseOrderOverviewComponent,
    OrderFulfillmentInswitchInhouseComponent,
  ],
  exports: [
    OrderContainerComponent,
    OrderOverviewEdComponent,
    OrderOverviewEssentComponent,
    OrderOverviewSmeComponent,
  ],
})
export class BecomeACustomerOrderModule {}
