import type { BaseSourceProperties } from '@innogy/jss/models';
import { generateFormIdFromUUID } from '@innogy/shared/forms';
import type {
  ScForm,
  ScFormFormGroupState,
  ScInputField,
} from '@innogy/sitecore-forms/models';
import type { Store } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';
import type {
  AbstractControlState,
  Boxed,
  FormGroupControls,
} from 'ngrx-forms';
import { isBoxed, unbox } from 'ngrx-forms';

import type { ScFormState } from '../create-generic-form-reducer';
import { sitecoreFormsKey } from '../create-generic-form-reducer';
import { controlNameForInput } from '../form-values';

/**
 * Transforms a global form Control ID to a local form control id.
 * @param globalControlId the global form control id. e.g. `formName.controlName`
 * @returns the local form control id: `controlName`.
 */
export const localFormControlId = (globalControlId: string) => {
  const fragments = globalControlId.split('.');
  if (fragments.length > 1) {
    fragments.shift();
    return fragments.join('.');
  }
  return fragments[0];
};

/**
 * Helper function that return the input provided via rendering which is associated with the provided abstractControl.
 * @param control AbstractControl extracted from a generic form's FormState.
 * @param form Generic Form (ScForm) provided via rendering.
 * @returns input provided via rendering which is associated with the provided abstractControl.
 */
export const inputForControl = (
  control: AbstractControlState<any>,
  form: ScForm
) =>
  form.fields.Inputs.find(
    (input) =>
      controlNameForInput(input.fields) === localFormControlId(control.id)
  );

/**
 * Helper function that finds and returns the unboxed value of the form control associated with the provided ScInputField
 * @param field ScInputField provided via rendering
 * @param formControls form controls encapsulated in the Generic Form's formState.
 * @returns unboxed value of the form control associated with the provided ScInputField
 */
export function controlValueForField(
  field: BaseSourceProperties &
    Item & {
      fields: ScInputField;
    },
  formControls: FormGroupControls<ScFormFormGroupState>
) {
  const controlName = controlNameForInput(field.fields);

  return parseControlValue(formControls[controlName]?.value);
}

/**
 * Shorthand used to parse form control values within the generic form context.
 * @param value form control value
 * @returns unboxed value, if applicable
 */
export function parseControlValue(value: Boxed<any> | any) {
  return isBoxed(value) ? unbox(value) : value;
}

/**
 * given the form UUID, returns a selector representing that form's slice of state
 * @param formUUID UUID of the form to select from the store
 * @param store$ NGRX Store
 * @returns a selector instance and a observable to subscribe to
 */
export function selectFormStateForForm(formUUID: string, store$: Store) {
  const formId = generateFormIdFromUUID(formUUID);
  const selector = createFeatureSelector<ScFormState>(
    `${sitecoreFormsKey}-${formId}`
  );
  return {
    selector,
    asObservable: store$.select(selector),
  };
}
