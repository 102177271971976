import type { InfoCardType } from '@innogy/common-ui/models';
import { createAction, props } from '@ngrx/store';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';

import { contractingActionPrefix } from '../../contstants';
import type { QuotationReactionMessage } from '../../interfaces';

export const sendQuotationReactionAction = createAction(
  `${contractingActionPrefix} Send quotation reaction`,
  props<{ token: string }>()
);

export const quotationReactionNoTokenErrorAction = createAction(
  `${contractingActionPrefix} No token provided error`
);

export const navigateToPageAction = createAction(
  `${contractingActionPrefix} Navigate to page`,
  props<{ page: LinkField }>()
);

export const setQuotationReactionMessageAction = createAction(
  `${contractingActionPrefix} Set quotation reaction message`,
  props<{
    message: QuotationReactionMessage;
    messageType?: InfoCardType;
  }>()
);
