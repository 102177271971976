<div class="d-flex justify-content-between mb-1">
  <div class="fw-bold">
    {{ tariffModalDescriptionLabel }}
  </div>
  <button
    class="btn p-0 border-0 btn-link text-end"
    (click)="viewTariffs.emit()"
    wlAutomationId="offer-trigger-aom-button"
    wlTrackLink
    [trackLinkInfo]="{
      rendering: { componentName: 'contractdetail' },
      field: 'toon prijs samenstelling',
      eventType: 'link-click'
    }"
  >
    {{ tariffModalLinkTextLabel }}
  </button>
</div>
