import { Component, inject } from '@angular/core';
import { UtilsEnvironmentModule } from '@innogy/utils/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wl-cookiewall-variant-b',
  standalone: true,
  imports: [UtilsEnvironmentModule],
  templateUrl: './cookiewall-variant-b.component.html',
  styleUrls: ['./cookiewall-variant-b.component.scss'],
})
export class CookiewallVariantBComponent {
  modal = inject(NgbActiveModal);

  accept() {
    this.modal.close();
  }

  reject() {
    this.modal.dismiss();
  }
}
