import { Component } from '@angular/core';
import { openModalFromFunnelSettingsAction } from '@innogy/become-a-customer/state';
import { GenericModalSources } from '@innogy/common-ui/shared/interfaces';
import { getBrandLogoLink, getShowMinimalNavigationHelp } from '@innogy/jss';
import { getEcmpHeader } from '@innogy/navigation/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'wl-minimal-header-container',
  template: '',
})
export class MinimalHeaderContainerComponent {
  constructor(readonly store$: Store<any>) {}

  readonly showHelp$ = this.store$.select(getShowMinimalNavigationHelp);
  readonly ecmpHeader$ = this.store$.select(getEcmpHeader);
  readonly brandLogoLink$ = this.store$.select(getBrandLogoLink);

  dispatchHelpAction() {
    this.store$.dispatch(
      openModalFromFunnelSettingsAction({
        source: GenericModalSources.CHATBOT,
      })
    );
  }

  onLogoClicked() {
    this.store$.dispatch(
      openModalFromFunnelSettingsAction({
        source: GenericModalSources.EXIT_FUNNEL,
      })
    );
  }
}
