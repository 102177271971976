<ngb-accordion
  wlTrackImpression="accordion-impression"
  [trackImpressionInfo]="{
    componentName: title || ''
  }"
  wlTrackAccordion
  [trackAccordionInfo]="{
    subject: 'Totaal verwachte kosten per jaar',
    name: title || '',
    placement: rendering
  }"
>
  <ngb-panel
    id="{{ title || 'unknown' }}-accordion-0"
    cardClass="accordion-item--dark-header mb-1"
  >
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="accordion-button">
        <wl-partial-accordion-title
          [icon]="faIcon"
          [title]="title"
          [after]="
            priceOverview?.totalPrice?.expectedYearlyAmount
              | currency : 'EUR' : 'symbol' : '1.2'
          "
        ></wl-partial-accordion-title>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <ngb-accordion class="m-0">
        <ngb-panel
          cardClass="accordion-item--light-header nested-accordion"
          *ngFor="
            let pricegroup of priceOverview?.priceGroups;
            let i = index;
            trackBy: trackByPricegroup
          "
          class="m-0"
          wlTrackImpression="accordion-impression"
          [trackImpressionInfo]="{
            componentName: pricegroup.description
          }"
          wlTrackAccordion
          [trackAccordionInfo]="{
            subject: title || '',
            name: pricegroup.description,
            placement: rendering
          }"
        >
          <ng-template ngbPanelHeader>
            <button ngbPanelToggle class="accordion-button">
              <wl-partial-accordion-title
                [title]="pricegroup.description"
                [after]="
                  pricegroup?.totalPrice?.expectedYearlyAmount
                    | currency : 'EUR' : 'symbol' : '1.2'
                "
              ></wl-partial-accordion-title>
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <div
              class="p-2 d-inline-flex w-100"
              *ngFor="let price of pricegroup.prices; trackBy: trackByPrice"
            >
              <div class="me-2">
                <p class="m-0">
                  {{ price.description }}
                </p>
                <p class="m-0 text-muted">
                  {{ calculateYearlyUnitAmount(price) }}
                  {{ price.unit }} x
                  {{ price.unitPrice | currency : 'EUR' : 'symbol' : '1.5' }}
                </p>
              </div>
              <div class="ms-auto">
                {{
                  price.expectedPeriodAmount
                    | currency : 'EUR' : 'symbol' : '1.2'
                }}
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
