import {
  isCleared,
  isInDeferral,
  isOnDue,
  isPastDue,
  isPromiseToPay,
} from './invoice-helper';
import type { CombinedFinancialItem } from './invoice.model';
import { InvoicePaymentStatus } from './invoice.model';

export function invoiceToPaymentStatus(
  financialItem?: CombinedFinancialItem
): InvoicePaymentStatus {
  if (financialItem == null) {
    return InvoicePaymentStatus.NONE;
  }

  if (isInDeferral(financialItem)) {
    return InvoicePaymentStatus.DUE;
  } else if (isPromiseToPay(financialItem)) {
    return InvoicePaymentStatus.PROMISE_TO_PAY;
  } else if (isCleared(financialItem)) {
    return InvoicePaymentStatus.PAID;
  } else if (isPastDue(financialItem)) {
    return InvoicePaymentStatus.PAST_DUE;
  } else if (isOnDue(financialItem)) {
    return InvoicePaymentStatus.DUE;
  }
  return InvoicePaymentStatus.NONE;
}
