import { Injectable, Injector } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { Store } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { take, tap } from 'rxjs/operators';

import { getSiteContext } from '../shared/page-name';
import type { TrackFeedbackInfo } from './track-feedback.model';

export type FeedbackType =
  | 'faq'
  | 'contentblock'
  | 'searchquery'
  | 'page'
  | 'directresult'
  | 'cmssearch'
  | 'faqresults';

@Injectable({
  providedIn: 'root',
})
export class TrackFeedbackService {
  public trackFeedback(
    componentName: string,
    trackFeedbackInfo: TrackFeedbackInfo
  ) {
    const config = this.injector.get(ENVIRONMENT_CONFIG);
    return getSiteContext(this.store$, config).pipe(
      tap((context) => {
        const placement = `${context}|${componentName}`.toLowerCase();
        this.angulartics.eventTrack.next({
          action: 'feedback-click',
          properties: {
            placement,
            ...trackFeedbackInfo,
          },
        });
      }),
      take(1)
    );
  }

  constructor(
    private readonly angulartics: Angulartics2,
    private readonly store$: Store<any>,
    private readonly injector: Injector
  ) {}
}
