<nav class="nav flex-column" *ngIf="items !== null">
  <div
    *ngIf="showCategories"
    class="d-flex flex-row mb-n1"
    [ngClass]="{
      'mx-1 justify-content-start': topNavigation,
      'justify-content-between': !topNavigation
    }"
  >
    <ng-container *ngFor="let category of items; let last = last">
      <ng-container *ngIf="category.active">
        <span
          [ngClass]="{
            'me-1': !last
          }"
          class="sidebar-nav-category"
          wlAutomationId="header-tools-navigation-category-{{
            category?.category
          }}"
          >{{ category.name }}</span
        >
      </ng-container>
      <ng-container *ngIf="!category.active">
        <a
          [ngClass]="{
            'me-1': !last
          }"
          class="p-2 pb-3 nav-link__category"
          (click)="navItemClick.emit(category)"
          *wlGenericLink="
            category.path;
            extras: {
              queryParamsHandling: 'merge'
            }
          "
          wlAutomationId="header-tools-navigation-category-{{
            category?.category
          }}"
          [title]="category.name"
          >{{ category.name }}</a
        >
      </ng-container>
    </ng-container>
  </div>
  <h6
    *ngIf="!showCategories && title"
    class="d-block mb-0 py-1 py-lg-2 nav__title"
  >
    {{ title }}
  </h6>

  <div
    [ngClass]="{
      'rounded bg-white': showCategories
    }"
  >
    <ng-container *ngFor="let category of items">
      <ng-container *ngIf="category.active">
        <ng-container *ngFor="let item of category.children; let last = last">
          <ng-container>
            <div class="nav-link__wrapper">
              <a
                class="nav-link"
                wlAutomationId="header-tools-navigation-item-{{ item?.name }}"
                [wlTrackLink]="item.name"
                [trackLinkInfo]="{
                  rendering: componentData,
                  field: item.name,
                  eventType: 'navigation-click'
                }"
                (click)="navItemClick.emit(item)"
                *wlGenericLink="
                  item.path;
                  extras: {
                    queryParamsHandling: 'merge'
                  }
                "
                [title]="item.name"
              >
                <wl-partial-list-item
                  [iconTrailing]="'chevron-right'"
                  [label]="item.name"
                  [active]="item.active"
                >
                </wl-partial-list-item>
              </a>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</nav>
