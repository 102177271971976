import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@innogy/config';
import type { Observable } from 'rxjs';

import type { InsulationNewCustomerConfirmationRequestBodyV1 } from '../../models';

@Injectable()
export class PostInsulationConfirmationService
  implements BaseService<InsulationNewCustomerConfirmationRequestBodyV1, void>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${
    this.apiUrl || ''
  }/eplus/insulation/new-customer/confirmation/v${this.apiVersion}`;

  public constructor(
    @Optional()
    @Inject(API_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(
    payload: InsulationNewCustomerConfirmationRequestBodyV1
  ): Observable<void> {
    return this.httpClient.post<void>(this.endpoint, payload);
  }
}
