import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@innogy/config';
import { CoreAnalyticsModule } from '@innogy/core/analytics';

import { EnvironmentClassesComponent } from './environment-classes/environment-classes.component';
import { SetupAnchorScrollingComponent } from './setup-anchor-scrolling/setup-anchor-scrolling.component';
import { VisitorIdentificationComponent } from './sitecore-identification/sitecore-identification.component';
import { StableMeasureComponent } from './stable-measure/stable-measure.component';

const exportedComponents = [
  EnvironmentClassesComponent,
  StableMeasureComponent,
  SetupAnchorScrollingComponent,
  VisitorIdentificationComponent,
];

@NgModule({
  imports: [ConfigModule, CommonModule, CoreAnalyticsModule],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
})
export class InitializeModule {}
