import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { DateInputFormatterPipe, UtilsModule } from '@innogy/utils/deprecated';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { NgrxFormsModule } from 'ngrx-forms';

import { AddressFormComponent } from './address-form/address-form.component';
import { ButtonContainerComponent } from './button/button-container/button-container.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxSetComponent } from './checkbox-set/checkbox-set.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EmailComponent } from './email/email.component';
import { DragAndDropDirective } from './file-input/drag-and-drop/drag-and-drop.directive';
import { FileInputComponent } from './file-input/file-input/file-input.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { InputGroupComponent } from './input-group/input-group.component';
import { NameFormComponent } from './name-form/name-form.component';
import { NumberComponent } from './number/number.component';
import { PhoneComponent } from './phone/phone.component';
import { PostalAddressFormComponent } from './postal-address-form/postal-address-form.component';
import { RadioComponent } from './radio/radio.component';
import { TextComponent } from './text/text.component';
import { ToggleWithInfoComponent } from './toggle-with-info/toggle-with-info.component';
import { ToggleComponent } from './toggle/toggle.component';
import { RadioBorderedComponent } from './radio-bordered/radio-bordered.component';

const exportedComponents = [
  ButtonComponent,
  ButtonContainerComponent,
  DropdownComponent,
  InputErrorComponent,
  ToggleComponent,
  ToggleWithInfoComponent,
  DatepickerComponent,
  DateInputComponent,
  TextComponent,
  NumberComponent,
  RadioComponent,
  CheckboxComponent,
  EmailComponent,
  PhoneComponent,
  FileInputComponent,
  DragAndDropDirective,
  AddressFormComponent,
  PostalAddressFormComponent,
  NameFormComponent,
  CheckboxSetComponent,
  InputGroupComponent,
  RadioBorderedComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    FontAwesomeModule,
    JssModule,
    FormsModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    CoreExperienceEditorModule,
    CoreAnalyticsModule,
    NgrxFormsModule,
    TranslateModule,
    CommonUiUtilityComponentsModule,
    UtilsContentsquareModule,
  ],
  declarations: [...exportedComponents],
  providers: [DatePipe, DateInputFormatterPipe],
  exports: [...exportedComponents],
})
export class CommonUiFormsModule {}
