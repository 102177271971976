<div *ngIf="showAddressTypeSelector" class="mb-2">
  <wl-radio
    [formsControl]="formState.controls.addressType"
    [radioItems]="radioItems"
    [rendering]="rendering"
    [inline]="true"
    fieldname="address-type"
  ></wl-radio>
</div>
<div class="address-form__card">
  <wl-postal-address-form
    [rendering]="$any(rendering)"
    [content]="content"
    [trackStep]="trackStep"
    [formState]="formState"
    [floatingLabels]="floatingLabels"
  ></wl-postal-address-form>
</div>
<ng-container *ngIf="showAddressNotFoundError()">
  <div class="mb-1">
    <wl-info-card
      [title]="getAddressNotFoundErrorTitle()"
      [message]="getAddressNotFoundErrorMessage()"
      [border]="true"
      [shadow]="false"
      [automationId]="automationId + '-address-not-found'"
    ></wl-info-card>
  </div>
</ng-container>
<ng-container *ngIf="formState.isValidationPending">
  <fa-icon size="lg" icon="spinner-third" [spin]="true"></fa-icon>
</ng-container>
<ng-container *ngIf="streetAndCityInputsVisible()">
  <div
    class="row address-form__card"
    *ngIf="formState.value.addressType === AddressType.ADDRESS"
  >
    <div class="col-12">
      <wl-text
        [rendering]="rendering"
        [formsControl]="formState.controls.street"
        [label]="content.StreetLabel"
        [placeholder]="content.StreetPlaceholder"
        [floatingLabel]="floatingLabels"
        [sendValue]="sendValues"
        [hashValue]="hashValues"
        [skipDirtyCheck]="true"
        updateOn="blur"
        fieldname="street"
      >
        <ng-container *ngIf="formState.controls.street.isTouched">
          <wl-input-error
            *ngIf="formState.controls.street.errors.required"
            [automationId]="automationId + '-street-required-error'"
            [rendering]="rendering"
            [label]="content.StreetRequiredError"
          ></wl-input-error>
        </ng-container>
      </wl-text>
    </div>
  </div>
  <div class="row address-form__card">
    <div class="col-12">
      <wl-text
        [rendering]="rendering"
        [formsControl]="formState.controls.city"
        [label]="content.CityLabel"
        [placeholder]="content.CityPlaceholder"
        [floatingLabel]="floatingLabels"
        [sendValue]="sendValues"
        [hashValue]="hashValues"
        [skipDirtyCheck]="true"
        updateOn="blur"
        fieldname="city"
      >
        <ng-container *ngIf="formState.controls.city.isTouched">
          <wl-input-error
            *ngIf="formState.controls.city.errors.required"
            [automationId]="automationId + '-city-required-error'"
            [rendering]="rendering"
            [label]="content.CityRequiredError"
          ></wl-input-error>
        </ng-container>
      </wl-text>
    </div>
  </div>
</ng-container>
