<!-- Desktop view -->
<header class="d-none d-lg-block border-bottom">
  <wl-top-navigation
    [navItems]="topNavigationItems"
    [openNavigation]="openNavigation"
    [serviceNavigationActive]="isServiceNavigationAvailable"
    [serviceNavigationLink]="serviceNavigationLink"
    [serviceNavigationTitle]="serviceNavigationTitle"
    [isLoggedIn]="isLoggedIn"
    [loginUrlLabel]="loginUrlLabel"
    [loginUrl]="loginUrl"
  >
  </wl-top-navigation>

  <wl-navbar
    [brandLogoLinkUrl]="brandLogoLinkUrl"
    [navItems]="originalNavItems"
    [language]="language"
  ></wl-navbar>
</header>

<!-- Mobile view -->
<header class="d-block d-lg-none">
  <div
    class="navbar p-2 border-bottom"
    [ngClass]="{
      'border-bottom': showCategories
    }"
  >
    <div class="nav-item">
      <a
        class="navbar-logo"
        *wlGenericLink="
          { value: { href: brandLogoLinkUrl || '/' } };
          extras: { queryParamsHandling: 'preserve' }
        "
      >
        <img [src]="'assets/logo.svg?v=2' | deployUrl" alt="logo" />
      </a>
    </div>
    <div class="nav-item">
      <wl-off-canvas
        direction="right-to-left"
        wlAutomationId="serviceNavigation"
        [openButtonTitle]="
          isServiceNavigationAvailable ? serviceNavigationTitle : 'Menu'
        "
        [title]="serviceNavigationTitle ?? 'Menu'"
        icon="bars"
        [backgroundColor]="showCategories ? 'light' : 'white'"
        [showToggleIcon]="true"
        [showTitleCollapsed]="true"
        [(open)]="isNavigationOpen"
        (openChange)="onOpenChange($event)"
        (back)="previousNavItem()"
        [showBackButton]="inSubNavigation"
      >
        <ng-container
          *ngIf="isMainNavigationAvailable && currentNavItems.length > 0"
        >
          <nav
            class="flex-column"
            aria-label="Menu"
            wlAutomationId="mainNavigation"
            wlCSCapture
          >
            <wl-drilldown-nav
              (itemSelected)="selectedNavItem($event)"
              [items]="currentNavItems"
            ></wl-drilldown-nav>
          </nav>
        </ng-container>
        <div class="mt-3" *ngIf="!inSubNavigation">
          <wl-header-tools-container
            [loginUrl]="loginUrl || serviceNavigationLink?.value?.href"
            [loginUrlLabel]="loginUrlLabel"
            (navItemClick)="isNavigationOpen = false"
          >
          </wl-header-tools-container>
          <ng-container *ngIf="topNavigationItems.length > 0">
            <h5
              *ngIf="showMainNavigationTitle"
              class="ms-2 mb-1 fw-bold"
              wlCSCapture
            >
              {{ mainNavigationTitle }}
            </h5>
            <ng-container *ngIf="isTopNavigationAvailable">
              <nav
                aria-label="Main"
                wlAutomationId="topNavigation"
                class="d-flex justify-content-start ms-2 mb-2"
              >
                <ng-container *ngFor="let navItem of topNavigationItems">
                  <a
                    *wlGenericLink="{ value: { href: navItem.path } }"
                    class="nav-link px-0 me-1 small text-secondary"
                    [class.text-brand]="navItem.active"
                    wlCSCapture
                  >
                    {{ navItem.name }}
                  </a>
                </ng-container>
              </nav>
            </ng-container>
          </ng-container>
        </div>

        <div
          class="mt-6"
          *ngIf="openNavigation && openNavigation.value && openNavigation.label"
        >
          <a
            class="open-link"
            wlTrackLink
            [trackLinkInfo]="{
              rendering: { componentName: { value: 'top-navigation' } },
              field: openNavigation.value,
              eventType: 'navigation-click'
            }"
            *wlGenericLink="openNavigation.value"
            wlCSCapture
          >
            {{ openNavigation.label }}
          </a>
        </div>
      </wl-off-canvas>
    </div>
  </div>
</header>
