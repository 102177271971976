import {
  getPrices,
  getPricesClear,
  getPricesError,
  getPricesSuccess,
} from '@essent/billing';
import type { CollectionStateType } from '@innogy/utils/state';
import { createCollectionStateReducer } from '@innogy/utils/state';

export const { initialState, reducer } = createCollectionStateReducer({
  getAction: getPrices,
  clearAction: getPricesClear,
  successAction: getPricesSuccess,
  errorAction: getPricesError,
});

export type State = CollectionStateType<typeof reducer>;
