<div wlCSMask>
  <div [id]="selector">
    <fa-icon
      class="text-brand"
      size="3x"
      icon="spinner-third"
      [spin]="true"
    ></fa-icon>
  </div>
</div>
