import { Component, Input } from '@angular/core';
@Component({
  selector: 'ed-commodity-offer-header',
  templateUrl: './commodity-offer-header.component.html',
  styleUrls: ['./commodity-offer-header.component.ed.scss'],
})
export class CommodityOfferEDHeaderComponent {
  @Input() duration?: string;
  @Input() productTitle?: string;
  @Input() productSubTitle?: string;
  @Input() price?: number;
  @Input() priceDurationLabel?: string;
  @Input() incentive?: string;
  @Input() highlight?: string;
  @Input() showPriceTag?: boolean = true;
}
