<wl-experience-editor>
  <ng-template wlLive>
    <wl-accordion
      [accordions]="accordions"
      [pageTitle]="pageTitle$ | async"
      [rendering]="rendering"
      wlCSCapture
    ></wl-accordion>
  </ng-template>

  <ng-template wlPreview>
    <div class="mb-3">
      <h4 class="accordion__title" *scText="rendering?.fields?.title"></h4>
      <ngb-accordion
        [closeOthers]="false"
        [destroyOnHide]="false"
        id="accordion"
        class="mb-1"
        [ngClass]="accordions?.class"
      >
        <ngb-panel *ngFor="let item of rendering?.fields?.items">
          <ng-template ngbPanelTitle>
            <wl-rich-text-wrapper
              [field]="item?.fields?.question"
              [rendering]="rendering"
            >
              <span></span>
            </wl-rich-text-wrapper>
            <fa-icon
              class="icon icon--chevron h-auto"
              size="md"
              icon="chevron-up"
            ></fa-icon>
          </ng-template>
          <ng-template ngbPanelContent>
            <wl-rich-text-wrapper
              [field]="item?.fields?.answer"
              [rendering]="rendering"
            >
              <div class="accordion__content"></div>
            </wl-rich-text-wrapper>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </ng-template>
</wl-experience-editor>
