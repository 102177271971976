import { Component, Input } from '@angular/core';
import { AnalyticsActionTypes } from '@innogy/core/analytics';
import { InnogyComponentRendering } from '@innogy/jss/models';
import type { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent {
  @Input()
  public messageDescription?: TextField | string;

  @Input()
  public messageField?: TextField | string;

  @Input()
  public rendering?: InnogyComponentRendering;

  @Input()
  public label?: string;

  @Input()
  public errorType: AnalyticsActionTypes = AnalyticsActionTypes.USER_ERROR;
  @Input()
  public automationId?: string;

  @Input() toolName?: string;
  @Input() stepname?: string;
  @Input() step?: number;

  get description() {
    if (typeof this.messageDescription === 'string') {
      return this.messageDescription;
    } else {
      return this.messageDescription?.value;
    }
  }

  get field() {
    if (typeof this.messageField === 'string') {
      return this.messageField;
    } else {
      return this.messageField?.value;
    }
  }
}
