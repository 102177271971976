import { Component, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { ImageFieldValue } from '@innogy/jss/models';

@Component({
  selector: 'wl-order-overview-sticker',
  templateUrl: './order-overview-sticker.component.html',
  styleUrls: ['./order-overview-sticker.component.scss'],
})
export class OrderOverviewStickerComponent {
  @Input() stickerImage?: ImageFieldValue;
  @Input() offer?: Offer;
  @Input() rendering?: ComponentRendering;

  get showSticker() {
    return this.offer?.incentiveImage != null || this.stickerImage != null;
  }
}
