import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import type { JssNavItem } from '@innogy/jss/models';
import { JssRouteBuilderService } from '@innogy/jss';
import type { RouteItem } from '@innogy/navigation/store';
import { Store } from '@ngrx/store';
import type { State } from '@innogy/shared/state';
import { logoutChatAndRedirect } from '@innogy/core/chatting';
import { EnvironmentConfig } from '@innogy/config/models';
import { ENVIRONMENT_CONFIG } from '@innogy/config';

@Component({
  selector: 'wl-header-tools',
  templateUrl: './header-tools.component.html',
  styleUrls: [
    './header-tools.component.essent.scss',
    './header-tools.component.ed.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderToolsComponent {
  @Input()
  language?: string;

  @Input()
  navItems: RouteItem[] = [];

  @Input()
  title?: string;

  @Input()
  isLoggedIn?: boolean;

  @Input()
  hasMultipleAccounts?: boolean;

  @Input()
  loginButtonText?: string;

  @Input()
  loginUrl?: string;

  @Input()
  loginUrlLabel?: string;

  @Input()
  fullName?: string;

  @Input()
  logOutButtonText?: string;

  @Input()
  logoutEndpointUrl?: string;

  @Input()
  switchAccountButtonText?: string;

  @Input()
  switchAccountEndpointUrl?: string;

  @Output()
  navItemClick = new EventEmitter<JssNavItem>();

  @Input()
  showCategories?: boolean;

  constructor(
    @Inject(ENVIRONMENT_CONFIG)
    private readonly config: EnvironmentConfig,
    private readonly urlBuilder: JssRouteBuilderService,
    readonly router: Router,
    private readonly store$: Store<State>
  ) {}

  getRouteUrl(navItem: JssNavItem, language: string) {
    return this.urlBuilder.createRouteData(navItem, language);
  }

  logout(): void {
    this.store$.dispatch(
      logoutChatAndRedirect({
        payload: {
          redirect_url: this.logoutEndpointUrl as string,
          chatEnabled: this.config.iAdvizeChatbot.enabled,
        },
      })
    );
  }
}
