import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ScrollService } from '@innogy/utils/deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, filter, map, tap } from 'rxjs/operators';

import {
  navigateToOrderFormPageAction,
  navigateToResultOverviewPageAction,
  onSolarPanelsFunnelNavigationAction,
  selectSolarPanelsFunnelSettings,
  selectSolarPanelsProductAction,
  setSelectedSolarPanelProductAction,
} from '../solar-panels-funnel';
import { showSolarPanelsProductOverviewAction } from './solar-panels-product-overview.actions';
import { solarPanelsProductOverviewKey } from './solar-panels-product-overview.reducer';

@Injectable()
export class SolarPanelsProductOverviewEffects {
  funnelSettings$ = this.store$.select(selectSolarPanelsFunnelSettings);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly scrollService: ScrollService
  ) {}

  public readonly showSolarPanelsProductOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onSolarPanelsFunnelNavigationAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(
        ([action, funnelSettings]) =>
          action.page === funnelSettings.productOverviewPage
      ),
      map(showSolarPanelsProductOverviewAction)
    )
  );

  public readonly scrollToProductOverview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSolarPanelsProductOverviewAction),
        delay(250),
        tap(() => {
          const productOverviewElement = this.document.getElementById(
            solarPanelsProductOverviewKey
          );

          this.scrollService.scrollToElementAnimated(
            this.scrollService.getWrappingDynamicRow(productOverviewElement)
          );
        })
      ),
    { dispatch: false }
  );

  public readonly setSelectedProductOnProductSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectSolarPanelsProductAction),
      map(({ product }) => setSelectedSolarPanelProductAction({ product }))
    )
  );

  public readonly dispatchNavigateToResultOverviewPageAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectSolarPanelsProductAction),
        concatLatestFrom(() => this.funnelSettings$),
        filter(([, funnelSettings]) => !!funnelSettings.showResultOverviewPage),
        map(([, { resultOverviewPage }]) =>
          navigateToResultOverviewPageAction({
            pageData: resultOverviewPage,
          })
        )
      )
  );

  public readonly dispatchNavigateToOrderFormPageAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectSolarPanelsProductAction),
      concatLatestFrom(() => this.funnelSettings$),
      filter(([, funnelSettings]) => !funnelSettings.showResultOverviewPage),
      map(([, { orderPage }]) =>
        navigateToOrderFormPageAction({ pageData: orderPage })
      )
    )
  );
}
