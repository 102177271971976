import { getSupplyAddressesError } from '@essent/supply-address-v3';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  clearSelectedSupplyAddressId,
  selectSupplyAddressId,
} from './supply-address.actions';

export interface State {
  selectedSupplyAddressId?: string;
}

export const initialState: State = {};

const _reducer = createReducer(
  initialState,
  on(selectSupplyAddressId, (state, action) => ({
    ...state,
    selectedSupplyAddressId: action.payload,
  })),
  on(clearSelectedSupplyAddressId, getSupplyAddressesError, () => initialState)
);

export function selectedSupplyAddressReducer(
  state: State = initialState,
  action: Action
): State {
  return _reducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedSupplyAddressId;
