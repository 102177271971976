<div
  class="composable-card"
  [ngClass]="[
    config?.backgroundColorClass ?? '',
    config?.borderClass ?? '',
    config?.borderRoundingClass ?? ''
  ]"
  [class.h-100]="config?.stretchVertical"
>
  <ng-content></ng-content>
</div>
