<div *ngIf="offer" class="commodity-price-breakdown">
  <wl-commodity-price-breakdown-row
    [title]="yearlyCostsLabel(offer)"
    [subtitle]="content?.costsPerYearWithDiscountLabel"
    [costWithDiscount]="offer?.expectedYearlyAmount"
    [costWithoutDiscount]="offer?.beforeDiscountExpectedYearlyAmount"
    [discountPrice]="offer?.discountPrice"
    [alignPriceHorizontal]="content?.showDiscountAmountInRowFormat"
  >
  </wl-commodity-price-breakdown-row>
  <wl-commodity-price-breakdown-row
    [title]="content?.costsPerMonthLabel"
    [subtitle]="content?.costsPerMonthWithDiscountLabel"
    [costWithDiscount]="offer?.expectedMonthlyAmount"
    [costWithoutDiscount]="offer?.budgetBillAmount"
    [discountPrice]="offer?.discountPrice"
    [alignPriceHorizontal]="content?.showDiscountAmountInRowFormat"
  >
  </wl-commodity-price-breakdown-row>
  <wl-commodity-price-breakdown-row
    *ngIf="content?.showBudgetbillAmount"
    [title]="content?.budgetbillAmountLabel"
    [subtitle]="content?.withoutDiscountLabel"
    [costWithoutDiscount]="offer?.budgetBillAmount"
    [discountPrice]="offer?.discountPrice"
    [isBBA]="true"
    [tooltipDescription]="content?.withoutDiscountTooltip"
  >
  </wl-commodity-price-breakdown-row>
</div>
