import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, Input } from '@angular/core';
import type {
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type {
  AnalyticsActionErrorTypes,
  ToolTrackValues,
} from './track-tool.model';
import { AnalyticsActionTypes } from './track-tool.model';
import { TrackToolService } from './track-tool.service';

interface TrackErrorModel extends ToolTrackValues {
  field: TextField | RichTextField | string;
  description: TextField | RichTextField | string;
  type: AnalyticsActionErrorTypes;
}

@Directive({
  selector: '[wlTrackError]',
})
export class TrackErrorDirective implements OnDestroy, OnInit {
  private readonly defaultValues: TrackErrorModel = {
    type: AnalyticsActionTypes.SYSTEM_ERROR,
    field: '',
    description: '',
    step: 0,
  };

  private readonly onDestroy$ = new Subject();

  @Input('wlTrackError')
  trackError: TrackErrorModel = this.defaultValues;

  ngOnInit(): void {
    this.triggerAngularTics();
  }

  private triggerAngularTics() {
    const values = {
      ...this.defaultValues,
      ...this.trackError,
    };

    const trackingValues = this.trackToolService.getTrackValues({
      ...values,
      type: AnalyticsActionTypes.TOOL_ERROR,
    });
    const extraprops = {
      fieldname: this.trackToolService.getValue(values.description),
      message: this.trackToolService.getValue(values.field),
      type: values.type,
    };

    return this.trackToolService
      .trackTool(trackingValues, extraprops)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  constructor(private readonly trackToolService: TrackToolService) {}

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
