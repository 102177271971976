import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import type {
  GetOffersNormalizedResponse,
  GetOffersPayload,
  GetOffersResponse,
  OfferEntries,
} from '@innogy/become-a-customer/shared/interfaces';
import { API_PUBLIC_PREFIX } from '@innogy/config';
import { map } from 'rxjs/operators';
import { snakeCase, toCamelcase } from '@essent/core-utils';

@Injectable()
export class GetOffersService
  implements BaseService<GetOffersPayload, GetOffersNormalizedResponse>
{
  readonly apiVersion: string = '1';
  readonly endpoint: string = `${this.apiUrl || ''}/cplusactivation/offers/v${
    this.apiVersion
  }`;

  public constructor(
    @Inject(API_PUBLIC_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(payload: GetOffersPayload) {
    const headers = { Accept: 'application/json' };
    const params = {
      // eslint-disable-next-line @typescript-eslint/ban-types
      ...snakeCase<object>({ ...payload }),
    };

    return this.httpClient
      .get(this.endpoint, {
        headers,
        params,
      })
      .pipe(
        toCamelcase<GetOffersResponse>(),
        map((res) => this.normalizeResponse(res))
      );
  }

  private normalizeResponse(
    response: GetOffersResponse
  ): GetOffersNormalizedResponse {
    const offerIds = response.offers.reduce<OfferEntries>(
      (accumulator, offer) => {
        accumulator[offer.offerId] = offer;
        return accumulator;
      },
      {}
    );

    return {
      durations: response.durations,
      isCustomer: response.isCustomer,
      offerset: response.offerset,
      offerIds: Object.keys(offerIds),
      offers: { ...offerIds },
    };
  }
}
