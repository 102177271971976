import type {
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';

export enum QuotationReactionMessageType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type QuotationReactionMessage = {
  title: TextField | undefined;
  message: RichTextField | undefined;
};
