import { Status } from '@essent/common';
import type { GetOffersNormalizedResponse } from '@innogy/become-a-customer/shared/interfaces';
import type { StateStatus } from '@innogy/utils/state';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  getOffers,
  getOffersSuccess,
  getOffersError,
  getOffersClear,
} from './get-offers.actions';

export interface GetOffersState
  extends StateStatus,
    GetOffersNormalizedResponse {}

const initialState: GetOffersState = {
  status: Status.IDLE,
  isCustomer: false,
  offerset: '',
  durations: {
    filter: 'default_duration',
    present: [],
    retrieved: [],
  },
  offerIds: [],
  offers: {},
};

const _getOffersReducer = createReducer(
  initialState,
  on(getOffers, (state) => ({
    ...state,
    status: Status.PENDING,
  })),
  on(getOffersSuccess, (state, action) => ({
    ...state,
    status: Status.SUCCESS,
    ...action.payload,
  })),
  on(getOffersError, (state, action) => ({
    ...state,
    status: Status.ERROR,
    error: action.payload,
  })),
  on(getOffersClear, (_) => initialState)
);

export function getOffersReducer(
  state: GetOffersState = initialState,
  action: Action
): GetOffersState {
  return _getOffersReducer(state, action);
}
