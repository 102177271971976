// tslint:disable:max-classes-per-file
import { createBaseAction } from '@essent/common';
import type { RequestedContractStatus } from '@essent/supply-address-v3';
import { union } from '@ngrx/store';

export const enum SelectedSupplyAddressTypes {
  SELECT_ID = '[SelectedSupplyAddressTypes] Select Id',
  CLEAR = '[SelectedSupplyAddressTypes] Clear',
  FETCH = '[SelectedSupplyAddressTypes] Fetch',
}

export const selectSupplyAddressId = createBaseAction<string | undefined>(
  SelectedSupplyAddressTypes.SELECT_ID
);
export const clearSelectedSupplyAddressId = createBaseAction(
  SelectedSupplyAddressTypes.CLEAR
);

const _union = union({
  selectSupplyAddressId,
  clearSelectedSupplyAddressId,
});
export type SupplyAddressActionsUnion = typeof _union;

export const setMonths = createBaseAction<{
  numberOfMonths: number;
  requestContractStatus: RequestedContractStatus;
}>('[SupplyAddresses] Set number of months');
export const fetchSupplyAddresses = createBaseAction<RequestedContractStatus>(
  '[SupplyAddresses] Number of months changed'
);
