import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { BaseService } from '@essent/common';
import type { Observable } from 'rxjs';
import { API_PREFIX } from '@innogy/config';

import type { InsulationProductWithCalculations } from '../../models';

@Injectable()
export class GetInsulationProductsService
  implements BaseService<any, InsulationProductWithCalculations[]>
{
  public readonly apiVersion = '1';
  public readonly endpoint = `${this.apiUrl || ''}/eplus/insulation/products/v${
    this.apiVersion
  }`;

  public constructor(
    @Optional()
    @Inject(API_PREFIX)
    private readonly apiUrl: string,
    private readonly httpClient: HttpClient
  ) {}

  public call$(): Observable<InsulationProductWithCalculations[]> {
    const headers = { Accept: 'application/json' };

    return this.httpClient.get<InsulationProductWithCalculations[]>(
      this.endpoint,
      { headers }
    );
  }
}
