import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BecomeACustomerCalculateModule } from '@innogy/become-a-customer/calculate';
import { BecomeACustomerOfferModule } from '@innogy/become-a-customer/offer';
import { BecomeACustomerOrderModule } from '@innogy/become-a-customer/order';
import { BecomeACustomerStateModule } from '@innogy/become-a-customer/state';
import { BecomeACustomerUsageQuestionnaireModule } from '@innogy/become-a-customer/usage-questionnaire';

@NgModule({
  imports: [
    CommonModule,
    BecomeACustomerStateModule,
    BecomeACustomerCalculateModule,
    BecomeACustomerUsageQuestionnaireModule,
    BecomeACustomerOfferModule,
    BecomeACustomerOrderModule,
    HammerModule,
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    },
  ],
})
export class BecomeACustomerModule {}
