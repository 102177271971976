import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from '@innogy/utils/deprecated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import {
  handleNavigationAction,
  handlePrivateErrorAction,
} from './generic.actions';

@Injectable()
export class GenericEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly errorHandler: ErrorHandler,
    private readonly location: LocationService,
    private readonly router: Router
  ) {}

  public readonly handleNavigationAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleNavigationAction),
        tap(({ page }) => {
          const [currentPath] = this.router.url.split('?');
          if (page && currentPath !== page.href) {
            this.location.navigateScLink(page);
          }
        })
      ),
    { dispatch: false }
  );

  public readonly handlePrivateErrorAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handlePrivateErrorAction),
        tap(({ message }) => {
          this.errorHandler.handleError(message);
        })
      ),
    { dispatch: false }
  );
}
