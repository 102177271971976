import { Component, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { getYearlyCostsLabel } from '@innogy/become-a-customer/shared';

import { CommodityOfferLabels } from '../../../commodity-offers.model';
@Component({
  selector: 'wl-commodity-price-breakdown',
  templateUrl: './commodity-price-breakdown.component.html',
  styleUrls: [
    './commodity-price-breakdown.component.ed.scss',
    './commodity-price-breakdown.component.essent.scss',
  ],
})
export class CommodityOfferPriceBreakdownComponent {
  @Input() offer?: Offer;
  @Input() content?: CommodityOfferLabels;

  yearlyCostsLabel(offer: Offer) {
    return getYearlyCostsLabel(
      this.content?.yearlyCostsTotalWithIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalWithoutIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalMultiYearWithIncentiveLabel ?? '',
      this.content?.yearlyCostsTotalMultiYearWithoutIncentiveLabel ?? '',
      offer
    );
  }
}
