import { provideBootstrapEffects } from '@innogy/utils/deprecated';

import { CalculateErrorEffects } from './calculate-error.effects';
import { CalculateFormSubmitEffects } from './calculate-form-submit.effects';
import { CalculateFormEffects } from './calculate-form.effects';
import { CalculateQuestionnaireEffects } from './calculate-questionnaire.effects';
import { CalculateTrackingEffects } from './calculate-tracking.effects';

export * from './calculate.actions';
export * from './calculate.reducer';
export * from './calculate.selectors';
export * from './calculate.state';
export * from './calculate-form-submit.effects';
export * from './calculate-form.effects';
export * from './calculate-tracking.effects';
export * from './calculate-questionnaire.effects';
export * from './calculate-error.effects';

export const calculateEffects = [
  CalculateTrackingEffects,
  CalculateErrorEffects,
];

export const calculateProviders = [
  provideBootstrapEffects([
    CalculateFormEffects,
    CalculateFormSubmitEffects,
    CalculateQuestionnaireEffects,
  ]),
];
