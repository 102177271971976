import type { JSSConfig } from './jss-config.model';

export const EnvConfigApp = {
  Essent: 'essent',
  EssentZakelijk: 'essent-zakelijk',
  MijnEssent: 'mijn-essent',
  MijnEssentZakelijk: 'mijn-essent-zakelijk',
  Energiedirect: 'energiedirect',
  MijnEnergiedirect: 'mijn-energiedirect',
  Energiewonen: 'energiewonen',
} as const;
export type EnvConfigApp = (typeof EnvConfigApp)[keyof typeof EnvConfigApp];

export const EnvConfigBrand = {
  Essent: 'essent',
  ED: 'ed',
  Energiewonen: 'energiewonen',
} as const;
export type EnvConfigBrand =
  (typeof EnvConfigBrand)[keyof typeof EnvConfigBrand];

export const EnvConfigType = {
  Open: 'open',
  Mijn: 'mijn',
} as const;
export type EnvConfigType = (typeof EnvConfigType)[keyof typeof EnvConfigType];

export const EnvConfigSegment = {
  Consument: 'consument',
  Zakelijk: 'zakelijk',
} as const;
export type EnvConfigSegment =
  (typeof EnvConfigSegment)[keyof typeof EnvConfigSegment];

export const EnvConfigEnvironment = {
  Development: 'dev',
  Test: 'tst',
  Acceptance: 'acc',
  Staging: 'staging',
  Production: 'prd',
} as const;
export type EnvConfigEnvironment =
  (typeof EnvConfigEnvironment)[keyof typeof EnvConfigEnvironment];

export interface SharedConfig {
  version: string;
  /**
   * Application name that matches the Sitecore naming.
   *
   * This name is also used for the distinction between apps when making calls to Sitecore.
   *
   * * BIG NOTE: the string is CASE-SENSITIVE.
   *
   * @example essent
   * @example energiedirect
   * @example mijn-essent
   * @example essent-zakelijk
   */
  app: EnvConfigApp;

  /**
   * Environment application is running is
   *
   * @example dev
   * @example tst
   * @example acc
   * @example staging
   * @example prd
   */
  environment: EnvConfigEnvironment;
  /**
   * Brand of the running application.
   *
   * Note: this is purely used for styling purposes.
   *
   * @example essent
   * @example ed
   */
  brand: EnvConfigBrand;
  bacSourceHeader: string;
  /**
   * Type of the running application.
   *
   * Note: this is purely used for styling purposes.
   *
   * @example open
   * @example mijn
   */
  type: EnvConfigType;
  /**
   * Segment of the running application.
   *
   * Note: this is used for styling purposes and segmentation on BAG-data (see: https://wiki.essent.nl/confluence/pages/viewpage.action?pageId=171579767)
   *
   * @example consument
   * @example zakelijk
   */
  segment: EnvConfigSegment;
  /**
   * basePath for settings & api calls
   *
   * @example /
   * @example /zakelijk
   *
   */
  basePath: string;
  /** Navigation configuration */
  navigation: {
    /**
     * The base path for the consumer segment
     */
    basePathConsumer: string;
    /**
     * The base path for the SME segment
     */
    basePathSME: string;
  };
  /**
   * Configuration for JSS
   *
   */
  sitecore: JSSConfig;
  services: {
    /**
     * API url for DigitalEngine that will be used for customer data.
     *
     * @example 'https://innogy-mock-essent-test-nodejs.azurewebsites.net/'
     *
     * @type {string}
     */
    digitalEngine: string;
  };
  seo?: {
    /**
     * Base URL used as origin for canonical tags
     *
     * @example 'http://example.com'
     * @example 'https://www.example.com'
     *
     * @type {string}
     */
    canonicalBaseUrl: string;
  };
  recaptcha: {
    enabled: boolean;
  };

  maintenanceMode: {
    enabled: boolean;
  };
}
