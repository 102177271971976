import { Injectable } from '@angular/core';
import { EnergyType, ProductDuration } from '@essent/new-customer';
import type { OrderFormLaststepResult } from '@innogy/become-a-customer/shared';
import {
  OrderFormLaststepService,
  mapOfferToCommerce,
} from '@innogy/become-a-customer/shared';
import { TEST_SUBMISSION_EMAIL } from '@innogy/become-a-customer/shared/testing';
import type { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';
import {
  AansluitingType,
  IncentiveType,
} from '@innogy/become-a-customer/shared/interfaces';
import { ConfigLoaderService } from '@innogy/config';
import {
  ContractType,
  CustomerType,
  ElectricityConsumptionType,
  ProductCategorie,
  ProductType,
  TrackProductService,
} from '@innogy/core/analytics';
import { ofFormSubmitAction } from '@innogy/utils/deprecated';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

import { getSupplyAddress } from '../bac/2.supply-address';
import {
  getConsumptionEnergyTypes,
  getConsumptionValuesVM,
} from '../bac/7.consumption';
import { getFlowId } from '../bac/flow-id';
import {
  getActiveOfferset,
  getPropositionDuration,
  getPropositionOffer,
  setPropositionOfferAction,
} from '../bac/offers';
import { getPartnerId } from '../bac/partner-id';
import { getUsageQuestionnaireFormState } from '../usage-questionnaire';
import { ORDER_CONFIRMATION_FORM_ID } from './confirmation';
import { getOrderState } from './order.selector';
import type { OrderState } from './order.state';
import { ORDER_PERSONAL_FORM_ID } from './personal';

@Injectable()
export class OrderTrackingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly productService: TrackProductService,
    private readonly orderLaststepService: OrderFormLaststepService,
    private readonly configLoaderService: ConfigLoaderService
  ) {}

  private readonly flowId$ = this.store$.pipe(select(getFlowId));
  private readonly partnerId$ = this.store$.pipe(select(getPartnerId));
  private readonly getSelectedOffer$ = this.store$.pipe(
    select(getPropositionOffer)
  );
  private readonly getSelectedDuration$ = this.store$.pipe(
    select(getPropositionDuration)
  );
  private readonly orderState$ = this.store$.pipe(select(getOrderState));
  private readonly consumptionValuesVM$ = this.store$.pipe(
    select(getConsumptionValuesVM)
  );
  private readonly consumptionEnergyTypes$ = this.store$.pipe(
    select(getConsumptionEnergyTypes)
  );
  private readonly getPropositionOffer$ = this.store$.pipe(
    select(getPropositionOffer)
  );
  private readonly sitecoreOfferSet$ = this.store$.pipe(
    select(getActiveOfferset)
  );
  private readonly supplyAddress$ = this.store$.pipe(select(getSupplyAddress));
  private readonly questionaireFormValues$ = this.store$.pipe(
    select(getUsageQuestionnaireFormState)
  );

  /**
   * Track product-detail
   */
  public readonly trackProductDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPropositionOfferAction),
        tap((action) => {
          if (action.offer) {
            const commerce = mapOfferToCommerce(action.offer);
            this.productService.trackDetail([commerce]);
          }
        })
      ),
    { dispatch: false }
  );

  public readonly trackFlowFinishedEvents$ = createEffect(
    () =>
      this.actions$.pipe(
        ofFormSubmitAction(ORDER_CONFIRMATION_FORM_ID),
        concatLatestFrom(() => [
          this.flowId$,
          this.partnerId$,
          this.orderState$,
          this.getSelectedOffer$,
          this.getSelectedDuration$,
          this.consumptionValuesVM$,
          this.consumptionEnergyTypes$,
          this.getPropositionOffer$,
          this.sitecoreOfferSet$,
          this.supplyAddress$,
          this.questionaireFormValues$,
        ]),
        filter(
          ([, , , orderState]) =>
            (orderState as OrderState).orderConfirmationForm.progressiveForm
              .formState.isValid
        ),
        tap(
          // eslint-disable-next-line complexity
          ([
            _,
            flowId,
            partnerId,
            orderState,
            offer,
            _duration,
            consumptionValuesVM,
            consumptionEnergyTypes,
            proposition,
            activeOfferset,
            supplyAddress,
            questionaire,
          ]) => {
            if (
              flowId &&
              orderState &&
              offer &&
              consumptionValuesVM &&
              !!proposition?.offerId
            ) {
              const childForm = (formKey: keyof OrderState) =>
                orderState[formKey].progressiveForm.formState.value;

              const values: OrderFormLaststepResult = {
                transactieId: flowId,
                leadId: partnerId || '',
                testAanmelding: this.isTestSubmission(
                  childForm(ORDER_PERSONAL_FORM_ID).email
                ),
                stroomverbruikJaar:
                  this.calculateNetElectricityValue(consumptionValuesVM),
                gasverbruikJaar: consumptionValuesVM.gas,
                typeKlant: supplyAddress?.customerSegment || '',
                isKlant: offer.isCustomer
                  ? CustomerType.Klant
                  : CustomerType.Prospect,
                aantalPersonen: questionaire?.value?.residents || '',
                typeAansluiting: this.calculateConnectionType(
                  consumptionEnergyTypes as EnergyType[]
                ),
                typeMeter: this.calculateMeterType(consumptionValuesVM),
                zonnepanelen:
                  !!consumptionValuesVM.electricity.returnSupplyNormal,
                emailNieuwsbrief: childForm(ORDER_CONFIRMATION_FORM_ID)
                  .informationMailAccepted,
                emailAanbiedingen: childForm(ORDER_CONFIRMATION_FORM_ID)
                  .actionMailAccepted,
                productNaam: offer.productTitle,
                productID: offer.offerId,
                productType: ProductType.Energy_Gas,
                productCategorie: ProductCategorie.Acquisitie,
                productLijst: activeOfferset?.offerset ?? '',
                productAantal: 1,
                vatIncluded: offer.vatIncluded,
                prijs: offer.expectedYearlyAmount,
                termijnbedrag: offer.budgetBillAmount || NaN,
                productKorting: offer.discountPrice || 0,
                incentiveNaam: offer.incentiveTitle ?? '',
                incentiveType: offer.incentiveType ?? IncentiveType.Cashback,
                contractAantal: offer.commodities.length,
                contractLooptijd: (
                  (parseInt(offer.duration, 10) || 0) * 12
                ).toString(),
                contractType: this.calculateContractType(offer.duration),
                incentiveID: offer.incentiveId || '',
              };

              this.orderLaststepService.trackOrderLaststep(values, offer);
            }
          }
        )
      ),
    { dispatch: false }
  );

  /**
   * checks whether or not the submission we make is to be considered a test submission.
   * To do so, we match the provided in email against a fixed address and test the environment we run in.
   * @param email the email we want to validate
   */
  private readonly isTestSubmission = (email: string) => {
    return (
      this.configLoaderService.getConfig().environment !== 'prd' ||
      email === TEST_SUBMISSION_EMAIL
    );
  };

  private readonly calculateConnectionType = (
    energyTypes?: EnergyType[]
  ): AansluitingType => {
    if (energyTypes === undefined || energyTypes.length === 0) {
      return AansluitingType.Onbekend;
    } else if (energyTypes.length === 1) {
      if (energyTypes[0] === EnergyType.GAS) {
        return AansluitingType.Gas;
      }
      return AansluitingType.Electriciteit;
    } else {
      return AansluitingType.ElectriciteitEnGas;
    }
  };

  private readonly calculateNetElectricityValue = (
    consumptionValuesVM: ConsumptionValuesVM
  ) => {
    const { electricity } = consumptionValuesVM;
    return (
      electricity.supplyLow +
      electricity.supplyNormal -
      (electricity.returnSupplyLow + electricity.returnSupplyNormal)
    );
  };

  private readonly calculateContractType = (duration: string) => {
    return duration === ProductDuration.FLEX
      ? ContractType.flexibel
      : ContractType.vast;
  };

  private readonly calculateMeterType = (
    consumptionValuesVM: ConsumptionValuesVM
  ) => {
    return consumptionValuesVM.electricity.supplyLow
      ? ElectricityConsumptionType.Dubbel
      : ElectricityConsumptionType.Enkel;
  };
}
