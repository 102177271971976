<section class="structured-content structured-content--{{ theme }}">
  <div
    class="structured-content__wrapper"
    [class.structured-content__wrapper--centered]="centered"
  >
    <div
      class="structured-content__heading"
      [class.structured-content--centered]="centered"
    >
      <div class="structured-content__icon">
        <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
        <ng-container *ngIf="!icon">
          <ng-content select="fa-icon[slot='icon']"></ng-content>
        </ng-container>
      </div>
      <ng-container *ngIf="heading">
        <h3>{{ heading }}</h3>
      </ng-container>
      <ng-container *ngIf="!heading">
        <ng-content select="[slot='heading']"></ng-content>
      </ng-container>
    </div>
    <div
      class="structured-content__sub-heading"
      [class.structured-content--centered]="centered"
    >
      <ng-container *ngIf="subheading">
        <h4 class="intro">{{ subheading }}</h4>
      </ng-container>
      <ng-container *ngIf="!subheading">
        <ng-content select="[slot='subheading']"></ng-content>
      </ng-container>
    </div>
    <div
      class="structured-content__paragraph"
      [class.structured-content--centered]="centered"
    >
      <ng-container *ngIf="paragraph">
        <p>{{ paragraph }}</p>
      </ng-container>
      <ng-container *ngIf="!paragraph">
        <ng-content select="[slot='paragraph']"></ng-content>
      </ng-container>
    </div>
  </div>
  <div class="structured-content__projection">
    <ng-content select="[slot='content']"></ng-content>
  </div>
  <div
    class="structured-content__buttons"
    [class.structured-content--centered]="centered"
    [class.structured-content__buttons--inline]="layoutButtonsInline"
  >
    <ng-content select="[slot='button-primary']"></ng-content>
    <ng-content select="[slot='button-secondary']"></ng-content>
  </div>
</section>
