import type { State as BaseState } from '@innogy/shared/state';
import type { Action, ActionReducerMap } from '@ngrx/store';

import * as fromSelect from './select.reducer';
import * as fromSupplyAddresses from './supply-addresses.reducer';

export interface SupplyAddressState {
  readonly supplyAddresses: fromSupplyAddresses.State;
  readonly selectedSupplyAddress: fromSelect.State;
}

export interface State extends BaseState {
  readonly supplyAddress: SupplyAddressState;
}

export const reducers: ActionReducerMap<SupplyAddressState, Action> = {
  supplyAddresses: fromSupplyAddresses.supplyAddressesReducer,
  selectedSupplyAddress: fromSelect.selectedSupplyAddressReducer,
};

export const supplyAddressSelectorKey = 'supplyAddress';
