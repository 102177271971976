import type { InnogyComponentRendering } from '@innogy/jss/models';
import { getDroplinkValue } from '@innogy/jss/utils';
import type { ImageField } from '@sitecore-jss/sitecore-jss-angular';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-angular';

export interface BackgroundSettings {
  backgroundClass?: string;
  useDesktopBackgroundAsFallback: boolean;
  mobile?: ResponsiveSettings;
  desktop?: ResponsiveSettings;
}

export interface ResponsiveSettings {
  src?: string;
  alt?: string;
  size?: string;
  position?: string;
  repeat?: string;
}

export const getBackgroundSettingsFromRendering = (
  rendering?: InnogyComponentRendering
): BackgroundSettings | undefined => {
  if (rendering) {
    return {
      backgroundClass: getDroplinkValue(rendering.fields, 'BackgroundColor'),
      useDesktopBackgroundAsFallback: getFieldValue<boolean>(
        rendering,
        'UseDesktopBackgroundAsFallback',
        false
      ),
      mobile: {
        src: getFieldValue<ImageField>(rendering, 'BackgroundImageMobile')?.src,
        alt: getFieldValue<ImageField>(rendering, 'BackgroundImageMobile')?.[
          'alt'
        ] as string | undefined,
        size: getDroplinkValue(rendering.fields, 'BackgroundSizeMobile'),
        position: getDroplinkValue(
          rendering.fields,
          'BackgroundPositionMobile'
        ),
        repeat: getDroplinkValue(rendering.fields, 'BackgroundRepeatMobile'),
      },
      desktop: {
        src: getFieldValue<ImageField>(rendering, 'BackgroundImageDesktop')
          ?.src,
        alt: getFieldValue<ImageField>(rendering, 'BackgroundImageDesktop')?.[
          'alt'
        ] as string | undefined,
        size: getDroplinkValue(rendering.fields, 'BackgroundSizeDesktop'),
        position: getDroplinkValue(
          rendering.fields,
          'BackgroundPositionDesktop'
        ),
        repeat: getDroplinkValue(rendering.fields, 'BackgroundRepeatDesktop'),
      },
    };
  }
  return undefined;
};
