import { NgModule } from '@angular/core';
import { ComposablesModule } from '@innogy/common-ui/common-renderings';
import { CommonUiFormsModule } from '@innogy/common-ui/forms';
import { DefaultComponentModule } from '@innogy/core/modules/components';
import {
  ProgressiveFormComponentsModule,
  ProgressiveFormModule,
} from '@innogy/shared/progressive-form';
import { SitecoreFormsModule } from '@innogy/sitecore-forms';
import { NgrxFormsModule } from 'ngrx-forms';

import { ProgressiveFormContainerComponent } from './progressive-form-container';
import {
  ProgressiveAddressFormComponent,
  ProgressivePaymentDetailsFormComponent,
  ProgressivePersonalDetailsFormComponent,
} from './progressive-form-steps';
import { ScProgressiveFormContainerComponent } from './sc-progressive-form-container/sc-progressive-form-container.component';
import { ProgressiveGenericFormComponent } from './progressive-form-steps/progressive-generic-form/progressive-generic-form.component';

const exportedComponents = [
  ScProgressiveFormContainerComponent,
  ProgressiveGenericFormComponent,
  ProgressiveFormContainerComponent,
  ProgressiveAddressFormComponent,
  ProgressivePersonalDetailsFormComponent,
  ProgressivePaymentDetailsFormComponent,
];

/**
 * @deprecated - please use the `CommonUiProgressiveFormsModule` instead.
 */
@NgModule({
  imports: [
    DefaultComponentModule,
    CommonUiFormsModule,
    NgrxFormsModule,
    ComposablesModule,
    SitecoreFormsModule,
    ProgressiveFormComponentsModule,
    ProgressiveFormModule,
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
})
export class SharedProgressiveFormSitecoreModule {}
