import type { ScLinkField } from '@innogy/jss/models';
import { createAction, props } from '@ngrx/store';

const PREFIX = `[Generic]`;

export const handleNavigationAction = createAction(
  `${PREFIX} Navigate`,
  props<{ page: ScLinkField | undefined }>()
);

export const handlePrivateErrorAction = createAction(
  `${PREFIX} Private Error`,
  props<{ message: string }>()
);
