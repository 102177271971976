import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  getDynamicPrices,
  getDynamicPricesAvailability,
  getDynamicPricesAvailabilitySuccess,
  getDynamicPricesByDate,
  getDynamicPricesByDateSuccess,
  getDynamicPricesSuccess,
} from '@essent/tariffs';
import { addDays } from 'date-fns';
import { waitForData } from '@innogy/utils/rxjs';

import {
  changeSelectedDate,
  initDynamicPrices,
  setDynamicPricesSuccess,
  setSelectedActionId,
  setSelectedDate,
  updateDynamicPrices,
} from '../actions';
import {
  selectGetDynamicPricesInfo,
  selectGetDynamicPricesPayload,
  selectIsInitialLoadSuccessfull,
} from '../selectors';
import { formatDate } from '../utils/format-date.util';
import {
  selectAvailableDateEnergyTypeAvailability,
  selectDynamicPricesAvailabilityData,
} from '../selectors/dynamic-prices-availability.selectors';

@Injectable()
export class DynamicPricesEffects {
  private readonly actions$ = inject(Actions);
  private readonly store$ = inject(Store);

  public initDynamicPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initDynamicPrices),
      withLatestFrom(this.store$.select(selectIsInitialLoadSuccessfull)),
      filter(([_, isInitialLoadSuccessfull]) => !isInitialLoadSuccessfull),
      withLatestFrom(this.store$.select(selectGetDynamicPricesInfo)),
      switchMap(([_, { actionId }]) => [
        getDynamicPricesAvailability({ payload: {} }),
        getDynamicPrices({
          actionId,
          payload: {},
        }),
      ])
    )
  );

  public getDynamicPricesAvailabilitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDynamicPricesAvailabilitySuccess),
      withLatestFrom(
        this.store$.select(selectAvailableDateEnergyTypeAvailability)
      ),
      switchMap(([_, { initialDate, initialDateAvailable }]) => {
        const actions = [];

        if (initialDate) {
          actions.push(setSelectedDate({ payload: initialDate }));
        }

        if (!initialDateAvailable) {
          actions.push(updateDynamicPrices());
        }

        return actions;
      })
    )
  );

  public getDynamicPricesByDateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDynamicPricesByDateSuccess),
      map((action) =>
        setSelectedActionId({ payload: action.actionId ?? 'unknown' })
      )
    )
  );

  public getDynamicPricesSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDynamicPricesSuccess),
      waitForData(this.store$.select(selectDynamicPricesAvailabilityData)),
      withLatestFrom(
        this.store$.select(selectAvailableDateEnergyTypeAvailability)
      ),
      switchMap(([[action], { initialDateAvailable }]) => {
        const actions = [];
        if (initialDateAvailable) {
          actions.push(
            setSelectedActionId({ payload: action.actionId ?? 'unknown' })
          );
        }
        actions.push(setDynamicPricesSuccess(action));
        return actions;
      })
    )
  );

  public updateDynamicPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDynamicPrices),
      withLatestFrom(this.store$.select(selectGetDynamicPricesInfo)),
      map(([_, { payload, actionId, entityIsSuccess }]) =>
        entityIsSuccess
          ? setSelectedActionId({ payload: actionId })
          : getDynamicPricesByDate({
              actionId,
              payload,
            })
      )
    )
  );

  public changeSelectedDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSelectedDate),
      withLatestFrom(this.store$.select(selectGetDynamicPricesPayload)),
      switchMap(([action, { date }]) => {
        let newDate: string = date;
        if (action.payload.offset) {
          newDate = formatDate(addDays(date, action.payload.offset));
        } else if (action.payload.newDate) {
          newDate = formatDate(action.payload.newDate);
        }
        return [setSelectedDate({ payload: newDate }), updateDynamicPrices()];
      })
    )
  );
}
