import { HttpErrorResponse } from '@angular/common/http';
import { type ApiActions, RequestStrategy } from '@essent/common';
import type { ClientMethod, PathMethods } from 'openapi-fetch';
import {
  combineLatest,
  exhaustMap,
  from,
  map,
  merge,
  mergeMap,
  type Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import type { HttpMethod, MediaType } from 'openapi-typescript-helpers';

export type ApiClientRequest<TResponse> = {
  apiActions: ApiActions<void, TResponse>;
  request: () => Promise<ApiClientResponse>;
  requestOptions?: RequestOptions;
};

export type ApiClientResponse = ReturnType<
  ClientMethod<Record<string, PathMethods>, HttpMethod, MediaType>
>;

type RequestOptions = {
  /**
   * @default RequestStrategy.CANCEL
   */
  requestStrategy?: RequestStrategy;
  actionId?: string;
};

const defaultRequestOptions: RequestOptions = {
  requestStrategy: RequestStrategy.CANCEL,
};

/**
 * Maps API client responses to corresponding API actions
 *
 * @experimental Please discuss with @team-atlas before using this function, it is still in development
 * (this helper function will be moved to core-modules once it is ready to be used)
 */
export const mapApiClientResponseToApiActions = map(
  <TResponse>([{ apiActions, requestOptions }, response]: [
    ApiClientRequest<TResponse>,
    ApiClientResponse
  ]) => {
    if (response.error) {
      return apiActions.errorAction({
        payload: new HttpErrorResponse({ error: response.error }),
        actionId: requestOptions?.actionId,
      });
    }

    return apiActions.successAction({
      payload: response.data,
      actionId: requestOptions?.actionId,
    });
  }
);

/**
 * Maps API client requests to API actions based on a specified request strategy
 *
 * @experimental Please discuss with @team-atlas before using this function, it is still in development
 * (this helper function will be moved to core-modules once it is ready to be used)
 *
 * @param mapper - Function to map "any" input values to an ApiClient request
 */
export const mapApiClientRequestToApiActions = <TResponse, TMapperInputValue>(
  mapper: (value: TMapperInputValue) => ApiClientRequest<TResponse>
) => {
  return (input$: Observable<TMapperInputValue>) => {
    const request$ = input$.pipe(
      switchMap((value): Observable<ApiClientRequest<TResponse>> => {
        const mappedRequest = mapper(value);
        return of({
          ...mappedRequest,
          requestOptions: {
            ...defaultRequestOptions,
            ...mappedRequest.requestOptions,
          },
        });
      })
    );

    // this makes it so you can never use the request action to trigger the effect, is this desirable?
    const pendingAction$ = request$.pipe(
      map(({ apiActions, requestOptions }) =>
        apiActions.requestAction({
          actionId: requestOptions?.actionId,
          payload: undefined,
        })
      )
    );

    const { CANCEL, IGNORE, PARALLEL } = RequestStrategy;

    const parallel$ = request$.pipe(
      filter(
        ({ requestOptions }) => requestOptions?.requestStrategy === PARALLEL
      )
    );
    const cancel$ = request$.pipe(
      filter(({ requestOptions }) => requestOptions?.requestStrategy === CANCEL)
    );
    const ignore$ = request$.pipe(
      filter(({ requestOptions }) => requestOptions?.requestStrategy === IGNORE)
    );

    // Handle requests based on the specified request strategy
    const requestStrategyHandler$: Observable<ApiClientResponse> = merge(
      // Processes requests in parallel.
      parallel$.pipe(mergeMap(({ request }) => from(request()))),
      // Ignores new requests while the current one is still running.
      ignore$.pipe(exhaustMap(({ request }) => from(request()))),
      // Cancels the current pending request when a new value is passed trough input$.
      // NOTE: This does not cancel the actual request, it only prevents the related apiActions from dispatching.
      cancel$.pipe(
        switchMap(({ request }) =>
          from(request()).pipe(takeUntil(merge(parallel$, ignore$)))
        )
      )
    );

    // Combine the original request with the response and map it to apiActions
    const apiActions$ = combineLatest([request$, requestStrategyHandler$]).pipe(
      mapApiClientResponseToApiActions
    );

    return merge(pendingAction$, apiActions$);
  };
};
