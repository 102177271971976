import { Status } from '@essent/common';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { StateStatus } from '@innogy/utils/state';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  getOffer,
  getOfferClear,
  getOfferError,
  getOfferSuccess,
} from './get-offer.actions';

export interface GetOfferState extends StateStatus {
  offer?: Offer;
}

const initialState: GetOfferState = {
  status: Status.IDLE,
};

const _getOfferReducer = createReducer(
  initialState,
  on(getOffer, (state) => ({
    ...state,
    status: Status.PENDING,
  })),
  on(getOfferSuccess, (state, action) => ({
    ...state,
    status: Status.SUCCESS,
    offer: action.payload,
  })),
  on(getOfferError, (state, action) => ({
    ...state,
    status: Status.ERROR,
    error: action.payload,
  })),
  on(getOfferClear, (_) => initialState)
);

export function getOfferReducer(
  state: GetOfferState = initialState,
  action: Action
): GetOfferState {
  return _getOfferReducer(state, action);
}
