import { Injectable, Injector } from '@angular/core';
import { BaseGuard } from '@innogy/core/ngrx';
import { Store } from '@ngrx/store';

import { initDynamicPrices, selectIsDynamicPricesLoading } from '../+state';

@Injectable({ providedIn: 'root' })
export class DynamicPricesGuard extends BaseGuard {
  constructor(
    protected readonly injector: Injector,
    protected readonly store$: Store
  ) {
    super(injector);
  }

  protected isStateLoading() {
    return this.store$.select(selectIsDynamicPricesLoading);
  }

  public getDispatchActions() {
    return [initDynamicPrices()];
  }
}
