import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { FinancialItem } from '@essent/financial-items';
import { FinancialItemStatus, PaymentMethod } from '@essent/financial-items';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { DateFnsPipe } from '../pipes/date-fns.pipe';
import { DaysUntilTodayPipe } from '../pipes/days-until-today.pipe';
import { isInDeferral, isCreditInvoice } from './invoice-helper';
import { invoiceToPaymentStatus } from './invoice-to-payment-status';
import type { CombinedFinancialItem } from './invoice.model';
import { InvoicePaymentStatus, TranslationKeys } from './invoice.model';

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {
  constructor(
    private readonly translate: TranslateService,
    private readonly dateFnsPipe: DateFnsPipe,
    private readonly daysUntilTodayPipe: DaysUntilTodayPipe
  ) {}

  public transform(item: CombinedFinancialItem): Observable<string> {
    if (!item?.dueDate) {
      return of('');
    }

    if (item.status === FinancialItemStatus.CLEARED) {
      return isCreditInvoice(item)
        ? this.translate.get(TranslationKeys.ClosedInvoicePaidOut)
        : this.translate.get(TranslationKeys.ClosedInvoicePaid);
    }

    if (isCreditInvoice(item)) {
      return this.translate.get(TranslationKeys.RefundPaymentInvoice, {
        paymentDate: this.dateFnsPipe.transform(
          new Date(item.dueDate),
          'dd MMMM y'
        ),
      });
    }

    const invoicePaymentStatus: InvoicePaymentStatus =
      invoiceToPaymentStatus(item);

    if (invoicePaymentStatus === InvoicePaymentStatus.PROMISE_TO_PAY) {
      return this.translate.get(TranslationKeys.PaymentInProgress);
    }

    if (isInDeferral(item)) {
      return this.translate.get(TranslationKeys.DeferredPayment, {
        amountOfDays: this.daysUntilTodayPipe.transform(
          (item as FinancialItem).deferralDate
        ),
      });
    }

    if (invoicePaymentStatus === InvoicePaymentStatus.PAST_DUE) {
      return this.translate.get(TranslationKeys.PayTodayWarning);
    }

    if (invoicePaymentStatus === InvoicePaymentStatus.DUE) {
      return this.translate.get(TranslationKeys.PayWithinXDays, {
        daysUntilPayment: this.daysUntilTodayPipe.transform(item.dueDate),
        dueDate: this.dateFnsPipe.transform(
          new Date(item.dueDate),
          'dd MMMM y'
        ),
      });
    }

    return this.translate.get(
      item.paymentMethod === PaymentMethod.DIRECT_DEBIT
        ? TranslationKeys.AutomaticPaymentInvoice
        : TranslationKeys.ManualPaymentInvoice,
      {
        paymentDate: this.dateFnsPipe.transform(
          new Date(item.dueDate),
          'dd MMMM y'
        ),
      }
    );
  }
}
