import { Injectable } from '@angular/core';
import { getIsExperienceEditorActive } from '@innogy/jss';
import { PlatformService } from '@innogy/core/platform';
import type { Action } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GuardService {
  dispatchActions(actions: Action[]) {
    actions.forEach((action) => {
      this.store$.dispatch(action);
    });
  }

  isExperienceEditorOrServerActive() {
    return this.store$
      .select(getIsExperienceEditorActive)
      .pipe(
        map(
          (isExperienceEditorActive) =>
            isExperienceEditorActive || this.platformService.isServer()
        )
      );
  }

  constructor(
    protected readonly store$: Store<any>,
    private readonly platformService: PlatformService
  ) {}
}
