<div class="offers">
  <div class="mt-lg-6">
    <wl-commodity-offer-header
      [alignment]="'vertical'"
      [headerTitle]="content?.componentTitle"
    ></wl-commodity-offer-header>
    <div class="mb-4">
      <wl-commodity-offer-calculation-based-on
        [variant]="'narrow'"
        [label]="content?.calculationBasedOnLabel"
        [address]="supplyAddress"
        [consumptions]="consumptionValues"
        [electricityLabel]="'ElectricityUsageUnit' | translate"
        [gasLabel]="'GasUsageUnit' | translate"
        [adjustCalculationLabel]="content?.adjustCalculationLabel"
        (adjustCalculation)="adjustCalculation.emit()"
      ></wl-commodity-offer-calculation-based-on>
    </div>
    <div
      class="d-none d-lg-block"
      *ngIf="content?.offerFootNote || content?.componentFootNote"
    >
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
  </div>
  <div>
    <div class="mb-3 mb-lg-0">
      <wl-commodity-offer-list
        [offers]="offers"
        [alignment]="'vertical'"
        [content]="content"
        [showMemberGetMember]="showMemberGetMember"
        (selectOffer)="selectOffer.emit($event)"
        (openMoreInfo)="openMoreInfo.emit($event)"
        (viewTariffs)="viewTariffs.emit($event)"
      ></wl-commodity-offer-list>
    </div>

    <div class="d-lg-none">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
    <wl-commodity-offer-reference-id
      *ngIf="content?.referenceIdLabel && flowId"
      [flowId]="flowId"
      [referenceIdLabel]="content?.referenceIdLabel"
    >
    </wl-commodity-offer-reference-id>
    <wl-commodity-offer-customer-information
      *ngIf="content?.customerIdLabel && accountId"
      [customerId]="accountId"
      [customerIdLabel]="content?.customerIdLabel"
      class="col-12"
    ></wl-commodity-offer-customer-information>
  </div>
</div>

<ng-template #text>
  <ng-container *ngIf="content?.offerFootNote">
    <hr class="my-3" />
    <wl-rich-text-wrapper [field]="{ value: content?.offerFootNote }"
      ><div></div
    ></wl-rich-text-wrapper>
  </ng-container>
  <ng-container *ngIf="content?.componentFootNote">
    <hr class="my-3" />
    <wl-rich-text-wrapper [field]="{ value: content?.componentFootNote }"
      ><div></div
    ></wl-rich-text-wrapper>
  </ng-container>
</ng-template>
