import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ComponentRendering,
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { FormState } from 'ngrx-forms';

@Component({
  selector: 'wl-toggle-with-info[rendering]',
  templateUrl: './toggle-with-info.component.html',
  styleUrls: ['./toggle-with-info.component.ed.scss'],
})
export class ToggleWithInfoComponent {
  @Input()
  rendering!: ComponentRendering;
  @Input()
  title?: TextField;
  @Input()
  description?: RichTextField;
  @Input()
  tooltip?: string;
  @Input()
  containerClass?: string;
  @Input()
  formControl?: FormState<boolean>;
  @Input()
  fieldname?: string;
  @Input()
  stepname?: string;
  @Input()
  formName?: string;
  @Input()
  checked = false;
  @Input()
  toggleId?: string;

  @Output()
  toggle = new EventEmitter<Event>();

  get isChecked() {
    return this.formControl?.value ?? this.checked;
  }

  get elementId() {
    return this.formControl?.id ?? this.toggleId ?? '';
  }

  onToggle(event: Event) {
    this.toggle.emit(event);
  }
}
