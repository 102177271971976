import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnInit,
} from '@angular/core';
import {
  TariffZoneElectricity,
  TariffZoneGas,
  type EnergyTypeDropdownVM,
  type Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import { EnergyType } from '@essent/new-customer';
import { getEnergyTypeDropdownVM } from '@innogy/become-a-customer/shared';

import { CommodityOfferLabels } from '../../../commodity-offers.model';
@Component({
  selector: 'ed-commodity-vertical-offer-dropdown',
  templateUrl: './commodity-vertical-offer-dropdown.component.html',
  styleUrls: ['./commodity-vertical-offer-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommodityVerticalOfferDropdownComponent implements OnInit {
  @Input() content?: CommodityOfferLabels;
  @Input() offer?: Offer;
  @Input() expanded = false;
  @Output() viewTariffs = new EventEmitter<void>();
  @Output() collapse = new EventEmitter<void>();
  @Output() expand = new EventEmitter<void>();

  electricityDropdownVM?: EnergyTypeDropdownVM;
  gasDropdownVM?: EnergyTypeDropdownVM;

  ngOnInit(): void {
    const electricityData = this.offer?.offerOverviews.find(
      (overview) => overview.energyType === EnergyType.ELECTRICITY
    );
    const gasEnergyData = this.offer?.offerOverviews.find(
      (overview) => overview.energyType === EnergyType.GAS
    );

    this.electricityDropdownVM = electricityData
      ? getEnergyTypeDropdownVM(electricityData, TariffZoneElectricity.Zone1)
      : undefined;
    this.gasDropdownVM = gasEnergyData
      ? getEnergyTypeDropdownVM(gasEnergyData, TariffZoneGas.Zone1)
      : undefined;
  }

  toggle() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.expand.emit();
    } else {
      this.collapse.emit();
    }
  }

  get dynamicPricingExpectedDeliveryRateTitle() {
    return this.offer?.isDynamicProduct
      ? this.content?.dropdownDynamicPricingExpectedDeliveryRateTitle
      : undefined;
  }
}
