import type { ActionCreator } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import type {
  FunctionWithParametersType,
  ActionReducer,
} from '@ngrx/store/src/models';
import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';

import type { CollectionState } from './collection-state';
import { createInitialCollectionState } from './collection-state';
//techdebt use @ngrx/entity for this
interface CollectionStateActionConfig<T> {
  getAction: ActionCreator<
    string,
    FunctionWithParametersType<any, { actionId?: string }>
  >;
  successAction: ActionCreator<
    string,
    FunctionWithParametersType<any, { actionId?: string; payload: T }>
  >;
  errorAction: ActionCreator<
    string,
    FunctionWithParametersType<
      any,
      { actionId?: string; payload: HttpErrorResponse }
    >
  >;
  clearAction: ActionCreator<
    string,
    FunctionWithParametersType<any, { actionId?: string }>
  >;
}

export function createCollectionStateReducer<T>(
  config: CollectionStateActionConfig<T>,
  initialState: CollectionState<T> = createInitialCollectionState()
) {
  const reducer = createReducer<CollectionState<T>>(
    initialState,
    on(config.getAction, (state, action) => {
      const entityId = action.actionId || 'unknown';
      const pendingEntities = {
        ...state.entities,
        [entityId]: {
          status: Status.PENDING,
          id: entityId,
        },
      };
      return {
        ...state,
        entities: pendingEntities,
        ids: Object.keys(pendingEntities),
      };
    }),
    on(config.successAction, (state, action) => {
      const entityId = action.actionId || 'unknown';
      const entities = {
        ...state.entities,
        [entityId]: {
          status: Status.SUCCESS,
          id: entityId,
          entry: action.payload,
        },
      };
      return {
        ...state,
        entities,
        ids: Object.keys(entities),
      };
    }),
    on(config.errorAction, (state, action) => {
      const entityId = action.actionId || 'unknown';
      const failureActionEntities = {
        ...state.entities,
        [entityId]: {
          status: Status.ERROR,
          id: entityId,
          error: action.payload,
        },
      };
      return {
        ...state,
        entities: failureActionEntities,
        ids: Object.keys(failureActionEntities),
      };
    }),
    on(config.clearAction, (state, action) => {
      const entityId = action.actionId;
      if (entityId == null) {
        return initialState;
      } else {
        const entities = { ...state.entities };
        delete entities[entityId];

        const ids = Object.keys(entities);

        return { entities, ids };
      }
    })
  );

  return { reducer, initialState };
}

export type CollectionStateType<T> = T extends ActionReducer<
  CollectionState<infer I>
>
  ? CollectionState<I>
  : never;
