import { Component, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';

import { MinimalHeaderContainerComponent } from '../../shared/minimal-header';

@Component({
  selector: 'wl-minimal-sticky-header-container',
  templateUrl: './minimal-sticky-header-container.component.html',
  styleUrls: [],
})
export class MinimalStickyHeaderContainerComponent extends MinimalHeaderContainerComponent {
  @HostBinding('class')
  class = 'd-block sticky-top';

  constructor(readonly store$: Store<any>) {
    super(store$);
  }
}
