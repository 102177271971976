import {
  getAddressCheck,
  getAddressCheckError,
  getAddressCheckSuccess,
} from '@essent/address';
import { RequestStrategy } from '@essent/common';
import { ofControlId } from '@innogy/utils/deprecated';
import type { Actions } from '@ngrx/effects';
import { createEffect, ofType } from '@ngrx/effects';
import type { MemoizedSelector, Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import {
  ClearAsyncErrorAction,
  MarkAsTouchedAction,
  SetUserDefinedPropertyAction,
  SetValueAction,
  StartAsyncValidationAction,
} from 'ngrx-forms';
import { merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import type { AddressControlIds, AddressFormState } from './address-form.types';
import { AddressTypes } from './address-form.types';

export const autoCompleteIsValid = (formState: AddressFormState) =>
  formState.controls.postalCode?.isValid &&
  formState.controls.communicationNumber?.isValid;

interface AddressFormOptions {
  skipAddressCheckOnPrefilledData: boolean;
}
const defaultOptions: AddressFormOptions = {
  skipAddressCheckOnPrefilledData: false,
};

/**
 * Create an anonymous address class due to angular and ngrx knowing somehow how to merge a different implementation of the class when instantiation twice.
 * @returns
 */
export function createAddressClassEffects() {
  return class {
    readonly asyncValidation = 'form-autocompletion';
    readonly addressValidationResultKey = 'ADDRESS_VALIDATION_RESULT';
    readonly clearAddressValidationAction = new ClearAsyncErrorAction(
      this.controlIds.addressFormId,
      this.asyncValidation
    );
    readonly clearAddressValidationResultAction =
      new SetUserDefinedPropertyAction(
        this.controlIds.addressFormId,
        this.addressValidationResultKey,
        undefined
      );

    readonly correspondenceAddress$ = this.store$.pipe(
      select((state) => this.formStateSelector(state)),
      map((address) => address.value)
    );

    readonly houseNumber$ = this.actions$.pipe(
      ofType<SetValueAction<number>>(SetValueAction.TYPE),
      ofControlId(this.controlIds.communicationNumberControlId),
      withLatestFrom(this.correspondenceAddress$, (_, address) => address),
      map((address) => address.communicationNumber),
      distinctUntilChanged()
    );

    readonly postCode$ = this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      ofControlId(this.controlIds.postalCodeControlId),
      withLatestFrom(this.correspondenceAddress$, (_, address) => address),
      map((address) => address.postalCode),
      distinctUntilChanged()
    );

    readonly autoCompleteFields$ = merge(
      this.houseNumber$,
      this.postCode$
    ).pipe(debounceTime(250));

    readonly addressCompletionValid$ = this.store$.pipe(
      select((state) => this.formStateSelector(state)),
      map(autoCompleteIsValid)
    );

    readonly isAddressDirty$ = this.store$.pipe(
      select((state) => this.formStateSelector(state)),
      map((formState: AddressFormState) => formState.isDirty)
    );

    loadAddress$ = createEffect(() =>
      this.autoCompleteFields$.pipe(
        withLatestFrom(
          this.addressCompletionValid$,
          this.correspondenceAddress$,
          this.isAddressDirty$,
          (_, isValid, address, isDirty) => ({ isValid, address, isDirty })
        ),
        filter(
          ({ isValid, address }) =>
            isValid && address.addressType === AddressTypes.ADDRESS
        ),
        mergeMap(({ address, isDirty }) =>
          isDirty || !this.options.skipAddressCheckOnPrefilledData
            ? [
                new StartAsyncValidationAction(
                  this.controlIds.addressFormId,
                  this.asyncValidation
                ),
                new SetValueAction(this.controlIds.streetControlId, ''),
                new SetValueAction(this.controlIds.cityControlId, ''),
                getAddressCheck({
                  actionId: this.controlIds.addressFormId,
                  requestStrategy: RequestStrategy.CANCEL,
                  payload: {
                    postcode: address.postalCode,
                    houseNumber: address.communicationNumber?.toString() ?? '',
                  },
                }),
              ]
            : [
                this.clearAddressValidationAction,
                this.clearAddressValidationResultAction,
              ]
        )
      )
    );

    loadAddressError$ = createEffect(() =>
      this.actions$.pipe(
        ofType(getAddressCheckError),
        filter(
          ({ actionId, payload }) =>
            actionId === this.controlIds.addressFormId && payload.status !== 404
        ),
        mergeMap(() => [
          new SetUserDefinedPropertyAction(
            this.controlIds.addressFormId,
            this.addressValidationResultKey,
            'ERROR'
          ),
          this.clearAddressValidationAction,
        ])
      )
    );

    loadAddressNotFound$ = createEffect(() =>
      this.actions$.pipe(
        ofType(getAddressCheckError),
        filter(
          ({ actionId, payload }) =>
            actionId === this.controlIds.addressFormId && payload.status === 404
        ),
        mergeMap(() => [
          new SetUserDefinedPropertyAction(
            this.controlIds.addressFormId,
            this.addressValidationResultKey,
            'NOT_FOUND'
          ),
          this.clearAddressValidationAction,
        ])
      )
    );

    loadAddressSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(getAddressCheckSuccess),
        filter(({ actionId }) => actionId === this.controlIds.addressFormId),
        mergeMap((action) => [
          new SetValueAction(
            this.controlIds.streetControlId,
            action.payload.street
          ),
          new SetValueAction(
            this.controlIds.cityControlId,
            action.payload.city
          ),
          // Marking the fields as touched ensures they are visually updated as well
          new MarkAsTouchedAction(this.controlIds.streetControlId),
          new MarkAsTouchedAction(this.controlIds.cityControlId),
          new SetUserDefinedPropertyAction(
            this.controlIds.addressFormId,
            this.addressValidationResultKey,
            'FOUND'
          ),
          this.clearAddressValidationAction,
        ])
      )
    );

    constructor(
      readonly actions$: Actions,
      readonly store$: Store,
      readonly controlIds: AddressControlIds,
      readonly formStateSelector: MemoizedSelector<any, AddressFormState>,
      readonly options: AddressFormOptions
    ) {}
  };
}

export function createAddressEffects(
  actions: Actions,
  store: Store,
  controlIds: AddressControlIds,
  formStateSelector: MemoizedSelector<any, AddressFormState>,
  options?: Partial<AddressFormOptions>
) {
  const addressClass = createAddressClassEffects();
  return new addressClass(actions, store, controlIds, formStateSelector, {
    ...defaultOptions,
    ...options,
  });
}
